import React from 'react';
import { toast } from 'react-toastify';
import Rodal from 'rodal';
import { MainUrl } from '../../utils/helper';
import useData from '../../ContextApi/UseData/useData';

const AddRelationShip = ({modalData}) => {
    const {show,setShow,update,setUpdate,} = modalData;
    const {token} = useData().user

const handlePostModal = (e)=>{
    e.preventDefault()
    const form = e.target;
    const name = form.name.value;
    const fromData = {
        name:name
    }

    const postModalData = async () => {
        const res = await fetch(`${MainUrl.url}/api/v1//user/emergency`, {
            method: "Post",
            headers: {
                "Content-Type": "application/json",
                "Authorization": token
            },
            body: JSON.stringify(fromData),
        })
        const resData = await res.json();
        if (res.ok) {
            toast.success(resData.status)
            setUpdate(!update)
            setShow(!show)
        }
        else {
            toast.error(resData.message)
            setShow(!show)
        }
    };
    postModalData()
}

    return (
        <div>
            <Rodal
                visible={show}
                enterAnimation="flip"
                animation="fade"
                onClose={() => setShow(!show)}
                customStyles={{
                    height: "auto",
                    width: "40%",
                    bottom: "auto",
                    top: "10%",
                    transform: "translateY(-50%)",
                }}
            >
                <h5 className="text-xl border-b py-3">Add Emergency RelationShip</h5>

                <div>
                    <form
                        onSubmit={handlePostModal}
                        className='pt-4' >

                        <div className='flex w-full '>
                            <input type='text' className='border-2 ps-2 py-1 rounded-s-md border-gray-200 focus-visible:outline-none w-4/5' name='name' placeholder='name' required />
                            <input className='cursor-pointer px-4 rounded-e-md bg-cyan-700  text-white font-medium text-sm w-1/5' type='submit' value="Submit" />
                        </div>

                    </form>
                </div>
            </Rodal>
        </div>
    );
};

export default AddRelationShip;
// import React, { useEffect } from "react";
// import useData from "../../ContextApi/UseData/useData";
// import { useNavigate } from "react-router-dom";
// import DashboardLayout from "../Layout/DashboardLayout";
// import LogIn from "../LogIn/LogIn";

// const ProtectRoute = () => {
//   const { token } = useData().user;
//   const navigate = useNavigate();
//   useEffect(() => {
//     if (token) {
//       navigate("/");
//     }
//   }, [token]);
//   return (
//     <div>
//       {token?.length > 10 ? (
//         <DashboardLayout />
//       ) : (
//         <LogIn />
//       )}
//     </div>
//   );
// };

import React from "react";

import { Navigate, useLocation } from "react-router-dom";
import useData from "../../ContextApi/UseData/useData";
import "../Loader/Loader.css"
const ProtectRoute = ({ children }) => {
  const { token, loader } = useData().user;
  const location = useLocation();

  if (loader) {
    return (
      <div id="loading-bar-spinner" className="spinner">
        <div className="spinner-icon"></div>
      </div>
    );
  }
  if (!token) {
    return <Navigate to="/login" state={{ from: location }} />;
  }
  return children;
};

export default ProtectRoute;

// export default ProtectRoute;

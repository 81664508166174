import React from 'react'
import Breadcrumbs from '../Breadcrumbs/Breadcrumbs';
import AllPaySlip from '../ProvidentFoundAccount/AllPaySlip';

const AllPaySlipPage = () => {
    const data = [
        {
          name: "All Pay-Slip Table",
        },
      ];
  return (
    <div>
        <Breadcrumbs data={data}/>
        <AllPaySlip/>
    </div>
  )
}

export default AllPaySlipPage
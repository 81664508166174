import React from "react";
import SalariesTable from "./SalariesTable";
import Breadcrumbs from "../Breadcrumbs/Breadcrumbs";

const SalaryPage = () => {
  const data = [
    {
      name: "Salary-Table",
    },
  ];

  return (
    <>
      <Breadcrumbs data={data} />
      <SalariesTable />
    </>
  );
};

export default SalaryPage;

import { Dialog } from "primereact/dialog";
import React from "react";

const Details = ({ detailsData }) => {
  const { showDetails, setShowDetails, details } = detailsData;

  return (
    <div>
      <Dialog
        header="Project Proposal Details"
        visible={showDetails}
        onHide={() => setShowDetails(!showDetails)}
        style={{ width: "40vw" }}
        breakpoints={{ "960px": "75vw", "641px": "100vw" }}
        draggable={false}
        resizable={false}
      >
        <div>
          <p className="test-base">
            <span className="font-medium">Project Name: </span>
            {details?.project_title}
          </p>
          <p className="test-base pt-2">
            <span className="font-medium">Description: </span>{" "}
            {details?.details}
          </p>
        </div>
      </Dialog>
    </div>
  );
};

export default Details;

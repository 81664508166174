import React, { useState } from "react";
import { toast } from "react-toastify";
import { MainUrl } from "../../utils/helper";
import useData from "../../ContextApi/UseData/useData";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";

const BonusPolicyModal = (bonusPolicyModalData) => {
  const { showBonusPolicy, setShowBonusPolicy, bonusType, update, setUpdate } =
    bonusPolicyModalData?.data;
  const { token } = useData().user;
  const [selectBonusType, setSelectBonusType] = useState(null);

  const bonus = bonusType?.map((user) => {
    let obj = {};
    obj.label = user?.name;
    obj.value = user?.id;
    return obj;
  });

  const handleBonusMOdal = (e) => {
    e.preventDefault();
    const from = e.target;
    const bonus = selectBonusType;
    const percentage = from.percentage.value;

    const data = {
      bonus_type_id: bonus,
      percentage,
    };

    const postModalData = async () => {
      const res = await fetch(`${MainUrl.url}/api/v1/bonus/policies`, {
        method: "Post",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
        body: JSON.stringify(data),
      });
      const resData = await res.json();
      if (res.ok) {
        setUpdate(!update);
        toast.success(resData.message);
        setShowBonusPolicy(!showBonusPolicy);
        setSelectBonusType(null);
      } else {
        toast.error(resData.message);
        setShowBonusPolicy(!showBonusPolicy);
        setSelectBonusType(null);
      }
    };
    postModalData();
  };

  return (
    <div>
      <Dialog
        visible={showBonusPolicy}
        header="Create Bonus Policy"
        onHide={() => setShowBonusPolicy(!showBonusPolicy)}
        style={{ width: "30vw" }}
        breakpoints={{ "960px": "75vw", "641px": "100vw" }}
        resizable={false}
        draggable={false}
      >
        <div>
          <form onSubmit={handleBonusMOdal} className="common">
            <div className="flex flex-col ">
              <label className="ps-2 pb-1">Select Bonus Type</label>

              <Dropdown
                value={selectBonusType}
                onChange={(e) => setSelectBonusType(e.value)}
                options={bonus}
                optionLabel="label"
                placeholder="Select a Bonus Type"
                filter
                className="w-full"
              />
            </div>
            <div className="flex flex-col pt-2">
              <label className="ps-2 pb-1">Percentage</label>
              <input
                type="number"
                className="border-2 border-gray-200 ps-2 py-0.5 rounded-sm  focus-visible:outline-none"
                name="percentage"
                placeholder="Percentage"
                step="any"
              />
            </div>
            <input
              className="cursor-pointer px-4 py-2 mt-4 bg-cyan-700 rounded-md text-white font-medium text-sm"
              type="submit"
              value="Submit"
            />
          </form>
        </div>
      </Dialog>
    </div>
  );
};

export default BonusPolicyModal;

import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import React, { useEffect, useState } from "react";
import { IoIosRefresh } from "react-icons/io";
import { toast } from "react-toastify";
import { MainUrl, showDummyImage } from "../../utils/helper";
import useData from "../../ContextApi/UseData/useData";
import Loader from "../Loader/Loader";
import { Dropdown } from "primereact/dropdown";
import { useLocation, useSearchParams } from "react-router-dom";
import Pagination from "../Pagination/Pagination";

const OverTime = () => {
  const { token } = useData().user;
  const [overTime, setOverTime] = useState(null);
  const [update, setUpdate] = useState(false);
  const [totalData, setTotalData] = useState(null);
  const [filterData, setFilterData] = useState({ name: "", month: "" });
  const [changeValue, setChangeValue] = useState({});
  const [isLoader, setIsLoader] = useState(false);
  const [selectedMonth, setSelectedMonth] = useState(null);

  const [viewData, setViewData] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchParams, setSearchParams] = useSearchParams();
  const [pageCount, setPageCount] = useState();
  const location = useLocation();

  const Months = [
    { label: "January", value: "January" },
    { label: "February", value: "February" },
    { label: "March", value: "March" },
    { label: "April", value: "April" },
    { label: "May", value: "May" },
    { label: "June", value: "June" },
    { label: "July", value: "July" },
    { label: "August", value: "August" },
    { label: "September", value: "September" },
    { label: "October", value: "October" },
    { label: "November", value: "November" },
    { label: "December", value: "December" },
  ];

  useEffect(() => {
    if (!token) {
      return;
    }

    const page = location?.search?.split("=")[1];
    if (page) {
      setPageNumber(+page);
      setCurrentPage(+page);
    }
    const fetchData = async () => {
      try {
        setIsLoader(true);
        const res = await fetch(
          `${MainUrl.url}/api/v1/overtime/all/${viewData}/${pageNumber}?${
            filterData.name && `name=${filterData.name}`
          }${filterData.month && `month=${filterData.month}`}`,
          {
            method: "get",
            headers: {
              Authorization: token,
            },
          }
        );
        if (res.ok && res.status === 200) {
          const resData = await res.json();
          setOverTime(resData.data?.results);
          setTotalData(resData.data?.totalData);
          setIsLoader(false);
        } else if (res.status === 204) {
          setOverTime([]);
          setIsLoader(false);
        }
      } catch (error) {
        toast.error(error?.message);
        setIsLoader(false);
      }
    };
    fetchData();
  }, [
    pageNumber,
    viewData,
    token,
    filterData,
    update,
    location,
    searchParams,
    currentPage,
  ]);

  useEffect(() => {
    const pages = Math.ceil(totalData / viewData);
    setPageCount(pages);
  }, [totalData, viewData]);

  const EmployeeNameTemplate = (data) => {
    return (
      <div className="flex items-center gap-x-4">
        <div className="h-10 w-10  ">
          <img
            src={`${MainUrl.url}${data?.user?.user_profile?.profile_photo}`}
            onError={({ currentTarget }, imageSize = "100x100") =>
              showDummyImage({ currentTarget }, imageSize)
            }
            alt=""
            className=" h-full w-full rounded-full"
          />
        </div>
        <h3 className="text-lg font-medium">
          {data?.user?.user_profile?.name}
        </h3>
      </div>
    );
  };

  const MonthTemplate = (data) => {
    return <p className="text-base font-medium">{data.month}</p>;
  };

  const HourTemplate = (data) => {
    return <p className="text-base font-medium">{data?.total_hours}</p>;
  };
  const TotalAmountTemplate = (data) => {
    return <p className="text-base font-medium">{data?.total_amount}</p>;
  };

  const YearTemplate = (data) => {
    return <p className="text-base font-medium">{data?.year}</p>;
  };

  const renderHeader = () => {
    const setValue = (e) => {
      setChangeValue({
        ...changeValue,
        [e.target?.name]: e.target.value,
      });
    };

    const handleFresh = () => {
      setFilterData({
        name: "",
        month: "",
      });
      setChangeValue({ name: "", month: "" });
      setUpdate(!update);
    };
    const handleSearch = async (e) => {
      e.preventDefault();
      const data = {
        month: selectedMonth ? selectedMonth : "",
        name: changeValue.name ? changeValue.name : "",
      };
      setFilterData(data);
    };

    return (
      <div className="grid grid-cols-12 items-center justify-between">
        <div className="col-span-8">
          <div className="grid grid-cols-12 gap-x-2 items-center">
            <div className="col-span-10">
              <form
                onSubmit={handleSearch}
                className="flex gap-x-3  w-full myInput"
              >
                <div className="w-full">
                  <input
                    onChange={(e) => setValue(e)}
                    className="ps-3 py-1 border border-gray-300 rounded inputShadow w-full"
                    placeholder="Search by Name"
                    name="name"
                    value={changeValue.name}
                  />
                </div>
                <div className="w-full">
                  <Dropdown
                    value={selectedMonth}
                    onChange={(e) => setSelectedMonth(e.value)}
                    options={Months}
                    optionLabel="label"
                    showClear
                    placeholder="Select a Month"
                    className="w-full"
                    name="month"
                  />
                </div>

                <div className="">
                  <button className="cursor-pointer px-4 py-2 w-full bg-cyan-700 rounded-md text-white font-medium text-sm">
                    Search
                  </button>
                </div>
              </form>
            </div>
            <div className="col-span-2 gap-x-2">
              <div>
                <span
                  onClick={handleFresh}
                  className="bg-cyan-700 text-white h-8 w-8 flex items-center justify-center rounded-full font-medium cursor-pointer"
                >
                  <IoIosRefresh />
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  const header = renderHeader();

  return (
    <div className="bg-white pb-4 mb-4">
      {isLoader ? (
        <Loader />
      ) : (
        <DataTable
          size="small"
          header={header}
          value={overTime}
          // footer={footerTemplate}
          selectionMode="single"
          emptyMessage="No employees found"
          dataKey="id"
          tableStyle={{ Width: "100%" }}
        >
          <Column
            field="user.user_profile.name"
            header="Name"
            body={EmployeeNameTemplate}
            sortable
            className=""
          ></Column>

          <Column
            field=""
            header="Month"
            body={MonthTemplate}
            sortable
            className=""
          ></Column>

          <Column
            field="hour_count"
            header="Total Hour"
            body={HourTemplate}
            sortable
            className=""
          ></Column>

          <Column
            field="total_amount"
            header="Total Amount"
            body={TotalAmountTemplate}
            sortable
            className=""
          ></Column>

          <Column
            field="year"
            header="Year"
            body={YearTemplate}
            sortable
            className=""
          ></Column>
        </DataTable>
      )}

      <div className="mt-4">
        {overTime?.length > 0 && (
          <Pagination
            currentPage={currentPage}
            pageNumber={pageNumber}
            setCurrentPage={setCurrentPage}
            setSearchParams={setSearchParams}
            pageCount={pageCount}
            setViewData={setViewData}
            viewData={viewData}
            setPageNumber={setPageNumber}
          />
        )}
      </div>
    </div>
  );
};

export default OverTime;

import React, { useEffect, useState } from "react";
import { AiOutlineCalendar } from "react-icons/ai";
import { BsCheckCircle, BsTelephoneX } from "react-icons/bs";
import { MdOutlinePolicy } from "react-icons/md";
import { TbCurrencyTaka } from "react-icons/tb";
import { toast } from "react-toastify";
import AmountModal from "./AmountModal";
import { MainUrl, showDummyImage } from "../../utils/helper";
import useData from "../../ContextApi/UseData/useData";

const MyAccount = () => {
  const [userData, setUserData] = useState(null);
  const [show, setShow] = useState(false);
  const { token } = useData().user;
  const [hidden, setHidden] = useState(false)

  useEffect(() => {
    if (!token) {
      return;
    }
    const fetchData = async () => {
      try {
        const res = await fetch(
          `${MainUrl.url}/api/v1/provident_fund/employee`,
          {
            method: "get",
            headers: {
              Authorization: token,
            },
          }
        );
        const data = await res.json();
        if (res.ok) {
          setUserData(data?.data);
        }
      } catch (error) {
        toast.error(error.message);
      }
    };
    fetchData();
  }, [token]);

  const modal = {
    show,
    setShow,
    hidden,
    setHidden
  };

  return (
    <div className="mt-10 ">
      <div className="bg-white px-2 py-4">
        <div className="flex justify-end">
          <button
            onClick={() => {
              setShow(!show)
              setHidden(!hidden)
            }}
            className="border px-4 py-2 bg-cyan-700 rounded text-white"
          >
            Cash Withdraw
          </button>
        </div>
        <div className="grid grid-cols-12 gap-x-4 mt-4">
          <div className="col-span-3 px-4 py-4 flex justify-center items-center shadow-sm rounded-lg border">
            <div>
              <div className=" h-[300px] w-[300px]">
                {console.log(`${MainUrl}${userData?.user?.user_profile?.profile_photo}`)}
                <img
                  src={`${MainUrl.url}${userData?.user?.user_profile?.profile_photo}`}
                  onError={({ currentTarget }, imageSize = "100x100") =>
                    showDummyImage({ currentTarget }, imageSize)
                  }
                  alt=""
                  className=" h-full w-full rounded-full"
                />
              </div>
              <div className="flex flex-col items-center">
                <div className=" text-2xl">
                  <p>{userData?.user?.user_profile?.name}</p>
                </div>
                <div className="flex items-center text-lg pt-1">
                  <p>Office ID:</p>
                  <p>{userData?.user?.office_id}</p>
                </div>
                <div className="flex items-center text-lg pt-1">
                  <p>Office ID:</p>
                  <p>{userData?.user?.email}</p>
                </div>
              </div>
            </div>
          </div>

          <div className="col-span-9 px-4 py-4 text-xl shadow-sm  rounded-lg border">
            <div className="flex items-center">
              <div className="flex items-center gap-x-2">
                <MdOutlinePolicy />
                <p className="w-[140px]">Policy:</p>
              </div>
              <p>{userData?.provident_fund_policy?.package}</p>
            </div>

            <div className="flex items-center pt-2">
              <div className="flex items-center gap-x-2">
                <TbCurrencyTaka />
                <p className="w-[140px]">Employee:</p>
              </div>
              <p>{userData?.employee_amount}/-</p>
            </div>

            <div className="flex items-center pt-2">
              <div className="flex items-center gap-x-2">
                <TbCurrencyTaka />
                <p className="w-[140px]">Company:</p>
              </div>
              <p>{userData?.company_amount}/-</p>
            </div>

            <div className="flex items-center pt-2">
              <div className="flex items-center gap-x-2">
                <TbCurrencyTaka />
                <p className="w-[140px]">Total</p>
              </div>
              <p>{userData?.total_amount}/-</p>
            </div>

            <div className="flex items-center pt-2">
              <div className="flex items-center gap-x-2">
                <TbCurrencyTaka />
                <p className="w-[140px]">Withdrawable</p>
              </div>
              <p>{userData?.withdrawable}/-</p>
            </div>

            <div className="flex items-center pt-2">
              <div className="flex items-center gap-x-2">
                <BsCheckCircle />
                <p className="w-[140px]">Eligibility</p>
              </div>
              <p>{userData?.eligibility === 0 ? "Not Eligible" : "Eligible"}</p>
            </div>

            <div className="flex items-center pt-2">
              <div className="flex items-center gap-x-2">
                <AiOutlineCalendar />
                <p className="w-[140px]">Opening</p>
              </div>
              <p>{userData?.opening_date}</p>
            </div>

            <div className="flex items-center pt-2">
              <div className="flex items-center gap-x-2">
                <BsTelephoneX />
                <p className="w-[140px]">Phone</p>
              </div>
              <p>{userData?.user?.user_profile?.phone_no}</p>
            </div>
          </div>
        </div>
      </div>
      {hidden&& <AmountModal modal={modal} />}
    </div>
   
  );
};

export default MyAccount;

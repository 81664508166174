import { Menu, MenuButton, MenuItem } from "@szhsin/react-menu";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import React, { useEffect, useState } from "react";
import { AiFillEdit, AiOutlineDelete, AiOutlinePlus } from "react-icons/ai";
import { BsThreeDotsVertical } from "react-icons/bs";
import { toast } from "react-toastify";
import CreateModal from "./CreateModal";
import UpdateModal from "./UpdateModal";
import useData from "../../ContextApi/UseData/useData";
import { MainUrl } from "../../utils/helper";
import Loader from "../Loader/Loader";
import DeleteModal from "../AllEmployee/DeleteModal/DeleteModal";

const ProgressStatus = () => {
  const { token } = useData().user;
  const [update, setUpdate] = useState(false);
  const [projectRole, setProjectRole] = useState(null);
  const [show, setShow] = useState(false);
  const [showUpdate, setShowUpdate] = useState(false);
  const [updateModaldata, setUpdateModalData] = useState(null);
  const [isLoader, setIsLoader] = useState(false);
  // Crete Modal
  const CreateURL = `/project/progress_status`;
  const createTham = "Create A New Project Progress Status";
  const CreatePlaceholder = "Progress Status";
  const successMessage = "successfully Create A Project Progress";

  // Update Modal
  const UpdateURL = "project/progress_status";
  const UpdateThem = "Update A New Project Progress Status";
  const successUpdateMessage = "successfully Update AProject Progress Status";

const [selectData, setSelectData] = useState(null)
const [showDelete, setShowDelete] = useState(false)

  useEffect(() => {
    if (!token) {
      return;
    }
    const fetchData = async () => {
      try {
        setIsLoader(true);
        const res = await fetch(
          `${MainUrl.url}/api/v1/project/progress_status`,
          {
            method: "get",
            headers: {
              Authorization: token,
            },
          }
        );
        const data = await res.json();
        if (res.ok) {
          console.log("data--->1", data);
          setProjectRole(data.data);
          setIsLoader(false);
        }
      } catch (error) {
        toast.error(error?.message);
        setIsLoader(false);
      }
    };
    fetchData();
  }, [update, token]);

  const projectRoleTemplate = (data) => {
    return <p className="text-base">{data.name}</p>;
  };

  const ActionTemplate = (data) => {
    return (
      <Menu
        direction="right"
        align="center"
        menuButton={
          <MenuButton className="px-4">
            <BsThreeDotsVertical />
          </MenuButton>
        }
        transition
      >
        <MenuItem
          className="hover:text-red-400"
          onClick={() => {
            setUpdateModalData(data);
            setShowUpdate(!showUpdate);
          }}
        >
          <span className="mr-2">
            <AiFillEdit />
          </span>
          UPdate
        </MenuItem>

        <MenuItem
          className="hover:text-red-400"
          onClick={() =>{
            setSelectData(data);
            setShowDelete(!showDelete)
          }}
        >
          <span>
            <AiOutlineDelete className="mr-2" />
          </span>
          Delete
        </MenuItem>
      </Menu>
    );
  };

  const renderHeader = () => {
    return (
      <div className="grid grid-cols-12 justify-between">
        <div className="col-span-6">
          <p>Project Status</p>
        </div>
        <div className="col-span-6">
          <div className="flex items-end justify-end">
            <button
              onClick={() => setShow(!show)}
              className="flex items-center gap-x-2  px-2 py-2.5 bg-cyan-700 text-white rounded-md"
            >
              <span className="text-base font-semibold">
                <AiOutlinePlus />
              </span>
              <span className="text-base font-medium">Project progress</span>
            </button>
          </div>
        </div>
      </div>
    );
  };
  const header = renderHeader();

  const modaldata = {
    setShow,
    show,
    update,
    setUpdate,
    CreateURL,
    createTham,
    CreatePlaceholder,
    successMessage,
  };

  const updateData = {
    update,
    setUpdate,
    showUpdate,
    setShowUpdate,
    updateModaldata,
    UpdateURL,
    UpdateThem,
    successUpdateMessage,
  };

  const DeleteUrl = "api/v1/project/progress_status";
  const DeleteData = {
    showDelete,
    setShowDelete,
    update,
    setUpdate,
    selectData,
    DeleteUrl,
  }

  return (
    <div className="border-top-none">
      {isLoader ? (
        <Loader />
      ) : (
        <DataTable
          paginator
          rows={5}
          rowsPerPageOptions={[5, 10, 25, 50]}
          size="small"
          header={header}
          value={projectRole}
          selectionMode="single"
          dataKey="id"
          tableStyle={{ Width: "100%" }}
        >
          <Column
            field=""
            header="Project Status"
            body={projectRoleTemplate}
            sortable
          ></Column>

          <Column
            field=""
            header="Action"
            body={ActionTemplate}
          ></Column>
        </DataTable>
      )}

      <CreateModal modaldata={modaldata} />
      {<UpdateModal updateData={updateData} />}
      {<DeleteModal DeleteData={DeleteData}/>}
    </div>
  );
};

export default ProgressStatus;

import React from "react";
import Breadcrumbs from "../Breadcrumbs/Breadcrumbs";
import MobileAllowance from "../MobileAllowance/MobileAllowance";

const GenMobileAllowancePage = () => {
  const data = [
    {
      name: "Mobile Allowance",
    },
  ];
  return (
    <>
      <Breadcrumbs data={data} />
      <MobileAllowance />
    </>
  );
};

export default GenMobileAllowancePage;

import React from "react";
import Breadcrumbs from "../Breadcrumbs/Breadcrumbs";
import ProvidentFund from "../ProvidentFund/ProvidentFund";

const GenProvidentFoundPage = () => {
  const data = [
    {
      name: "provident Found",
    },
  ];
  return (
    <>
      <Breadcrumbs data={data} />
      <ProvidentFund />
    </>
  );
};

export default GenProvidentFoundPage;

import React, { useState, useEffect } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { AiFillEdit, AiOutlineDelete } from "react-icons/ai";
import { BsFiletypeCsv } from "react-icons/bs";
import { IoIosRefresh } from "react-icons/io";
// import { GrFormPrevious, GrFormNext } from "react-icons/gr";
import { Dialog } from "primereact/dialog";
import { Tooltip } from "primereact/tooltip";
import { CSVLink } from "react-csv";
import "./SalariesTable.css";
import { toast } from "react-toastify";
import Loader from "../Loader/Loader";
import { MainUrl, MonthValueSame, showDummyImage } from "../../utils/helper";
import useData from "../../ContextApi/UseData/useData";
import { Dropdown } from "primereact/dropdown";
import DeleteModal from "../AllEmployee/DeleteModal/DeleteModal";
import Pagination from "../Pagination/Pagination";
import { useLocation, useSearchParams } from "react-router-dom";
import moment from "moment";

const SalariesTable = () => {
  const { token } = useData().user;
  const [employees, setEmployees] = useState([]);
  const [expandedRows, setExpandedRows] = useState([]);
  // const [totalPages, setTotalPages] = useState("");
  const [visible, setVisible] = useState(false);
  const [employeeModal, setEmployeeModal] = useState(null);
  const [month, setMonth] = useState("");
  const [update, setUpdate] = useState(false);
  const [viewData, setViewData] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);
  const [currentPage, setCurrentPage] = useState(1); 
  const [totalData, setTotalData] = useState(null);
  const [filterData, setFilterData] = useState({
    name: "",
    office_id: "",
    month: "",
    year: "",
  });
  const [changeValue, setChangeValue] = useState({});
  const [isLoader, setIsLoader] = useState(false);
  const [selectedMonth, setSelectedMonth] = useState(null);
  const [showDelete, setShowDelete] = useState(false);
  const [selectData, setSelectData] = useState(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const [pageCount, setPageCount] = useState();
  const location = useLocation();

  // get table Data
  useEffect(() => {
    if (!token) {
      return;
    }
    const page = location?.search?.split("=")[1];
    if (page) {
      setPageNumber(+page);
      setCurrentPage(+page);
    }
    const fetchData = async () => {
      const res = await fetch(
        `${MainUrl.url}/api/v1/salaries/all/${viewData}/${pageNumber}?${
          filterData.name && `name=${filterData.name}`
        }${filterData.office_id && `&office_id=${filterData.office_id}`}${
          filterData.month && `&month=${filterData.month}`
        }${filterData.year && `&year=${filterData.year}`}`,
        {
          method: "Get",
          headers: {
            Authorization: token,
          },
        }
      );

      console.log("res", res);
      setIsLoader(true);
      if (res.ok && res.status !== 204) {
        const data = await res.json();
        setEmployees(data.data?.salaries);
        setTotalData(data.data?.totalCount);
        setIsLoader(false);
      } else if (res.status === 204) {
        setEmployees([]);
        setTotalData([]);
        setIsLoader(false);
      } else {
        setEmployees([]);
        setTotalData([]);
        setIsLoader(false);
      }
    };
    fetchData();
  }, [
    update,
    viewData,
    pageNumber,
    token,
    filterData,
    location,
    searchParams,
    currentPage,
  ]);

  // get total page number
  useEffect(() => {
    const pages = Math.ceil(totalData / viewData);
    setPageCount(pages);
  }, [totalData, viewData]);

  const allowExpansion = (rowData) => {
    return rowData;
  };

  const onRowToggle = (event) => {
    setExpandedRows(event.data);
  };

  const renderHeader = () => {
    const processesData = employees?.map((employee) => {
      employee.name = employee?.user?.user_profile?.name;
      employee.OfficeId = employee?.user?.office_id;
      return employee;
    });

    const headers = [
      { label: "Year", key: "year" },
      { label: "Month", key: "month" },
      { label: "Name", key: "name" },
      { label: "Office ID", key: "OfficeId" },
      { label: "Basic", key: "basic" },
      { label: "Over Time", key: "overtime" },
      { label: "Bonus", key: "bonus" },
      { label: "Deduction", key: "deduction" },
      { label: "Facilities", key: "facilities" },
      { label: "Special allowance", key: "special_allowance" },
      { label: "Total", key: "total" },
    ];

    const setValue = (e) => {
      setChangeValue({
        ...changeValue,
        [e?.target?.name]: e?.target?.value,
      });
    };

    // handle Refresh button
    const handleFresh = () => {
      setSelectedMonth(null);
      setChangeValue({
        name: "",
        office_id: "",
        year: "",
        month: "",
      });
      setFilterData({
        name: "",
        office_id: "",
        year: "",
        month: "",
      });
      setUpdate(!update);
    };

    const handleSearch = (e) => {
      e.preventDefault();
      const data = {
        name: changeValue.name ? changeValue.name : "",
        office_id: changeValue.office_id ? changeValue.office_id : "",
        year: changeValue.year ? changeValue.year : "",
        month: selectedMonth ? selectedMonth : "",
      };
      setFilterData(data);
    };
    return (
      <div className="grid grid-cols-12 gap-4 common">
        <div className=" col-span-11 gap-x-4">
          <form className="grid grid-cols-12 gap-4" onSubmit={handleSearch}>
            <div className="col-span-11">
              <div className="grid grid-cols-4 gap-x-2">
                <div className="w-full">
                  <InputText
                    onChange={(e) => setValue(e)}
                    className="w-full"
                    value={changeValue.name}
                    placeholder="Search by Name"
                    name="name"
                  />
                </div>

                <div className="w-full">
                  <InputText
                    onChange={(e) => setValue(e)}
                    placeholder=" Search by Office ID"
                    value={changeValue.office_id}
                    name="office_id"
                    className="w-full"
                  />
                </div>

                <div className="w-full">
                  <InputText
                    onChange={(e) => setValue(e)}
                    placeholder=" Search by Year"
                    value={changeValue.year}
                    name="year"
                    className="w-full"
                  />
                </div>

                <div className="w-full">
                  <Dropdown
                    value={selectedMonth}
                    onChange={(e) => setSelectedMonth(e.value)}
                    options={MonthValueSame}
                    optionLabel="label"
                    showClear
                    placeholder="Select a Month"
                    className="w-full"
                    name="month"
                  />
                </div>
              </div>
            </div>

            <div className="col-span-1">
              <input
                type="submit"
                value="Search"
                className="cursor-pointer px-4 py-2 bg-cyan-700 rounded-md text-white font-medium text-sm"
              />
            </div>
          </form>
        </div>

        <div className="col-span-1 flex justify-between ">
          <div
            className=" bg-cyan-700 h-8 w-8 flex items-center justify-center rounded-full
                text-white font-medium cursor-pointer"
          >
            <CSVLink
              data={processesData}
              headers={headers}
              filename="salary.csv"
            >
              <Tooltip target=".logo" position="top" />
              <BsFiletypeCsv className="logo" data-pr-tooltip="Download CSV" />
            </CSVLink>
          </div>

          <div>
            <span
              onClick={handleFresh}
              className="bg-cyan-700 text-white h-8 w-8 flex items-center justify-center rounded-full font-medium cursor-pointer"
            >
              <IoIosRefresh />
            </span>
          </div>
        </div>
      </div>
    );
  };

  const EmployeeNameTemplate = (data) => {
    return (
      <div className="flex items-center gap-x-4">
        <div className="h-10 w-10  ">
          <img
            src={`${MainUrl.url}${data?.user?.user_profile?.profile_photo}`}
            onError={({ currentTarget }, imageSize = "100x100") =>
              showDummyImage({ currentTarget }, imageSize)
            }
            alt=""
            className=" h-full w-full rounded-full"
          />
        </div>
        <h3 className="text-lg ">{data.user.user_profile.name}</h3>
      </div>
    );
  };

  // Date and time format
  const dateTimeTemplate = (data) => {
    const date = data?.modified_at;
    const formatDate = moment(date).format("MM-DD-YYYY, h:mm a");
    return <p className="text-base">{formatDate}</p>;
  };

  const ActionTemplate = (data) => {
    return (
      <div className=" flex items-center gap-x-2">
        <div
          className="bg-cyan-700 h-8 w-8 flex items-center justify-center rounded-full cursor-pointer"
          onClick={() => {
            setVisible(true);
            setEmployeeModal(data);
          }}
        >
          <AiFillEdit className="text-white" />
        </div>
        <div
          className="bg-red-400 h-8 w-8 flex items-center justify-center rounded-full cursor-pointer"
          onClick={() => {
            setSelectData(data);
            setShowDelete(!showDelete);
          }}
        >
          <AiOutlineDelete className="text-white" />
        </div>
      </div>
    );
  };

  const basicTemplate = (data) => {
    return <p className="text-lg ">{data.basic}</p>;
  };

  const FacilitiesTemplate = (data) => {
    return <p className="text-lg ">{data.facilities}</p>;
  };

  const BonusTemplate = (data) => {
    return <p className="text-lg ">{data.bonus}</p>;
  };

  const overtimeTemplate = (data) => {
    return <p className="text-lg ">{data.overtime}</p>;
  };

  const deductionTemplate = (data) => {
    return <p className="text-lg ">{data.deduction}</p>;
  };

  const TotalTemplate = (data) => {
    return <p className="text-lg ">{data.total}</p>;
  };

  const OfficeIdTemplate = (data) => {
    return <p className="text-lg">{data.user.office_id}</p>;
  };

  const MonthTemplate = (data) => {
    return <p className="text-lg ">{data.month}</p>;
  };

  const YearTemplate = (data) => {
    return <p className="text-lg ">{data.year}</p>;
  };

  // ExpandData Show
  const rowExpansionTemplate1 = (data) => {
    let createArrey = [];
    createArrey.push(data);
    return (
      <div className="px-10">
        <DataTable
          size={"small"}
          value={createArrey}
          className=" text-black w-full"
        >
          <Column
            className="text-sm"
            body={basicTemplate}
            header="BASIC"
            style={{ width: "20%" }}
          ></Column>
          <Column
            className="text-sm"
            body={FacilitiesTemplate}
            header="FACILITIES"
            style={{ width: "20%" }}
          ></Column>
          <Column
            className="text-sm"
            body={BonusTemplate}
            header="BOUNS"
            style={{ width: "20%" }}
          ></Column>
          <Column
            className="text-sm"
            body={overtimeTemplate}
            header="OVER TIME"
            style={{ width: "20%" }}
          ></Column>
          <Column
            className="text-sm"
            body={deductionTemplate}
            header="DEDUCTION"
            style={{ width: "20%" }}
          ></Column>
          <Column
            className="text-sm"
            body={TotalTemplate}
            header="TOTAL"
            style={{ width: "20%" }}
          ></Column>
        </DataTable>
      </div>
    );
  };

  const header = renderHeader();

  // handle Update EmployeeInfo
  const handleUpdate = (e) => {
    e.preventDefault();
    const form = e.target;
    const year = form.year.value;
    const basic = form.basic.value;
    const facilities = form.facilities.value;
    const bonus = form.bonus.value;
    const overtime = form.overtime.value;
    const deduction = form.deduction.value;
    const total = form.total.value;

    const updateData = {
      year: year,
      month: month?.length > 0 ? month : employeeModal?.month,
      basic: basic,
      facilities: facilities,
      bonus: bonus,
      overtime: overtime,
      deduction: deduction,
      total: total,
    };

    // updateNote()
    let updateNote = async () => {
      const res = await fetch(
        `${MainUrl.url}/api/v1/salaries/one/${employeeModal?.id}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
          body: JSON.stringify(updateData),
        }
      );
      const resData = await res.json();
      if (res.ok) {
        setUpdate(!update);
        toast.success("successfully updated");
        setVisible(false);
      } else {
        toast.error(resData.message);
        setVisible(false);
      }
    };
    updateNote();
  };

  const DeleteUrl = "api/v1/salaries/one";
  const DeleteData = {
    showDelete,
    setShowDelete,
    update,
    setUpdate,
    selectData,
    DeleteUrl,
  };

  return (
    <div className="card myTable mb-5 bg-white pb-4">
      {isLoader ? (
        <Loader />
      ) : (
        <DataTable
          size={"small"}
          value={employees.length ? employees : []}
          header={header}
          expandedRows={expandedRows}
          onRowToggle={onRowToggle}
          rowExpansionTemplate={rowExpansionTemplate1}
          dataKey="id"
          emptyMessage="No employees found."
          style={{ minWidth: "50rem", textAlign: "center" }}
        >
          <Column expander={allowExpansion}></Column>
          <Column
            field="user.user_profile.name"
            body={EmployeeNameTemplate}
            header="EMPLOYEE"
            sortable
            style={{ minWidth: "10%" }}
            filterPlaceholder="Search by Employee Name"
          />
          <Column
            field="user.office_id"
            className="text-sm"
            body={OfficeIdTemplate}
            header="OFFICE ID"
            sortable
            style={{ minWidth: "10%" }}
            filterPlaceholder="Search by Office Id"
          />
          <Column
            field="month"
            className="text-sm"
            body={MonthTemplate}
            header="MONTH"
            filterField="month"
            sortable
            style={{ minWidth: "10%" }}
            filterPlaceholder="Search by Month"
          />
          <Column
            field="year"
            className="text-sm"
            body={YearTemplate}
            header="YEAR"
            sortable
            style={{ width: "15%" }}
            filterPlaceholder="Search by Year"
          />
          <Column
            className="text-sm"
            header="MOFIFIED AT"
            body={dateTimeTemplate}
            style={{ minWidth: "10%" }}
          />
          <Column
            className=""
            header="ACTION"
            body={ActionTemplate}
            style={{ minWidth: "10%" }}
          />
        </DataTable>
      )}
      <div className="mt-4">
        {employees?.length > 0 && (
          <Pagination
            currentPage={currentPage}
            pageNumber={pageNumber}
            setCurrentPage={setCurrentPage}
            setSearchParams={setSearchParams}
            pageCount={pageCount}
            setViewData={setViewData}
            viewData={viewData}
            setPageNumber={setPageNumber}
          />
        )}
      </div>

      {/* Update Modal */}
      <Dialog
        header="Update Salary"
        visible={visible}
        onHide={() => setVisible(false)}
        style={{ width: "40vw" }}
        breakpoints={{ "960px": "75vw", "641px": "100vw" }}
        draggable={false}
        resizable={false}
      >
        <div className="m-0 myCalender">
          <form onSubmit={handleUpdate}>
            <div className="flex flex-col gap-y-1 pt-1 w-full">
              <label htmlFor=" ">Employee Name</label>
              <InputText
                type="text"
                readOnly
                className="p-inputtext-sm"
                value={employeeModal?.user.user_profile.name}
                placeholder="Employee Name"
              />
            </div>
            <div className="flex lg:flex-row flex-col justify-between gap-x-3 pt-3">
              <div className="w-full">
                <label className="">Month</label>
                <select
                  onClick={(e) => setMonth(e.target.value)}
                  className=" block w-full py-1.5 px-2  border border-gray-400 rounded-md"
                >
                  <option
                    defaultValue={employeeModal?.month}
                    value={employeeModal?.month}
                    placeholder="Month"
                  >
                    {employeeModal?.month}
                  </option>
                  <option value="January">January</option>
                  <option value="February">February</option>
                  <option value="March">March</option>
                  <option value="April">April</option>
                  <option value="May">May</option>
                  <option value="June">June</option>
                  <option value="July">July</option>
                  <option value="August">August</option>
                  <option value="September">September</option>
                  <option value="October">October</option>
                  <option value="November">November </option>
                  <option value="December">December</option>
                </select>
              </div>
              <div className="flex flex-col  w-full">
                <label htmlFor=" ">Year</label>
                <InputText
                  defaultValue={employeeModal?.year}
                  placeholder="year"
                  name="year"
                />
              </div>
            </div>
            <div className="flex lg:flex-row flex-col justify-between gap-x-3">
              <div className="flex flex-col gap-y-1 pt-1 w-full">
                <label htmlFor="">Basic</label>
                <InputText
                  type="text"
                  defaultValue={employeeModal?.basic}
                  className="p-inputtext-sm"
                  placeholder="Basic"
                  name="basic"
                />
              </div>
              <div className="flex flex-col gap-y-1 pt-1 w-full">
                <label htmlFor="">Facilities</label>
                <InputText
                  type="text"
                  defaultValue={employeeModal?.facilities}
                  className="p-inputtext-sm"
                  placeholder="Facilities"
                  name="facilities"
                />
              </div>
            </div>
            <div className="flex lg:flex-row flex-col justify-between gap-x-3">
              <div className="flex flex-col gap-y-1 pt-1 w-full">
                <label htmlFor="">Bonus</label>
                <InputText
                  type="text"
                  defaultValue={employeeModal?.bonus}
                  className="p-inputtext-sm"
                  placeholder="Bonus"
                  name="bonus"
                />
              </div>
              <div className="flex flex-col gap-y-1 pt-1 w-full">
                <label htmlFor=" ">Over Time</label>
                <InputText
                  type="text"
                  defaultValue={employeeModal?.overtime}
                  className="p-inputtext-sm"
                  placeholder="Over Time"
                  name="overtime"
                />
              </div>
            </div>

            <div className="flex lg:flex-row flex-col justify-between gap-x-3">
              <div className="flex flex-col gap-y-1 pt-1 w-full">
                <label htmlFor=" ">Deduction</label>
                <InputText
                  type="text"
                  defaultValue={employeeModal?.deduction}
                  className="p-inputtext-sm "
                  placeholder="Deduction"
                  name="deduction"
                />
              </div>

              <div className="flex flex-col gap-y-1 pt-1 w-full">
                <label htmlFor="" className="font-medium text-green-500">
                  Total
                </label>
                <InputText
                  type="text"
                  defaultValue={employeeModal?.total}
                  className="p-inputtext-sm "
                  placeholder="Total"
                  name="total"
                />
              </div>
            </div>
            <input
              type="submit"
              value="Update"
              className="cursor-pointer mt-6 px-4 py-2 bg-cyan-700 rounded-md text-white font-medium text-base"
            />
          </form>
        </div>
      </Dialog>

      {<DeleteModal DeleteData={DeleteData} />}
    </div>
  );
};

export default SalariesTable;

import React, { useState } from "react";
import { toast } from "react-toastify";
import useData from "../../ContextApi/UseData/useData";
import { MainUrl } from "../../utils/helper";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";

const DeductionCreateModal = ({
  setShowAddModal,
  showAddModal,
  allUser,
  typeName,
  setUpdate,
  update,
}) => {
  const { token } = useData().user;
  const [selectUser, setSelectUser] = useState(null);
  const [selectDeductionType, setSelectDeductionType] = useState(null);

  const AllUsersOptions = allUser?.map((user) => {
    let obj = {};
    obj.label = user?.user_profile?.name;
    obj.value = user.id;
    return obj;
  });

  const deductionType = typeName?.map((type) => {
    let obj = {};
    obj.label = type.name;
    obj.value = type.id;
    return obj;
  });

  const handleCreateDeduction = (e) => {
    e.preventDefault();
    const form = e.target;
    const user_id = selectUser;
    const deduction_type_id = selectDeductionType;
    const total_amount = form.amount.value;

    const updateData = {
      user_id,
      deduction_type_id,
      total_amount,
    };

    if (!selectUser) {
      return toast.warn("Please Select a User");
    }

    if (!selectDeductionType) {
      return toast.warn("Please select a Deduction Type");
    }

    console.log("Update", updateData.total_amount.length);

    if (!updateData.total_amount) {
      return toast.warn("Please Enter Deduction Amount");
    }

    const updateNote = async () => {
      const res = await fetch(
        `${MainUrl.url}/api/v1/deduction_amount/all/10/0`,
        {
          method: "post",
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
          body: JSON.stringify(updateData),
        }
      );
      const resData = await res.json();
      if (res.ok) {
        toast.success("successfully updated");
        setShowAddModal(false);
        setUpdate(!update);
        setSelectDeductionType(null);
        setSelectUser(null);
        form.reset();
      } else {
        toast.error(resData.message);
        setShowAddModal(false);
        setSelectDeductionType(null);
        setSelectUser(null);
        form.reset();
      }
    };
    updateNote();
  };

  return (
    <Dialog
      header="Create New Deduction"
      visible={showAddModal}
      onHide={() => {
        setShowAddModal(!showAddModal);
        setSelectDeductionType(null);
        setSelectUser(null);
      }}
      style={{ width: "30vw" }}
      breakpoints={{ "960px": "75vw", "641px": "100vw" }}
      draggable={false}
      resizable={false}
    >
      <form className="pt-4" onSubmit={handleCreateDeduction}>
        <div className="flex flex-col ">
          <label>Select Employee</label>
          <Dropdown
            value={selectUser}
            onChange={(e) => setSelectUser(e.value)}
            options={AllUsersOptions}
            optionLabel="label"
            placeholder="Select a User"
            filter
            className="w-full md:w-14rem"
          />
        </div>

        <div className="pt-2 flex flex-col">
          <label>Select Deduction Type</label>
          <Dropdown
            value={selectDeductionType}
            onChange={(e) => setSelectDeductionType(e.value)}
            options={deductionType}
            optionLabel="label"
            placeholder="Select a Deduction Type"
            filter
            className="w-full md:w-14rem"
          />
        </div>
        <div className="pt-2 flex flex-col">
          <label>Total Amount</label>
          <input
            className="ps-3 py-1.5 border border-gray-300 rounded-lg inputShadow"
            placeholder="Total Amount"
            name="amount"
          />
        </div>
        <input
          className="cursor-pointer px-4 py-2 mt-4 bg-cyan-700 rounded-md text-white font-medium text-sm"
          type="submit"
          value="Submit"
        />
      </form>
    </Dialog>
  );
};

export default DeductionCreateModal;

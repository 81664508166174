import { createBrowserRouter } from "react-router-dom";
import LogIn from "../Component/LogIn/LogIn";
import ProvidentFoundAccount from "../Component/ProvidentFoundAccount/ProvidentFoundAccount";
import ErrorPage from "../Component/ErrorPage/ErrorPage";
import DashBoard from "../Component/DashBoard/DashBoard";
import ProtectRoute from "../Component/ProtectRoute/ProtectRoute";
import DashboardLayout from "../Component/Layout/DashboardLayout";
import SalaryPage from "../Component/SalariesTable/SalaryPage";
import DeductionPage from "../Component/DeductionTable/DeductionPage";
import AllEmployeePage from "../Component/AllEmployee/AllEmployeePage";
import AllPaySlipPage from "../Component/Pages/AllPaySlipPage";
import MyAccountPage from "../Component/Pages/MyAccountPage";
import MyPaySlipPage from "../Component/Pages/MyPaySlipPage";
import ClientPage from "../Component/Pages/ClientPage";
import BonusLogPage from "../Component/Pages/BonusLogPage";
import OverTimePage from "../Component/Pages/OverTimePage";
import AdminOverTimeLogPage from "../Component/Pages/AdminOverTimeLogPage";
import LeaveApplyPage from "../Component/Pages/LeaveApplyPage";
import ApplyProjectProposalPage from "../Component/Pages/ApplyProjectProposalPage";
import ProjectProposalPage from "../Component/Pages/ProjectProposalPage";
import ProjectProgressPage from "../Component/Pages/ProjectProgressPage";
import ProjectRolePage from "../Component/Pages/ProjectRolePage";
import ProjectProgressStatusPage from "../Component/Pages/ProjectProgressStatusPage";
import AllIncentivePage from "../Component/Pages/AllIncentivePage";
import EligibilityPage from "../Component/Pages/EligibilityPage";
import DesignationsPage from "../Component/Pages/DesignationsPage";
import GenProvidentFoundPage from "../Component/Pages/GenProvidentFoundPage";
import GenBonusPolicyPage from "../Component/Pages/GenBonusPolicyPage";
import OverTimePolicyPage from "../Component/Pages/OverTimePolicyPage";
import GenLeavePolicyPage from "../Component/Pages/GenLeavePolicyPage";
import GenLeaveTypePage from "../Component/Pages/GenLeaveTypePage";
import GenDeductionTypePage from "../Component/Pages/GenDeductionTypePage";
import GenEmerGencyRelationPage from "../Component/Pages/GenEmerGencyRelationPage";
import GenMobileAllowancePage from "../Component/Pages/GenMobileAllowancePage";
import AllDepartmentPage from "../Component/Pages/AllDepartmentPage";
import AllSubDepartmentPage from "../Component/Pages/AllSubDepartmentPage";
import NoticesPage from "../Component/Pages/NoticesPage";
import UpdateProfilePage from "../Component/Pages/UpdateProfilePage";
import TodayAttendancePage from "../Component/Pages/TodayAttendancePage";
import LeaveApplicationPage from "../Component/LeaveApplication/LeaveApplicationPage";
import CreateUserPage from "../Component/Pages/CreateUserPage";
import ProfilePage from "../Component/Pages/ProfilePage";
import Test from "../Component/Test/Test";
import TransportAllowanceType from "../Component/TransportAllowance/TransportAllowanceType";
import TransportPolicy from "../Component/TransportPolicy/TransportPolicy";
import TransportLog from "../Component/TransportLog/TransportLog";
import SpecialAllowance from "../Component/SpecialAllowance/SpecialAllowance";
export const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <ProtectRoute>
        <DashboardLayout />
      </ProtectRoute>
    ),
    errorElement: <ErrorPage />,
    children: [
      {
        path: "/",
        element: <DashBoard />,
      },
      {
        path: "/salary",
        element: <SalaryPage />,
      },
      {
        path: "/deduction",
        element: <DeductionPage />,
      },
      {
        path:"/transport-log",
        element:<TransportLog/>
      },
      {
        path: "/bonus-log",
        element: <BonusLogPage />,
      },
      {
        path: "/bonus-policy",
        element: <GenBonusPolicyPage />,
      },
      {
        path: "/over-time-policy",
        element: <OverTimePolicyPage />,
      },
      {
        path: "/leave-policy",
        element: <GenLeavePolicyPage />,
      },
      {
        path: "/leave-type",
        element: <GenLeaveTypePage />,
      },
      {
        path: "/deduction-type",
        element: <GenDeductionTypePage />,
      },
      {
        path: "/emergency-relationShip",
        element: <GenEmerGencyRelationPage />,
      },
      {
        path: "/provident-fund-policy",
        element: <GenProvidentFoundPage />,
      },
      {
        path: "/designation",
        element: <DesignationsPage />,
      },

      {
        path: "/all-Employee",
        element: <AllEmployeePage />,
      },
      {
        path: "/createUser",
        // element: <CreateUser />,
        element:<CreateUserPage/>

      },
      {
        path: "updateProfile/:id",
        element: <UpdateProfilePage />,
      },
      {
        path:"profile",
        element:<ProfilePage/>
      },
      {
        path: "client",
        element: <ClientPage />,
      },
      {
        path: "providentFundAccount",
        element: <ProvidentFoundAccount />,
      },
      {
        path: "all-pay-slip",
        element: <AllPaySlipPage />,
      },
      {
        path: "my-account",
        element: <MyAccountPage />,
      },
      // // delete hobe
      // {
      //   path: "design",
      //   element: <PayBillDesign />,
      // },
      //
      {
        path: "my-pay-slip",
        element: <MyPaySlipPage />,
      },
      {
        path: "leave-apply",
        element: <LeaveApplyPage />,
      },
      {
        path: "leave-update/:id",
        element: <LeaveApplyPage />,
      },
      {
        path: "leave-application",
        element:<LeaveApplicationPage/>
      },

      // overTime
      {
        path: "over-time",
        element: <OverTimePage />,
      },
      {
        path: "admin-over-time",
        element: <AdminOverTimeLogPage />,
      },
      {
        path: "attendance",
        element: <TodayAttendancePage />,
      },
      {
        path: "all-department",
        element: <AllDepartmentPage />,
      },
      {
        path: "sub-department",
        element: <AllSubDepartmentPage />,
      },
      {
        path: "all-incentive",
        element: <AllIncentivePage/>,
      },
      {
        path: "eligibility",
        element: <EligibilityPage/>,
      },
   
      {
        path: "project-progress",
        element: <ProjectProgressPage/>,
      },
      {
        path: "project-role",
        element: <ProjectRolePage />,
      },
      {
        path: "project-progress-status",
        element: <ProjectProgressStatusPage />,
      },
      {
        path: "apply-project-proposal",
        element: <ApplyProjectProposalPage />,
      },
      // pore kaj korbo
      // {
      //   path: "update-project-proposal/:id",
      //   element: <ApplyProjectProposalPage />,
      // },
      {
        path: "project-proposal",
        element: <ProjectProposalPage/>,
      },

      {
        path: "mobile-allowance",
        element: <GenMobileAllowancePage />,
      },
      {
        path: "/Notice",
        element: <NoticesPage />,
      },
      {
        path:"/test",
        element:<Test/>
      },
      {
        path:"/transport-type",
        element:<TransportAllowanceType/>
      },
      {
        path:"/transport-policy",
        element:<TransportPolicy/>
      },
      {
        path:"/special-allowance",
        element:<SpecialAllowance/>
      }
    ],
  },

  {
    path: "/login",
    element: <LogIn />,
  },
]);

import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { AiFillEdit, AiOutlineDelete, AiOutlinePlus } from "react-icons/ai";
import CreateProvidentPolicyModal from "./CreateProvidentPolicyModal";
import UpdateProvidentPolicy from "./UpdateProvidentPolicy";
import { MainUrl } from "../../utils/helper";
import useData from "../../ContextApi/UseData/useData";
import Loader from "../Loader/Loader";
import DeleteModal from "../AllEmployee/DeleteModal/DeleteModal";

const ProvidentFund = () => {
  const { token } = useData().user;
  const [polices, setpolices] = useState(null);
  const [showProvidentPolicy, setShowProvidentPolicy] = useState(false);
  const [updateShow, setUpdateShow] = useState(false);
  const [update, setUpdate] = useState(false);
  const [updateModalData, setUpdateModalData] = useState();
  const [isLoader, setIsLoader] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [selectData, setSelectData] = useState(null);

  // GET TABLE DATA
  useEffect(() => {
    if (!token) {
      return;
    }
    const fetchData = async () => {
      setIsLoader(true);
      const res = await fetch(`${MainUrl.url}/api/v1/provident_fund/policies`, {
        method: "get",
        headers: {
          Authorization: token,
        },
      });

      const data = await res.json();
      if (res.ok) {
        setpolices(data.data);
        setIsLoader(false);
      } else {
        toast.error(data.message);
        console.error(data.message);
        setIsLoader(false);
      }
    };
    fetchData();
  }, [update, token]);

  // const handleDelete = async (id, name) => {
  //   const agree = window.confirm(`are you sure to delete ${name}`);
  //   if (agree) {
  //     try {
  //       const res = await fetch(
  //         `${MainUrl.url}/api/v1/provident_fund/policies/${id}`,
  //         { method: "delete", headers: { Authorization: token } }
  //       );
  //       if (res.ok) {
  //         setUpdate(!update);
  //       }
  //     } catch (error) {
  //       toast.error(error.message);
  //     }
  //   }
  // };

  const ActionTemplate = (data) => {
    return (
      <div className=" flex items-center gap-x-2">
        <div
          className="bg-cyan-700 h-8 w-8 flex items-center justify-center rounded-full cursor-pointer"
          onClick={() => {
            setUpdateShow(!updateShow);
            setUpdateModalData(data);
          }}
        >
          <AiFillEdit className="text-white" />
        </div>
        <div
          className="bg-red-400 h-8 w-8 flex items-center justify-center rounded-full cursor-pointer"
          onClick={() => {
            setSelectData(data);
            setShowDelete(!showDelete);
          }}
        >
          <AiOutlineDelete className="text-white" />
        </div>
      </div>
    );
  };

  const NameTemplate = (data) => {
    return <p className="text-base">{data?.package}</p>;
  };
  const PercentageTemplate = (data) => {
    return <p className="text-base">{data?.percentage}</p>;
  };
  const DurationTemplate = (data) => {
    return <p className="text-base">{data.duration} Year</p>;
  };
  const ModifyTimeTemplate = (data) => {
    const date = data?.revised_at;
    let formatDate = new Date(date)?.toLocaleDateString("en-GB", {
      year: "numeric",
      month: "numeric",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
      timeZone: "BST",
    });
    return <p className="text-base">{formatDate}</p>;
  };

  const renderHeader = () => {
    return (
      <div className="flex justify-end">
        <div className="pt-2">
          <button
            onClick={() => setShowProvidentPolicy(!showProvidentPolicy)}
            className="flex items-center gap-x-2  px-2 py-2.5 bg-cyan-700 text-white rounded-md"
          >
            <span className="text-base font-semibold">
              <AiOutlinePlus />
            </span>{" "}
            <span className="text-base font-medium">New Policy</span>{" "}
          </button>
        </div>
      </div>
    );
  };
  const header = renderHeader();

  const modalData = {
    showProvidentPolicy,
    update,
    setUpdate,
    setShowProvidentPolicy,
  };
  const updateModal = {
    updateShow,
    update,
    setUpdate,
    setUpdateShow,
    updateModalData,
  };

  const DeleteUrl = "api/v1/provident_fund/policies";
  const DeleteData = {
    showDelete,
    setShowDelete,
    update,
    setUpdate,
    selectData,
    DeleteUrl,
  };
  return (
    <div>
      {isLoader ? (
        <Loader />
      ) : (
        <DataTable
          header={header}
          value={polices}
          paginator
          rows={5}
          rowsPerPageOptions={[5, 10, 25, 50]}
          selectionMode="single"
          size={"small"}
          dataKey="id"
          tableStyle={{ Width: "100%" }}
        >
          <Column
            field="Name"
            body={NameTemplate}
            header="Package Name"
          ></Column>
          <Column
            field="Name"
            body={PercentageTemplate}
            header="Percentage"
          ></Column>
          <Column
            field="Name"
            body={DurationTemplate}
            header="Duration"
          ></Column>
          <Column
            field="Name"
            body={ModifyTimeTemplate}
            header="Revisit At"
          ></Column>
          <Column
            field="Name"
            body={ActionTemplate}
            className="w-[10%]"
            header="Action"
          ></Column>
        </DataTable>
      )}

      {<CreateProvidentPolicyModal modalData={modalData} />}
      {<UpdateProvidentPolicy updateModal={updateModal} />}
      {<DeleteModal DeleteData={DeleteData} />}
    </div>
  );
};

export default ProvidentFund;

import React from "react";
import Breadcrumbs from "../Breadcrumbs/Breadcrumbs";
import ProgressStatus from "../ProjectManagement/ ProgressStatus";

const ProjectProgressStatusPage = () => {
  const data = [
    {
      name: "Project Progress Status",
    },
  ];
  return (
    <>
      <Breadcrumbs data={data} />
      <ProgressStatus />
    </>
  );
};

export default ProjectProgressStatusPage;

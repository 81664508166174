import React from "react";
import Rodal from "rodal";
import "rodal/lib/rodal.css";

const ProgressDetails = ({ detailsData }) => {
  const { showDetails, setShowDetails, details } = detailsData;

  return (
    <div>
      <Rodal
        visible={showDetails}
        enterAnimation="flip"
        animation="slideRight"
        onClose={() => setShowDetails(!showDetails)}
        customStyles={{
          height: "auto",
          width: "40%",
          bottom: "auto",
          top: "10%",
          transform: "translateY(-50%)",
        }}
      >
        <h5 className="text-xl border-b py-3">Project Progress Details</h5>
        <div>
          <p className="text-lg font-medium pt-4">
            <span className="font-semibold">Project Name: </span>
            {details?.project?.project_title}
          </p>
          <p className="text-lg font-medium pt-2">
            <span className="font-semibold">Description: </span>
            {details?.project?.details}
          </p>
        </div>
      </Rodal>
    </div>
  );
};

export default ProgressDetails;

import React from "react";
import Clients from "../Clients/Clients";
import Breadcrumbs from "../Breadcrumbs/Breadcrumbs";

const ClientPage = () => {
  const data = [
    {
      name: "All Client Table",
    },
  ];

  return (
    <>
      <Breadcrumbs data={data} />
      <Clients />
    </>
  );
};

export default ClientPage;

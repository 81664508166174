import React from "react";
import Breadcrumbs from "../Breadcrumbs/Breadcrumbs";
import DepartmentAll from "../Department/DepartmentAll";

const AllDepartmentPage = () => {
  const data = [
    {
      name: "Department",
      slash: "/",
      route:null
    },
    {
      name: "All Department",
      route:null
    },
  ];
  return (
    <>
      <Breadcrumbs data={data} />
      <DepartmentAll />
    </>
  );
};

export default AllDepartmentPage;

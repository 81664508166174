import React from 'react'
import ProjectProposal from '../ProjectManagement/ProjectProposal'
import Breadcrumbs from '../Breadcrumbs/Breadcrumbs'

const ProjectProposalPage = () => {
    const data = [
        {
          name: "Project Proposal",
        },
      ];
  return (
    <div>
        <Breadcrumbs data={data}/>
        <ProjectProposal/>
    </div>
  )
}

export default ProjectProposalPage
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import React, { useEffect, useState } from "react";
import useData from "../../ContextApi/UseData/useData";
import { MainUrl } from "../../utils/helper";
import { toast } from "react-toastify";

const CreateTransportLog = ({ CreateData }) => {
  const { showLog, setShowLog, update, setUpdate } = CreateData;
  const { modifyAllUser, token } = useData().user;
  const [selectUser, setSelectUser] = useState(null);
  const [transportTypes, setTransportTypes] = useState(null);
  const [selectTransportType, setSelectTransportType] = useState(null);
  const [selectTravelType, setSelectTravelType] = useState(null);
  const [changeDistance, setChangeDistance] = useState("");

  //   transport Type
  useEffect(() => {
    if (!token) {
      return;
    }
    const fetchData = async () => {
      try {
        const res = await fetch(`${MainUrl.url}/api/v1/transport/types`, {
          method: "Get",
          headers: {
            Authorization: token,
          },
        });
        const resData = await res.json();
        if (res.ok) {
          setTransportTypes(resData.data);
        }
      } catch (error) {
        toast.error(error.message);
      }
    };
    fetchData();
  }, [token]);

  const TravelTypes = [
    { id: 0, name: "In City" },
    { id: 1, name: "Out City" },
  ];

  //   postData
  const handlePostData = (e) => {
    e.preventDefault();
    const form = e.target;
    const data = {
      user_id: selectUser,
      transport_type_id: selectTransportType.id,
      distance: changeDistance,
      in_city: selectTransportType?.id,
    };
    console.log("data", data);

    const postData = async () => {
      let res = await fetch(`${MainUrl.url}/api/v1/transport/logs`, {
        method: "post",
        headers: {
          "Content-type": "application/json",
          Authorization: token,
        },
        body: JSON.stringify(data),
      });
      if (res.ok) {
        toast.success("successfully updated");
        setShowLog(!showLog);
        setUpdate(!update);
        setSelectTransportType(null)
        setSelectUser(null)
        setTransportTypes(null)
        form.reset();
      } else {
        const resData = await res.json();
        toast.error(resData.message);
        setShowLog(!showLog);
        form.reset();
      }
    };
    postData();
  };

  return (
    <Dialog
      header="Create Transport Allowance"
      visible={showLog}
      onHide={() => setShowLog(!showLog)}
      style={{ width: "30vw" }}
      breakpoints={{ "960px": "75vw", "641px": "100vw" }}
      draggable={false}
      resizable={false}
    >
      <form onSubmit={handlePostData} className="common">
        <div className="flex flex-col mb-2">
          <label className="pb-1 ps-1"> Select Employee</label>
          <div>
            <Dropdown
              value={selectUser}
              onChange={(e) => setSelectUser(e.value)}
              options={modifyAllUser}
              optionLabel="label"
              placeholder="Select a Employee"
              filter
              showClear
              className="w-full md:w-14rem"
            />
          </div>
        </div>

        <div className="flex flex-col mb-2">
          <label className="pb-1 ps-1"> Select Transition Type</label>
          <div>
            <Dropdown
              value={selectTransportType}
              onChange={(e) => setSelectTransportType(e.value)}
              options={transportTypes}
              optionLabel="name"
              placeholder="Select Transition Type"
              showClear
              className="w-full md:w-14rem"
            />
          </div>
        </div>

        <div className="flex flex-col mb-2">
          <label className="pb-1 ps-1"> Select Travel Type</label>
          <div>
            <Dropdown
              value={selectTravelType}
              onChange={(e) => setSelectTravelType(e.value)}
              options={TravelTypes}
              optionLabel="name"
              placeholder="Select Transition Type"
              showClear
              className="w-full md:w-14rem"
            />
          </div>
        </div>

        <div className="flex flex-col mb-2">
          <label className="pb-1 ps-1">Distance</label>
          <input
            type="number"
            className="border-2 ps-2 py-1 rounded-s-md border-gray-200 focus-visible:outline-none w-full"
            onChange={(e) => setChangeDistance(e.target.value)}
            placeholder="Distance"
            step="any"
          />
        </div>
        <input
          className="cursor-pointer px-4 py-1 rounded-md bg-cyan-700  text-white text-base font-medium "
          type="submit"
          value="Submit"
        />
      </form>
    </Dialog>
  );
};

export default CreateTransportLog;

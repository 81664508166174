import React, { useState } from "react";
import { toast } from "react-toastify";
import html2pdf from "html2pdf.js";
import logo from "../../Assets/logo.png";
import "./payBillDesign.css";
import { MainUrl } from "../../utils/helper";
import useData from "../../ContextApi/UseData/useData";
import { Dialog } from "primereact/dialog";

const AmountModal = ({ modal }) => {
  const { show, setShow, hidden, setHidden } = modal;
  const [cash, setCash] = useState("");
  const { token } = useData().user;

  const numberToText = require("number-to-text");
  require("number-to-text/converters/en-us"); // load converter

  const generatePDF = () => {
    const element = document.getElementById("contentToConvert");
    const opt = {
      margin: 10,
      filename: "generated.pdf",
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: "mm", format: "A4", orientation: "portrait" },
    };

    html2pdf().from(element).set(opt).save();
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = {
      withdrawn_amount: cash,
    };

    let updateNote = async () => {
      const res = await fetch(
        `${MainUrl.url}/api/v1/provident_fund/employee/payslip`,
        {
          method: "Post",
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
          body: JSON.stringify(formData),
        }
      );
      const resData = await res.json();
      if (res.ok) {
        toast.success("successfully updated");
        generatePDF();
        setShow(!show);
        setHidden(!hidden);
      } else {
        toast.error(resData.message);
        setShow(!show);
        setHidden(!hidden);
      }
    };

    updateNote();
  };

  return (
    <div>
      <Dialog
        header="Provident Fund Cash Withdraw"
        visible={show}
        onHide={() => setShow(!show)}
        style={{ width: "30vw" }}
        breakpoints={{ "960px": "75vw", "641px": "100vw" }}
      >
        <form onSubmit={handleSubmit}>
          <div className="flex w-full ">
            <input
              onChange={(e) => setCash(e.target.value)}
              type="number"
              className="border-2 ps-2 py-1 rounded-s-md border-gray-200 focus-visible:outline-none w-4/5"
              name="cash"
            />
            <input
              className="cursor-pointer px-4 rounded-e-md bg-cyan-700  text-white font-medium text-sm w-1/5"
              type="submit"
              value="Submit"
            />
          </div>
        </form>
      </Dialog>

      {/* print PDF  */}

      <section id="contentToConvert" className="printable">
        <div>
          <article className="overflow-hidden">
            <div className="bg-white text-white">
              <div className="p-6 bg-gray-400">
                <div className="flex justify-between">
                  <div className="h-[30px] w-[235px]">
                    <img className="h-full w-full" src={logo} alt="" />
                  </div>

                  <p className=" px-6 py-2 bg-red-500 text-center">Pay</p>
                </div>

                <div className="flex justify-between items-center gap-x-4">
                  <p className="w-[60%] pt-2">
                    113 Kazi NarZul Islam Ave, Concord Tower,Lavel7(704),
                    Dhaka-1205, Bangladesh. Phone +880963888444,
                    E-mail:hellow@softopia.io,
                  </p>

                  <div className="flex gap-x-1 w-[40%]">
                    <p className="lineHight text-lg w-[200px]">Voucher No:</p>
                    <p className="border-dashed border-b-2 border-black tracking-wide w-full"></p>
                  </div>
                </div>
              </div>
              <div className="pt-8 bg-white text-gray-600 py-9">
                <div className="grid grid-cols-12 gap-x-2">
                  <div className="col-span-9 w-full flex gap-x-2">
                    <p className="lineHight text-lg w-[160px]">Account Head:</p>
                    <p className="border-dashed border-b-2 border-black tracking-wide w-full"></p>
                  </div>
                  <div className="flex gap-x-1 col-span-3">
                    <p className="lineHight text-lg w-[60px]">Date:</p>
                    <p className="border-dashed border-b-2 border-black tracking-wide w-full"></p>
                  </div>
                </div>
              </div>

              {/* table */}
              <div>
                <div className="relative overflow-x-auto ">
                  <table className="w-full text-left ">
                    <thead className="text-base font-medium text-gray-700 uppercase ">
                      <tr className="border border-gray-400 grid grid-cols-12">
                        <th
                          scope="col"
                          className="text-black ps-3 py-3 col-span-9"
                        >
                          The amount being paid To
                        </th>
                        <th
                          scope="col"
                          className="py-3 ps-3 border-l border-black bg-pink-200 col-span-3"
                        >
                          Amount(Taka)
                        </th>
                      </tr>
                    </thead>

                    <tbody>
                      <tr className="border border-gray-400 grid grid-cols-12">
                        <th
                          scope="row"
                          className="ps-6 py-3 font-medium text-gray-900 whitespace-nowrap col-span-9"
                        >
                          Provident Fund Withdraw
                        </th>
                        <th
                          scope="row"
                          className="ps-6 py-3 font-medium text-gray-900 whitespace-nowrap border-l border-black bg-pink-200 col-span-3"
                        >
                          {cash}/-
                        </th>
                      </tr>

                      <tr className="border border-gray-400 grid grid-cols-12">
                        <th
                          scope="row"
                          className="ps-6 py-3 font-medium text-gray-900 whitespace-nowrap col-span-9"
                        ></th>
                        <th
                          scope="row"
                          className="ps-6 py-3 font-medium text-gray-900 whitespace-nowrap border-l border-black bg-pink-200 col-span-3"
                        ></th>
                      </tr>
                      <tr className="border border-gray-400 grid grid-cols-12">
                        <th
                          scope="row"
                          className="ps-6 py-3 font-medium text-gray-900 whitespace-nowrap col-span-9"
                        ></th>
                        <th
                          scope="row"
                          className="ps-6 py-3 font-medium text-gray-900 whitespace-nowrap border-l border-black bg-pink-200 col-span-3"
                        ></th>
                      </tr>

                      <tr className="border border-gray-400 grid grid-cols-12">
                        <th
                          scope="row"
                          className="ps-6 py-3 font-medium text-gray-900 whitespace-nowrap col-span-9"
                        ></th>
                        <th
                          scope="row"
                          className="ps-6 py-3 font-medium text-gray-900 whitespace-nowrap border-l border-black bg-pink-200 col-span-3"
                        ></th>
                      </tr>

                      <tr className="border border-gray-400 grid grid-cols-12">
                        <th
                          scope="row"
                          className="ps-6 py-3 font-medium text-gray-900 whitespace-nowrap col-span-9"
                        ></th>
                        <th
                          scope="row"
                          className="ps-6 py-3 font-medium text-gray-900 whitespace-nowrap border-l border-black bg-pink-200 col-span-3"
                        ></th>
                      </tr>

                      <tr className="border border-gray-400 grid grid-cols-12">
                        <th
                          scope="row"
                          className="ps-6 py-3 font-medium text-gray-900 whitespace-nowrap col-span-9"
                        ></th>
                        <th
                          scope="row"
                          className="ps-6 py-3 font-medium text-gray-900 whitespace-nowrap border-l border-black bg-pink-200 col-span-3"
                        ></th>
                      </tr>

                      <tr className="border border-gray-400 grid grid-cols-12">
                        <th
                          scope="row"
                          className="ps-6 py-2 font-medium text-gray-900 whitespace-nowrap col-span-5"
                        >
                          CASH/CHEQUE/P.O/D.D
                        </th>
                        <th
                          scope="row"
                          className="ps-6 py-2 font-medium text-gray-900 whitespace-nowrap col-span-4"
                        >
                          A/C NO:
                        </th>
                        <th
                          scope="row"
                          className="ps-6 py-2 font-medium text-gray-900 whitespace-nowrap border-l border-black bg-pink-200 col-span-3"
                        ></th>
                      </tr>

                      <tr className=" grid grid-cols-12">
                        <td className="ps-1 py-3 font-medium text-gray-900 whitespace-nowrap col-span-8 w-full">
                          <div className="flex gap-x-1 w-full">
                            <span className="lineHight text-sm whitespace-pre-wrap  tracking-wide w-full">
                              TAKA (in ward):{" "}
                              <span className="text-sm pb-1 border-dashed border-b-2 border-black w-full">
                                {cash &&
                                  numberToText.convertToText(cash, {
                                    case: "UpperCase",
                                  })}
                              </span>
                            </span>
                          </div>
                        </td>
                        <th
                          scope="row"
                          className="ps-1.5 py-3 text-lg font-medium text-gray-900 whitespace-nowrap col-span-1"
                        >
                          Total:
                        </th>
                        <th
                          scope="row"
                          className="ps-6 py-3 font-medium text-gray-900 whitespace-nowrap bg-pink-200 col-span-3 border-l  border-b border-gray-900 "
                        ></th>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="mt-16 grid grid-cols-4 gap-x-6 text-black text-lg">
                <p className="border-t border-dashed border-gray-700 ">
                  Received By
                </p>
                <p className="border-t border-dashed border-gray-700">
                  prepared By
                </p>
                <p className="border-t border-dashed border-gray-700">
                  Checked By
                </p>
                <p className="border-t border-dashed border-gray-700">
                  Authorized By
                </p>
              </div>
            </div>
          </article>
        </div>
      </section>
    </div>
  );
};

export default AmountModal;

import { useEffect, useState } from "react";
import { MainUrl } from "../utils/helper";
import { toast } from "react-toastify";

const useUserContext = () => {
  const [token, setToken] = useState("");
  const [loader, setLoader] = useState(true);
  const [allUser, setAllUser] = useState(null);
  const [profile, setProfile] = useState(null);
  const [allDepartment, setAllDepartment] = useState(null);
  const [allDesignation, setAllDesignation] = useState(null);
  const [Notice, setNotice] = useState(null);

  useEffect(() => {
    if (!token) {
      return;
    }

    // get  profile
    const profile = async () => {
      const res = await fetch(`${MainUrl.url}/api/v1/user/my_profile`, {
        method: "Get",
        headers: {
          Authorization: token,
        },
      });
      const resData = await res.json();
      if (res.ok) {
        setProfile(resData.data);
      }
    };
    profile();
  }, [token]);

  // refresh token
  useEffect(() => {
    const refresh = async () => {
      try {
        const res = await fetch(`${MainUrl.url}/api/v1/refresh_token`, {
          method: "GET",
          credentials: "include",
        });
        const resData = await res.json();
        if (res.ok) {
          setToken(resData.data.accessToken);
        }
        setLoader(false);
      } catch (error) {
        console.error(error);
      }
    };
    if (!token) {
      refresh();
    }
    const interval = setInterval(refresh, 14 * 60 * 1000);
    return () => clearInterval(interval);
  }, [token, loader]);

  // get all user
  useEffect(() => {
    if (!token) {
      return;
    }
    const fetchData = async () => {
      if (!token) {
        return;
      }
      try {
        const res = await fetch(`${MainUrl.url}/api/v1/all_users/999999/0`, {
          method: "get",
          headers: {
            Authorization: token,
          },
        });
        const data = await res.json();
        if (res.ok) {
          setAllUser(data.data?.results);
        }
      } catch (error) {
        toast.error(error?.message);
      }
    };
    fetchData();
  }, [token]);

  // get all department
  useEffect(() => {
    if (!token) {
      return;
    }
    const fetchData = async () => {
      try {
        const res = await fetch(`${MainUrl.url}/api/v1/department`, {
          method: "get",
          headers: {
            Authorization: token,
          },
        });
        const resData = await res.json();
        if (res.ok) {
          setAllDepartment(resData.data);
        }
      } catch (error) {
        console.error(error.message);
      }
    };
    fetchData();
  }, [token]);

  // get User designations
  useEffect(() => {
    if (!token) {
      return;
    }
    const fetchData = async () => {
      try {
        const res = await fetch(`${MainUrl.url}/api/v1/user/designations`, {
          method: "get",
          headers: {
            Authorization: token,
          },
        });
        const resData = await res.json();
        if (res.ok) {
          setAllDesignation(resData.data);
        }
      } catch (error) {
        console.error(error.message);
      }
    };
    fetchData();
  }, [token]);


  // get All notice
  useEffect(() => {
    if (!token) {
      return;
    }
    const fetchDate = async () => {
      try {
        const res = await fetch(`${MainUrl.url}/api/v1/notice/all/9999999/0`, {
          method: "get",
          headers: {
            Authorization: token,
          },
        });
        const resData = await res.json();
        if (res.ok) {
          setNotice(resData.data.records);
        }
      } catch (error) {
        console.error(error.message);
      }
    };
    fetchDate()
  }, [token]);

  const modifyAllUser = allUser?.map((user) => {
    let obj = {};
    obj.label = user?.user_profile?.name;
    obj.value = user?.id;
    return obj;
  });

  return {
    token,
    setToken,
    profile,
    allUser,
    setAllUser,
    loader,
    allDepartment,
    setAllDepartment,
    allDesignation,
    setAllDesignation,
    Notice,
    modifyAllUser
  };
};

export default useUserContext;

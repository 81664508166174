import React from "react";
import { PiUsersFourLight } from "react-icons/pi";
import { FiUserCheck, FiUserMinus } from "react-icons/fi";
import { LiaPlaneDepartureSolid } from "react-icons/lia";
import ReactApexChart from "react-apexcharts";

const DashBoard = () => {
  // Recruits Reports start
  const series = [
    {
      data: [35, 5, 30, 0, 40, 25, 0, 0, 15, 20],
    },
  ];

  const options = {
    chart: {
      type: "bar",
      height: 350,
    },
    title: {
      text: "Recruits Reports",
      align: "left",
    },
    plotOptions: {
      bar: {
        borderRadius: 1,
        horizontal: true,
      },
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      categories: [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
      ],
    },
  };

  // Recruits Reports end

  // Profit Growth Margin start
  const leaveSeries = [
    {
      name: "Projects",
      data: [8, 20, 15, 22],
    },
  ];

  const leaveOptions = {
    chart: {
      height: 350,
      type: "line",
      zoom: {
        enabled: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "straight",
    },
    title: {
      text: "Project Count",
      align: "left",
    },
    grid: {
      row: {
        colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
        opacity: 0.5,
      },
    },
    xaxis: {
      categories: ["2020", "2021", "2022", "2023"],
    },
  };

  // Profit Growth Margin end

  // total Expanse
  const expanseSeries = [20, 40, 10, 5, 15, 5, 5];
  const expanseOptions = {
    chart: {
      width: 500,
      type: "pie",
    },
    title: {
      text: "Expense Chart",
      align: "left",
      // padding: 100,
      // margin: 30
    },
    labels: [
      "Operational",
      "Capital",
      "Maintenance",
      "Petty",
      "Services",
      "Rent",
      "Damage Control",
    ],
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
  };

  // const reportSeries = [
  //   {
  //     data: [400, 430, 448, 470, 540, 580, 690, 1100, 1200, 1380],
  //   },
  // ];

  // const reportPptions = {
  //   chart: {
  //     type: "bar",
  //     height: 350,
  //   },
  //   plotOptions: {
  //     bar: {
  //       borderRadius: 4,
  //       horizontal: true,
  //     },
  //   },
  //   title: {
  //     text: "Product Trends by Month",
  //     align: "left",
  //   },
  //   dataLabels: {
  //     enabled: false,
  //   },
  //   xaxis: {
  //     categories: [
  //       "South Korea",
  //       "Canada",
  //       "United Kingdom",
  //       "Netherlands",
  //       "Italy",
  //       "France",
  //       "Japan",
  //       "United States",
  //       "China",
  //       "Germany",
  //     ],
  //   },
  // };

  let dataLabels = {
    monthDataSeries: {
      dates: ["2020", "2021", "2022", "2023", "2024"],
    },
  };

  const reportSeries = [
    {
      name: "Profit Margin",
      data: [15, 20, 48, 50, 70],
    },
  ];

  const reportOptions = {
    chart: {
      type: "area",
      height: 350,
      zoom: {
        enabled: false,
      },
    },
    dataLabels: {
      enabled: true,
    },
    stroke: {
      curve: "straight",
    },

    title: {
      text: "Fundamental Analysis of Profit",
      align: "left",
    },
    subtitle: {
      text: "Price Movements",
      align: "left",
    },
    labels: dataLabels.monthDataSeries.dates,
    xaxis: {
      type: "datetime",
    },
    yaxis: {
      opposite: false,
    },
    legend: {
      horizontalAlign: "left",
    },
  };

  return (
    <div className="pb-5">
      <div className="grid lg:grid-cols-4 md:grid-cols-3 grid-cols-2 gap-2">
        <div className="border bg-white p-4 rounded">
          <div className="flex gap-x-3">
            <div className="p-4 bg-green-500 text-white rounded">
              <FiUserCheck size={30} />
            </div>
            <div>
              <p className="text-base">Total Present</p>
              <p className="text-xl font-medium">80</p>
            </div>
          </div>
        </div>

        <div className="border bg-white p-4 rounded">
          <div className="flex gap-x-3">
            <div className="p-4 bg-green-500 text-white rounded">
              <LiaPlaneDepartureSolid size={30} />
            </div>
            <div>
              <p className="text-base">On Leave</p>
              <p className="text-xl font-medium">10</p>
            </div>
          </div>
        </div>

        <div className="border bg-white p-4 rounded">
          <div className="flex gap-x-3">
            <div className="p-4 bg-green-500 text-white rounded">
              <FiUserMinus size={30} />
            </div>
            <div>
              <p className="text-base">Total Absentees</p>
              <p className="text-xl font-medium">09</p>
            </div>
          </div>
        </div>

        <div className="border bg-white p-4 rounded">
          <div className="flex  gap-x-3">
            <div className="p-4 bg-green-500 text-white rounded">
              <PiUsersFourLight size={30} />
            </div>
            <div>
              <p className="text-lg">Total Employees</p>
              <p className="text-lg font-medium">99</p>
            </div>
          </div>
        </div>
      </div>

      <div className="grid lg:grid-cols-2 grid-cols-1 gap-2 mt-5">
        <div className="bg-white p-2">
          <div id="chart" className="">
            <ReactApexChart
              options={options}
              series={series}
              type="bar"
              height={350}
            />
          </div>
        </div>



        <div className="bg-white p-2">
          <div id="chart">
            <ReactApexChart
              options={reportOptions}
              series={reportSeries}
              type="area"
              height={350}
            />
          </div>
        </div>

        <div className="bg-white p-2">
          <div id="chart">
            <ReactApexChart
              options={expanseOptions}
              series={expanseSeries}
              type="pie"
              width={400}
            />
          </div>
        </div>

        <div className="bg-white p-2">
          <div id="chart">
            <ReactApexChart
              options={leaveOptions}
              series={leaveSeries}
              type="line"
              height={350}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashBoard;

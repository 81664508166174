import React, { useState } from "react";
import { Outlet } from "react-router-dom";
import Sidebar from "../Sidebar/Sidebar";
import Navbar from "../Navbar/Navbar";
// import Breadcrumbs from "../Breadcrumbs/Breadcrumbs";

const DashboardLayout = () => {
  const [show, setShow] = useState(true);
  return (
    // <div>
    //   <Sidebar show={show} setShow={setShow} />
    //   <div
    //     className={`transition-all duration-300 ${
    //       show ? "ml-0 lg:ml-72" : "ml-0"
    //     }`}
    //   >
    //     <Navbar show={show} setShow={setShow} />
    //     <div className="px-3 pt-6">
    //       <Outlet></Outlet>
    //     </div>
    //   </div>
    // </div>

    <>
      <Sidebar show={show} setShow={setShow} />
      <div
        className={`transition1 z-10 pb-12 lg:pb-0 ${
          show ?  "md:pl-0 lg:pl-64":"pl-0"
        }`}
      >
        <Navbar show={show} setShow={setShow} />
        <div className="px-3 pt-6">
          <Outlet />
        </div>
      </div>
    </>
  );
};

export default DashboardLayout;

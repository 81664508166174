import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import React, { useEffect, useState } from "react";
import { AiOutlinePlus } from "react-icons/ai";
import { IoIosRefresh } from "react-icons/io";
import { toast } from "react-toastify";
import CreateProvidentFundModal from "./CreateProvidentFundModal";
import { MainUrl, showDummyImage } from "../../utils/helper";
import useData from "../../ContextApi/UseData/useData";
import Loader from "../Loader/Loader";
import { useLocation, useSearchParams } from "react-router-dom";
import Pagination from "../Pagination/Pagination";

const ProvidentFoundAccount = () => {
  const { token } = useData().user;
  const [expandedRows, setExpandedRows] = useState([]);
  const [providentData, setProvidentData] = useState(null);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [update, setUpdate] = useState(false);
  const [totalData, setTotalData] = useState(null);
  const [isLoader, setIsLoader] = useState(false);
  const [changeValue, setChangeValue] = useState({});
  const [filterData, setFilterData] = useState({
    name: "",
    office_id: "",
  });

  const [viewData, setViewData] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchParams, setSearchParams] = useSearchParams();
  const [pageCount, setPageCount] = useState();
  const location = useLocation();

  // GET TABLE DATA
  useEffect(() => {
    if (!token) {
      return;
    }

    const page = location?.search?.split("=")[1];
    if (page) {
      setPageNumber(+page);
      setCurrentPage(+page);
    }

    const fetchData = async () => {
      try {
        setIsLoader(true);
        const res = await fetch(
          `${
            MainUrl.url
          }/api/v1/provident_fund/accounts/${viewData}/${pageNumber}?${
            filterData.name && `name=${filterData.name}`
          }${filterData.office_id && `&office_id=${filterData.office_id}`}`,
          {
            method: "get",
            headers: {
              Authorization: token,
            },
          }
        );
        const resData = await res.json();
        if (res.ok) {
          setProvidentData(resData.data.result);
          setTotalData(resData.data?.total_count);
          setIsLoader(false);
        }
      } catch (error) {
        toast.error(error?.message);
        setIsLoader(false);
      }
    };
    fetchData();
  }, [update, token, pageNumber, viewData, filterData, location, searchParams,currentPage]);

  // get total page number
  useEffect(() => {
    const pages = Math.ceil(totalData / viewData);
    setPageCount(pages);
  }, [totalData, viewData]);

  // const footerTemplate = () => {
  //   return (
  //     providentData?.length > 0 && (
  //       <div className="flex justify-center items-center gap-x-4 bg-transparent">
  //         <div className="flex items-center gap-x-2">
  //           <button
  //             disabled={pageNumber === 0 && true}
  //             onClick={() => setPageNumber(pageNumber >= 0 && pageNumber - 1)}
  //             className="px-2 py-2 border rounded-full disabled:opacity-25"
  //           >
  //             <GrFormPrevious className=" " />
  //           </button>
  //           <div className="flex gap-3">
  //             {Array(pages)
  //               ?.fill(0)
  //               ?.map((page, i) => (
  //                 <button
  //                   key={i}
  //                   onClick={() => setPageNumber(i)}
  //                   className={`border px-4 py-1 rounded ${
  //                     pageNumber === i ? "bg-blue-600 text-white" : ""
  //                   }`}
  //                 >
  //                   {i + 1}
  //                 </button>
  //               ))}
  //           </div>
  //           <button
  //             disabled={pageNumber === pages - 1 && true}
  //             onClick={() => setPageNumber(pageNumber + 1)}
  //             className="px-2 py-2 border rounded-full disabled:opacity-25"
  //           >
  //             <GrFormNext />
  //           </button>
  //         </div>
  //         <div>
  //           <select
  //             defaultValue={viewData}
  //             className="px-2 py-2 border rounded-sm  focus-visible:outline-0"
  //             onChange={(e) => setViewData(e.target.value)}
  //           >
  //             <option value="5">5</option>
  //             <option value="10">10</option>
  //             <option value="15">15</option>
  //             <option value="20">20</option>
  //             <option value="50">50</option>
  //           </select>
  //         </div>
  //       </div>
  //     )
  //   );
  // };

  const allowExpansion = (rowData) => {
    return rowData;
  };

  const onRowToggle = (event) => {
    setExpandedRows(event.data);
  };

  // All Template
  const EmployeeNameTemplate = (data) => {
    return (
      <div className="flex items-center gap-x-4">
        <div className="h-10 w-10  ">
          <img
            src={`${MainUrl.url}/${data?.user?.user_profile?.profile_photo}`}
            onError={({ currentTarget }, imageSize = "100x100") =>
              showDummyImage({ currentTarget }, imageSize)
            }
            alt=""
            className=" h-full w-full rounded-full"
          />
        </div>
        <h3 className="text-lg font-medium">
          {data?.user?.user_profile?.name}
        </h3>
      </div>
    );
  };

  const OfficeIdTemplate = (data) => {
    return <p>{data?.user?.office_id}</p>;
  };

  const PhoneNumberTemplate = (data) => {
    console.log("data", data);
    return <p>{data?.user?.user_profile?.phone_no}</p>;
  };

  const PolicyTemplate = (data) => {
    return <p>{data?.provident_fund_policy?.package}</p>;
  };

  const EligibilityTemplate = (data) => {
    return <p>{data?.eligibility === 0 ? "Not Eligible" : "Eligible"}</p>;
  };

  const EmployeeAmountTemplate = (data) => {
    return <p>{data?.employee_amount}</p>;
  };
  const CompanyAmountTemplate = (data) => {
    return <p>{data?.company_amount}</p>;
  };
  const TotalAmountTemplate = (data) => {
    return <p>{data?.total_amount}</p>;
  };

  const WithdrawableTemplate = (data) => {
    return <p>{data?.withdrawable}</p>;
  };

  const OpeningDateTemplate = (data) => {
    return <p>{data?.opening_date}</p>;
  };

  const rowExpansionTemplate1 = (data) => {
    let createArrey = [];
    createArrey.push(data);
    return (
      <div className="px-10">
        <DataTable
          size={"small"}
          value={createArrey}
          className=" text-black w-full"
        >
          <Column
            field=""
            body={EmployeeAmountTemplate}
            header="Employee Amount"
          ></Column>
          <Column
            field=""
            body={CompanyAmountTemplate}
            header="Company Amount"
          ></Column>
          <Column
            field=""
            body={TotalAmountTemplate}
            header="Total Amount"
          ></Column>
          <Column
            field=""
            body={WithdrawableTemplate}
            header="Withdrawable"
          ></Column>
          <Column
            field=""
            body={OpeningDateTemplate}
            header="Opening Date"
          ></Column>
        </DataTable>
      </div>
    );
  };

  const renderHeader = () => {
    const setValue = (e) => {
      setChangeValue({
        ...changeValue,
        [e.target.name]: e.target.value,
      });
    };

    const handleFresh = () => {
      setUpdate(!update);
      setChangeValue({
        name: "",
        office_id: "",
      });
      setFilterData({
        name: "",
        office_id: "",
      });
    };

    const handleSearch = async (e) => {
      e.preventDefault();
      const data = {
        name: changeValue.name ? changeValue.name : "",
        office_id: changeValue.office_id ? changeValue.office_id : "",
      };
      setFilterData(data);
    };

    return (
      <div className="">
        <div>
          <div className="grid grid-cols-12 items-center justify-between">
            <div className="col-span-8">
              <div className="grid grid-cols-12 gap-x-2 items-center">
                <div className="col-span-10">
                  <form
                    className="flex gap-x-3  w-full myInput"
                    onSubmit={handleSearch}
                  >
                    <div className="w-full">
                      <input
                        onChange={(e) => setValue(e)}
                        className="ps-3 py-1.5 border border-gray-300 rounded-lg inputShadow w-full"
                        placeholder="Search by Name"
                        name="name"
                        value={changeValue.name}
                      />
                    </div>
                    <div className="w-full">
                      <input
                        onChange={(e) => setValue(e)}
                        className="ps-3 py-1.5 border border-gray-300 rounded-lg inputShadow w-full"
                        placeholder="Search by Office Id"
                        name="office_id"
                        value={changeValue.office_id}
                      />
                    </div>

                    <div className="">
                      <input
                        type="submit"
                        value="Search"
                        className="cursor-pointer px-4 py-2 w-full bg-cyan-700 rounded-md text-white font-medium text-sm submit"
                      />
                    </div>
                  </form>
                </div>
                <div className="col-span-2 gap-x-2">
                  <div>
                    <span
                      onClick={handleFresh}
                      className="bg-cyan-700 text-white h-8 w-8 flex items-center justify-center rounded-full font-medium cursor-pointer"
                    >
                      <IoIosRefresh />
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-span-4">
              <div className="flex items-end justify-end">
                <button
                  onClick={() => setShowCreateModal(!showCreateModal)}
                  className="flex items-center gap-x-2  px-2 py-2.5 bg-cyan-700 text-white rounded-md"
                >
                  <span className="text-base font-semibold">
                    <AiOutlinePlus />
                  </span>
                  <span className="text-base font-medium">
                    Provident Fund Account
                  </span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  const header = renderHeader();

  const createProvident = {
    showCreateModal,
    setShowCreateModal,
    update,
    setUpdate,
  };
  return (
    <div className="bg-white pb-4 mb-4">
      {isLoader ? (
        <Loader />
      ) : (
        <DataTable
          size="small"
          header={header}
          value={providentData}
          expandedRows={expandedRows}
          onRowToggle={onRowToggle}
          rowExpansionTemplate={rowExpansionTemplate1}
          // footer={footerTemplate}
          selectionMode="single"
          dataKey="id"
          tableStyle={{ Width: "100%" }}
        >
          <Column expander={allowExpansion}></Column>
          <Column
            field="user_profile.name"
            body={EmployeeNameTemplate}
            header="Employee Name"
            sortable
            className=""
          ></Column>
          <Column
            field="user.office_id"
            body={OfficeIdTemplate}
            header="Office Id"
            sortable
          ></Column>
          <Column
            field=""
            body={PhoneNumberTemplate}
            header="Phone No"
          ></Column>
          <Column field="" body={PolicyTemplate} header="Policy"></Column>
          <Column
            field=""
            body={EligibilityTemplate}
            header="Eligibility"
          ></Column>
        </DataTable>
      )}

      <div className="mt-4">
        {providentData?.length > 0 && (
          <Pagination
            currentPage={currentPage}
            pageNumber={pageNumber}
            setCurrentPage={setCurrentPage}
            setSearchParams={setSearchParams}
            pageCount={pageCount}
            setViewData={setViewData}
            viewData={viewData}
            setPageNumber={setPageNumber}
          />
        )}
      </div>

      {<CreateProvidentFundModal createProvident={createProvident} />}
    </div>
  );
};

export default ProvidentFoundAccount;

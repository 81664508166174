import React, { useState } from "react";
import { toast } from "react-toastify";
import { MainUrl } from "../../utils/helper";
import useData from "../../ContextApi/UseData/useData";
import { Dialog } from "primereact/dialog";

const UpdateRelationship = ({ updateModal }) => {
  const [relation, setRelation] = useState();
  const { showUpdate, setShowUpdate, updateModalData, update, setUpdate } =
    updateModal;
  const { token } = useData().user;

  const handleUpdate = (e) => {
    e.preventDefault();
    const type = relation || updateModal?.name;
    const formData = {
      name: type,
    };

    if (!token) {
      return;
    }
    let updateNote = async () => {
      const res = await fetch(
        `${MainUrl.url}/api/v1/user/emergency/${updateModalData?.id}`,
        {
          method: "Put",
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
          body: JSON.stringify(formData),
        }
      );
      const resData = await res.json();
      if (res.ok) {
        setUpdate(!update);
        toast.success("successfully updated");
        setShowUpdate(!showUpdate);
      } else {
        toast.error(resData.message);
        setShowUpdate(!showUpdate);
      }
    };

    updateNote();
  };
  return (
    <div>
      <Dialog
        header="Update Emergency RelationShip"
        visible={showUpdate}
        onHide={() => setShowUpdate(!showUpdate)}
        style={{ width: "30vw" }}
        breakpoints={{ "960px": "75vw", "641px": "100vw" }}
        draggable={false} resizable={false}
      >
        <div>
          <form onSubmit={handleUpdate} className="pt-4">
            <div className="flex w-full ">
              <input
                onChange={(e) => setRelation(e.target.value)}
                type="text"
                className="border-2 ps-2 py-1 rounded-s-md border-gray-200 focus-visible:outline-none w-4/5"
                defaultValue={updateModalData?.name}
                name="type"
              />
              <input
                className="cursor-pointer px-4 rounded-e-md bg-cyan-700  text-white font-medium text-sm w-1/5"
                type="submit"
                value="Submit"
              />
            </div>
          </form>
        </div>
      </Dialog>
    </div>
  );
};

export default UpdateRelationship;

import React from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { MainUrl } from "../../utils/helper";
import useData from "../../ContextApi/UseData/useData";
import { Dialog } from "primereact/dialog";

const CreateClient = ({ modalData }) => {
  const { showModal, setShowModal, update, setUpdate } = modalData;
  const { token } = useData().user;
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    const CrateUser = async () => {
      const res = await fetch(`${MainUrl.url}/api/v1/clients/create`, {
        method: "post",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
        body: JSON.stringify(data),
      });
      const resData = await res.json();
      if (res.ok) {
        toast.success("successfully Crate a New User");
        setUpdate(!update);
        setShowModal(!showModal)
        reset();
      } else {
        toast.error(resData.message);
        setShowModal(!showModal)
      }
    };
    CrateUser();
  };

  return (
    <div>
      <Dialog
        header="Add New Client"
        visible={showModal}
        onHide={() => setShowModal(!showModal)}
        style={{ width: "30vw" }}
        breakpoints={{ "960px": "75vw", "641px": "100vw" }}
        draggable={false}
        resizable={false}
      >
        <form onSubmit={handleSubmit(onSubmit)} className="pt-4">
          <div className="w-full">
            <label className="">Client Name</label>
            <input
              type="text"
              className="border-2 ps-2 py-1 mt-2 rounded-s-md border-gray-200 focus-visible:outline-none w-full"
              placeholder="Name"
              {...register("name", {
                required: "This field is required",
                message: "This field is required",
              })}
            />
            {errors?.name && (
              <p className="text-red-400 text-xs ">{errors?.name?.message}</p>
            )}
          </div>
          <div className="w-full pt-3">
            <label className="">Client Email</label>
            <input
              type="email"
              className="border-2 ps-2 py-1 mt-2 rounded-s-md border-gray-200 focus-visible:outline-none w-full"
              placeholder="exemple@gmail.com"
              {...register("email", {
                required: "This field is required",
                message: "This field is required",
              })}
            />
            {errors?.email && (
              <p className="text-red-400 text-xs">{errors?.email?.message}</p>
            )}
          </div>
          <div className="w-full pt-3">
            <label className="">Contact Number</label>
            <input
              type="text"
              className="border-2 ps-2 py-1 mt-2 rounded-s-md border-gray-200 focus-visible:outline-none w-full"
              placeholder="+880175321456"
              {...register("contact_number", {
                required: "This field is required",
                pattern: {
                  value: /(^(\+8801))[|3-9]{1}(\d){8}$/i,
                  message: "Invalid Phone Number",
                },
              })}
            />
            {errors?.contact_number && (
              <p className="text-red-400 text-xs ">
                {errors?.contact_number?.message}
              </p>
            )}
          </div>
          <div className="w-full pt-3">
            <div>
              <label className="">Address</label>
              <input
                type="text"
                className="border-2 ps-2 py-1 mt-2 rounded-s-md border-gray-200 focus-visible:outline-none w-full"
                placeholder="Address"
                {...register("address", {
                  required: "This field is required",
                  minLength: {
                    value: 20,
                    message: "provide address in minimum 20 letter",
                  },
                  maxLength: {
                    value: 300,
                    message: "provide address in  maximum 300 letter",
                  },
                })}
              />
            </div>
            {errors?.address?.type === "minLength" && (
              <p className="text-red-400 text-xs pl-[125px]">
                {errors?.address?.message}
              </p>
            )}
            {errors?.address?.type === "maxLength" && (
              <p className="text-red-400 text-xs pl-[125px]">
                {errors?.address?.message}
              </p>
            )}
          </div>
          <input
            className="cursor-pointer px-4 py-2 mt-4 rounded bg-cyan-700  text-white font-medium text-sm"
            type="submit"
            value="Submit"
          />
        </form>
      </Dialog>
    </div>
  );
};

export default CreateClient;

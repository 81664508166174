import React from "react";
import Notices from "../Notices/Notices";
import Breadcrumbs from "../Breadcrumbs/Breadcrumbs";

const NoticesPage = () => {
  const data = [
    {
      name: "Notice",
      route: null,
    },
  ];
  return (
    <>
      <Breadcrumbs data={data}/>
      <Notices/>
    </>
  );
};

export default NoticesPage;

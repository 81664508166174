import React, { useState } from "react";
import useData from "../../ContextApi/UseData/useData";
import { MainUrl } from "../../utils/helper";
import { toast } from "react-toastify";
import { Dialog } from "primereact/dialog";

const UpdateMobileAllowance = ({ UpdateData }) => {
  const {
    update,
    setUpdate,
    showUpdateMobile,
    setShowUpdateMobile,
    selectData,
  } = UpdateData;

  const [amount, setAmount] = useState();
  const { token } = useData().user;

  const handleUpdate = (e) => {
    e.preventDefault();
    const balance = amount || selectData?.amount_bdt;
    const formData = {
      amount_bdt: balance,
    };

    if (!token) {
      return;
    }
    let updateNote = async () => {
      const res = await fetch(
        `${MainUrl.url}/api/v1/mobile_allowance/policy/${selectData?.id}`,
        {
          method: "patch",
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
          body: JSON.stringify(formData),
        }
      );

      if (res.ok) {
        setUpdate(!update);
        toast.success("successfully updated");
        setShowUpdateMobile(!showUpdateMobile);
      } else {
        const resData = await res.json();
        toast.error(resData.message);
        setShowUpdateMobile(!showUpdateMobile);
      }
    };

    updateNote();
  };

  return (
    <Dialog
      header="Create Mobile Allowance"
      visible={showUpdateMobile}
      onHide={() => setShowUpdateMobile(!showUpdateMobile)}
      style={{ width: "30vw" }}
      breakpoints={{ "960px": "75vw", "641px": "100vw" }}
      draggable={false}
      resizable={false}
    >
      <form onSubmit={handleUpdate} className="pt-4">
        <div className="flex flex-col mb-2">
          <label className="pb-1 ps-1"> Department Name</label>
          <input
            type="number"
            className="border-2 ps-2 py-1 rounded-s-md border-gray-200 focus-visible:outline-none w-full"
            placeholder={`${selectData?.department?.name}`}
            readOnly
          />
        </div>

        <div className="flex flex-col mb-2">
          <label className="pb-1 ps-1"> Allowance Amount</label>
          <input
            type="number"
            onChange={(e) => setAmount(e.target.value)}
            className="border-2 ps-2 py-1 rounded-s-md border-gray-200 focus-visible:outline-none w-full"
            placeholder="Amount"
            name="amount_bdt"
            defaultValue={selectData?.amount_bdt}
          />
        </div>

        <input
          className="cursor-pointer px-4 py-1 rounded-md bg-cyan-700  text-white text-base font-medium "
          type="submit"
          value="Submit"
        />
      </form>
    </Dialog>
  );
};

export default UpdateMobileAllowance;

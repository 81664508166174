import React from "react";
import Breadcrumbs from "../Breadcrumbs/Breadcrumbs";
import LeavePolicy from "../LeavePolicy/LeavePolicy";

const GenLeavePolicyPage = () => {
  const data = [
    {
      name: "Leave Policy",
    },
  ];
  return (
    <>
      <Breadcrumbs data={data} />
      <LeavePolicy />
    </>
  );
};

export default GenLeavePolicyPage;

import React from "react";
import Breadcrumbs from "../Breadcrumbs/Breadcrumbs";
import Designations from "../Designations/Designations";

const DesignationsPage = () => {
  const data = [
    {
      name: "Designation",
    },
  ];
  return (
    <>
      <Breadcrumbs data={data} />
      <Designations />
    </>
  );
};

export default DesignationsPage;

import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import React, { useEffect, useState } from "react";
import { AiOutlinePlus } from "react-icons/ai";
import { IoIosRefresh } from "react-icons/io";
import { toast } from "react-toastify";
import { MainUrl, showDummyImage } from "../../utils/helper";
import useData from "../../ContextApi/UseData/useData";
import Loader from "../Loader/Loader";
import { useLocation, useSearchParams } from "react-router-dom";
import Pagination from "../Pagination/Pagination";

const DailyOverTime = () => {
  const { token } = useData().user;
  const [DailyOverTime, setDailyOverTime] = useState(null);
  const [update, setUpdate] = useState(false);
  const [totalData, setTotalData] = useState(null);
  const [filterData, setFilterData] = useState({ name: "", office_id: "" });
  const [changeValue, setChangeValue] = useState({});
  const [isLoader, setIsLoader] = useState(false);

  const [viewData, setViewData] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchParams, setSearchParams] = useSearchParams();
  const [pageCount, setPageCount] = useState();
  const location = useLocation();

  useEffect(() => {
    if (!token) {
      return;
    }

    const page = location?.search?.split("=")[1];
    if (page) {
      setPageNumber(+page);
      setCurrentPage(+page);
    }

    const fetchData = async () => {
      try {
        setIsLoader(true);
        const res = await fetch(
          `${MainUrl.url}/api/v1//overtime/logs/all/${viewData}/${pageNumber}?${
            filterData?.name && `name=${filterData?.name}`
          }${filterData?.office_id && `&office_id=${filterData?.office_id}`}`,
          {
            method: "Get",
            headers: {
              Authorization: token,
            },
          }
        );
        const data = await res.json();
        if (res.ok) {
          setDailyOverTime(data.data?.records);
          setTotalData(data.data?.totalData);
          setIsLoader(false);
        }
      } catch (error) {
        toast.error(error?.message);
        setIsLoader(false);
      }
    };
    fetchData();
  }, [
    update,
    pageNumber,
    viewData,
    token,
    filterData,
    location,
    currentPage,
    searchParams,
  ]);

  useEffect(() => {
    const pages = Math.ceil(totalData / viewData);
    setPageCount(pages);
  }, [totalData, viewData]);

  const EmployeeNameTemplate = (data) => {
    return (
      <div className="flex items-center gap-x-4">
        <div className="h-10 w-10  ">
          <img
            src={`${MainUrl.url}/${data?.user?.user_profile?.profile_photo}`}
            onError={({ currentTarget }, imageSize = "100x100") =>
              showDummyImage({ currentTarget }, imageSize)
            }
            alt=""
            className=" h-full w-full rounded-full"
          />
        </div>
        <h3 className="text-lg font-medium">
          {data?.user?.user_profile?.name}
        </h3>
      </div>
    );
  };

  const OfficeIdTemplate = (data) => {
    return <p>{data?.user?.office_id}</p>;
  };

  const HourTemplate = (data) => {
    return <p>{data?.hour_count}</p>;
  };

  const AmountTemplate = (data) => {
    return <p>{data?.total_amount}</p>;
  };
  const DateTemplate = (data) => {
    return <p>{data?.date}</p>;
  };



  const renderHeader = () => {
    const setValue = (e) => {
      setChangeValue({
        ...changeValue,
        [e.target?.name]: e.target.value,
      });
    };

    const handleFresh = () => {
      setFilterData({ name: "", office_id: "" });
      setChangeValue({ name: "", office_id: "" });
      setUpdate(!update);
    };

    const handleSearch = async (e) => {
      e.preventDefault();
      const data = {
        name: changeValue.name ? changeValue.name : "",
        office_id: changeValue.office_id ? changeValue.office_id : "",
      };
      setFilterData(data);
    };

    return (
      <div className="">
        <div>
          <div className="grid grid-cols-12 items-center justify-between">
            <div className="col-span-8">
              <div className="grid grid-cols-12 gap-x-2 items-center">
                <div className="col-span-10">
                  <form
                    onSubmit={handleSearch}
                    className="flex gap-x-3  w-full myInput"
                  >
                    <div className="w-full">
                      <input
                        onChange={(e) => setValue(e)}
                        className="ps-3 py-1.5 border border-gray-300 rounded-lg inputShadow w-full"
                        placeholder="Search by Name"
                        name="name"
                        value={changeValue.name}
                      />
                    </div>
                    <div className="w-full">
                      <input
                        onChange={(e) => setValue(e)}
                        className="ps-3 py-1.5 border border-gray-300 rounded-lg inputShadow w-full"
                        placeholder="Search by Office Id"
                        name="office_id"
                        value={changeValue.office_id}
                      />
                    </div>

                    <div className="">
                      <button className="cursor-pointer px-4 py-2 w-full bg-cyan-700 rounded-md text-white font-medium text-sm">
                        Search
                      </button>
                    </div>
                  </form>
                </div>
                <div className="col-span-2 gap-x-2">
                  <div>
                    <span
                      onClick={handleFresh}
                      className="bg-cyan-700 text-white h-8 w-8 flex items-center justify-center rounded-full font-medium cursor-pointer"
                    >
                      <IoIosRefresh />
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-span-4">
              <div className="flex items-end justify-end">
                <button
                  // onClick={()=>setShowCreateModal(!showCreateModal)}
                  className="flex items-center gap-x-2  px-2 py-2.5 bg-cyan-700 text-white rounded-md"
                >
                  <span className="text-base font-semibold">
                    <AiOutlinePlus />
                  </span>
                  <span className="text-base font-medium">
                    Calculate OverTime
                  </span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  const header = renderHeader();

  return (
    <div className="bg-white pb-4 mb-4">
      {isLoader ? (
        <Loader />
      ) : (
        <DataTable
          size="small"
          header={header}
          value={DailyOverTime}
          // footer={footerTemplate}
          selectionMode="single"
          dataKey="id"
          tableStyle={{ Width: "100%" }}
        >
          <Column
            field="provident_fund_account?.user?.office_id"
            header="Name"
            body={EmployeeNameTemplate}
            className=""
          ></Column>

          <Column
            field="user?.office_id"
            header="Office ID"
            body={OfficeIdTemplate}
            sortable
            className=""
          ></Column>

          <Column
            field="total_hours"
            header="Hour"
            body={HourTemplate}
            sortable
            className=""
          ></Column>

          <Column
            field="provident_fund_account?.user?.office_id"
            header="Amount"
            body={AmountTemplate}
            sortable
            className=""
          ></Column>

          <Column
            field="provident_fund_account?.user?.office_id"
            header="Date"
            body={DateTemplate}
            sortable
            className=""
          ></Column>
        </DataTable>
      )}

      <div className="mt-4">
        {DailyOverTime?.length > 0 && (
          <Pagination
            currentPage={currentPage}
            pageNumber={pageNumber}
            setCurrentPage={setCurrentPage}
            setSearchParams={setSearchParams}
            pageCount={pageCount}
            setViewData={setViewData}
            viewData={viewData}
            setPageNumber={setPageNumber}
          />
        )}
      </div>
    </div>
  );
};

export default DailyOverTime;

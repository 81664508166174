import React from "react";
import OverTime from "../OverTime/OverTime";
import Breadcrumbs from "../Breadcrumbs/Breadcrumbs";

const OverTimePage = () => {
  const data = [
    {
      name: "Over Time Table",
    },
  ];
  return (
    <>
      <Breadcrumbs data={data} />
      <OverTime />
    </>
  );
};

export default OverTimePage;

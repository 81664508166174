import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { MainUrl } from "../../utils/helper";
import useData from "../../ContextApi/UseData/useData";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";

const CreateMobileAllowance = ({ CreateData }) => {
  const { createAllowance, setCreateAllowance, update, setUpdate } = CreateData;
  const [allDepartment, setAllDepartment] = useState(null);
  const [AllowanceData, setAllowanceData] = useState({});
  const [department, setDepartment] = useState(null)
  const { token } = useData().user;

  useEffect(() => {
    if (!token) {
      return;
    }

    const getDepartMent = async () => {
      try {
        const res = await fetch(`${MainUrl.url}/api/v1/department`, {
          method: "get",
          headers: {
            Authorization: token,
          },
        });
        const resData = await res.json();
        if (res.ok) {
          setAllDepartment(resData.data);
        }
      } catch (error) {
        toast.error(error.message);
      }
    };
    getDepartMent();
  }, [token]);

  // selcet modify data
  let departName = allDepartment?.map((depart) => {
    let obj = {};
    obj.label = depart?.name;
    obj.value = depart?.id;
    return obj;
  });

  const handleAllowance = (e, getter, setter) => {
    setter({
      ...getter,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = e.target;
    const departmentName =department;
    AllowanceData.department_id = departmentName;

    const postAllowance = async () => {
      const res = await fetch(
        `${MainUrl.url}/api/v1/mobile_allowance/policies`,
        {
          method: "post",
          headers: {
            "Content-type": "application/json",
            Authorization: token,
          },
          body: JSON.stringify(AllowanceData),
        }
      );
      const resData = await res.json();
      if (res.ok) {
        setUpdate(!update);
        toast.success("successfully updated");
        setCreateAllowance(!createAllowance);
        form.reset()
      } else {
        toast.error(resData.message);
        setCreateAllowance(!createAllowance);
      }
    };
    postAllowance();
  };

  return (
    <Dialog
      header="Create Mobile Allowance"
      visible={createAllowance}
      onHide={() => setCreateAllowance(!createAllowance)}
      style={{ width: "30vw" }}
      breakpoints={{ "960px": "75vw", "641px": "100vw" }}
      draggable={false}
      resizable={false}
    >
      <div>
        <form onSubmit={handleSubmit} className="pt-4 common">
          <div className="flex flex-col mb-2">
            <label className="pb-1 ps-1">Select Department</label>
            <Dropdown
              value={department}
              onChange={(e) => setDepartment(e.value)}
              options={departName}
              optionLabel="label"
              placeholder="Select a Department"
              filter
              showClear
              className="w-full md:w-14rem"
            />
          </div>

          <div className="flex flex-col mb-2">
            <label className="pb-1 ps-1"> Allowance Amount</label>
            <input
              type="number"
              className="border-2 ps-2 py-1 rounded-s-md border-gray-200 focus-visible:outline-none w-full"
              onChange={(e) =>
                handleAllowance(e, AllowanceData, setAllowanceData)
              }
              placeholder="Amount"
              name="amount_bdt"
            />
          </div>

          <input
            className="cursor-pointer px-4 py-1 rounded-md bg-cyan-700  text-white text-base font-medium "
            type="submit"
            value="Submit"
          />
        </form>
      </div>
    </Dialog>
  );
};

export default CreateMobileAllowance;

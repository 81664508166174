import React from 'react'
import Breadcrumbs from '../Breadcrumbs/Breadcrumbs'
import EmergencyRelationShip from '../EmergencyRelationShip/EmergencyRelationShip'


const GenEmerGencyRelationPage = () => {
    const data = [
        {
          name: "Emergency RelationShip",
        },
      ];
  return (
    <>
     <Breadcrumbs data={data}/>
     <EmergencyRelationShip/>   
    </>
  )
}

export default GenEmerGencyRelationPage
import React from "react";
import { toast } from "react-toastify";
import { MainUrl } from "../../utils/helper";
import useData from "../../ContextApi/UseData/useData";
import { Dialog } from "primereact/dialog";

const CreateLeaveTypeModal = ({ createModalData }) => {
  const { crateTypeModal, setCreateTypeModal, update, setUpdate } =
    createModalData;
  const { token } = useData().user;

  const handleCreateLeave = (e) => {
    e.preventDefault();
    const form = e.target;
    const type = form.type.value;
    const fromData = {
      name: type,
    };

    const postModalData = async () => {
      const res = await fetch(`${MainUrl.url}/api/v1/leave/leave_types`, {
        method: "Post",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
        body: JSON.stringify(fromData),
      });
      const resData = await res.json();
      if (res.ok) {
        toast.success(resData.status);
        setUpdate(!update);
      } else {
        toast.error(resData.message);
      }
    };
    postModalData();
  };

  return (
    <div>
      <Dialog
        visible={crateTypeModal}
        header="Add New Leave type"
        onHide={() => setCreateTypeModal(!crateTypeModal)}
        style={{ width: "30vw" }}
        breakpoints={{ "960px": "75vw", "641px": "100vw" }}
        draggable={false}
        resizable={false}
      >
        {/* <h5 className="text-xl border-b py-3">Add New Leave type</h5> */}
        <div>
          <form onSubmit={handleCreateLeave} className="pt-4">
            <div className="flex w-full ">
              <input
                type="text"
                className="border-2 ps-2 py-1 rounded-s-md border-gray-200 focus-visible:outline-none w-4/5"
                name="type"
                placeholder="Leave Type"
                required
              />
              <input
                className="cursor-pointer px-4 rounded-e-md bg-cyan-700  text-white font-medium text-sm w-1/5"
                type="submit"
                value="Submit"
              />
            </div>
          </form>
        </div>
      </Dialog>
    </div>
  );
};

export default CreateLeaveTypeModal;

import React, { useState } from "react";
import { toast } from "react-toastify";
import Rodal from "rodal";
import useData from "../../ContextApi/UseData/useData";
import { MainUrl } from "../../utils/helper";

const UpdateModal = ({ updateData }) => {
  const {token} = useData().user
    
  const {
    update,
    setUpdate,
    showUpdate,
    setShowUpdate,
    updateModaldata,
    UpdateURL,
    UpdateThem,
    successUpdateMessage,
  } = updateData;
  const [ChangeDefaultName, setChangeDefaultName] = useState(null);

  const handleCreateDeductionType = (e) => {
    if(!token){
      return
    }
    e.preventDefault();
    if (!ChangeDefaultName) {
      toast.error("please Change Name or close Modal");
      return;
    }
    const fromData = {
      name: ChangeDefaultName,
    };
    const postModalData = async () => {
      const res = await fetch(
        `${MainUrl.url}/api/v1/${UpdateURL}/${updateModaldata.id}`,
        {
          method: "put",
          headers: {
            "Content-type": "application/json",
            "Authorization":token,
          },
          body: JSON.stringify(fromData),
        }
      );
      const resData = await res.json();
      if (res.ok) {
        setUpdate(!update);
        toast.success(successUpdateMessage);
        setShowUpdate(!showUpdate);
        e.target.reset()
    
      } else {
        toast.error(resData.message);
        setShowUpdate(!showUpdate);
      }
    };
    postModalData();
  };

  return (
    <div>
      <Rodal
        visible={showUpdate}
        onClose={() => setShowUpdate(!showUpdate)}
        customStyles={{
          height: "auto",
          bottom: "auto",
          top: "30%",
          transform: "translateY(-50%)",
        }}
      >
        <h5 className="text-xl border-b py-3">{UpdateThem}</h5>
        <form onSubmit={handleCreateDeductionType} className="pt-4">
          <div className="flex w-full ">
            <input
              type="text"
              className="border-2 ps-2 py-1 rounded-s-md border-gray-200 focus-visible:outline-none w-4/5"
              defaultValue={updateModaldata?.name}
              onChange={(e) => setChangeDefaultName(e.target.value)}
            />
            <input
              className="cursor-pointer px-4 rounded-e-md bg-cyan-700  text-white font-medium text-sm w-1/5"
              type="submit"
              value="Submit"
            />
          </div>
        </form>
      </Rodal>
    </div>
  );
};



export default UpdateModal
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import React, { useEffect, useState } from "react";
import { AiFillEdit, AiOutlineDelete, AiOutlinePlus } from "react-icons/ai";
import { toast } from "react-toastify";
import CreateEligibility from "./CreateEligibility";
import UpdateEligibility from "./UpdateEligibility";
import moment from "moment";
import { Menu, MenuButton, MenuItem } from "@szhsin/react-menu";
import { BsThreeDotsVertical } from "react-icons/bs";
import { MainUrl } from "../../utils/helper";
import useData from "../../ContextApi/UseData/useData";
import Loader from "../Loader/Loader";
import DeleteModal from "../AllEmployee/DeleteModal/DeleteModal";

const Eligibility = () => {
  const { token } = useData().user;
  const [Eligibility, setEligibility] = useState(null);
  const [update, setUpdate] = useState(false);
  const [show, setShow] = useState(false);
  const [selectData, setSelectData] = useState(null);
  const [showEligibility, setShowEligibility] = useState(false);
  const [isLoader, setIsLoader] = useState(false);
  const [showDelete, setShowDelete] = useState(false);

  // GET TABLE DATA
  useEffect(() => {
    if (!token) {
      return;
    }
    const fetchData = async () => {
      try {
        setIsLoader(true);
        const res = await fetch(`${MainUrl.url}/api/v1/incentive/eligibility`, {
          method: "get",
          headers: {
            Authorization: token,
          },
        });
        const data = await res.json();
        if (res.ok) {
          setEligibility(data.data);
          setIsLoader(false);
        }
      } catch (error) {
        toast.error(error.message);
        setIsLoader(false);
      }
    };
    fetchData();
  }, [update, token]);

  const NameTemplate = (data) => {
    return <p className="text-base">{data.name}</p>;
  };

  const ModifyDateTemplate = (data) => {
    const formattedDate = moment(data.modified_at).format("YYYY-MM-DD");
    return <p className="text-base">{formattedDate}</p>;
  };

  const ActionTemplate = (data) => {
    return (
      <Menu
        direction={"right"}
        align={"center"}
        menuButton={
          <MenuButton>
            <BsThreeDotsVertical />
          </MenuButton>
        }
        transition
      >
        <MenuItem
          className="hover:text-red-400"
          onClick={() => {
            setSelectData(data);
            setShow(!show);
          }}
        >
          <span className="mr-2">
            <AiFillEdit />
          </span>
          UPdate
        </MenuItem>
        <MenuItem
          className="hover:text-red-400"
          onClick={() => {
            setSelectData(data);
            setShowDelete(!showDelete);
          }}
        >
          <span>
            <AiOutlineDelete className="mr-2" />
          </span>{" "}
          Delete
        </MenuItem>
      </Menu>
    );
  };

  const renderHeader = () => {
    return (
      <div className="grid grid-cols-12 justify-between">
        <div className="col-span-6">
          <h2 className="text-xl font-semibold">Eligibility</h2>
        </div>

        <div className="col-span-6">
          <div className="flex items-end justify-end">
            <button
              onClick={() => setShowEligibility(!showEligibility)}
              className="flex items-center gap-x-2  px-2 py-2.5 bg-cyan-700 text-white rounded-md"
            >
              <span className="text-base font-semibold">
                <AiOutlinePlus />
              </span>
              <span className="text-base font-medium">Eligibility</span>
            </button>
          </div>
        </div>
      </div>
    );
  };
  const header = renderHeader();

  const CreateData = {
    showEligibility,
    setShowEligibility,
    update,
    setUpdate,
  };

  const UpdateData = {
    show,
    setShow,
    update,
    setUpdate,
    selectData,
  };

  const DeleteUrl = "api/v1/incentive/eligibility";
  const DeleteData = {
    showDelete,
    setShowDelete,
    update,
    setUpdate,
    selectData,
    DeleteUrl,
  };

  return (
    <div className="">
      {isLoader ? (
        <Loader />
      ) : (
        <DataTable
          size="small"
          paginator
          rows={5}
          rowsPerPageOptions={[5, 10, 25, 50]}
          header={header}
          value={Eligibility}
          selectionMode="single"
          dataKey="id"
          tableStyle={{ Width: "100%" }}
        >
          <Column
            field="provident_fund_account?.user?.office_id"
            header="Eligibility Name"
            className=""
            body={NameTemplate}
          ></Column>

          <Column
            field="provident_fund_account?.user?.office_id"
            header="Modify"
            body={ModifyDateTemplate}
            className=""
          ></Column>

          <Column
            field="provident_fund_account?.user?.office_id"
            header="Action"
            body={ActionTemplate}
            className=""
          ></Column>
        </DataTable>
      )}

      {<CreateEligibility CreateData={CreateData} />}
      {<UpdateEligibility UpdateData={UpdateData} />}
      {<DeleteModal DeleteData={DeleteData} />}
    </div>
  );
};

export default Eligibility;

import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import React, { useEffect, useState } from "react";
import { AiOutlineDelete, AiOutlinePlus } from "react-icons/ai";
import { toast } from "react-toastify";
import { MainUrl, showDummyImage } from "../../utils/helper";
import useData from "../../ContextApi/UseData/useData";
import { useLocation, useSearchParams } from "react-router-dom";
import Pagination from "../Pagination/Pagination";
import Loader from "../Loader/Loader";
import { Menu, MenuButton, MenuItem } from "@szhsin/react-menu";
import { BsThreeDotsVertical } from "react-icons/bs";
import CreateSpecialAllowance from "./CreateSpecialAllowance";
import DeleteModal from "../AllEmployee/DeleteModal/DeleteModal";

const SpecialAllowance = () => {
  const { token } = useData().user;
  const [specialAllowance, setSpecialAllowance] = useState(null);
  const [update, setUpdate] = useState(false);
  const [isLoader, setIsLoader] = useState(false);
  const [totalData, setTotalData] = useState(null);
  const [viewData, setViewData] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageCount, setPageCount] = useState();
  const [searchParams, setSearchParams] = useSearchParams();
  const location = useLocation();
  const [showModal, setShowModal] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [selectData, setSelectData] = useState(null);

  useEffect(() => {
    if (!token) {
      return;
    }
    const page = location?.search?.split("=")[1];
    if (page) {
      setPageNumber(+page);
      setCurrentPage(+page);
    }
    const fetchData = async () => {
      try {
        setIsLoader(true);
        const res = await fetch(
          `${MainUrl.url}/api/v1/special_allowance/all_allowances/${viewData}/${pageNumber}`,
          {
            method: "get",
            headers: {
              Authorization: token,
            },
          }
        );
        const data = await res.json();
        if (res.ok) {
          console.log(data.data?.results);
          setSpecialAllowance(data.data?.records);
          setTotalData(data.data?.totalData);
          setIsLoader(false);
        }
      } catch (error) {
        toast.error(error?.message);
        setIsLoader(false);
      }
    };
    fetchData();
  }, [pageNumber, viewData, location, token, searchParams, update]);

  // get total page number
  useEffect(() => {
    const pages = Math.ceil(totalData / viewData);
    setPageCount(pages);
  }, [totalData, viewData]);

  const EmployeeNameTemplate = (data) => {
    console.log("data--->", data);
    return (
      <div className="flex items-center gap-x-4">
        <div className="h-10 w-10  ">
          <img
            src={`${MainUrl.url}${data?.user?.user_profile?.profile_photo}`}
            onError={({ currentTarget }, imageSize = "100x100") =>
              showDummyImage({ currentTarget }, imageSize)
            }
            alt=""
            className=" h-full w-full rounded-full"
          />
        </div>
        <h3 className="text-base ">{data.user?.user_profile?.name}</h3>
      </div>
    );
  };

  const OfficeIdTemplate = (data) => {
    return <p className="text-base">{data.user?.office_id}</p>;
  };

  const AmountTemplate = (data) => {
    return <p className="text-base">{data?.amount}</p>;
  };

  const StartDateTemplate = (data) => {
    return <p>{data?.start_date}</p>;
  };

  const EndDateTemplate = (data) => {
    return <p>{data?.end_date}</p>;
  };

  const ActionTemplate = (data) => {
    return (
      <Menu
        direction={"right"}
        align={"center"}
        menuButton={
          <MenuButton>
            <BsThreeDotsVertical />
          </MenuButton>
        }
        transition
      >
        <MenuItem
          className="hover:text-red-400"
          onClick={() => {
            setShowDelete(!showDelete);
            setSelectData(data);
          }}
        >
          <span>
            <AiOutlineDelete className="mr-2" />
          </span>
          Delete
        </MenuItem>
      </Menu>
    );
  };

  const renderHeader = () => {
    return (
      <div className="flex items-end justify-end">
        <button
          onClick={() => setShowModal(!showModal)}
          className="flex items-center gap-x-2  px-2 py-2.5 bg-cyan-700 text-white rounded-md"
        >
          <span className="text-base font-semibold">
            <AiOutlinePlus />
          </span>
          <span className="text-base font-medium">Special Allowance</span>
        </button>
      </div>
    );
  };
  const header = renderHeader();

  const modalData = {
    showModal,
    setShowModal,
    update,
    setUpdate,
  };

  const DeleteUrl = "api/v1/special_allowance/one/allowance";
  const DeleteData = {
    showDelete,
    setShowDelete,
    update,
    setUpdate,
    selectData,
    DeleteUrl,
  };

  return (
    <div className="bg-white pb-4">
      {isLoader ? (
        <Loader />
      ) : (
        <DataTable
          size="small"
          header={header}
          value={specialAllowance}
          selectionMode="single"
          dataKey="id"
          tableStyle={{ Width: "100%" }}
        >
          <Column
            field=""
            body={EmployeeNameTemplate}
            header="EMPLOYEE"
            className=""
          ></Column>
          <Column
            field=""
            body={OfficeIdTemplate}
            header="Office ID"
            className=""
          ></Column>

          <Column
            field=""
            body={AmountTemplate}
            header="Amount"
            className=""
          ></Column>

          <Column
            field=""
            body={StartDateTemplate}
            header="Start Date"
            className=""
          ></Column>

          <Column
            field=""
            body={EndDateTemplate}
            header="Start Date"
            className=""
          ></Column>

          <Column
            field=""
            body={ActionTemplate}
            header="Start Date"
            className=""
          ></Column>
        </DataTable>
      )}
      <div className="mt-4">
        {specialAllowance?.length > 0 && (
          <Pagination
            currentPage={currentPage}
            pageNumber={pageNumber}
            setCurrentPage={setCurrentPage}
            setSearchParams={setSearchParams}
            pageCount={pageCount}
            setViewData={setViewData}
            viewData={viewData}
            setPageNumber={setPageNumber}
          />
        )}
      </div>
      {<CreateSpecialAllowance modalData={modalData} />}
      {<DeleteModal DeleteData={DeleteData} />}
    </div>
  );
};

export default SpecialAllowance;

import React from "react";
import Breadcrumbs from "../Breadcrumbs/Breadcrumbs";
import DeductionTable from "./DeductionTable";

const DeductionPage = () => {
  const data = [
    {
      name: "Deduction-Table",
    },
  ];
  return (
    <>
      <Breadcrumbs data={data} />
      <DeductionTable />
    </>
  );
};

export default DeductionPage;

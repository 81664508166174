import { Dialog } from "primereact/dialog";
import React, { useEffect, useState } from "react";
import { MainUrl } from "../../utils/helper";
import CreatableSelect from "react-select/creatable";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import useData from "../../ContextApi/UseData/useData";
import Noimg from "../../Assets/fileView.jpg";
import { toast } from "react-toastify";

const CreateNoticeModal = ({ CreateDate }) => {
  const { token } = useData().user;
  const { setCreateNotice, createNotice, setUpdate, update } = CreateDate;
  const [selectNotice, setSelectNotice] = useState("");

  const [image, setImage] = useState(null);
  const [selectImg, setSelectImg] = useState(null);
  const [description, setDescription] = useState(null);
  const [heading, setHeading] = useState(null);
  const [types, setTypes] = useState(null);

  useEffect(() => {
    if (!token) {
      return;
    }
    const fetchData = async () => {
      try {
        const res = await fetch(`${MainUrl.url}/api/v1/notice/type`, {
          method: "Get",
          headers: {
            Authorization: token,
          },
        });
        const resData = await res.json();
        if (res.ok) {
          setTypes(resData?.data);
        }
      } catch (error) {
        console.error(error.message);
      }
    };

    fetchData();
  }, [token]);

  const noticeTypes = types?.map((type) => {
    let obj = {};
    obj.label = type?.name;
    obj.value = type?.id;
    return obj;
  });

  const imgChange = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(e.target.files[0]);
      fileReader.onload = () => {
        setImage(fileReader.result);
        setSelectImg(e.target.files[0]);
      };
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = {
      headline: heading,
      notice_type_id: selectNotice,
      notice_file: selectImg,
      description: description,
    };
    let formData = new FormData();
    for (const i in data) {
      const element = data[i];
      formData.append(`${i}`, element);
    }
    const postData = async () => {
      const res = await fetch(`${MainUrl.url}/api/v1/notice/post`, {
        method: "Post",
        headers: {
          Authorization: token,
        },
        body: formData,
      });
      if (res.ok) {
        toast.success("Notice Created SuccessFully");
        setCreateNotice(!createNotice);
        setUpdate(!update);
        setImage(null);
        setDescription(null);
      } else {
        const resData = await res.json();
        toast.warn(resData.message);
      }
    };
    postData();
  };
  return (
    <div>
      <Dialog
        header="Create Notice"
        visible={createNotice}
        onHide={() => {
          setCreateNotice(!createNotice);
          setImage(null);
          setDescription(null);
        }}
        style={{ width: "50vw" }}
        breakpoints={{ "960px": "75vw", "641px": "100vw" }}
        draggable={false}
      >
        <form
          onSubmit={handleSubmit}
          className="border-t border-dashed notice-update pt-2"
        >
          <div className="flex flex-col gap-2">
            <label className="ps-2">Heading</label>
            <InputText
              onChange={(e) => setHeading(e.target.value)}
              placeholder="Header"
            />
          </div>
          <div className="flex flex-col gap-2 pt-2">
            <label className="ps-2">Notice Type</label>
            <CreatableSelect
              onChange={(e) => setSelectNotice(e?.value)}
              isClearable
              options={noticeTypes}
            />
          </div>

          <div className="mt-4 h-80 w-full border border-dashed relative">
            <img
              src={image ? image : `${Noimg}`}
              alt=""
              className=" h-full w-full"
            />
            <input
              type="file"
              className={`absolute top-0 left-0 w-full h-full opacity-0`}
              onChange={imgChange}
            />
          </div>
          <div className="pt-4">
            <label className="ps-2">Description</label>
            <InputTextarea
              autoResize
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              placeholder="Description"
              rows={5}
              className="w-full"
            />
          </div>
          <button className="bg-cyan-600 text-white px-4 py-2 rounded">
            Update
          </button>
        </form>
      </Dialog>
    </div>
  );
};

export default CreateNoticeModal;

import React, { useEffect, useState } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { RxCross1 } from "react-icons/rx";
import { AiOutlinePlus } from "react-icons/ai";
import { toast } from "react-toastify";
import { MainUrl } from "../../utils/helper";
import useData from "../../ContextApi/UseData/useData";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";

const CreateSubDepartment = ({ CreateModal }) => {

  const { show, setShow, update, setUpdate } = CreateModal;
  const [departmentHead, setDepartmentHead] = useState(null);
  const { token } = useData().user;
  const [allDepartment, setAllDepartment] = useState(null);
  // const [selectDepartment, setSelectDepartment] = useState(null);

  useEffect(() => {
    if (!token) {
      return;
    }
    const fetchData = async () => {
      try {
        const res = await fetch(`${MainUrl.url}/api/v1/department`, {
          method: "Get",
          headers: {
            Authorization: token,
          },
        });
        const data = await res.json();
        if (res.ok) {
          setAllDepartment(data.data);
        }
      } catch (error) {
        toast.error(error?.message);
      }
    };
    fetchData();
  }, [token]);

  const subDep = allDepartment?.map((user) => {
    let obj = {};
    obj.label = user?.name;
    obj.value = user?.id;
    return obj;
  });

  const {
    register,
    handleSubmit,
    reset,
    // formState: { errors },
    control,
  } = useForm({
    defaultValues: {
      sub_department: [{ name: "" }],
    },
  });

  const { fields, append, remove } = useFieldArray({
    name: "sub_department",
    control,
  });

  const submitDeptName = (data) => {
    if (!departmentHead) {
      toast.error("Department Head is required");
      return;
    }
    const validatedSubDepartments =
      data?.sub_department.length > 0 &&
      data?.sub_department?.filter((dept) => dept?.name && dept);
    const postData = {
      sub_departments:
        validatedSubDepartments?.length > 0 ? validatedSubDepartments : null,
      parent_id: departmentHead,
    };
    let CreactePostDepartment = async () => {
      const res = await fetch(`${MainUrl.url}/api/v1/sub_department`, {
        method: "post",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
        body: JSON.stringify(postData),
      });
      const resData = await res.json();
      if (res.ok) {
        setUpdate(!update);
        toast.success("successfully updated");
        setShow(!show);
        setDepartmentHead(null)
        reset()
      } else {
        toast.error(resData.message);
        setShow(!show);
        setDepartmentHead(null)
      }
    };
    CreactePostDepartment();
  };
  return (
    <div>
      <Dialog
        visible={show}
        header="Add New DepartMent"
        onHide={() => {
          setShow(!show);
          setDepartmentHead(null)
          reset()
        }}
        style={{ width: "30vw" }}
        breakpoints={{ "960px": "50vw", "641px": "100vw" }}
        draggable={false}
        resizable={false}
      >
        <form action="" onSubmit={handleSubmit(submitDeptName)}>
          <label htmlFor="" className="text-small common-text-color-body">
            Department
          </label>
          <Dropdown
            value={departmentHead}
            onChange={(e) => setDepartmentHead(e?.value)}
            options={subDep}
            optionLabel="label"
            placeholder="Select a User"
            filter
            showClear
            className="w-full md:w-14rem"
          />

          <label htmlFor="" className="flex items-center gap-3 pt-4">
            <span className="text-small common-text-color-body font-[500]">
              Sub Department
            </span>
            <AiOutlinePlus
              size={25}
              onClick={() => append({ name: "" })}
              className="p-0.5 bg-cyan-700 text-white font-semibold text-center rounded-md cursor-pointer"
            />
          </label>

          <div
            className={`${
              fields.length > 5 ? "overflow-y-scroll h-72 " : ""
            } my-3`}
          >
            {fields.map((field, index) => {
              return (
                <div key={field.id} className="relative mb-3">
                  <input
                    type="text"
                    {...register(`sub_department.${index}.name`)}
                    placeholder="Sub Department"
                    className="w-full py-2 pl-3 border rounded-md focus:outline-none text-small common-text-color-body"
                  />
                  {index > 0 && (
                    <RxCross1
                      className="absolute right-2 top-3.5 cursor-pointer"
                      onClick={() => remove(index)}
                      size={16}
                    />
                  )}
                </div>
              );
            })}
          </div>

          <div className="flex justify-between">
            <button className="bg-cyan-700 text-white px-4 py-2 rounded">
              Submit
            </button>
          </div>
        </form>
      </Dialog>
    </div>
  );
};

export default CreateSubDepartment;

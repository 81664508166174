import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import React, { useEffect, useState } from "react";
import { AiFillEdit, AiOutlineDelete, AiOutlinePlus } from "react-icons/ai";
import { IoIosRefresh } from "react-icons/io";
import { toast } from "react-toastify";
import { MainUrl, showDummyImage } from "../../utils/helper";
import useData from "../../ContextApi/UseData/useData";
import { Menu, MenuButton, MenuItem } from "@szhsin/react-menu";
import { BsThreeDotsVertical } from "react-icons/bs";
import moment from "moment";
import Pagination from "../Pagination/Pagination";
import { useLocation, useSearchParams } from "react-router-dom";
import CreateTransportLog from "./CreateTransportLog";

const TransportLog = () => {
  const { token } = useData().user;
  const [TransportLogData, setTransportLogData] = useState(null);
  const [update, setUpdate] = useState(false);
  const [totalData, setTotalData] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [viewData, setViewData] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageCount, setPageCount] = useState();
  const [searchParams, setSearchParams] = useSearchParams();
  const location = useLocation();
  const [showLog , setShowLog] = useState(false)

  useEffect(() => {
    if (!token) {
      return;
    }
    const page = location?.search?.split("=")[1];
    if (page) {
      setPageNumber(+page);
      setCurrentPage(+page);
    }
    const fetchData = async () => {
      try {
        const res = await fetch(
          `${MainUrl.url}/api/v1/transport/logs/${viewData}/${pageNumber}`,
          {
            method: "get",
            headers: {
              Authorization: token,
            },
          }
        );
        const data = await res.json();
        if (res.ok) {
          console.log(data.data?.results);
          setTransportLogData(data.data?.results);
          setTotalData(data.data?.totalCount);
        }
      } catch (error) {
        toast.error(error?.message);
      }
    };
    fetchData();
  }, [
    update,
    pageNumber,
    viewData,
    token,
    location,
    searchParams,
    currentPage,
  ]);

  // get total page number
  useEffect(() => {
    const pages = Math.ceil(totalData / viewData);
    setPageCount(pages);
  }, [totalData, viewData]);

  const EmployeeNameTemplate = (data) => {
    console.log("data--->", data);
    return (
      <div className="flex items-center gap-x-4">
        <div className="h-10 w-10  ">
          <img
            src={`${MainUrl.url}${data?.user?.user_profile?.profile_photo}`}
            onError={({ currentTarget }, imageSize = "100x100") =>
              showDummyImage({ currentTarget }, imageSize)
            }
            alt=""
            className=" h-full w-full rounded-full"
          />
        </div>
        <h3 className="text-lg ">{data?.user?.user_profile?.name}</h3>
      </div>
    );
  };
  const OfficeIdTemplate = (data) => {
    return <p>{data?.user?.office_id}</p>;
  };

  const TransportTypeTemplate = (data) => {
    return <p>{data.transport_type.name}</p>;
  };

  const DistanceTemplate = (data) => {
    return <p>{data?.distance}</p>;
  };

  const TravelTypeTemplate = (data) => {
    return <p>{data.in_city === 0 ? "In City" : "Out City"}</p>;
  };

  const AmountTemplate = (data) => {
    return <p>{data?.total_amount}</p>;
  };

  const dateTimeTemplate = (data) => {
    const date = data?.modified_at;
    const formatDate = moment(date).format("MM-DD-YYYY, h:mm a");
    return <p className="text-base">{formatDate}</p>;
  };

  const ActionTemplate = (data) => {
    return (
      <Menu
        direction={"right"}
        align={"center"}
        menuButton={
          <MenuButton>
            <BsThreeDotsVertical />
          </MenuButton>
        }
        transition
      >
        <MenuItem
          className="hover:text-red-400"
          onClick={() => {
            // setSelectData(data);
            // setShowUpdate(!ShowUpdate);
          }}
        >
          <span className="mr-2">
            <AiFillEdit />
          </span>
          Update
        </MenuItem>
        <MenuItem
          className="hover:text-red-400"
          onClick={() => {
            // setSelectData(data);
            // setShowDelete(!showDelete);
          }}
        >
          <span>
            <AiOutlineDelete className="mr-2" />
          </span>
          Delete
        </MenuItem>
      </Menu>
    );
  };

  const renderHeader = () => {
    const handleSearch = async (e) => {
      e.preventDefault();
    };

    // const handleFresh = () => {
    //   setUpdate(!update);
    //   setFilterName("");
    //   setFilterOfficeId("");
    // };
    return (
      <div className="">
        <div>
          <div className="grid grid-cols-12 items-center justify-between">
            <div className="col-span-8">
              <div className="grid grid-cols-12 gap-x-2 items-center">
                <div className="col-span-10">
                  <form
                    className="flex gap-x-3  w-full myInput"
                    onSubmit={handleSearch}
                  >
                    <div className="w-full">
                      <input
                        // ref={filterNameRef}
                        // onChange={(e) => setFilterName(e.target.value)}
                        className="ps-3 py-1.5 border border-gray-300 rounded-lg inputShadow w-full"
                        placeholder="Search by Name"
                        name="name"
                      />
                    </div>
                    <div className="w-full">
                      <input
                        // onChange={(e) => setFilterOfficeId(e.target.value)}
                        className="ps-3 py-1.5 border border-gray-300 rounded-lg inputShadow w-full"
                        placeholder="Search by Office Id"
                        name="office_id"
                      />
                    </div>

                    <div className="">
                      <input
                        type="submit"
                        value="Search"
                        className="cursor-pointer px-4 py-2 w-full bg-cyan-700 rounded-md text-white font-medium text-sm submit"
                      />
                    </div>
                  </form>
                </div>
                <div className="col-span-2 gap-x-2">
                  <div>
                    <span
                      //   onClick={handleFresh}
                      className="bg-cyan-700 text-white h-8 w-8 flex items-center justify-center rounded-full font-medium cursor-pointer"
                    >
                      <IoIosRefresh />
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-span-4">
              <div className="flex items-end justify-end">
                <button
                  onClick={()=>setShowLog(!showLog)}
                  className="flex items-center gap-x-2  px-2 py-2.5 bg-cyan-700 text-white rounded-md"
                >
                  <span className="text-base font-semibold">
                    <AiOutlinePlus />
                  </span>
                  <span className="text-base font-medium">Transport Log</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  const header = renderHeader();


  const CreateData = {
    showLog, setShowLog,update, setUpdate
  }


  return (
    <div className="bg-white">
      <DataTable
        size="small"
        header={header}
        value={TransportLogData}
        // footer={footerTemplate}
        selectionMode="single"
        dataKey="id"
        tableStyle={{ Width: "100%" }}
      >
        <Column
          field=""
          header="Employee"
          body={EmployeeNameTemplate}
          className=""
        ></Column>
        <Column
          field=""
          header="Office Id"
          body={OfficeIdTemplate}
          sortable
          className=""
        ></Column>
        <Column
          field=""
          header="Transport Type"
          body={TransportTypeTemplate}
          className=""
        ></Column>
        <Column
          field=""
          header="Distance"
          body={DistanceTemplate}
          className=""
        ></Column>
        <Column
          field=""
          header="Travel Type"
          body={TravelTypeTemplate}
          sortable
          className=""
        ></Column>
        <Column
          field=""
          header="Total Amount"
          body={AmountTemplate}
          sortable
          className=""
        ></Column>
        <Column
          field=""
          header="Date"
          body={dateTimeTemplate}
          sortable
          className=""
        ></Column>
        <Column
          field=""
          header="Action"
          body={ActionTemplate}
          className="w-[10%]"
        ></Column>
      </DataTable>
      <div className="mt-4">
        {TransportLogData?.length > 0 && (
          <Pagination
            currentPage={currentPage}
            pageNumber={pageNumber}
            setCurrentPage={setCurrentPage}
            setSearchParams={setSearchParams}
            pageCount={pageCount}
            setViewData={setViewData}
            viewData={viewData}
            setPageNumber={setPageNumber}
          />
        )}
      </div>
      {<CreateTransportLog CreateData={CreateData}/>}
    </div>
  );
};

export default TransportLog;

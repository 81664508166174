import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Tooltip } from "primereact/tooltip";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { AiFillEdit, AiOutlineDelete, AiOutlinePlus } from "react-icons/ai";
import { BsFiletypeCsv } from "react-icons/bs";
import { IoIosRefresh } from "react-icons/io";
import { Calendar } from "primereact/calendar";
import { CSVLink } from "react-csv";
import DeductionDetailsModal from "./DeductionDetailsModal";
import DeductionCreateModal from "./DeductionCreateModal";
import { Dropdown } from "primereact/dropdown";
import "./Deductiontable.css";
import { MainUrl, showDummyImage } from "../../utils/helper";
import useData from "../../ContextApi/UseData/useData";
import Loader from "../Loader/Loader";
import DeleteModal from "../AllEmployee/DeleteModal/DeleteModal";
import DeductionUpdateModal from "./DeductionUpdateModal";
import Pagination from "../Pagination/Pagination";
import { useLocation, useSearchParams } from "react-router-dom";
import moment from "moment";

const DeductionTable = () => {
  const { token, allUser } = useData().user;
  const [deductionValue, setDeductionValue] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [showUpdate, setShowUpdate] = useState(false);
  const [update, setUpdate] = useState(false);
  const [selectUpdate, setSelectUpdate] = useState(null);
  const [typeName, setTypeName] = useState(null);
  const [deductionDetails, setDeductionDetails] = useState(null);
  const [firstDate, setFirstDate] = useState(null)
  const [lastDate, setLastDate ] =  useState(null)
  const [show, setShow] = useState(false);
  const [totalData, setTotalData] = useState(null);
  const [showAddModal, setShowAddModal] = useState(false);
  const [filterData, setFilterData] = useState({
    name: "",
    office_id: "",
    year: "",
    month: "",
    deduction_type_id: "",
  });
  const [changeValue, setChangeValue] = useState({});
  const [selectedMonth, setSelectedMonth] = useState(null);
  const [selectDeductionType, setSelectDeductionType] = useState(null);
  const [userInfos, setUserInfos] = useState(null);
  const [isLoader, setIsLoader] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [selectData, setSelectData] = useState(null);
  const [viewData, setViewData] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchParams, setSearchParams] = useSearchParams();
  const [pageCount, setPageCount] = useState();
  const location = useLocation();

  const deductionMonth = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];


  // get  table Data
  useEffect(() => {
    if (!token) {
      return;
    }
    const page = location?.search?.split("=")[1];
    if (page) {
      setPageNumber(+page);
      setCurrentPage(+page);
    }
    const fetchData = async () => {
      setIsLoader(true);
      try {
        const res = await fetch(
          `${
            MainUrl.url
          }/api/v1/deduction_amount/all/${viewData}/${pageNumber}?${
            filterData.name && `name=${filterData.name}`
          }${filterData.office_id && `&office_id=${filterData.office_id}`}${
            filterData.year && `&year=${filterData.year}`
          }${filterData.month && `&month=${filterData.month}`}${
            filterData.deduction_type_id &&
            `&deduction_type_id=${filterData.deduction_type_id}`
          }`,
          {
            method: "Get",
            headers: {
              Authorization: token,
            },
          }
        );
        const data = await res.json();
        if (res.ok) {
          setDeductionValue(data?.data?.result);
          setTotalData(data.data?.total_count);
          setIsLoader(false);
        } else {
          toast.error(data.message);
          console.error(data.message);
          setIsLoader(false);
        }
      } catch (error) {
        setIsLoader(false);
      }
    };
    fetchData();
  }, [
    update,
    viewData,
    pageNumber,
    token,
    filterData,
    location,
    currentPage,
    searchParams,
  ]);

  useEffect(() => {
    const pages = Math.ceil(totalData / viewData);
    setPageCount(pages);
  }, [totalData, viewData]);

  // get deduction type
  useEffect(() => {
    if (!token) {
      return;
    }
    const deductionFetch = async () => {
      const res = await fetch(`${MainUrl.url}/api/v1/deduction_types`, {
        method: "get",
        headers: {
          Authorization: token,
        },
      });
      const data = await res.json();
      if (res.ok) {
        setTypeName(data.data);
      } else {
        toast.error(data.message);
        console.error(data.message);
      }
    };
    deductionFetch();
  }, [token]);

  const deductionType = typeName?.map((type) => {
    let obj = {};
    obj.label = type?.name;
    obj.value = type?.id;
    return obj;
  });

  const EmployeeNameTemplate = (data) => {
    return (
      <div className="flex items-center gap-x-4">
        <div className="h-10 w-10  ">
          <img
            src={`${MainUrl.url}/${data?.user?.user_profile?.profile_photo}`}
            onError={({ currentTarget }, imageSize = "100x100") =>
              showDummyImage({ currentTarget }, imageSize)
            }
            alt=""
            className=" h-full w-full rounded-full"
          />
        </div>
        <h3 className="text-base">{data.user?.user_profile?.name}</h3>
      </div>
    );
  };

  const TotalAmountTemplate = (data) => {
    return <p>{data?.total_amount}</p>;
  };

  const countTemplate = (data) => {
    return <p>{data?.count === null && "N/A "}</p>;
  };

  const DeductionTypeTemplate = (data) => {
    return <p>{data?.deduction_type?.name}</p>;
  };

  const ModifiedTemplate = (data) => {
    const date = data?.modified_at;
    let formatDate = new Date(date)?.toLocaleDateString("en-GB", {
      year: "numeric",
      month: "numeric",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
      timeZone: "BST",
    });
    return <p className="text-xs font-semibold">{formatDate}</p>;
  };

  const deductionMonthtemplate = (data) => {
    return <p>{deductionMonth[data?.month]}</p>;
  };

  const DeductionYearTemplate = (data) => {
    return <p>{data.year}</p>;
  };

  const ActionTemplate = (data) => {
    return (
      <div className=" flex items-center gap-x-2">
        <div
          className="bg-cyan-700 h-8 w-8 flex items-center justify-center rounded-full cursor-pointer"
          onClick={() => {
            setShowUpdate(!showUpdate);
            setSelectUpdate(data);
          }}
        >
          <AiFillEdit className="text-white" />
        </div>
        <div
          className="bg-red-400 h-8 w-8 flex items-center justify-center rounded-full cursor-pointer"
          onClick={() => {
            setShowDelete(!showDelete);
            setSelectData(data);
          }}
        >
          <AiOutlineDelete className="text-white" />
        </div>
      </div>
    );
  };

  const renderHeader = () => {
    const processesData = deductionValue?.map((deduction) => {
      deduction.deduction_typeName = deduction?.deduction_type?.name;
      deduction.OfficeId = deduction?.user?.office_id;
      deduction.name = deduction?.user?.user_profile?.name;
      return deduction;
    });

    const deductionData = [
      { label: "Year", key: "year" },
      { label: "Month", key: "month" },
      { label: "Name", key: "name" },
      { label: "Office ID", key: "OfficeId" },
      { label: "Total Deduction", key: "total_amount" },
      { label: "Deduction Type", key: "deduction_typeName" },
    ];

    const userInfo = allUser?.map((user) => {
      let obj = {};
      obj.label = user?.user_profile?.name;
      obj.value = user?.id;
      return obj;
    });

    // global search
    const months = [
      { value: "0", label: "January" },
      { value: "1", label: "February" },
      { value: "2", label: "March" },
      { value: "3", label: "April" },
      { value: "4", label: "May" },
      { value: "5", label: "June" },
      { value: "6", label: "July" },
      { value: "7", label: "August" },
      { value: "8", label: "September" },
      { value: "9", label: "October" },
      { value: "10", label: "November" },
      { value: "11", label: "December" },
    ];

    // handle Refresh button
    const handleFresh = () => {
      setUpdate(!update);
      setSelectDeductionType(null);
      setSelectedMonth(null);
      setChangeValue({
        name: "",
        office_id: "",
        year: "",
        month: "",
        deduction_type_id: "",
      });
      setFilterData({
        name: "",
        office_id: "",
        year: "",
        month: "",
        deduction_type_id: "",
      });
    };

    // get data to formDate to ToDate
    const handleDeductionDate = (e) => {
      e.preventDefault();

      const userId = userInfos;
      const fromDate = moment(firstDate).format("DD-MM-YYYY"); 
      const toDate = moment(lastDate).format("DD-MM-YYYY") 

      const searchUser = async () => {
        if (!token) {
          return;
        }
        if (userId || firstDate || lastDate) {
          const res = await fetch(
            `${MainUrl.url}/api/v1/deduction/total/${userId}?from=${fromDate}&to=${toDate}`,
            {
              method: "get",
              headers: {
                Authorization: token,
              },
            }
          );

          const resData = await res.json();
          if (res.ok) {
            setDeductionDetails(resData.data);
            setShow(true);
          } else {
            toast.error(resData.message);
            setUserInfos(null)
            setFirstDate(null)
            setLastDate(null)
            console.error(resData.message);
          }
        }
      };
      searchUser();
    };

    const setValue = (e) => {
      setChangeValue({
        ...changeValue,
        [e.target.name]: e.target.value,
      });
    };

    const handleSearch = async (e) => {
      e.preventDefault();
      const data = {
        month: selectedMonth ? selectedMonth : "",
        name: changeValue.name ? changeValue.name : "",
        office_id: changeValue.office_id ? changeValue.office_id : "",
        year: changeValue.year ? changeValue.year : "",
        deduction_type_id: selectDeductionType ? selectDeductionType : "",
      };
      setFilterData(data);
    };

    return (
      <>
        <div className="flex justify-end items-center gap-x-4">
          <form
            className="flex gap-x-3 items-center justify-start w-full myInput"
            onSubmit={handleSearch}
          >
            <div className="flex gap-x-3 items-center justify-center w-[90%]">
              <div className="w-[20%]">
                <input
                  onChange={(e) => setValue(e)}
                  className="ps-3 py-1.5 border border-gray-300 rounded-lg inputShadow w-full"
                  placeholder="Search by Name"
                  name="name"
                  value={changeValue.name}
                />
              </div>
              <div className="w-[20%]">
                <input
                  onChange={(e) => setValue(e)}
                  className="ps-3 py-1.5 border border-gray-300 rounded-lg inputShadow w-full"
                  placeholder="Search by Office Id"
                  name="office_id"
                />
              </div>
              <div className="w-[20%]">
                <input
                  onChange={(e) => setValue(e)}
                  className="ps-3 py-1.5 border border-gray-300 rounded-lg inputShadow w-full"
                  placeholder="Search by Year"
                  name="year"
                />
              </div>
              <div className="w-[20%]">
                <Dropdown
                  value={selectedMonth}
                  onChange={(e) => setSelectedMonth(e.value)}
                  options={months}
                  optionLabel="label"
                  showClear
                  placeholder="Select a Month"
                  className="w-full"
                  name="month"
                />
              </div>
              <div className="w-[20%]">
                <Dropdown
                  value={selectDeductionType}
                  onChange={(e) => setSelectDeductionType(e.value)}
                  options={deductionType}
                  optionLabel="label"
                  showClear
                  placeholder="Select a Deduction Type"
                  className="w-full"
                  name="deduction_type_id"
                />
              </div>
            </div>

            <div className="w-[10%]">
              <input
                type="submit"
                value="Search"
                className="cursor-pointer px-4 py-2 w-full bg-cyan-700 rounded-md text-white font-medium text-sm submit"
              />
            </div>
          </form>

          <span
            onClick={handleFresh}
            className="bg-cyan-700 text-white h-8 w-8 flex items-center justify-center rounded-full font-medium cursor-pointer"
          >
            <IoIosRefresh />
          </span>

          <CSVLink
            data={processesData?.length ? processesData : []}
            headers={deductionData}
            filename="salary.csv"
          >
            <div
              className="bg-cyan-700 h-8 w-8 flex items-center justify-center rounded-full
                text-white font-medium cursor-pointer"
            >
              <Tooltip target=".logo" position="top" />
              <BsFiletypeCsv className="logo" data-pr-tooltip="Download CSV" />
            </div>
          </CSVLink>
        </div>

        <div className="grid grid-cols-12 pt-2">
          <form onSubmit={handleDeductionDate} className="myInput col-span-10 ">
            <div className="grid grid-cols-12 gap-x-2">
              <div className="col-span-3">
                <Dropdown
                  value={userInfos}
                  onChange={(e) => setUserInfos(e.value)}
                  options={userInfo}
                  optionLabel="label"
                  showClear
                  filter
                  placeholder="Select a User"
                  className="w-full"
                />
              </div>

              <div className="col-span-3">
                <Calendar
                  dateFormat="yy-mm-dd"
                  value={firstDate}
                  onChange={(e)=>setFirstDate(e?.value)}
                  className="p-inputtext-sm w-full"
                  placeholder="From"
                />
              </div>

              <div className="col-span-3">
                <Calendar
                  dateFormat="yy-mm-dd"
                  value={lastDate}
                  onChange={(e)=>setLastDate(e?.value)}
                  className="p-inputtext-sm w-full"
                  placeholder="To"
                />
              </div>

              <div className="col-span-3">
                <input
                  type="submit"
                  value="Search"
                  className="cursor-pointer bg-cyan-700 rounded-md text-white font-medium text-sm py-2.5 px-6"
                />
              </div>
            </div>
          </form>

          <div className="col-span-2 flex justify-end">
            <button
              onClick={() => setShowAddModal(true)}
              className="flex items-center gap-x-2  px-2 py-2.5 bg-cyan-700 text-white rounded-md"
            >
              <span className="text-base font-semibold">
                <AiOutlinePlus />
              </span>{" "}
              <span className="text-base font-medium">Deduction</span>{" "}
            </button>
          </div>
        </div>
      </>
    );
  };

  const header = renderHeader();
  const DeleteUrl = "api/v1/deduction_amount/one";
  const DeleteData = {
    showDelete,
    setShowDelete,
    update,
    setUpdate,
    selectData,
    DeleteUrl,
  };

  const updateData = {
    showUpdate,
    setShowUpdate,
    selectUpdate,
    update,
    setUpdate,
    deductionType,
  };

  return (
    <div className="card bg-white pb-4 mb-4">
      {isLoader ? (
        <Loader />
      ) : (
        <DataTable
          header={header}
          size={"small"}
          value={deductionValue}
          // footer={footerTemplate}
          selectionMode="single"
          selection={selectedProduct}
          onSelectionChange={(e) => setSelectedProduct(e.value)}
          dataKey="id"
          tableStyle={{ Width: "100%" }}
        >
          <Column
            field="Name"
            body={EmployeeNameTemplate}
            header="Name"
            className="w-[15%]"
          ></Column>
          <Column
            field="Total Amount"
            body={TotalAmountTemplate}
            header="Total Amount"
            className="w-[10%]"
          ></Column>
          <Column
            field="Count"
            header="Count"
            body={countTemplate}
            className="w-[5%]"
          ></Column>
          <Column
            field="Deduction Type"
            body={DeductionTypeTemplate}
            header="Deduction Type"
            className="w-[10%]"
          ></Column>
          <Column
            field="Deduction Type"
            body={deductionMonthtemplate}
            header="Month"
            className="w-[10%]"
          ></Column>
          <Column
            field="Deduction Type"
            body={DeductionYearTemplate}
            header="Year"
            className="w-[10%]"
          ></Column>
          <Column
            field="Modified At"
            body={ModifiedTemplate}
            header="Modified At"
            className="w-[10%]"
          ></Column>
          <Column
            field="Action"
            body={ActionTemplate}
            header="Action"
            className="w-[10%]"
          ></Column>
        </DataTable>
      )}

      <div className="mt-4">
        {deductionValue?.length > 0 && (
          <Pagination
            currentPage={currentPage}
            pageNumber={pageNumber}
            setCurrentPage={setCurrentPage}
            setSearchParams={setSearchParams}
            pageCount={pageCount}
            setViewData={setViewData}
            viewData={viewData}
            setPageNumber={setPageNumber}
          />
        )}
      </div>

      {deductionDetails && (
        <DeductionDetailsModal
          deductionDetails={deductionDetails}
          // formMonth={formMonth}
          firstDate={firstDate}
          setFirstDate={setFirstDate}

          lastDate={lastDate}
          setLastDate={setLastDate}

          setUserInfos={setUserInfos}
          userInfos={userInfos}
          setShow={setShow}
          show={show}
        />
      )}

      {
        // Create Modal
        <DeductionCreateModal
          showAddModal={showAddModal}
          allUser={allUser}
          setUpdate={setUpdate}
          update={update}
          setShowAddModal={setShowAddModal}
          typeName={typeName}
        />
      }
      {<DeductionUpdateModal updateData={updateData} />}
      {<DeleteModal DeleteData={DeleteData} />}
      
    </div>
  );
};

export default DeductionTable;

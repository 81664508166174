import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { AiFillEdit } from "react-icons/ai";
import OverTimePolicyModal from "./OverTimePolicyModal";
import { MainUrl } from "../../utils/helper";
import useData from "../../ContextApi/UseData/useData";
import Loader from "../Loader/Loader";

const OverTimePolicy = () => {
  const moment = require("moment");
  const [overTimeData, setOverTimeData] = useState(null);
  const [showOverTime, setShowOverTime] = useState(false);
  const [percentData, setPercentData] = useState([]);
  const [update, setUpdate] = useState(false);
  const [isLoader, setIsLoader] = useState(false);
  const { token } = useData().user;

  useEffect(() => {
    if (!token) {
      return;
    }
    const fetchData = async () => {
      const res = await fetch(`${MainUrl.url}/api/v1/overtime/policies`, {
        method: "get",
        headers: {
          Authorization: token,
        },
      });
      setIsLoader(true);
      const data = await res.json();
      if (res.ok) {
        setOverTimeData(data?.data);
        setIsLoader(false);
      } else {
        toast.error(data.message);
        console.error(data.message);
        setIsLoader(false);
      }
    };
    fetchData();
  }, [update, token]);

  const dealyTemplate = (data) => {
    const timeString = data.delay;
    const duration = moment.duration(timeString);
    const hours = duration.hours();
    const minutes = duration.minutes();

    let output = "";

    if (hours > 0) {
      hours > 1 ? (output += hours + " hours ") : (output += hours + " hour ");
    }

    if (minutes > 0) {
      output += minutes + " minutes";
    }

    if (!output) {
      output = "Delay not set";
    }
    return <p className="text-lg font-medium">{output.trim()}</p>;
  };

  const modifiedtemplaye = (data) => {
    const date = data?.modified_at;
    let formatDate = new Date(date)?.toLocaleDateString("en-GB", {
      year: "numeric",
      month: "numeric",
      day: "numeric",
    });
    return <p className="font-medium">{formatDate}</p>;
  };

  const percentTemplate = (data) => {
    return <p>{data.percentage}</p>;
  };

  const ActionTemplate = (data) => {
    return (
      <button
        onClick={() => {
          setPercentData(data);
          setShowOverTime(!showOverTime);
        }}
        className="flex items-center gap-x-1 hover:text-green-300 duration-150"
      >
        {" "}
        <span>
          <AiFillEdit />
        </span>{" "}
        Update
      </button>
    );
  };

  const modalData = {
    showOverTime,
    percentData,
    update,
    setUpdate,
    setShowOverTime,
  };

  return (
    <div>
      {isLoader ? (
        <Loader />
      ) : (
        <DataTable
          size="small"
          value={overTimeData}
          tableStyle={{ minWidth: "50rem" }}
        >
          <Column field="name" body={dealyTemplate} header="Delay"></Column>
          <Column
            field="category"
            body={modifiedtemplaye}
            header="Modify At"
          ></Column>
          <Column
            field="quantity"
            body={percentTemplate}
            header="Percentage"
          ></Column>
          <Column
            field="quantity"
            body={ActionTemplate}
            header="Action"
          ></Column>
        </DataTable>
      )}

      {<OverTimePolicyModal modalData={modalData} />}
    </div>
  );
};

export default OverTimePolicy;

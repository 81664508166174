import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import React, { useEffect, useState } from "react";
import { IoIosRefresh } from "react-icons/io";
import { toast } from "react-toastify";
import { MainUrl, showDummyImage } from "../../utils/helper";
import useData from "../../ContextApi/UseData/useData";
import { Image } from "primereact/image";
import { Calendar } from "primereact/calendar";
import moment from "moment";
import Loader from "../Loader/Loader";
import { Menu, MenuButton, MenuItem } from "@szhsin/react-menu";
import { BsThreeDotsVertical } from "react-icons/bs";
import { AiFillEdit, AiOutlineDelete } from "react-icons/ai";
import NoticeUpdateModal from "./NoticeUpdateModal";
import DeleteModal from "../AllEmployee/DeleteModal/DeleteModal";
import CreateNoticeModal from "./CreateNoticeModal";
import "./UpdateModal.css";
import { useLocation, useSearchParams } from "react-router-dom";
import Pagination from "../Pagination/Pagination";

const Notices = () => {
  const { token } = useData().user;
  const [changedDate, setChangedDate] = useState(null);
  const [Notices, setNotices] = useState(null);
  const [update, setUpdate] = useState(false);
  const [isLoader, setIsLoader] = useState(false);
  const [totalData, setTotalData] = useState(null);
  const [filterData, setFilterData] = useState({
    headline: "",
    date: "",
  });
  const [changeValue, setChangeValue] = useState({});
  const [selectData, setSelectData] = useState(null);
  const [ShowUpdate, setShowUpdate] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [date, setDate] = useState("");
  const [createNotice, setCreateNotice] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [viewData, setViewData] = useState(10);

  const [currentPage, setCurrentPage] = useState(1);
  const [searchParams, setSearchParams] = useSearchParams();
  const [pageCount, setPageCount] = useState();
  const location = useLocation();

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoader(true);
        const res = await fetch(
          `${MainUrl.url}/api/v1/notice/all/${viewData}/${pageNumber}?${
            filterData.headline && `headline=${filterData.headline}`
          }${filterData.date && `&date=${filterData.date}`}`,
          {
            method: "get",
            headers: {
              AUthorization: token,
            },
          }
        );
        const resData = await res.json();
        if (res.ok) {
          setNotices(resData.data.records);
          setTotalData(resData.data?.totalData);
          setIsLoader(false);
        }
      } catch (error) {
        toast.error(error?.message);
        setIsLoader(false);
      }
    };
    fetchData();
  }, [
    pageNumber,
    viewData,
    token,
    filterData,
    update,
    currentPage,
    location,
    searchParams,
  ]);

  useEffect(() => {
    const pages = Math.ceil(totalData / viewData);
    setPageCount(pages);
  }, [totalData, viewData]);

  const HeaderTemplate = (data) => {
    return <h4 className="text-base">{data?.headline}</h4>;
  };

  const dateTimeTemplate = (data) => {
    const date = data?.date;
    let formatDate = new Date(date)?.toLocaleDateString("en-GB", {
      year: "numeric",
      month: "numeric",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
      timeZone: "BST",
    });
    return <p className="text-base">{formatDate}</p>;
  };

  const NoticeTypeTemplate = (data) => {
    return <h4>{data?.notice_type?.name}</h4>;
  };

  const fileTemplate = (data) => {
    return (
      <div className="card flex justify-content-center w-[80px] h-[40px] imgZoom">
        <Image
          className="zoom w-full"
          src={`${MainUrl.url}${data?.notice_file}`}
          zoomSrc={`${MainUrl.url}${data?.notice_file}`}
          onError={({ currentTarget }, imageSize = "100x100") =>
            showDummyImage({ currentTarget }, imageSize)
          }
          width="80"
          height="20"
          alt="Image"
          preview
        />
      </div>
    );
  };

  const descriptionTemplate = (data) => {
    return <p>{data?.description}</p>;
  };

  const ActionTemplate = (data) => {
    return (
      <Menu
        direction={"right"}
        align={"center"}
        menuButton={
          <MenuButton>
            <BsThreeDotsVertical />
          </MenuButton>
        }
        transition
      >
        <MenuItem
          className="hover:text-red-400"
          onClick={() => {
            setSelectData(data);
            setShowUpdate(!ShowUpdate);
          }}
        >
          <span className="mr-2">
            <AiFillEdit />
          </span>
          Update
        </MenuItem>
        <MenuItem
          className="hover:text-red-400"
          onClick={() => {
            setSelectData(data);
            setShowDelete(!showDelete);
          }}
        >
          <span>
            <AiOutlineDelete className="mr-2" />
          </span>
          Delete
        </MenuItem>
      </Menu>
    );
  };


  const renderHeader = () => {
    const setValue = (e) => {
      setChangeValue({
        ...changeValue,
        [e.target.name]: e.target.value,
      });
    };

    const handleSearch = async (e) => {
      e.preventDefault();
      if (changedDate) {
        const formatDateNotice = moment(changedDate).format("DD-MM-YYYY");
        setDate(formatDateNotice);
      }
      const data = {
        headline: changeValue.headline ? changeValue.headline : "",
        date: date ? date : "",
      };
      setFilterData(data);
    };

    const handleFresh = () => {
      setUpdate(!update);
      setFilterData({
        headline: "",
        date: "",
      });
      setChangeValue({
        headline: "",
        date: "",
      });
    };
    return (
      <div className="grid grid-cols-12 gap-x-2 items-center">
        <div className="col-span-10 flex gap-x-2 items-center">
          <form
            className="flex gap-x-3  w-full myInput"
            onSubmit={handleSearch}
          >
            <div className="w-full">
              <input
                onChange={(e) => setValue(e)}
                className="ps-3 py-1.5 border border-gray-300 rounded-lg inputShadow w-full"
                placeholder="Search by Head Line"
                value={changeValue.headline}
                name="headline"
              />
            </div>
            <div className="w-full">
              <Calendar
                value={changeValue}
                className="w-full"
                onChange={(e) => setChangedDate(e ? e.value : null)}
                placeholder="Search by Date"
              />
            </div>

            <div className="">
              <input
                type="submit"
                value="Search"
                className="cursor-pointer px-4 py-2 w-full bg-cyan-700 rounded-md text-white font-medium text-sm submit"
              />
            </div>
          </form>
          <div>
            <span
              onClick={handleFresh}
              className="bg-cyan-700 text-white h-8 w-8 flex items-center justify-center rounded-full font-medium cursor-pointer"
            >
              <IoIosRefresh />
            </span>
          </div>
        </div>
        <div className="col-span-2 gap-x-2">
          <div className="flex justify-end">
            <button
              onClick={() => setCreateNotice(!createNotice)}
              className="px-4 py-2 bg-cyan-700 text-white rounded"
            >
              Create Notice
            </button>
          </div>
        </div>
      </div>
    );
  };
  const header = renderHeader();

  const UpdateData = {
    ShowUpdate,
    setShowUpdate,
    selectData,
  };

  const DeleteUrl = "api/v1/notice/one";
  const DeleteData = {
    showDelete,
    setShowDelete,
    update,
    setUpdate,
    selectData,
    DeleteUrl,
  };

  const CreateDate = {
    setCreateNotice,
    createNotice,
    setUpdate, update
  };

  return (
    <div className="bg-white pb-4 mb-4 overflow-auto">
      {isLoader ? (
        <Loader />
      ) : (
        <DataTable
          size="small"
          header={header}
          value={Notices}
          selectionMode="single"
          dataKey="id"
          tableStyle={{ Width: "100%" }}
          className="overflow-auto"
        >
          <Column header="Header" body={HeaderTemplate} style={{ maxWidth: "15%" }} className=""></Column>
          <Column
            header="Date"
            body={dateTimeTemplate}
            style={{ maxWidth: "12%" }}
            //   sortable
            className=""
          ></Column>
          <Column
            header="Notice Type"
            body={NoticeTypeTemplate}
            style={{ maxWidth: "5%" }}
            className=""
          ></Column>
          <Column header="File" body={fileTemplate} style={{ maxWidth: "5%" }} className=""></Column>
          <Column
            header="Description"
            style={{ maxWidth: "200px" }}
            body={descriptionTemplate}
            className=""
          ></Column>
          <Column
            header="Action"
            style={{ maxWidth: "5%" }}
            body={ActionTemplate}
            className=""
          ></Column>
        </DataTable>
      )}

      <div className="mt-4">
        {Notices?.length > 0 && (
          <Pagination
          currentPage={currentPage}
          pageNumber={pageNumber}
          setCurrentPage={setCurrentPage}
          setSearchParams={setSearchParams}
          pageCount={pageCount}
          setViewData={setViewData}
          viewData={viewData}
          setPageNumber={setPageNumber}
          />
        )}
      </div>

      {<CreateNoticeModal CreateDate={CreateDate} />}
      {<NoticeUpdateModal UpdateData={UpdateData} />}
      {<DeleteModal DeleteData={DeleteData} />}
    </div>
  );
};

export default Notices;

import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import React, { useEffect, useState } from "react";
import { IoIosRefresh } from "react-icons/io";
import { toast } from "react-toastify";
import { MainUrl, showDummyImage } from "../../utils/helper";
import useData from "../../ContextApi/UseData/useData";
import Loader from "../Loader/Loader";
import { useLocation, useSearchParams } from "react-router-dom";
import Pagination from "../Pagination/Pagination";

const TodayAttendance = () => {
  const { token } = useData().user;
  const [attendance, setAttendance] = useState(null);
  const [filterData, setFilterData] = useState({
    name: "",
    office_id: "",
  });
  const [changeValue, setChangeValue] = useState({});
  const [isLoader, setIsLoader] = useState(false);
  const [update, setUpdate] = useState(false);
  const [totalData, setTotalData] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [viewData, setViewData] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchParams, setSearchParams] = useSearchParams();
  const [pageCount, setPageCount] = useState();
  const location = useLocation();

  useEffect(() => {
    if (!token) {
      return;
    }
    const page = location?.search?.split("=")[1];
    if (page) {
      setPageNumber(+page);
      setCurrentPage(+page);
    }

    const fetchData = async () => {
      try {
        setIsLoader(true);
        const res = await fetch(
          `${MainUrl.url}/api/v1/attendance/all/${viewData}/${pageNumber}?${
            filterData.name && `name=${filterData.name}`
          }${filterData.office_id && `&office_id&${filterData.office_id}`}`,
          {
            method: "Get",
            headers: {
              Authorization: token,
            },
          }
        );
        const data = await res.json();
        if (res.ok) {
          setTotalData(data.data?.totalData);
          setAttendance(data.data?.records);
          setIsLoader(false);
        }
      } catch (error) {
        toast.error(error?.message);
        setIsLoader(false);
      }
    };
    fetchData();
  }, [
    pageNumber,
    viewData,
    filterData,
    token,
    update,
    location,
    currentPage,
    searchParams,
  ]);

  useEffect(() => {
    const pages = Math.ceil(totalData / viewData);
    setPageCount(pages);
  }, [totalData, viewData]);

  const EmployeeNameTemplate = (data) => {
    return (
      <div className="flex items-center gap-x-4">
        <div className="h-10 w-10  ">
          <img
            src={`${MainUrl.url}/${data?.user?.user_profile?.profile_photo}`}
            onError={({ currentTarget }, imageSize = "100x100") =>
              showDummyImage({ currentTarget }, imageSize)
            }
            alt=""
            className=" h-full w-full rounded-full"
          />
        </div>
        <h3 className="text-basetext-base">{data.user?.user_profile?.name}</h3>
      </div>
    );
  };

  const Datetemplate = (data) => {
    return <p className="text-base">{data?.date}</p>;
  };

  const OfficeIdTemplate = (data) => {
    return <p className="text-base">{data?.user?.office_id}</p>;
  };

  const presentTemplate = (data) => {
    return (
      <p className="text-base">
        {data.present_status === 0 ? "Absent" : "Present"}
      </p>
    );
  };

  const CheckInTemplate = (data) => {
    // const time = moment().format('LT')
    return <p className="text-base">{data?.check_in}</p>;
  };

  const CheckOutTemplate = (data) => {
    return <p className="text-base">{data?.check_out}</p>;
  };

  const renderHeader = () => {
    const setValue = (e) => {
      setChangeValue({
        ...changeValue,
        [e?.target?.name]: e?.target?.value,
      });
    };
    const handleSearch = (e) => {
      e.preventDefault();
      const data = {
        name: changeValue.name ? changeValue.name : "",
        office_id: changeValue.office_id ? changeValue.office_id : "",
      };
      setFilterData(data);
    };

    const handleFresh = () => {
      setFilterData({
        name: "",
        office_id: "",
      });
      setChangeValue({
        name: "",
        office_id: "",
      });
      setUpdate(!update);
    };
    return (
      <div className="grid grid-cols-12 items-center justify-between">
        <div className="col-span-8">
          <div className="grid grid-cols-12 gap-x-2 items-center">
            <div className="col-span-10">
              <form
                className="flex gap-x-3  w-full myInput"
                onSubmit={handleSearch}
              >
                <div className="w-full">
                  <input
                    onChange={(e) => setValue(e)}
                    className="ps-3 py-1.5 border border-gray-300 rounded-lg inputShadow w-full"
                    placeholder="Search by Name"
                    value={changeValue.name}
                    name="name"
                  />
                </div>
                <div className="w-full">
                  <input
                    onChange={(e) => setValue(e)}
                    className="ps-3 py-1.5 border border-gray-300 rounded-lg inputShadow w-full"
                    placeholder="Search by Office Id"
                    value={changeValue.office_id}
                    name="office_id"
                  />
                </div>

                <div className="">
                  <input
                    type="submit"
                    value="Search"
                    className="cursor-pointer px-4 py-2 w-full bg-cyan-700 rounded-md text-white font-medium text-sm submit"
                  />
                </div>
              </form>
            </div>
            <div className="col-span-2 gap-x-2">
              <div>
                <span
                  onClick={handleFresh}
                  className="bg-cyan-700 text-white h-8 w-8 flex items-center justify-center rounded-full font-medium cursor-pointer"
                >
                  <IoIosRefresh />
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  const header = renderHeader();

  return (
    <div className="mb-5 bg-white pb-4">
      {isLoader ? (
        <Loader />
      ) : (
        <DataTable
          size="small"
          header={header}
          value={attendance}
          // footer={footerTemplate}
          selectionMode="single"
          dataKey="id"
          tableStyle={{ Width: "100%" }}
        >
          <Column
            field=""
            header="Name"
            body={EmployeeNameTemplate}
            className=""
          ></Column>
          <Column
            field="user.office_id"
            header="Office ID"
            body={OfficeIdTemplate}
            sortable
            className=""
          ></Column>

          <Column
            field="user.date"
            header="Date"
            body={Datetemplate}
            sortable
            className=""
          ></Column>

          <Column
            field="present_status"
            header="Present Status"
            body={presentTemplate}
            sortable
            className=""
          ></Column>

          <Column
            field="check_in"
            header="Check In"
            body={CheckInTemplate}
            sortable
            className=""
          ></Column>
          <Column
            field="check_out"
            header="Check OUT"
            body={CheckOutTemplate}
            sortable
            className=""
          ></Column>
        </DataTable>
      )}

      <div className="mt-4">
        {attendance?.length > 0 && (
          <Pagination
          currentPage={currentPage}
          pageNumber={pageNumber}
          setCurrentPage={setCurrentPage}
          setSearchParams={setSearchParams}
          pageCount={pageCount}
          setViewData={setViewData}
          viewData={viewData}
          setPageNumber={setPageNumber}
          />
        )}
      </div>
    </div>
  );
};

export default TodayAttendance;

import React from "react";
import { FaBars } from "react-icons/fa6";
import { RiBarChartHorizontalFill } from "react-icons/ri";
import useData from "../../ContextApi/UseData/useData";
import { MainUrl, showDummyImage } from "../../utils/helper";
import { Link, useNavigate } from "react-router-dom";
import { Menu, MenuButton, MenuItem } from "@szhsin/react-menu";

const Navbar = ({ show, setShow }) => {
  const { token, setToken } = useData().user;
  const navigate = useNavigate();
  const { profile } = useData().user;

  const logOut = async () => {
    const res = await fetch(`${MainUrl.url}/api/v1/logout`, {
      method: "Get",
      credentials: "include",
      headers: {
        Authorization: token,
      },
    });
    if (res.ok) {
      setToken(null);
      navigate("/login");
    }
  };

  return (
    <div className="flex sticky z-10 top-0 justify-between py-5 pr-10 pl-4 bg-white duration-200 shadow">
      {!show ? (
        <>
          {/* <div className="block lg:hidden"></div> */}
          <RiBarChartHorizontalFill
            className=" transition duration-700 cursor-pointer"
            onClick={() => setShow(!show)}
            size={22}
          />
        </>
      ) : (
        <FaBars
          className="transition duration-700 cursor-pointer"
          onClick={() => setShow(!show)}
          size={22}
        />
      )}

      <div>
        <Menu
          menuButton={
            <MenuButton>
              {profile && (
                <div className="flex relative gap-2 items-center">
                  <div className="w-6 h-6 rounded-full">
                    <img
                      src={`${MainUrl.url}/${profile?.profile_photo}`}
                      onError={({ currentTarget }, imageSize = "100x100") =>
                        showDummyImage({ currentTarget }, imageSize)
                      }
                      alt=""
                      className=" h-full w-full rounded-full"
                    />
                  </div>
                  <p className="cursor-pointer md:block hidden">{profile?.name}</p>
                </div>
              )}
            </MenuButton>
          }
          transition
        >
          <MenuItem>
            <Link to={"/profile"}>Profile</Link>
          </MenuItem>
          <MenuItem>
          <Link  to={"my-account"}>Account</Link>
          </MenuItem>
          <MenuItem onClick={logOut}>Logout</MenuItem>
        </Menu>
      </div>

    </div>
  );
};

export default Navbar;

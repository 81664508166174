import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Tooltip } from "primereact/tooltip";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import {  AiOutlineDelete, AiOutlinePlus } from "react-icons/ai";
import { BsFiletypeCsv } from "react-icons/bs";
import { IoIosRefresh } from "react-icons/io";
import { CSVLink } from "react-csv";
import CreateBonusLog from "./CreateBonusLog";
import UpdateBonusLog from "./UpdateBonusLog";
import useData from "../../ContextApi/UseData/useData";
import { MainUrl, showDummyImage } from "../../utils/helper";
import Loader from "../Loader/Loader";
import DeleteModal from "../AllEmployee/DeleteModal/DeleteModal";
import { Dropdown } from "primereact/dropdown";
import { useLocation, useSearchParams } from "react-router-dom";
import Pagination from "../Pagination/Pagination";

const BonusLog = () => {
  const { token, allUser } = useData().user;
  const [bonusLog, setBonusLog] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [visible, setVisible] = useState(false);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [update, setUpdate] = useState(false);
  // const [updateBonusModal, setUpdateBonusModal] = useState(null);
  const [totalData, setTotalData] = useState(null);
  const [bonusType, setBonusType] = useState([]);
  const [isClearable] = useState(true);
  const [isSearchable] = useState(true);
  const [filterData, setFilterData] = useState({
    name: "",
    office_id: "",
    bonus_type: "",
    bonus_status: "",
  });
  const [changeValue, setChangeValue] = useState({});
  const [isLoader, setIsLoader] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [selectData, setSelectData] = useState(null);
  const [selectBonusTypeId, setSelectBonusTypeId] = useState(null);
  const [selectBonusStatusId, setSelectBonusStatusId] = useState(null);
  const [viewData, setViewData] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchParams, setSearchParams] = useSearchParams();
  const [pageCount, setPageCount] = useState();
  const location = useLocation();

  // get table data
  useEffect(() => {
    if (!token) {
      return;
    }

    const page = location?.search?.split("=")[1];
    if (page) {
      setPageNumber(+page);
      setCurrentPage(+page);
    }

    const fetchData = async () => {
      setIsLoader(true);
      const res = await fetch(
        `${MainUrl.url}/api/v1/bonus/log/all/${viewData}/${pageNumber}?${
          filterData.name && `name=${filterData.name}`
        }${filterData.office_id && `&office_id=${filterData.office_id}`}${
          filterData.bonus_type && `&bonus_type_id=${filterData.bonus_type}`
        }${
          filterData.bonus_status && `&bonus_status=${filterData.bonus_status}`
        }`,
        {
          method: "get",
          headers: {
            Authorization: token,
          },
        }
      );
      const data = await res.json();
      if (res.ok) {
        setBonusLog(data?.data?.result);
        setTotalData(data.data?.total_count);
        setIsLoader(false);
      } else {
        console.error(data.message);
        setBonusLog([]);
        setIsLoader(false);
      }
    };
    fetchData();
  }, [update, viewData, pageNumber, token, filterData,location,currentPage, searchParams]);

  // pagination
  useEffect(() => {
    const pages = Math.ceil(totalData / viewData);
    setPageCount(pages);
  }, [totalData, viewData]);

  // get  bonus type
  useEffect(() => {
    if (!token) {
      return;
    }
    const Bonus = async () => {
      const res = await fetch(`${MainUrl.url}/api/v1/bonus/types`, {
        method: "Get",
        headers: {
          Authorization: token,
        },
      });
      const data = await res.json();
      if (res.ok) {
        setBonusType(data?.data);
      } else {
        toast.error(data.message);
        console.error(data.message);
      }
    };
    Bonus();
  }, [token]);

  const EmployeeNameTemplate = (data) => {
    return (
      <div className="flex items-center gap-x-4">
        <div className="h-10 w-10  ">
          <img
            src={`${MainUrl.url}/${data?.user?.user_profile?.profile_photo}`}
            onError={({ currentTarget }, imageSize = "100x100") =>
              showDummyImage({ currentTarget }, imageSize)
            }
            alt=""
            className=" h-full w-full rounded-full"
          />
        </div>
        <h3 className="text-base ">{data.user?.user_profile?.name}</h3>
      </div>
    );
  };

  const OfficeId = (data) => {
    return <p>{data.user.office_id}</p>;
  };

  const DesignationTemplate = (data) => {
    return <p>{data.user.user_designation?.name}</p>;
  };

  const BonusType = (data) => {
    return <p>{data.bonus_type?.name}</p>;
  };

  const ModifiedTemplate = (data) => {
    const date = data?.modified_at;
    let formatDate = new Date(date)?.toLocaleDateString("en-GB", {
      year: "numeric",
      month: "numeric",
      day: "numeric",
    });
    return <p className="text-xs font-semibold">{formatDate}</p>;
  };

  const BonusAmount = (data) => {
    return <p>{data.bonus_amount}</p>;
  };

  const BonusStatus = (data) => {
    if (data.bonus_status === 1) {
      return (
        <button className="outline outline-1 outline-green-400 px-2 py-1 text-sm rounded-md">
          Resolve
        </button>
      );
    } else {
      return (
        <button className="outline outline-1 outline-yellow-400-400 px-2 py-1 text-sm rounded-md">
          Pending
        </button>
      );
    }
  };


  const ActionTemplate = (data) => {
    return (
      <div className=" flex items-center gap-x-2">
        {/* <div
          className="bg-cyan-700 h-8 w-8 flex items-center justify-center rounded-full cursor-pointer"
          onClick={() => {
            setShowUpdateModal(true);
            setUpdateBonusModal(data);
          }}
        >
          <AiFillEdit className="text-white" />
        </div> */}
        <div
          className="bg-red-400 h-8 w-8 flex items-center justify-center rounded-full cursor-pointer"
          onClick={() => {
            setSelectData(data);
            setShowDelete(!showDelete);
          }}
        >
          <AiOutlineDelete className="text-white" />
        </div>
      </div>
    );
  };

  const bonusTypeId = bonusType?.map((user) => {
    let obj = {};
    obj.label = user?.name;
    obj.value = user?.id;
    return obj;
  });

  // console.log("Bonus", bonusTypeId);

  const renderHeader = () => {
    const processesData = bonusLog?.map((bonus) => {
      bonus.bonusType = bonus?.bonus_type?.name;
      bonus.officeId = bonus.user.office_id;
      bonus.user_name = bonus?.user?.user_profile?.name;
      return bonus;
    });

    // csv Download template
    const deductionData = [
      { label: "Name", key: "user_name" },
      { label: "Office ID", key: "officeId" },
      { label: "Bonus Amount ", key: "bonus_amount" },
      { label: "Bonus Type", key: "bonusType" },
      { label: "Bonus Status", key: "bonus_status" },
    ];

    // handle Refresh button
    const handleFresh = () => {
      setFilterData({
        name: "",
        office_id: "",
        bonus_type: "",
        bonus_status: "",
      });
      setChangeValue({
        name: "",
        office_id: "",
        bonus_type: "",
        bonus_status: "",
      });
      setSelectBonusStatusId(null);
      setSelectBonusTypeId(null);
      setUpdate(!update);
    };

    const BonusStatus = [
      { name: "Resolve", value: "1" },
      { name: "Pending", value: "0" },
    ];

    const setValue = (e) => {
      setChangeValue({
        ...changeValue,
        [e.target?.name]: e.target.value,
      });
    };

    const handleSearch = async (e) => {
      e.preventDefault();
      const data = {
        name: changeValue.name ? changeValue.name : "",
        office_id: changeValue.office_id ? changeValue.office_id : "",
        bonus_type: selectBonusTypeId ? selectBonusTypeId : "",
        bonus_status: selectBonusStatusId ? selectBonusStatusId : "",
      };
      setFilterData(data);
    };

    return (
      <>
        <div className="flex justify-end items-center gap-x-4">
          <form
            onSubmit={handleSearch}
            className="flex gap-x-3 items-center justify-start w-full myInput"
          >
            <div className="flex gap-x-3 items-center justify-center w-[90%]">
              <div className="w-[20%]">
                <input
                  onChange={(e) => setValue(e)}
                  className="ps-3 py-1.5 border border-gray-300 rounded-lg inputShadow w-full"
                  placeholder="Search by Name"
                  name="name"
                  value={changeValue.name}
                />
              </div>
              <div className="w-[20%]">
                <input
                  onChange={(e) => setValue(e)}
                  className="ps-3 py-1.5 border border-gray-300 rounded-lg inputShadow w-full"
                  placeholder="Search by Office Id"
                  name="office_id"
                  value={changeValue.office_id}
                />
              </div>
              <div className="w-[20%]">
                {/* <select
                  name="bonus_type"
                  onChange={(e) => setValue(e)}
                  className="w-full py-2  px-2 bg-transparent text-gray-400  border rounded border-gray-300 focus-visible:outline-none "
                >
                  <option value="" selected>
                    Select Bonus type
                  </option>
                  {bonusType?.map((bonus, i) => {
                    return (
                      <option
                        key={i}
                        placeholder="Select Bonus type"
                        className=" text-gray-800"
                        value={bonus?.id}
                      >
                        {bonus?.name}
                      </option>
                    );
                  })}
                </select> */}

                <Dropdown
                  value={selectBonusTypeId}
                  onChange={(e) => setSelectBonusTypeId(e.value)}
                  showClear
                  options={bonusTypeId}
                  optionLabel="label"
                  placeholder="Select a Country"
                  filter
                  className="w-full md:w-14rem"
                />
              </div>

              <div className="w-[20%]">
                <Dropdown
                  value={selectBonusStatusId}
                  onChange={(e) => setSelectBonusStatusId(e.value)}
                  options={BonusStatus}
                  showClear
                  optionLabel="name"
                  placeholder="Select a Country"
                  className="w-full md:w-14rem"
                />
              </div>
            </div>
            <div className="w-[10%]">
              <button className="cursor-pointer px-4 py-2 w-full bg-cyan-700 rounded-md text-white font-medium text-sm">
                Search
              </button>
            </div>
          </form>

          <span
            onClick={handleFresh}
            className="bg-cyan-700 text-white h-8 w-8 flex items-center justify-center rounded-full font-medium cursor-pointer"
          >
            <IoIosRefresh />
          </span>

          <CSVLink
            data={processesData?.length ? processesData : []}
            headers={deductionData}
            filename="Bonus.csv"
          >
            <div
              className="bg-cyan-700 h-8 w-8 flex items-center justify-center rounded-full
                text-white font-medium cursor-pointer"
            >
              <Tooltip target=".logo" position="top" />
              <BsFiletypeCsv className="logo" data-pr-tooltip="Download CSV" />
            </div>
          </CSVLink>
        </div>

        <div className="flex  justify-end">
          <div className="pt-2">
            <button
              onClick={() => setVisible(!visible)}
              className="flex items-center gap-x-2  px-2 py-2.5 bg-cyan-700 text-white rounded-md"
            >
              <span className="text-base font-semibold">
                <AiOutlinePlus />
              </span>{" "}
              <span className="text-base font-medium">Bonus Log</span>{" "}
            </button>
          </div>
        </div>
      </>
    );
  };

  const header = renderHeader();
  const modalData = {
    visible,
    allUser,
    bonusType,
    isSearchable,
    isClearable,
    update,
    setUpdate,
    setVisible,
  };

  const updateModaldata = {
    showUpdateModal,
    setShowUpdateModal,
    update,
    setUpdate,
    allUser,
    bonusType,
    
  };

  const DeleteUrl = "api/v1/bonus/log/one";
  const DeleteData = {
    showDelete,
    setShowDelete,
    update,
    setUpdate,
    selectData,
    DeleteUrl,
  };

  return (
    <div className="card bg-white pb-4 mb-4">
      {isLoader ? (
        <Loader />
      ) : (
        <DataTable
          header={header}
          value={bonusLog}
          selectionMode="single"
          selection={selectedProduct}
          size={"small"}
          onSelectionChange={(e) => setSelectedProduct(e.value)}
          dataKey="id"
          tableStyle={{ Width: "100%" }}
        >
          <Column
            field="Name"
            body={EmployeeNameTemplate}
            header="Name"
            className="w-[15%]"
          ></Column>
          <Column
            field="Total Amount"
            body={OfficeId}
            header="Office Id"
            className="w-[10%]"
          ></Column>
          <Column
            field=""
            body={DesignationTemplate}
            header="Designation"
            className="w-[10%]"
          ></Column>
          <Column
            field="Count"
            body={BonusType}
            header="Bonus Type"
            className="w-[10%]"
          ></Column>
          <Column
            field="Deduction Type"
            body={BonusAmount}
            header="Bonus Amount"
            className="w-[10%]"
          ></Column>
          <Column
            field="Deduction Type"
            body={BonusStatus}
            header="Bonus Status"
            className="w-[10%]"
          ></Column>
          <Column
            field="Modified At"
            body={ModifiedTemplate}
            header="Modified At"
            className="w-[10%]"
          ></Column>
          <Column
            field="Action"
            body={ActionTemplate}
            header="Action"
            className="w-[10%]"
          ></Column>
        </DataTable>
      )}

      <div className="mt-4">
        {bonusLog?.length > 0 && (
          <Pagination
            currentPage={currentPage}
            pageNumber={pageNumber}
            setCurrentPage={setCurrentPage}
            setSearchParams={setSearchParams}
            pageCount={pageCount}
            setViewData={setViewData}
            viewData={viewData}
            setPageNumber={setPageNumber}
          />
        )}
      </div>

      {<CreateBonusLog data={modalData} />}
      {<UpdateBonusLog data={updateModaldata} />}
      {<DeleteModal DeleteData={DeleteData} />}
    </div>
  );
};

export default BonusLog;

import React from "react";
import Breadcrumbs from "../Breadcrumbs/Breadcrumbs";
import LeaveType from "../LeaveType/LeaveType";

const GenLeaveTypePage = () => {
  const data = [
    {
      name: "Leave Type",
    },
  ];
  return (
    <>
      <Breadcrumbs data={data} />
      <LeaveType />
    </>
  );
};

export default GenLeaveTypePage;

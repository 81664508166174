import React from "react";
import { useNavigate } from "react-router-dom";

const Breadcrumbs = ({ data }) => {
  const navigate = useNavigate();
  const homeObj = { name: "Home", slash: "/", route: "/" };
  const final = [homeObj, ...data];

  return (
    <div className=" py-4 ps-4 mb-2 flex">
      {final.map(({ name, route, slash }, i) => {
        return (
          <button
            onClick={() => navigate(route)}
            className={`${route && "text-cyan-700 text-base font-medium"
            } ${!route && "cursor-text"}`}
            key={i}
          >
            {name}
            <span className="px-2">{slash}</span>
          </button>
        );
      })}
    </div>
  );
};

export default Breadcrumbs;

import React, { useState } from "react";
import Rodal from "rodal";
import Select from "react-select";
import { toast } from "react-toastify";
import { MainUrl } from "../../utils/helper";
import useData from "../../ContextApi/UseData/useData";

const UpdateMember = ({ Updateteam }) => {
  const [selectUser, setSelectUser] = useState(null);
  const [selectRole, setSelectRole] = useState(null);
  const { token } = useData().user;

  const [isClearable] = useState(true);
  const {
    showUpdateMember,
    setShowUpdateMember,
    role,
    allUser,
    update,
    setUpdate,
    projectData,
  } = Updateteam;
  const users = allUser?.map((user) => {
    let obj = {};
    obj.label = user?.user_profile?.name;
    obj.value = user?.user_profile?.id;
    return obj;
  });

  const roles = role?.map((singleRole) => {
    let obj = {};
    obj.label = singleRole?.name;
    obj.value = singleRole?.id;
    return obj;
  });

  const handleSubmit = (e) => {
    if (!token) {
      return;
    }
    e.preventDefault();
    const formData = {
      user_id: selectUser || projectData?.user?.id,
      project_role_id: selectRole || projectData?.project_role?.id,
    };
    let CreateTeam = async () => {
      const res = await fetch(
        `${MainUrl.url}/api/v1/project/member/${projectData?.id}`,
        {
          method: "put",
          headers: {
            "Content-type": "application/json",
            Authorization: token,
          },
          body: JSON.stringify(formData),
        }
      );
      const resData = await res.json();
      if (res.ok) {
        setUpdate(!update);
        toast.success("successfully Update team member");
        setShowUpdateMember(!showUpdateMember);
      } else {
        toast.error(resData.message);
        setShowUpdateMember(!showUpdateMember);
      }
    };
    CreateTeam();
  };

  return (
    <div>
      <Rodal
        visible={showUpdateMember}
        enterAnimation="flip"
        animation="slideRight"
        onClose={() => setShowUpdateMember(!showUpdateMember)}
        customStyles={{
          height: "auto",
          width: "50%",
          bottom: "auto",
          top: "10%",
          transform: "translateY(-50%)",
        }}
      >
        <h5 className="text-xl border-b py-3">Update Team</h5>

        <form onSubmit={handleSubmit}>
          <div className="mb-2 w-full">
            <span className="text-base font-[500]">Select Project</span>
            <div className="w-full">
              <Select
                className="basic-single"
                classNamePrefix="select"
                isClearable={isClearable}
                onChange={(e) => setSelectUser(e?.value)}
                options={users}
                placeholder={projectData?.user?.user_profile?.name}
              />
            </div>
          </div>

          <div className="mb-2 w-full">
            <span className="text-base font-[500]">Select Role</span>
            <div className="w-full">
              <Select
                className="basic-single"
                classNamePrefix="select"
                isClearable={isClearable}
                onChange={(e) => setSelectRole(e?.value)}
                options={roles}
                placeholder={projectData?.project_role?.name}
              />
            </div>
          </div>

          <input
            type="submit"
            value="Submit"
            className="text-white bg-cyan-700 font-medium text-xl  px-4 py-1 rounded"
          />
        </form>
      </Rodal>
    </div>
  );
};

export default UpdateMember;

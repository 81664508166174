import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import React, { useEffect, useState } from "react";
import { AiFillEdit, AiOutlineDelete, AiOutlinePlus } from "react-icons/ai";
import { IoIosRefresh } from "react-icons/io";
import CreateClient from "./CreateClient";
import UpdateClient from "./UpdateClient";
import useData from "../../ContextApi/UseData/useData";
import { MainUrl } from "../../utils/helper";
import Loader from "../Loader/Loader";
import DeleteModal from "../AllEmployee/DeleteModal/DeleteModal";
import { useLocation, useSearchParams } from "react-router-dom";
import Pagination from "../Pagination/Pagination";

const Clients = () => {
  const { token } = useData().user;
  const [ClientData, setClientData] = useState(null);
  const [totalData, setTotalData] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [update, setUpdate] = useState(false);
  const [updateModal, setUpdateModal] = useState(false);
  const [updateClintData, setUpdateClientData] = useState(null);
  const [isLoader, setIsLoader] = useState(false);
  const [changeValue, setChangeValue] = useState({});
  const [filterData, setFilterData] = useState({
    email: "",
  });
  const [showDelete, setShowDelete] = useState(false);
  const [selectData, setSelectData] = useState(null);
  const [viewData, setViewData] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchParams, setSearchParams] = useSearchParams();
  const [pageCount, setPageCount] = useState();
  const location = useLocation();

  useEffect(() => {
    const fetchData = async () => {
      if (!token) {
        return;
      }
      const page = location?.search?.split("=")[1];
      if (page) {
        setPageNumber(+page);
        setCurrentPage(+page);
      }

      try {
        setIsLoader(true);
        const res = await fetch(
          `${MainUrl.url}/api/v1/clients/all/${viewData}/${pageNumber}?${
            filterData.email && `email=${filterData.email}`
          }`,
          {
            method: "Get",
            headers: {
              Authorization: token,
            },
          }
        );
        if (res.ok) {
          if (res.status === 200) {
            const data = await res.json();
            setClientData(data.data?.records);
            setTotalData(data.data?.totalData);
            setIsLoader(false);
          } else {
            setClientData([]);
            setIsLoader(false);
          }
        }
      } catch (error) {
        console.log(error.message);
      }
    };
    fetchData();
  }, [pageNumber, viewData, update, token, filterData, location, searchParams]);

  useEffect(() => {
    const pages = Math.ceil(totalData / viewData);
    setPageCount(pages);
  }, [totalData, viewData]);

  const handleFresh = () => {
    setChangeValue({
      email: "",
    });
    setFilterData({
      email: "",
    });
    setUpdate(!update);
  };

  const NameTemplate = (data) => {
    return <p className="text-base">{data?.name}</p>;
  };
  const EmailTemplate = (data) => {
    return <p>{data?.email}</p>;
  };

  const ContactNumberTemplate = (data) => {
    return <p>{data?.contact_number}</p>;
  };

  const AddressTemplate = (data) => {
    return (
      <div className="group flex relative">
        {data?.address.length > 20 ? (
          <span>{data?.address.slice(0, 20)}..</span>
        ) : (
          <span>{data?.address}</span>
        )}
        {data?.address?.length < 30 && (
          <span
            className="group-hover:opacity-100 transition-opacity bg-gray-800 px-1.5 py-1 z-10 text-sm text-gray-100 rounded-md absolute left-1/3 -top-10
      -translate-x-1/2 translate-y-full opacity-0 mx-auto"
          >
            {data?.address}
          </span>
        )}
      </div>
    );
  };

  const ActionTemplate = (data) => {
    return (
      <div className=" flex items-center gap-x-2">
        <div
          onClick={() => {
            setUpdateModal(!updateModal);
            setUpdateClientData(data);
          }}
          className="bg-cyan-700 h-8 w-8 flex items-center justify-center rounded-full cursor-pointer"
        >
          <AiFillEdit className="text-white" />
        </div>
        <div
          className="bg-red-400 h-8 w-8 flex items-center justify-center rounded-full cursor-pointer"
          onClick={() => {
            setSelectData(data);
            setShowDelete(!showDelete);
          }}
        >
          <AiOutlineDelete className="text-white" />
        </div>
      </div>
    );
  };

 
  const renderHeader = () => {
    const setValue = (e) => {
      setChangeValue({
        ...changeValue,
        [e?.target?.name]: e?.target?.value,
      });
    };

    const handleSearch = async (e) => {
      e.preventDefault();
      const data = {
        email: changeValue.email ? changeValue.email : "",
      };
      setFilterData(data);
    };

    return (
      <div className="grid grid-cols-12 items-center justify-between">
        <div className="col-span-8">
          <div className="grid grid-cols-12 gap-x-2 items-center">
            <div className="col-span-10">
              <form
                className="flex gap-x-3  w-full myInput"
                onSubmit={handleSearch}
              >
                <div className="w-full">
                  <input
                    onChange={(e) => setValue(e)}
                    className="ps-3 py-1.5 border border-gray-300 rounded-lg inputShadow w-full"
                    placeholder="Search by Name or Gmail"
                    name="email"
                    value={changeValue.email}
                  />
                </div>
                <div className="">
                  <input
                    type="submit"
                    value="Search"
                    className="cursor-pointer px-4 py-2 w-full bg-cyan-700 rounded-md text-white font-medium text-sm submit"
                  />
                </div>
              </form>
            </div>
            <div className="col-span-2 gap-x-2">
              <div>
                <span
                  onClick={handleFresh}
                  className="bg-cyan-700 text-white h-8 w-8 flex items-center justify-center rounded-full font-medium cursor-pointer"
                >
                  <IoIosRefresh />
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="col-span-4">
          <div className="flex items-end justify-end">
            <button
              onClick={() => setShowModal(!showModal)}
              className="flex items-center gap-x-2  px-2 py-2.5 bg-cyan-700 text-white rounded-md"
            >
              <span className="text-base font-semibold">
                <AiOutlinePlus />
              </span>
              <span className="text-base font-medium">Client</span>
            </button>
          </div>
        </div>
      </div>
    );
  };
  const header = renderHeader();

  const modalData = {
    showModal,
    update,
    setUpdate,
    setShowModal,
  };

  const UpdateData = {
    updateModal,
    update,
    updateClintData,
    setUpdate,
    setUpdateModal,
    setUpdateClientData,
  };
  const DeleteUrl = "api/v1/client/one";
  const DeleteData = {
    showDelete,
    setShowDelete,
    update,
    setUpdate,
    selectData,
    DeleteUrl,
  };

  return (
    <div className="bg-white pb-4 mb-4">
      {isLoader ? (
        <Loader />
      ) : (
        <DataTable
          size="small"
          header={header}
          value={ClientData}
          // footer={footerTemplate}
          selectionMode="single"
          dataKey="id"
          tableStyle={{ Width: "100%" }}
        >
          <Column
            field="name"
            body={NameTemplate}
            header="Client Name"
            sortable
          ></Column>
          <Column
            field="email"
            body={EmailTemplate}
            header="Email"
            sortable
          ></Column>
          <Column
            field="contact_number"
            body={ContactNumberTemplate}
            header="Phone Number"
            sortable
          ></Column>
          <Column
            field="address"
            body={AddressTemplate}
            header="Address"
            sortable
          ></Column>
          <Column
            field=""
            body={ActionTemplate}
            header="Action"
            className="w-[15%]"
            sortable
          ></Column>
        </DataTable>
      )}

      <div className="mt-4">
        {ClientData?.length > 0 && (
          <Pagination
            currentPage={currentPage}
            pageNumber={pageNumber}
            setCurrentPage={setCurrentPage}
            setSearchParams={setSearchParams}
            pageCount={pageCount}
            setViewData={setViewData}
            viewData={viewData}
            setPageNumber={setPageNumber}
          />
        )}
      </div>

      {<CreateClient modalData={modalData} />}
      {<UpdateClient UpdateData={UpdateData} />}
      {<DeleteModal DeleteData={DeleteData} />}
    </div>
  );
};

export default Clients;

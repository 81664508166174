import React from "react";
import BonusLog from "../BonusLog/BonusLog";
import Breadcrumbs from "../Breadcrumbs/Breadcrumbs";

const BonusLogPage = () => {
  const data = [
    {
      name: "Bonus Table",
    },
  ];
  return (
    <div>
      <Breadcrumbs data={data}/>
      <BonusLog />
    </div>
  );
};

export default BonusLogPage;

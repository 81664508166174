import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import React, { useEffect, useState } from "react";

import { toast } from "react-toastify";
import { MainUrl } from "../../utils/helper";
import useData from "../../ContextApi/UseData/useData";
import { AiFillEdit, AiOutlineDelete, AiOutlinePlus } from "react-icons/ai";
import { Menu, MenuButton, MenuItem } from "@szhsin/react-menu";
import { BsThreeDotsVertical } from "react-icons/bs";
import CreateTransportPolicy from "./CreateTransportPolicy";
import DeleteModal from "../AllEmployee/DeleteModal/DeleteModal";

const TransportPolicy = () => {
  const { token } = useData().user;
  const [update, setUpdate] = useState(false);
  const [TransportPolicy, setTransportPolicy] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [selectData, setSelectData] = useState(null)
  const [showDelete, setShowDelete] = useState(false)


  //   get Table Data
  useEffect(() => {
    if (!token) {
      return;
    }
    const fetchData = async () => {
      try {
        const res = await fetch(`${MainUrl.url}/api/v1/transport/policies`, {
          method: "get",
          headers: {
            Authorization: token,
          },
        });
        const data = await res.json();
        if (res.ok) {
          setTransportPolicy(data.data);
        }
      } catch (error) {
        toast.error(error?.message);
      }
    };
    fetchData();
  }, [update, token]);

  const DesignationsTemplate = (data) => {
    console.log("data", data);
    return (
      <div>
        <p className="test-base">{data?.user_designation?.name}</p>
      </div>
    );
  };


  const TransportTypeTemplate = (data) => {
    return (
      <div>
        <p>{data?.transport_type?.name}</p>
      </div>
    );
  };


  const TravelTypeTemplate = (data) => {
    return (
      <div>
        <p>{data.in_city === 0 ? "In City" : "Out City"}</p>
      </div>
    );
  };

  const AllowancePerKiloTemplate = (data) => {
    return (
      <div className="text-base">
        <p>{data?.allowance_per_km}</p>
      </div>
    );
  };

  const ActionTemplate = (data) => {
    return (
      <Menu
        direction={"right"}
        align={"center"}
        menuButton={
          <MenuButton>
            <BsThreeDotsVertical />
          </MenuButton>
        }
        transition
      >
        <MenuItem
          className="hover:text-red-400"
          onClick={() => {
            setSelectData(data);
            setShowModal(!showModal)
            // setShowUpdateMobile(!showUpdateMobile);
          }}
        >
          <span className="mr-2">
            <AiFillEdit />
          </span>
          Update
        </MenuItem>
        <MenuItem
          className="hover:text-red-400"
          onClick={() => {
            setShowDelete(!showDelete)
            setSelectData(data)
          }}
        >
          <span>
            <AiOutlineDelete className="mr-2" />
          </span>{" "}
          Delete
        </MenuItem>
      </Menu>
    );
  };

  const renderHeader = () => {
    return (
      <div className="flex justify-end">
        <button
          onClick={() => setShowModal(!showModal)}
          className="flex items-center gap-x-2  px-2 py-2.5 bg-cyan-700 text-white rounded-md"
        >
          <span className="text-base font-semibold">
            <AiOutlinePlus />
          </span>
          <span className="text-base font-medium">Travel Allowance</span>
        </button>
      </div>
    );
  };
  const header = renderHeader();

  const CreateData = {
    showModal,
    setShowModal,
    update,
    setUpdate,
    selectData,
    setSelectData
  };

  const DeleteUrl = "api/v1/transport/policy";
  const DeleteData = {
    showDelete,
    setShowDelete,
    update,
    setUpdate,
    selectData,
    DeleteUrl,
  };

  return (
    <div>
      <DataTable
        size="small"
        header={header}
        value={TransportPolicy}
        selectionMode="single"
        dataKey="id"
        tableStyle={{ Width: "100%" }}
      >
        <Column
          field=""
          header="Designation"
          body={DesignationsTemplate}
          sortable
          className=""
        ></Column>

        <Column
          field=""
          header="Transport Type"
          body={TransportTypeTemplate}
          sortable
          className=""
        ></Column>

        <Column
          field=""
          header="Travel Type"
          body={TravelTypeTemplate}
          sortable
          className=""
        ></Column>

        <Column
          field=""
          header="Allowance Per Kilometer"
          body={AllowancePerKiloTemplate}
          sortable
          className=""
        ></Column>
        <Column
          field=""
          header="Action"
          body={ActionTemplate}
          className="w-10"
        ></Column>
      </DataTable>

      {<CreateTransportPolicy CreateData={CreateData} />}
      {
        <DeleteModal DeleteData={DeleteData}/>
      }
    </div>
  );
};

export default TransportPolicy;

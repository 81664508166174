import React from "react";
import Breadcrumbs from "../Breadcrumbs/Breadcrumbs";
import CreateUser from "../AllEmployee/CreateUser";

const CreateUserPage = () => {
  const data = [
    {
      name: "All Employee-Table",
      slash: "/",
      route:"/all-Employee"
    },
    {
      name: "Create User",
    },
  ];
  return (
    <div>
      <Breadcrumbs data={data} />
      <CreateUser />
    </div>
  );
};

export default CreateUserPage;

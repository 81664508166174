import { RxDashboard } from "react-icons/rx";
import { GiCheckboxTree, GiSettingsKnobs } from "react-icons/gi";
import {
  PiHandCoinsLight,
  PiNotepadLight,
  PiUserPlus,
  PiUsersFourLight,
} from "react-icons/pi";
import { FaUsersViewfinder } from "react-icons/fa6";
import { TbMoneybag } from "react-icons/tb";
import { LiaCampgroundSolid, LiaCutSolid } from "react-icons/lia";
import {
  MdOutlineEmojiTransportation,
  MdOutlineMoreTime,
} from "react-icons/md";
import { GoProjectSymlink } from "react-icons/go";
import { RiMoneyDollarCircleLine } from "react-icons/ri";
import { BsPiggyBank } from "react-icons/bs";

export const MainUrl = {
  url: process.env.REACT_APP_DOMAIN,
  localToken: process.env.REACT_APP_localUrl,
  localUrl: process.env.REACT_APP_localToken,
};

export const saveInCookie = (name, value) => {
  document.cookie = `${name}=${value}`;
};

export const getFromCookie = (name) => {
  const items = document.cookie.split(";");
  const item = items.find((item) => item.includes(name))?.split("=")[1];
  return item;
};

export const showDummyImage = ({ currentTarget }, imageSize) => {
  currentTarget.onerror = null;
  currentTarget.src = `https://dummyimage.com/${imageSize}`;
};

export const formatTimeAgo = (timestamp) => {
  const now = new Date();
  const date = new Date(timestamp);
  if (timestamp?.length <= 0) {
    return <span className="text-red-500">No data</span>;
  }
  const timeDifferenceInSeconds = Math.floor((now - date) / 1000);

  if (timeDifferenceInSeconds < 60) {
    return "Just now";
  }

  const timeDifferenceInMinutes = Math.floor(timeDifferenceInSeconds / 60);

  if (timeDifferenceInMinutes < 60) {
    return `${timeDifferenceInMinutes} minute${
      timeDifferenceInMinutes > 1 ? "s" : ""
    } ago`;
  }

  const timeDifferenceInHours = Math.floor(timeDifferenceInMinutes / 60);

  if (timeDifferenceInHours < 24) {
    return `${timeDifferenceInHours} hour${
      timeDifferenceInHours > 1 ? "s" : ""
    } ago`;
  }

  const timeDifferenceInDays = Math.floor(timeDifferenceInHours / 24);

  if (timeDifferenceInDays < 30) {
    return `${timeDifferenceInDays} day${
      timeDifferenceInDays > 1 ? "s" : ""
    } ago`;
  }

  const timeDifferenceInMonths = Math.floor(timeDifferenceInDays / 30);

  if (timeDifferenceInMonths < 12) {
    return `${timeDifferenceInMonths} month${
      timeDifferenceInMonths > 1 ? "s" : ""
    } ago`;
  }
  const timeDifferenceInYears = Math.floor(timeDifferenceInMonths / 12);
  return `${timeDifferenceInYears} year${
    timeDifferenceInYears > 1 ? "s" : ""
  } ago`;
};

export const MonthValueSame = [
  { value: "January", label: "January" },
  { value: "February", label: "February" },
  { value: "March", label: "March" },
  { value: "April", label: "April" },
  { value: "May", label: "May" },
  { value: "June", label: "June" },
  { value: "July", label: "July" },
  { value: "August", label: "August" },
  { value: "September", label: "September" },
  { value: "October", label: "October" },
  { value: "November", label: "November" },
  { value: "December", label: "December" },
];

export const sidebarItems = [
  {
    id: 1,
    title: "Dashboard",
    icon: <RxDashboard size={20} />,
    role: ["admin", "user", "manager"],
    to: "/",
  },
  {
    id: 2,
    title: "Department",
    icon: <GiCheckboxTree size={20} />,
    role: ["admin", "manager"],
    links: [
      {
        id: 1,
        title: "All Department",
        to: "/all-department",
      },
      {
        id: 1,
        title: "Sub Department",
        to: "/sub-department",
      },
    ],
  },
  {
    id: 3,
    title: "Notice",
    icon: <PiNotepadLight size={20} />,
    to: "/notice",
  },
  {
    id: 4,
    title: "Attendance",
    icon: <FaUsersViewfinder size={20} />,
    to: "/attendance",
  },
  {
    id: 6,
    title: "Salaries",
    icon: <TbMoneybag size={20} />,
    to: "/salary",
  },
  {
    id: 7,
    title: "Deduction",
    icon: <LiaCutSolid size={20} />,
    to: "/deduction",
  },
  {
    id: 8,
    title: "All Employee",
    icon: <PiUsersFourLight size={20} />,
    to: "/all-Employee",
  },
  {
    id: 9,
    title: "Provident Fund",
    icon: <BsPiggyBank size={20} />,
    links: [
      {
        id: 1,
        title: "Provident Accounts",
        to: "/providentFundAccount",
      },
      {
        id: 2,
        title: "All Payslip",
        to: "/all-pay-slip",
      },
      {
        id: 3,
        title: "My Account",
        to: "/my-account",
      },
      {
        id: 4,
        title: "My Payslip",
        to: "my-pay-slip",
      },
    ],
  },
  {
    id: 10,
    title: "Clients",
    icon: <PiUserPlus size={20} />,
    to: "/client",
  },
  {
    id: 11,
    title: "Bonus",
    icon: <PiHandCoinsLight size={20} />,
    links: [
      {
        id: 1,
        title: "Bonus Log",
        to: "/bonus-log",
      },
    ],
  },
  {
    id: 12,
    title: "Overtime",
    icon: <MdOutlineMoreTime size={20} />,
    links: [
      {
        id: 1,
        title: "Overtime",
        to: "/over-time",
      },
      {
        id: 2,
        title: "Admin Overtime log",
        to: "/admin-over-time",
      },
    ],
  },
  {
    id: 13,
    title: "Leave",
    icon: <LiaCampgroundSolid size={20} />,
    links: [
      {
        id: 1,
        title: "Leave Apply",
        to: "/leave-apply",
      },
      {
        id: 2,
        title: "Leave Applications",
        to: "/leave-application",
      },
    ],
  },
  {
    id: 14,
    title: "Project Management",
    icon: <GoProjectSymlink size={20} />,
    links: [
      {
        id: 1,
        title: "Apply Project Proposal",
        to: "/apply-project-proposal",
      },
      {
        id: 2,
        title: "Project Proposal",
        to: "/project-proposal",
      },
      {
        id: 3,
        title: "Project progresses",
        to: "/project-progress",
      },
      {
        id: 4,
        title: "Project Roles",
        to: "/project-role",
      },
      {
        id: 5,
        title: "Project Status",
        to: "/project-progress-status",
      },
    ],
  },
  // {
  //   id:14,
  //   title:"Recruitment",
  //   icon:  <AiOutlineDashboard/>,
  //   links:[
  //     {
  //       id:1,
  //       title:"All Circular",
  //       to:""
  //     },
  //     {
  //       id:2,
  //       title:"Create Short Candidate",
  //       to:""
  //     },
  //     {
  //       id:3,
  //       title:"Short Candidate List",
  //       to:""
  //     },
  //     {
  //       id:4,
  //       title:"Interview Remarks",
  //       to:""
  //     },
  //     {
  //       id:5,
  //       title:"Interview Stage",
  //       to:""
  //     }
  //   ]
  // },
  // {
  //   id: 15,
  //   title:"Requisition",
  //   icon: <AiOutlineDashboard/>,
  //   links:[
  //     {
  //       id:1,
  //       title:"Product Request",
  //       to:""
  //     },
  //     {
  //       id:2,
  //       title:"My Wises",
  //       to:""
  //     },
  //     {
  //       id:3,
  //       title:"Product Log",
  //       to:""
  //     },
  //     {
  //       id:4,
  //       title:"Services Request",
  //       to:""
  //     },
  //     {
  //       id:5,
  //       title:"Patty cast",
  //       to:""
  //     },
  //     {
  //       id:6,
  //       title:"Cost Method",
  //       to:""
  //     },
  //     {
  //       id:7,
  //       title:"Cost Category",
  //       to:""
  //     },
  //   ]
  // },
  // {
  //   id:16,
  //   title:"Inventory",
  //   icon: <AiOutlineDashboard/>,
  //   to:""
  // },
  {
    id: 18,
    title: "Incentive",
    icon: <RiMoneyDollarCircleLine size={20} />,
    links: [
      {
        id: 1,
        title: "All Incentive",
        to: "/all-incentive",
      },
      {
        id: 2,
        title: "Eligibility",
        to: "/eligibility",
      },
    ],
  },

  {
    id: 20,
    title: "Transport Log",
    icon: <MdOutlineEmojiTransportation size={20} />,
    to: "/transport-log",
  },
  {
    id: 21,
    title: "Special Allowance",
    icon: <MdOutlineEmojiTransportation size={20} />,
    to: "special-allowance",
  },

  {
    id: 19,
    title: "General Setting",
    icon: <GiSettingsKnobs size={20} />,
    links: [
      {
        id: 1,
        title: "Designation",
        to: "/designation",
      },
      {
        id: 2,
        title: "Provident Fund Policy",
        to: "/provident-fund-policy",
      },
      {
        id: 3,
        title: "Bonus Policy",
        to: "/bonus-policy",
      },
      {
        id: 4,
        title: "Overtime Policy",
        to: "/over-time-policy",
      },
      {
        id: 5,
        title: "Leave Policy",
        to: "/leave-policy",
      },
      {
        id: 6,
        title: "Leave Type",
        to: "/leave-type",
      },
      {
        id: 7,
        title: "Deduction Type",
        to: "/deduction-type",
      },
      {
        id: 8,
        title: "Emergency Relationship",
        to: "/emergency-relationShip",
      },
      {
        id: 9,
        title: "Mobile Allowance",
        to: "/mobile-allowance",
      },
      {
        id: 10,
        title: "Transport Allowance",
        to: "/transport-type",
      },
      {
        id: 11,
        title: "Transport Policy",
        to: "/transport-policy",
      },
    ],
  },
];

// const DeleteUrl = "api/v1/salaries/one";
// const DeleteData = {
//   showDelete,
//   setShowDelete,
//   update,
//   setUpdate,
//   selectData,
//   DeleteUrl,
// };

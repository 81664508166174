import React, { useState } from "react";
import { sidebarItems } from "../../utils/helper";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import logo from "../../Assets/logo.png";

const Sidebar = ({ show, setShow }) => {
  const sideBar = sidebarItems;
  const navigate = useNavigate();
  const [active, setActive] = useState(null);

  return (
    // <aside
    //   className={`w-72 h-full overflow-auto example fixed pt-5 transition-all duration-[250ms]  z-50 ${
    //     show ? "left-0" : "-left-full"
    //   } bg-slate-800 text-white`}
    // >

    //   {/* maping */}
    // <div>
    //   <div className="flex justify-center items-center w-full">
    //     <div className="h-[53px] w-[200px] mb-10">
    //       <img className="h-full w-full " src={logo} alt="office logo" />
    //     </div>
    //   </div>
    //   <ul>
    //     {sideBar?.map(({ title, links, id, to, icon }, i) => {
    //       return (
    //         <li key={i} className="gap-4">
    //           <div
    //             onClick={() => {
    //               setActive(id === active ? null : id);
    //               to && navigate(to);
    //             }}
    //             className={`py-2 px-4  flex items-center justify-between w-full hover:bg-slate-700 duration-150 ${active===id &&"bg-slate-700"}`}
    //           >
    //             <div className={`cursor-pointer flex items-center gap-x-3 w-full`}>
    //               <span>{icon}</span>
    //               <p>{title}</p>
    //             </div>
    //             {links?.length && (
    //               <div className="transition">
    //                 {active === id ? (
    //                   <IoIosArrowUp
    //                     size={20}
    //                     className="cursor-pointer"
    //                   />
    //                 ) : (
    //                   <IoIosArrowDown
    //                     size={20}
    //                     className="cursor-pointer"
    //                   />
    //                 )}
    //               </div>
    //             )}
    //           </div>
    //           {active === id && (
    //             <ul>
    //               {links?.map((item, i) => {
    //                 return (
    //                   <li
    //                     key={i}
    //                     onClick={() => navigate(item?.to)}
    //                     className="py-2 ps-10 cursor-pointer hover:bg-slate-700"
    //                   >
    //                     {item.title}
    //                   </li>
    //                 );
    //               })}
    //             </ul>
    //           )}
    //         </li>
    //       );
    //     })}
    //   </ul>
    // </div>

    //   {show && (
    //     <div
    //       onClick={() => setShow(false)}
    //       className="block fixed  top-0 left-0 h-screen w-full bg-black opacity-10 lg:hidden"
    //     ></div>
    //   )}
    // </aside>

    <div className="">
      <div
        style={{ zIndex: 5500 }}
        className={`SideBar h-full w-64 shadow-lg transition1 overflow-y-auto  fixed  bg-slate-800 text-white ${
          show ? "-left-64 lg:left-0" : "left-0 lg:-left-64"
        }`}
      >
        <div>
          <div className="flex justify-center items-center w-full pt-4">
            <div className="h-[53px] w-[200px] mb-10">
              <img className="h-full w-full " src={logo} alt="office logo" />
            </div>
          </div>
          <ul>
            {sideBar?.map(({ title, links, id, to, icon }, i) => {
              return (
                <li key={i} className="gap-4">
                  <div
                    onClick={() => {
                      setActive(id === active ? null : id);
                      to && navigate(to);
                    }}
                    className={`py-2 px-4  flex items-center justify-between w-full hover:bg-slate-700 duration-150 ${
                      active === id && "bg-slate-700"
                    }`}
                  >
                    <div
                      className={`cursor-pointer flex items-center gap-x-3 w-full`}
                    >
                      <span>{icon}</span>
                      <p>{title}</p>
                    </div>
                    {links?.length && (
                      <div className="transition">
                        {active === id ? (
                          <IoIosArrowUp size={20} className="cursor-pointer" />
                        ) : (
                          <IoIosArrowDown
                            size={20}
                            className="cursor-pointer"
                          />
                        )}
                      </div>
                    )}
                  </div>
                  {active === id && (
                    <ul>
                      {links?.map((item, i) => {
                        return (
                          <li
                            key={i}
                            onClick={() => navigate(item?.to)}
                            className="py-2 ps-10 cursor-pointer hover:bg-slate-700"
                          >
                            {item.title}
                          </li>
                        );
                      })}
                    </ul>
                  )}
                </li>
              );
            })}
          </ul>
        </div>
      </div>

      {show && (
        <div
          onClick={() => setShow(false)}
          className={`layout w-full h-full bg-black fixed top-0 z-40 opacity-20 block lg:hidden ${
            show ? "left-0" : "-left-full"
          }`}
        ></div>
      )}
    </div>
  );
};

export default Sidebar;

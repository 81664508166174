import React, { useEffect, useState } from "react";
import { AiFillEdit, AiOutlineDelete, AiOutlinePlus } from "react-icons/ai";
import useData from "../../ContextApi/UseData/useData";
import { MainUrl } from "../../utils/helper";
import { toast } from "react-toastify";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Menu, MenuButton, MenuItem } from "@szhsin/react-menu";
import { BsThreeDotsVertical } from "react-icons/bs";
import moment from "moment";
import TransportAllowanceTypeModal from "./TransportAllowanceTypeModal";
import DeleteModal from "../AllEmployee/DeleteModal/DeleteModal";

const TransportAllowanceType = () => {
  const { token } = useData().user;
  const [transportTypes, setTransportTypes] = useState(null);
  const [update, setUpdate] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [selectData, setSelectedData] = useState(null);
  const [showDelete, setShowDelete] = useState(false);

  useEffect(() => {
    if (!token) {
      return;
    }
    const fetchData = async () => {
      try {
        const res = await fetch(`${MainUrl.url}/api/v1/transport/types`, {
          method: "Get",
          headers: {
            Authorization: token,
          },
        });
        const resData = await res.json();
        if (res.ok) {
          setTransportTypes(resData.data);
        }
      } catch (error) {
        toast.error(error.message);
      }
    };
    fetchData();
  }, [update, token]);

  const NameTemplate = (data) => {
    return <p className="text-base">{data?.name}</p>;
  };

  const ModifyDateTemplate = (data) => {
    const formattedDate = moment(data.modified_at).format("YYYY-MM-DD");
    return <p className="text-base">{formattedDate}</p>;
  };

  const ActionTemplate = (data) => {
    return (
      <Menu
        direction={"right"}
        align={"center"}
        menuButton={
          <MenuButton>
            <BsThreeDotsVertical />
          </MenuButton>
        }
        transition
      >
        <MenuItem
          className="hover:text-red-400"
          onClick={() => {
            setSelectedData(data);
            setShowModal(!showModal);
          }}
        >
          <span className="mr-2">
            <AiFillEdit />
          </span>
          Update
        </MenuItem>
        <MenuItem
          className="hover:text-red-400"
          onClick={() => {
            setSelectedData(data);
            setShowDelete(!showDelete);
          }}
        >
          <span>
            <AiOutlineDelete className="mr-2" />
          </span>{" "}
          Delete
        </MenuItem>
      </Menu>
    );
  };

  const renderHeader = () => {
    return (
      <div className="grid grid-cols-12 justify-between">
        <div className="col-span-6">
          <h2 className="text-xl font-semibold">Transport Allowance Type</h2>
        </div>

        <div className="col-span-6">
          <div className="flex items-end justify-end">
            <button
              onClick={() => setShowModal(!showModal)}
              className="flex items-center gap-x-2  px-2 py-2.5 bg-cyan-700 text-white rounded-md"
            >
              <span className="text-base font-semibold">
                <AiOutlinePlus />
              </span>
              <span className="text-base font-medium">Transport Allowance</span>
            </button>
          </div>
        </div>
      </div>
    );
  };
  const header = renderHeader();

  const modalData = {
    showModal,
    setShowModal,
    update,
    setUpdate,
    selectData,
    setSelectedData,
  };

  const DeleteUrl = "api/v1/transport/type";
  const DeleteData = {
    showDelete,
    setShowDelete,
    update,
    setUpdate,
    selectData,
    DeleteUrl,
  };

  return (
    <>
      <DataTable
        size="small"
        header={header}
        value={transportTypes}
        selectionMode="single"
        dataKey="id"
        tableStyle={{ Width: "100%" }}
      >
        <Column
          header="Transport Name"
          className=""
          body={NameTemplate}
        ></Column>
        <Column
          header="Modify At"
          body={ModifyDateTemplate}
          className=""
        ></Column>
        <Column header="Action" body={ActionTemplate} className=""></Column>
      </DataTable>
      {<TransportAllowanceTypeModal modalData={modalData}/>}
      {<DeleteModal DeleteData={DeleteData}/>}
    </>
  );
};

export default TransportAllowanceType;

import React from "react";
import Breadcrumbs from "../Breadcrumbs/Breadcrumbs";
import TodayAttendance from "../Attendance/TodayAttendance";

const TodayAttendancePage = () => {
  const data = [
    {
      name: "Attendance",
      slash: "/",
    },
    {
      name: "To-Day Attendance",
    },
  ];
  return (
    <>
      <Breadcrumbs data={data} />
      <TodayAttendance />
    </>
  );
};

export default TodayAttendancePage;

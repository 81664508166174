import { Dialog } from "primereact/dialog";
import React from "react";
import { TfiClose } from "react-icons/tfi";
import "./DeleteModal.css";
import { toast } from "react-toastify";
import useData from "../../../ContextApi/UseData/useData";
import { MainUrl } from "../../../utils/helper";

const DeleteModal = ({ DeleteData }) => {
  const {
    showDelete,
    setShowDelete,
    selectData,
    update,
    setUpdate,
    DeleteUrl,
  } = DeleteData;
  const { token } = useData().user;

  const handleDelete = async () => {
    if (!token) {
      return;
    }
    try {
      const res = await fetch(`${MainUrl.url}/${DeleteUrl}/${selectData?.id}`, {
        method: "delete",
        headers: {
          Authorization: token,
        },
      });
      if (res.ok) {
        setUpdate(!update);
        toast.success("Data Deleted SuccessFully");
        setShowDelete(!showDelete);
      } else {
        const resData = await res.json();
        toast.warn(resData?.message);
        setShowDelete(!showDelete);
      }
    } catch (error) {
      toast.error(error.message);
    }
  };
  return (
    <div>
      <Dialog
        visible={showDelete}
        style={{ width: "25vw" }}
        breakpoints={{ '960px': '75vw', '641px': '100vw' }}
        position={"top"}
        draggable={false}
        resizable={false}
        onHide={() => setShowDelete(!showDelete)}
        className="delete"
      >
        <div>
          <div className="text-red-400  border-2 border-red-400 rounded-full w-20 h-20 p-4 mx-auto">
            <TfiClose
              onClick={() => setShowDelete(!showDelete)}
              size={20}
              className="w-full h-full "
            />
          </div>

          <h4 className="text-center text-xl pt-4">Are You Sure?</h4>
          <p className="text-center">
            Do you really want to delete these records? This process Cannot be
            undone{" "}
          </p>
          <div className="pt-4  flex justify-center gap-x-4">
            <button
              onClick={() => setShowDelete(!showDelete)}
              className="py-2 px-4 bg-gray-800 text-white text-base font-medium rounded"
            >
              Cancel
            </button>
            <button
              onClick={handleDelete}
              className="py-2 px-4 bg-red-600 text-white text-base font-medium rounded"
            >
              Delete
            </button>
          </div>
        </div>
      </Dialog>
    </div>
  );
};

export default DeleteModal;

import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { AiFillEdit, AiOutlineDelete, AiOutlinePlus } from "react-icons/ai";
import AddLeavePolicyModal from "./AddLeavePolicyModal";
import UpDateLeavePolicy from "./UpDateLeavePolicy";
import { MainUrl } from "../../utils/helper";
import useData from "../../ContextApi/UseData/useData";
import Loader from "../Loader/Loader";
import DeleteModal from "../AllEmployee/DeleteModal/DeleteModal";

const LeavePolicy = () => {
  const { token } = useData().user;
  const [leavePolicyData, setLeavePolicyData] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [update, setUpdate] = useState(false);
  const [UpdateData, setUpdateData] = useState("");
  const [isLoader, setIsLoader] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [selectData, setSelectData] = useState(null);


// Get TABLE DATA
  useEffect(() => {
    if(!token){
        return
    }
    const fetchData = async () => {
      try {
        setIsLoader(true);
        const res = await fetch(`${MainUrl.url}/api/v1/leave/policies`, {
          method: "get",
          headers: {
            Authorization: token,
          },
        });
        const data = await res.json();
        if (res.ok) {
          setLeavePolicyData(data?.data);
          setIsLoader(false);
        } else {
          toast.error(data.message);
          console.error(data.message);
          setIsLoader(false);
        }
      } catch (error) {
        console.log(error.message);
        setIsLoader(false);
      }
    };
    fetchData();
  }, [update, token]);

  const renderHeader = () => {
    return (
      <div className="flex justify-end">
        <div className="pt-2">
          <button
            onClick={() => setShowModal(!showModal)}
            className="flex items-center gap-x-2  px-2 py-2.5 bg-cyan-700 text-white rounded-md"
          >
            <span className="text-base font-semibold">
              <AiOutlinePlus />
            </span>{" "}
            <span className="text-base">Leave Policy</span>{" "}
          </button>
        </div>
      </div>
    );
  };
  const header = renderHeader();

  const NameTemplate = (data) => {
    return <p className="text-base">{data?.leave_type?.name}</p>;
  };

  const LeaveStatusName = (data) => {
    return (
      <p className="text-base">{data.leave_pay_status.name}</p>
    );
  };

  const DayCountTemplate = (data) => {
    return <p className="text-base">{data.day_count}</p>;
  };
  const ModifyTimeTemplate = (data) => {
    const date = data?.modified_at;
    let formatDate = new Date(date)?.toLocaleDateString("en-GB", {
      year: "numeric",
      month: "numeric",
      day: "numeric",
    });
    return <p className="text-base">{formatDate}</p>;
  };

  // const handleDelete = async (id, name) => {
  //   const agree = window.confirm(`are you sure to delete ${id} and ${name}`);
  //   if (agree) {
  //     try {
  //       const res = await fetch(`${MainUrl.url}/api/v1/leave/policies/${id}`, {
  //         method: "delete",
  //         headers: {
  //           Authorization: token,
  //         },
  //       });
  //       if (res.ok) {
  //         setUpdate(!update);
  //       }
  //     } catch (error) {
  //       toast.error(error.message);
  //     }
  //   }
  // };

  const ActionTemplate = (data) => {
    // const id = data?.id;
    // const name = data?.leave_type?.name;
    return (
      <div className=" flex items-center gap-x-2">
        <div
          className="bg-cyan-700 h-8 w-8 flex items-center justify-center rounded-full cursor-pointer"
          onClick={() => {
            setShowUpdateModal(!showUpdateModal);
            setUpdateData(data);
          }}
        >
          <AiFillEdit className="text-white" />
        </div>
        <div
          className="bg-red-400 h-8 w-8 flex items-center justify-center rounded-full cursor-pointer"
          onClick={() => {
            setSelectData(data)
            setShowDelete(!showDelete)
          }}
        >
          <AiOutlineDelete className="text-white" />
        </div>
      </div>
    );
  };

  const modalData = {
    showModal,
    update,
    setUpdate,
    setShowModal,
  };

  const updateModal = {
    showUpdateModal,
    UpdateData,
    update,
    setUpdate,
    setShowUpdateModal,
  };

  const DeleteUrl = "api/v1/leave/policies";
  const DeleteData = {
    showDelete,
    setShowDelete,
    update,
    setUpdate,
    selectData,
    DeleteUrl,
  }

  return (
    <div>
      {isLoader ? (
        <Loader />
      ) : (
        <DataTable
          header={header}
          value={leavePolicyData}
          selectionMode="single"
          paginator
          rows={5}
          rowsPerPageOptions={[5, 10, 25, 50]}
          size={"small"}
          dataKey="id"
          tableStyle={{ Width: "100%" }}
        >
          <Column field="Name" body={NameTemplate} header="Name"></Column>
          <Column
            field="Name"
            body={LeaveStatusName}
            header="Leave status"
          ></Column>
          <Column
            field="Name"
            body={DayCountTemplate}
            header="Day Count"
          ></Column>
          <Column
            field="Name"
            body={ModifyTimeTemplate}
            header="Modified"
          ></Column>
          <Column
            field="Name"
            body={ActionTemplate}
            className="w-[10%]"
            header="Action"
          ></Column>
        </DataTable>
      )}

      {<AddLeavePolicyModal modalData={modalData} />}
      {<UpDateLeavePolicy updateModal={updateModal} />}
      {
        <DeleteModal DeleteData={DeleteData}/>
      }
    </div>
  );
};

export default LeavePolicy;

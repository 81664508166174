import React from 'react'
import ProfileUpdate from '../AllEmployee/ProfileUpdate'
import Breadcrumbs from '../Breadcrumbs/Breadcrumbs'

const UpdateProfilePage = () => {
    const data = [
        {
            name: "Employees Profile",
            slash: "/",
            route:"/all-Employee"
          },
          {
            name: "Employee Profile Update",
     
          },
      ];
  return (
    <>
    <Breadcrumbs data={data}/>
     <ProfileUpdate/>   
    </>
  )
}

export default UpdateProfilePage
import React from "react";
import MyPaySlip from "../ProvidentFoundAccount/MyPaySlip";
import Breadcrumbs from "../Breadcrumbs/Breadcrumbs";

const MyPaySlipPage = () => {
  const data = [
    {
      name: "My Pay-Slip Table",
    },
  ];
  return (
    <>
      <Breadcrumbs data={data} />
      <MyPaySlip />
    </>
  );
};

export default MyPaySlipPage;

import React, { useState } from "react";
import { toast } from "react-toastify";
import { MainUrl } from "../../utils/helper";
import useData from "../../ContextApi/UseData/useData";
import { Dialog } from "primereact/dialog";

const OverTimePolicyModal = ({ modalData }) => {
  const [percentValue, setPercentValue] = useState();
  const { showOverTime, setShowOverTime, percentData, update, setUpdate } =
    modalData;
  const { token } = useData().user;

  const handleOverTimePolicyUpdate = (e) => {
    e.preventDefault();
    const form = e.target;
    const hour = form.hour.value ? form.hour.value : "00";
    const minute = form.minute.value ? form.minute.value : "00";
    const percent = percentValue || percentData?.percentage;
    const second = "00";
    const time = `${hour}:${minute}:${second}`;
    const fromData = {
      delay: time,
      percentage: percent,
    };
    if (hour.length !== 2) {
      toast.error("please enter hour value 2 number");
      return;
    }

    if (minute.length !== 2) {
      toast.error("please enter minute value 2 number");
      return;
    }

    const postModalData = async () => {
      const res = await fetch(
        `${MainUrl.url}/api/v1/overtime/policies/${percentData?.id}`,
        {
          method: "put",
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
          body: JSON.stringify(fromData),
        }
      );
      const resData = await res.json();

      if (res.ok) {
        setUpdate(!update);
        setShowOverTime(!showOverTime);
        form.reset();
        toast.success(resData.data);
      } else {
        toast.error(resData.message);
      }
    };
    postModalData();
  };

  return (
    <div>
      <Dialog
        visible={showOverTime}
        onHide={() => setShowOverTime(!showOverTime)}
        style={{ width: "30vw" }}
        breakpoints={{ "960px": "75vw", "641px": "100vw" }}
        resizable={false}
        draggable={false}
        header="Update Bonus Time Policy"
      >
        <div>
          <form onSubmit={handleOverTimePolicyUpdate} className="pt-4">
            <div className="grid grid-cols-2 gap-x-2">
              <div className="flex flex-col">
                <label className="ps-2 pb-1">Hour</label>
                <input
                  className="border-2 rounded-s-md border-gray-200 ps-2 py-1 focus-visible:outline-none"
                  type="number"
                  defaultValue="00"
                  name="hour"
                  maxLength="2"
                />
              </div>
              <div className="flex flex-col">
                <label className="ps-2 pb-1">Minute</label>
                <input
                  className="border-2 rounded-s-md border-gray-200 ps-2 py-1 focus-visible:outline-none"
                  type="number"
                  defaultValue="00"
                  name="minute"
                  maxLength="2"
                />
              </div>
            </div>


              <div className="flex flex-col pt-2">
                <label className="ps-2 pb-1">percentage</label>
                <input
                  className="border-2 rounded-s-md border-gray-200 ps-2 py-1 focus-visible:outline-none"
                  type="number"
                  defaultValue={percentData.percentage}
                  step={"any"}
                  onChange={(e) => setPercentValue(e.target.value)}
                />
              </div>
        
            <input
              className="cursor-pointer px-4 py-2 mt-4 bg-cyan-700 rounded-md text-white font-medium text-sm"
              type="submit"
              value="Submit"
            />
          </form>
        </div>
      </Dialog>
    </div>
  );
};

export default OverTimePolicyModal;

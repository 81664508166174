import React from "react";
import { toast } from "react-toastify";
import { MainUrl } from "../../utils/helper";
import useData from "../../ContextApi/UseData/useData";
import { Dialog } from "primereact/dialog";

const BonusTypeModal = (bonusTypeModal) => {
  const {
    showBonusTypeModal,
    setShowBonusTypeModal,
    bonusUpdate,
    setBonusUpdate,
  } = bonusTypeModal.data;
  const { token } = useData().user;

  const handleBonusType = (e) => {
    e.preventDefault();
    const from = e.target;
    const typeName = from.name.value;
    const bonusType = {
      name: typeName,
    };
    const postModalData = async () => {
      const res = await fetch(`${MainUrl.url}/api/v1/bonus/types`, {
        method: "Post",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
        body: JSON.stringify(bonusType),
      });
      const resData = await res.json();
      if (res.ok) {
        toast.success("successfully created a new bonus type");
        setShowBonusTypeModal(!showBonusTypeModal);
        setBonusUpdate(!bonusUpdate);
      } else {
        toast.error(resData.message);
        setShowBonusTypeModal(!showBonusTypeModal);
      }
    };
    postModalData();
  };

  return (
    <div>
      <Dialog
        visible={showBonusTypeModal}
        onHide={() => setShowBonusTypeModal(!showBonusTypeModal)}
        style={{ width: "30vw" }}
        breakpoints={{ "960px": "75vw", "641px": "100vw" }}
        resizable={false}
        draggable={false}
        header="Create And Update Bonus Type"
      >
        <div>
          <form onSubmit={handleBonusType} className="pt-4 common">
            <div className="flex flex-col ">
              <label>Create Bonus Type</label>
              <div className="flex rounded-md w-full">
                <input
                  type="text"
                  className=" border-2 rounded-s-md border-gray-200 ps-2 py-1 focus-visible:outline-none w-[90%]"
                  name="name"
                />
                <input
                  className="cursor-pointer px-2 bg-cyan-700  text-white font-medium text-sm w-[10%]"
                  type="submit"
                  value="Submit"
                />
              </div>
            </div>
          </form>
        </div>
      </Dialog>
    </div>
  );
};

export default BonusTypeModal;

import React from "react";
import { AiOutlineIdcard, AiOutlineMail } from "react-icons/ai";
import { HiOutlineBuildingOffice} from "react-icons/hi2";
import { PiUserListLight } from "react-icons/pi";
import { FaRegCircleUser } from "react-icons/fa6";
import Rodal from "rodal";
import { RiMoneyDollarCircleLine } from "react-icons/ri";
import { TbReceiptTax } from "react-icons/tb";


const AccountInfoModal = ({ AccountModalData }) => {
  const { showAccountModal, setShowAccountModal,accountData } = AccountModalData;

  return (
    <div className="profileModal">
      <Rodal
        visible={showAccountModal}
        enterAnimation="flip"
        animation="fade"
        onClose={() => setShowAccountModal(!showAccountModal)}
        customStyles={{
          height: "auto",
          width: "40%",
          bottom: "auto",
          top: "10%",
          transform: "translateY(-50%)",
        }}
      >
        <h5 className="text-xl border-b py-3">Employee Account Details</h5>

        <div className="mt-5">
          <div className="flex items-center gap-x-2">
            <div className="flex items-center  w-36 gap-x-1">
                <span className="bg-cyan-700 p-1.5 rounded-full">
                    <AiOutlineMail className="text-lg text-white "/>
                </span>
                <p className="text-lg">Email </p>
            </div>
            <p className="text-lg">{accountData?.email}</p>
          </div>

          <div className="mt-2 flex items-center gap-x-2">
            <div className="flex items-center  w-36 gap-x-1">
                <span className="bg-cyan-700 p-1.5 rounded-full">
                    <PiUserListLight className="text-lg  text-white"/>
                </span>
                <p className="text-lg">Office Id </p>
            </div>
            <p className="text-lg">{accountData?.office_id}</p>
          </div>

          <div className="mt-2 flex items-center gap-x-2">
            <div className="flex items-center  w-36 gap-x-1">
                <span className="bg-cyan-700 p-1.5 rounded-full">
                    <AiOutlineIdcard className="text-lg text-white"/>
                </span>
                <p className="text-lg">Account No </p>
            </div>
            <p className="text-lg">{accountData?.account_no}</p>
          </div>

          

          <div className="mt-2 flex items-center gap-x-2">
            <div className="flex items-center  w-36 gap-x-1">
                <span className="bg-cyan-700 p-1.5 rounded-full">
                    <FaRegCircleUser className="text-lg text-white"/>
                </span>
                <p className="text-lg">Designation </p>
            </div>
            <p className="text-lg">{accountData?.user_designation?.name}</p>
          </div>

          <div className="mt-2 flex items-center gap-x-2">
            <div className="flex items-center  w-36 gap-x-1">
                <span className="bg-cyan-700 p-1.5 rounded-full" >
                    <HiOutlineBuildingOffice className="text-lg text-white"/>
                </span>
                <p className="text-lg">Department </p>
            </div>
            <p className="text-lg">{accountData?.department?.name}</p>
          </div>

          <div className="mt-2 flex items-center gap-x-2">
            <div className="flex items-center  w-36 gap-x-1">
                <span className="bg-cyan-700 p-1.5 rounded-full">
                    <RiMoneyDollarCircleLine className="text-lg text-white"/>
                </span>
                <p className="text-lg">Salary  Basic </p>
            </div>
            <p className="text-lg">{accountData?.salary_basic}</p>
          </div>

          <div className="mt-2 flex items-center gap-x-2">
            <div className="flex items-center  w-36 gap-x-1">
                <span className="bg-cyan-700 p-1.5 rounded-full">
                    <TbReceiptTax className="text-lg text-white"/>
                </span>
                <p className="text-lg">Tin Number </p>
            </div>
            <p className="text-lg">{accountData?.tin}</p>
          </div>

        </div>
      </Rodal>
    </div>
  );
};

export default AccountInfoModal;

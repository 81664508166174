import "./App.css"
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.min.css";
import 'primeicons/primeicons.css'
import { RouterProvider } from "react-router-dom";
import { router } from "./Router/Router";
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import '@szhsin/react-menu/dist/index.css';
import '@szhsin/react-menu/dist/transitions/slide.css';




function App() {
  return (
    <div className=''>
      <ToastContainer/>
      <RouterProvider router={router}></RouterProvider>
    </div>
  );
}

export default App;

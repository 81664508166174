import React from "react";
import MyAccount from "../ProvidentFoundAccount/MyAccount";
import Breadcrumbs from "../Breadcrumbs/Breadcrumbs";

const MyAccountPage = () => {
  const data = [
    {
      name: "My Account",
    },
  ];
  return (
    <>
      <Breadcrumbs data={data} />
      <MyAccount />
    </>
  );
};

export default MyAccountPage;

import React, { useState } from "react";
import { toast } from "react-toastify";
import Rodal from "rodal";
import { MainUrl } from "../../utils/helper";
import useData from "../../ContextApi/UseData/useData";

const UpdateDeductionType = ({ updateRodal }) => {
  const [deductionType, setDeductionType] = useState();
  const { showUpdateModal, setShowUpdateModal, modalData, update, setUpdate } =
    updateRodal;
  const { token } = useData().user;

  const handleUpdate = (e) => {
    e.preventDefault();
    const type = deductionType || deductionType?.name;
    const formData = {
      name: type,
    };

    let updateNote = async () => {
      const res = await fetch(
        `${MainUrl.url}/api/v1/deduction_types/one/${modalData?.id}`,
        {
          method: "Put",
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
          body: JSON.stringify(formData),
        }
      );
      const resData = await res.json();
      if (res.ok) {
        setUpdate(!update);
        toast.success("successfully updated");
        setShowUpdateModal(!showUpdateModal);
      } else {
        toast.error(resData.message);
        setShowUpdateModal(!showUpdateModal);
      }
    };

    updateNote();
  };
  return (
    <div>
      <Rodal
        visible={showUpdateModal}
        enterAnimation="flip"
        animation="fade"
        onClose={() => setShowUpdateModal(!showUpdateModal)}
        customStyles={{
          height: "auto",
          width: "40%",
          bottom: "auto",
          top: "10%",
          transform: "translateY(-50%)",
        }}
      >
        <h5 className="text-xl border-b py-3">Update Deduction type</h5>

        <div>
          <form onSubmit={handleUpdate} className="pt-4">
            <div className="flex w-full ">
              <input
                onChange={(e) => setDeductionType(e.target.value)}
                type="text"
                className="border-2 ps-2 py-1 rounded-s-md border-gray-200 focus-visible:outline-none w-4/5"
                defaultValue={modalData?.name}
                name="type"
              />
              <input
                className="cursor-pointer px-4 rounded-e-md bg-cyan-700  text-white font-medium text-sm w-1/5"
                type="submit"
                value="Submit"
              />
            </div>
          </form>
        </div>
      </Rodal>
    </div>
  );
};

export default UpdateDeductionType;

import React from "react";
import Breadcrumbs from "../Breadcrumbs/Breadcrumbs";
import BonusPolicy from "../BonusPolicy/BonusPolicy";

const GenBonusPolicyPage = () => {
  const data = [
    {
      name: "Bonus Policy",
    },
  ];
  return (
    <>
      <Breadcrumbs data={data} />
      <BonusPolicy />
    </>
  );
};

export default GenBonusPolicyPage;

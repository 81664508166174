import React from "react";
import {
  TfiAngleDoubleLeft,
  TfiAngleDoubleRight,
  TfiAngleLeft,
  TfiAngleRight,
} from "react-icons/tfi";

const Pagination = ({
  pageCount,
  setViewData,
  viewData,
  setPageNumber,
  setSearchParams,
  currentPage,
  setCurrentPage,
  pageNumber,
}) => {
  const handlePageChange = (page) => {
    setCurrentPage(page);
    setPageNumber(page);
  };

  const renderPagination = () => {
    const pages = [];
    const maxVisiblePages = 5; // Adjust as needed

    let start = Math.max(currentPage - Math.floor(maxVisiblePages / 2), 1);
    let end = Math.min(start + maxVisiblePages - 1, pageCount);

    if (end - start < maxVisiblePages - 1) {
      start = Math.max(end - maxVisiblePages + 1, 1);
    }
    for (let i = start; i <= end; i++) {
      pages.push(
        <button
          className={`p-2 w-8 h-8 rounded-full text-sm  border flex items-center justify-center mx-1 focus:outline outline-gray-100 ${
            currentPage === i
              ? "bg-cyan-700 focus:bg-cyan-700 text-white"
              : "border text-gray-700"
          }`}
          key={i}
          onClick={() => {
            handlePageChange(i);
            setPageNumber(i);
            setSearchParams({ search: i });
          }}
        >
          {i}
        </button>
      );
    }

    return pages;
  };

  return (
    <div className="flex items-center justify-center">
      <div className="flex items-center">
        <button
          className="p-2 w-8 h-8 mx-1 rounded-full  outline-gray-400"
          onClick={() => {
            handlePageChange(1);
            setSearchParams({ search: 1 });
          }}
        >
          <TfiAngleDoubleLeft />
        </button>
        <button
          disabled={currentPage === 1}
          className={`p-2 w-8 h-8 mx-1 rounded-full  outline-gray-400 ${
            currentPage === 1 && "disabled:opacity-25"
          }`}
          onClick={() => {
            handlePageChange(currentPage - 1)
            setSearchParams({ search: currentPage - 1 });
          }}
        >
          <TfiAngleLeft />
        </button>
        {renderPagination()}
        <button
          className={`p-2 w-8 h-8 mx-1 rounded-full  outline-gray-400 ${
            currentPage === pageNumber && "disabled:opacity-25"
          }`}
          disabled={pageCount === pageNumber}
          onClick={() => {
            handlePageChange(currentPage + 1)
            setSearchParams({ search: currentPage + 1 });
          }}
        >
          <TfiAngleRight />
        </button>
        <button
          className="p-2 w-8 h-8 mx-1 rounded-full  outline-gray-400"
          onClick={() => {
            handlePageChange(pageCount);
            setSearchParams({ search: pageCount });
          }}
        >
          <TfiAngleDoubleRight />
        </button>
      </div>
     
      <select
        onClick={(e) => {
          viewData !== e.target.value && setViewData(e.target.value);
        }}
        className="focus:outline-none p-1"
      >
        <option value="10">10</option>
        <option value="20">20</option>
        <option value="30">30</option>
        <option value="40">40</option>
        <option value="50">50</option>
      </select>
    </div>
  );
};

export default Pagination;

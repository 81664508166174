import React, { useState } from "react";
import useData from "../../ContextApi/UseData/useData";
import { MainUrl } from "../../utils/helper";
import { toast } from "react-toastify";
import { Dialog } from "primereact/dialog";

const UpDateSubDepartment = ({ UpdateModal }) => {
  const { selectedData, showUpdate, setShowUpdate, update, setUpdate } =
    UpdateModal;
  const [ChangeDefaultName, setChangeDefaultName] = useState(null);
  const { token } = useData().user;

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = {
      name: ChangeDefaultName,
    };

    let updateNote = async () => {
      if (!token) {
        return;
      }
      const res = await fetch(
        `${MainUrl.url}/api/v1/sub_department/one/${selectedData.id}`,
        {
          method: "Put",
          headers: {
            "Content-type": "application/json",
            Authorization: token,
          },
          body: JSON.stringify(formData),
        }
      );
      const resData = await res.json();
      if (res.ok) {
        setUpdate(!update);
        toast.success("successfully updated");
        setShowUpdate(!showUpdate);
      } else {
        toast.error(resData.message);
        setShowUpdate(!showUpdate);
      }
    };
    updateNote();
  };
  return (
    <div>
      <Dialog
        header="Update Sub-Department"
        visible={showUpdate}
        onHide={() => setShowUpdate(!showUpdate)}
        style={{ width: "30vw" }}
        breakpoints={{ "960px": "50vw", "641px": "100vw" }}
        draggable={false}
        resizable={false}
      >
        {/* <h5 className="text-xl border-b py-3"></h5> */}

        <div>
          <form onSubmit={handleSubmit} className="pt-4">
            <div className="flex flex-col">
              <label className="text-base font-medium">
                Sub-Department Name
              </label>
              <input
                onChange={(e) => setChangeDefaultName(e.target.value)}
                type="text"
                className="border-2 ps-2 py-1 mt-2 rounded-sm border-gray-200 focus-visible:outline-none w-full"
                defaultValue={selectedData?.name}
                required
              />
            </div>

            <input
              className="cursor-pointer px-4 py-2 rounded-sm bg-cyan-700  text-white font-medium text-sm w-1/5 mt-4"
              type="submit"
              value="Submit"
            />
          </form>
        </div>
      </Dialog>
    </div>
  );
};

export default UpDateSubDepartment;

import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { MainUrl } from "../../utils/helper";
import useData from "../../ContextApi/UseData/useData";
import { Dropdown } from "primereact/dropdown";
import { Dialog } from "primereact/dialog";
import "../Common.css"

const UpDateLeavePolicy = ({ updateModal }) => {
  const [leaveDay, setLeaveDay] = useState();
  // const [isClearable] = useState(true);
  // const [isSearchable] = useState(true);
  const [AllLeaveStatus, setAllLeaveStatus] = useState([]);
  const [leaveStatus, setLeaveStatus] = useState(null);

  const { token } = useData().user;
  const { showUpdateModal, setShowUpdateModal, UpdateData, update, setUpdate } =
    updateModal;

  console.log("UpdateData", UpdateData);



  // get Status Data
  useEffect(() => {
    if (!token) {
      return;
    }
    const fetchData = async () => {
      const res = await fetch(`${MainUrl.url}/api/v1/leave/leave_pay_status`, {
        method: "get",
        headers: {
          Authorization: token,
        },
      });

      const data = await res.json();
      if (res.ok) {
        setAllLeaveStatus(data?.data);
      } else {
        toast.error(data.message);
        console.error(data.message);
      }
    };
    fetchData();
  }, [token]);

  // modify the Data
  const status = AllLeaveStatus?.map((user) => {
    let obj = {};
    obj.label = user?.name;
    obj.value = user?.id;
    return obj;
  });

// Update
  const handleUpdateLeavePolicy = (e) => {
    e.preventDefault();
    const day = leaveDay || UpdateData?.day_count;
    const leaveType = UpdateData?.leave_type?.id;
    const formData = {
      leave_type_id: leaveType,
      leave_pay_status_id: leaveStatus
        ? leaveStatus
        : UpdateData?.leave_pay_status?.id,
      day_count: day,
    };
    let updateNote = async () => {
      const res = await fetch(
        `${MainUrl.url}/api/v1/leave/policies/${UpdateData?.id}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
          body: JSON.stringify(formData),
        }
      );
      const resData = await res.json();
      if (res.ok) {
        setUpdate(!update);
        toast.success("successfully updated");
        setShowUpdateModal(!showUpdateModal);
        setLeaveStatus(null)
      } else {
        toast.error(resData.message);
        setShowUpdateModal(!showUpdateModal);
        setLeaveStatus(null)
      }
    };
    updateNote();
  };

  return (
    <Dialog
      header="Update Leave Policy"
      visible={showUpdateModal}
      onHide={() => {  
        setShowUpdateModal(!showUpdateModal)
        setLeaveStatus(null)
      }}
      style={{ width: "30vw" }}
      breakpoints={{ "960px": "75vw", "641px": "100vw" }}
      draggable={false}
      resizable={false}
    >
      <form onSubmit={handleUpdateLeavePolicy} className="pt-4 common">
        <div className="flex flex-col ">
          <label>Leave Name:</label>
          <input
            type="text"
            className="border-2 border-gray-200 ps-2 py-0.5 rounded-sm  focus-visible:outline-none disabled:opacity-10 cursor-not-allowed"
            defaultValue={UpdateData?.leave_type?.name}
            readOnly
          />
        </div>

        <div className="flex flex-col ">
          <label> Leave Status:</label>
          <Dropdown
            value={leaveStatus}
            onChange={(e) => setLeaveStatus(e.value)}
            options={status}
            optionLabel="label"
            placeholder={UpdateData?.leave_pay_status?.name}
            filter
          />
        </div>

        <div className="flex flex-col ">
          <label> Leave Day:</label>
          <input
            onChange={(e) => setLeaveDay(e.target.value)}
            type="number"
            className="border-2 border-gray-200 ps-2 py-0.5 rounded-sm  focus-visible:outline-none"
            defaultValue={UpdateData?.day_count}
            required
          />
        </div>
        <input
          className="cursor-pointer px-4 py-2 mt-4 bg-cyan-700 rounded-md text-white font-medium text-sm"
          type="submit"
          value="Submit"
        />
      </form>
    </Dialog>
  );
};

export default UpDateLeavePolicy;

import { Calendar } from "primereact/calendar";
import React, { useEffect, useRef, useState } from "react";
import { Editor } from "@tinymce/tinymce-react";
import "../../App.css";
import { toast } from "react-toastify";
import { MainUrl, showDummyImage } from "../../utils/helper";
import useData from "../../ContextApi/UseData/useData";
import moment from "moment";
import { useParams } from "react-router-dom";
import { Dropdown } from "primereact/dropdown";


const LeaveApply = () => {
  const { token } = useData().user;
  const { id } = useParams();
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [imgUpload, setImgUpload] = useState("");
  const [image, setImage] = useState(null);
  const [leaveType, setLeaveType] = useState(null);
  const [leaveTypeValue, setLeaveTypeValue] = useState("");
  const [applicationData, setApplicationData] = useState(null);

  // get one user data
  useEffect(() => {
    if (!id) {
      return;
    }
    const getLeaveApply = async () => {
      const res = await fetch(
        `${MainUrl.url}/api/v1/leave/leave_apply/one/${id}`,
        {
          method: "Get",
          headers: {
            Authorization: token,
          },
        }
      );
      const resData = await res.json();
      if (res.ok) {
        setApplicationData(resData.data);
      }
    };
    getLeaveApply();
  }, [id, token]);

  const imgChange = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      setImgUpload(e.target.files[0]);
      const fileReader = new FileReader();
      fileReader.readAsDataURL(e.target.files[0]);
      fileReader.onload = () => {
        setImage(fileReader.result);
      };
    }
  };

  const editorRef = useRef(null);
  const getData = () => {
    if (editorRef.current) {
      return editorRef.current.getContent();
    }
  };

  // get leave type
  useEffect(() => {
    if (!token) {
      return;
    }
    const fetchData = async () => {
      try {
        const res = await fetch(`${MainUrl.url}/api/v1/leave/leave_types`, {
          method: "get",
          headers: {
            Authorization: token,
          },
        });
        const data = await res.json();
        if (res.ok) {
          setLeaveType(data.data);
        }
      } catch (error) {
        toast.error(error.message3);
      }
    };
    fetchData();
  }, [token]);

  const typeOfLeave = leaveType?.map((type) => {
    let obj = {};
    obj.label = type?.name;
    obj.value = type?.id;
    return obj;
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    const details = getData();
    const getStartDate = moment(startDate).format("YYYY-MM-DD");
    const getEndDate = moment(endDate).format("YYYY-MM-DD");

    const postData = {
      leave_type_id: leaveTypeValue
        ? leaveTypeValue
        : applicationData.leave_type.id,
      leave_start: getStartDate ? getStartDate : applicationData?.leave_start,
      leave_end: getEndDate ? getEndDate : applicationData?.leave_end,
      description: details ? details : applicationData?.description,
      leave_document: imgUpload ? imgUpload : applicationData?.leave_document,
    };

    let formData = new FormData();
    for (const i in postData) {
      const element = postData[i];
      formData.append(`${i}`, element);
    }

    let postLeave = async () => {
      if (id) {
        const res = await fetch(
          `${MainUrl.url}/api/v1/leave/leave_apply/one/${id}`,
          {
            method: "put",
            headers: {
              Authorization: token,
            },
            body: formData,
          }
        );
        const resData = await res.json();
        if (res.ok && res.status !== 204) {
          toast.success("successfully updated");
        } else {
          toast.error(resData.message);
        }
      } else {
        const res = await fetch(`${MainUrl.url}/api/v1/leave/leave_apply`, {
          method: "post",
          headers: {
            Authorization: token,
          },
          body: formData,
        });
        const resData = await res.json();
        if (res.ok && res.status !== 204) {
          toast.success("successfully updated");
        } else {
          toast.error(resData.message);
        }
      }
    };
    postLeave();
  };

  return (
    <div className="bg-white px-4 py-3 common">
      <div className="">
        <p className="text-xl font-bold">Request for Leave</p>
 
      </div>
      <form onSubmit={handleSubmit} className="pt-5 w-full">
        <div className="flex items-center">
          <p className="w-[150px]">Leave Type</p>
          <div className="w-1/4">
            <Dropdown
              value={leaveTypeValue}
              onChange={(e) => setLeaveTypeValue(e?.value)}
              options={typeOfLeave}
              optionLabel="label"
              placeholder={
                applicationData
                  ? applicationData?.leave_type?.name
                  : "Select Leave Type"
              }
              filter
              className="w-full md:w-14rem"
            />
          </div>
        </div>

        <div className="flex items-center pt-4 LeaveApply">
          <p className="w-[150px]">Leave Start</p>
          <div className="w-1/4 flex items-center ">
            <Calendar
              value={startDate}
              onChange={(e) => setStartDate(e.value)}
              placeholder={
                applicationData
                  ? applicationData?.leave_start
                  : "Leave Start Date"
              }
              dateFormat="dd/mm/yy"
              className="w-full "
            />
          </div>
        </div>

        <div className="flex items-center pt-4 LeaveApply">
          <p className="w-[150px]">Leave End</p>
          <div className="w-1/4 flex items-center ">
            <Calendar
              value={endDate}
              onChange={(e) => setEndDate(e.value)}
              placeholder={
                applicationData ? applicationData?.leave_end : "Leave End Date"
              }
              className="w-full"
              dateFormat="dd/mm/yy"
            />
          </div>
        </div>

        <div className="flex items-center gap-x-2 pt-4 LeaveApply w-full">
          <p className="w-[150px]">Description</p>
          <div className="w-full">
            <Editor
              className="w-full"
              apiKey="n5ins8uf0v0k5fk76tet3iwhg0ysf3x6pjbyjb37uv4ghgdy"
              onInit={(evt, editor) => (editorRef.current = editor)}
              initialValue={applicationData?.description}
              init={{
                height: 300,
                menubar: false,
                plugins: [
                  "advlist",
                  "autolink",
                  "lists",
                  "link",
                  "image",
                  "charmap",
                  "preview",
                  "anchor",
                  "searchreplace",
                  "visualblocks",
                  "code",
                  "fullscreen",
                  "insertdatetime",
                  "media",
                  "table",
                  "code",
                  "help",
                  "wordcount",
                ],
                toolbar:
                  "undo redo | blocks | " +
                  "bold italic forecolor media | alignleft aligncenter " +
                  "alignright bullist numlist outdent indent removeformat",
                content_style:
                  "body { font-family: -apple-system, BlinkMacSystemFont, Spectral, Georgia, serif; font-size:16px }",
              }}
            />
          </div>
        </div>
        <div className="flex items-center pt-4">
          <p className="w-[150px]">Upload a file</p>

          <div className="w-56 h-40 relative mb-3 border border-1 border-dashed">
            <img
              src={`${
                image
                  ? image
                  : `${MainUrl.url}/${applicationData?.leave_document}`
              }`}
              alt=""
              onError={({ currentTarget }, imageSize = "100x100") =>
                showDummyImage({ currentTarget }, imageSize)
              }
              className=" h-full w-full"
            />
            <input
              type="file"
              className={`absolute top-0 left-0 w-48 h-full rounded-full opacity-0`}
              accept={`"image/*"`}
              onChange={imgChange}
            />
          </div>
        </div>
        <input
          type="submit"
          value={id? "Update Leave": "Request Leave"}
          className="px-4 py-2 bg-cyan-700 cursor-pointer rounded mt-5 text-white ml-[150px] "
        />
      </form>
    </div>
  );
};

export default LeaveApply;

import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import React, { useEffect, useState } from "react";
import { AiFillEdit, AiOutlineDelete } from "react-icons/ai";
import { IoIosRefresh } from "react-icons/io";
import { toast } from "react-toastify";
import { MainUrl, showDummyImage } from "../../utils/helper";
import useData from "../../ContextApi/UseData/useData";
import { Menu, MenuButton, MenuItem } from "@szhsin/react-menu";
import { BsThreeDotsVertical } from "react-icons/bs";
import { Link, useLocation, useSearchParams } from "react-router-dom";
import Pagination from "../Pagination/Pagination";
import DeleteModal from "../AllEmployee/DeleteModal/DeleteModal";

const LeaveApplication = () => {
  const { token } = useData().user;
  const [LeaveApplication, setLeaveApplication] = useState(null);
  const [update, setUpdate] = useState(false);
  const [totalData, setTotalData] = useState(null);
  const [selectData, setSelectData] = useState(null);
  const [showDelete, setShowDelete] = useState(false);
  const [viewData, setViewData] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchParams, setSearchParams] = useSearchParams();
  const [pageCount, setPageCount] = useState();
  const location = useLocation();

  useEffect(() => {
    if (!token) {
      return;
    }

    const page = location?.search?.split("=")[1];
    if (page) {
      setPageNumber(+page);
      setCurrentPage(+page);
    }

    const fetchData = async () => {
      try {
        const res = await fetch(
          `${MainUrl.url}/api/v1/leave/leave_applications/${viewData}/${pageNumber}`,
          {
            method: "Get",
            headers: {
              Authorization: token,
            },
          }
        );
        const resData = await res.json();
        if (res.ok) {
          setLeaveApplication(resData.data?.results);
          setTotalData(resData.data?.totalCount);
        }
      } catch (error) {
        toast.error(error?.message);
      }
    };
    fetchData();
  }, [
    update,
    pageNumber,
    viewData,
    token,
    location,
    searchParams,
    currentPage,
  ]);

  useEffect(() => {
    const pages = Math.ceil(totalData / viewData);
    setPageCount(pages);
  }, [totalData, viewData]);

  const EmployeeNameTemplate = (data) => {
    return (
      <div className="flex items-center gap-x-4">
        <div className="h-10 w-10  ">
          <img
            src={`${MainUrl.url}${data?.user?.user_profile?.profile_photo}`}
            onError={({ currentTarget }, imageSize = "100x100") =>
              showDummyImage({ currentTarget }, imageSize)
            }
            alt=""
            className=" h-full w-full rounded-full"
          />
        </div>
        <h3 className="text-base">{data.user.user_profile.name}</h3>
      </div>
    );
  };

  const OfficeIdTemplate = (data) => {
    return <p className="text-base">{data.user?.office_id}</p>;
  };

  const LeaveStartTemplate = (data) => {
    return <p className="text-base">{data?.leave_start}</p>;
  };

  const LeaveEndTemplate = (data) => {
    return <p className="text-base">{data?.leave_end}</p>;
  };

  const LeaveTypeTemplate = (data) => {
    return <p className="text-base">{data?.leave_type?.name}</p>;
  };

  const LeaveStatusTemplate = (data) => {
    return <p className="text-base">{data?.leave_pay_status?.name}</p>;
  };

  const LeaveApprovalTemplate = (data) => {
    return <p className="text-base">{data?.leave_approval_status?.name}</p>;
  };

  //   const ActionTemplate = (data) => {
  //     return (
  //       <div className=" flex items-center gap-x-2">
  //         <div
  //           className="bg-cyan-700 h-8 w-8 flex items-center justify-center rounded-full cursor-pointer"
  //           onClick={() => {
  //             // setVisible(true);
  //             // setEmployeeModal(data);
  //           }}
  //         >
  //           <AiFillEdit className="text-white" />
  //         </div>
  //         <div
  //           className="bg-red-400 h-8 w-8 flex items-center justify-center rounded-full cursor-pointer"
  //           onClick={() => {
  //             // setSelectData(data);
  //             // setShowDelete(!showDelete);
  //           }}
  //         >
  //           <AiOutlineDelete className="text-white" />
  //         </div>
  //       </div>
  //     );
  //   };

  const ActionTemplate = (data) => {
    const id = data?.id;
    return (
      <Menu
        direction="right"
        align="center"
        menuButton={
          <MenuButton>
            <BsThreeDotsVertical />
          </MenuButton>
        }
        transition
      >
        <MenuItem>
          <Link to={`/leave-update/${id}`} className="flex items-center">
            <span className="mr-2">
              <AiFillEdit />
            </span>
            UPdate
          </Link>
        </MenuItem>

        <MenuItem
          className="hover:text-red-400"
          onClick={() => {
            setShowDelete(!showDelete);
            setSelectData(data);
          }}
        >
          <span>
            <AiOutlineDelete className="mr-2" />
          </span>
          Delete
        </MenuItem>
      </Menu>
    );
  };

  const renderHeader = () => {
    const handleSearch = async (e) => {
      e.preventDefault();
    };

    // const handleFresh = () => {
    //   setUpdate(!update);
    //   setFilterName("");
    //   setFilterOfficeId("");
    // };

    return (
      <div className="grid grid-cols-12 items-center justify-between">
        <div className="col-span-8">
          <div className="grid grid-cols-12 gap-x-2 items-center">
            <div className="col-span-10">
              <form
                className="flex gap-x-3  w-full myInput"
                onSubmit={handleSearch}
              >
                <div className="w-full">
                  <input
                    // ref={filterNameRef}
                    // onChange={(e) => setFilterName(e.target.value)}
                    className="ps-3 py-1.5 border border-gray-300 rounded-lg inputShadow w-full"
                    placeholder="Search by Name"
                    name="name"
                  />
                </div>
                <div className="w-full">
                  <input
                    // onChange={(e) => setFilterOfficeId(e.target.value)}
                    className="ps-3 py-1.5 border border-gray-300 rounded-lg inputShadow w-full"
                    placeholder="Search by Office Id"
                    name="office_id"
                  />
                </div>

                <div className="">
                  <input
                    type="submit"
                    value="Search"
                    className="cursor-pointer px-4 py-2 w-full bg-cyan-700 rounded-md text-white font-medium text-sm submit"
                  />
                </div>
              </form>
            </div>
            <div className="col-span-2 gap-x-2">
              <div>
                <span
                  //   onClick={handleFresh}
                  className="bg-cyan-700 text-white h-8 w-8 flex items-center justify-center rounded-full font-medium cursor-pointer"
                >
                  <IoIosRefresh />
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  const header = renderHeader();

  const DeleteUrl = "api/v1/leave/leave_apply/one";
  const DeleteData = {
    showDelete,
    setShowDelete,
    update,
    setUpdate,
    selectData,
    DeleteUrl,
  };

  return (
    <div className="bg-white pb-4 mb-4">
      <DataTable
        size="small"
        header={header}
        value={LeaveApplication}
        // footer={footerTemplate}
        selectionMode="single"
        dataKey="id"
        tableStyle={{ Width: "100%" }}
      >
        <Column field="" header="Employee" body={EmployeeNameTemplate}></Column>
        <Column
          field="user?.office_id"
          body={OfficeIdTemplate}
          header="Office ID"
          sortable
        ></Column>
        <Column
          field="leave_start"
          body={LeaveStartTemplate}
          header="Leave Start"
          sortable
        ></Column>
        <Column
          field="leave_end"
          body={LeaveEndTemplate}
          header="Leave End"
          sortable
        ></Column>
        <Column
          field="leave_type.id"
          body={LeaveTypeTemplate}
          header="Leave Type"
          sortable
        ></Column>
        <Column
          field="leave_pay_status.id"
          body={LeaveStatusTemplate}
          header="Leave Status"
          sortable
        ></Column>
        <Column
          field="leave_approval_status.id"
          body={LeaveApprovalTemplate}
          header="Approval Status"
          sortable
        ></Column>
        <Column field="" body={ActionTemplate} header="Action"></Column>
      </DataTable>

      <div className="mt-4">
        {LeaveApplication?.length > 0 && (
          <Pagination
            currentPage={currentPage}
            pageNumber={pageNumber}
            setCurrentPage={setCurrentPage}
            setSearchParams={setSearchParams}
            pageCount={pageCount}
            setViewData={setViewData}
            viewData={viewData}
            setPageNumber={setPageNumber}
          />
        )}
      </div>

      {<DeleteModal DeleteData={DeleteData} />}
    </div>
  );
};

export default LeaveApplication;

import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { AiFillEdit, AiOutlineDelete, AiOutlinePlus } from "react-icons/ai";
import BonusPolicyModal from "./BonusPolicyModal";
import BonusTypeModal from "./BonusTypeModal";
import { MainUrl } from "../../utils/helper";
import useData from "../../ContextApi/UseData/useData";
import Loader from "../Loader/Loader";
import UpdateModal from "./UpdateModal";
import DeleteModal from "../AllEmployee/DeleteModal/DeleteModal";

const BonusPolicy = () => {
  const [update, setUpdate] = useState(false);
  const [selectedBonusPolicy, setSelectedBonusPolicy] = useState(null);
  const [policyData, setPolicyData] = useState(null);
  const [showBonusPolicy, setShowBonusPolicy] = useState(false);
  const [showBonusTypeModal, setShowBonusTypeModal] = useState(false);
  const [bonusType, setBonusType] = useState(null);
  const [isClearable] = useState(true);
  const [isSearchable] = useState(true);
  const { token } = useData().user;
  const [isLoader, setIsLoader] = useState(false);
  const [bonusUpdate, setBonusUpdate] = useState(false)

  const [updateModal,setUpdateModal] = useState(false)
  const [updateData, setUpdateData] = useState(null)
  const [showDelete, setShowDelete ] = useState(false)
  const [selectData, setSelectData] = useState(null)





  // get table Data
  useEffect(() => {
    if (!token) {
      return;
    }
    const fetchData = async () => {
      setIsLoader(true);
      const res = await fetch(`${MainUrl.url}/api/v1/bonus/policies`, {
        method: "get",
        headers: {
          Authorization: token,
        },
      });

      const data = await res.json();
      if (res.ok) {
        setPolicyData(data?.data);
        setIsLoader(false);
      } else {
        toast.error(data.message);
        console.error(data.message);
        setIsLoader(false);
      }
    };
    fetchData();
  }, [update, token]);

  // get  bonus type
  useEffect(() => {
    if (!token) {
      return;
    }
    const Bonus = async () => {
      const res = await fetch(`${MainUrl.url}/api/v1/bonus/types`, {
        method: "get",
        headers: {
          Authorization: token,
        },
      });
      const data = await res.json();
      if (res.ok) {
        setBonusType(data?.data);
      } else {
        toast.error(data.message);
        console.error(data.message);
      }
    };
    Bonus();
  }, [token, bonusUpdate]);

  const bonusNameTemplate = (data) => {
    return <p>{data?.bonus_type?.name}</p>;
  };

  const PercentageTemplate = (data) => {
    return <p>{data?.percentage}</p>;
  };

  const ActionTemplate = (data) => {
    // const id = data?.id;
    // const name = data?.user?.user_profile?.name;
    return (
      <div className=" flex items-center gap-x-2">
        <div
          className="bg-cyan-700 h-8 w-8 flex items-center justify-center rounded-full cursor-pointer"
          onClick={() => {
            setUpdateModal(!updateModal);
            setUpdateData(data)
          }}
        >
          <AiFillEdit className="text-white" />
        </div>
        <div
          className="bg-red-400 h-8 w-8 flex items-center justify-center rounded-full cursor-pointer"
          onClick={() => {
            setSelectData(data);
            setShowDelete(!showDelete)
          }}
        >
          <AiOutlineDelete className="text-white" />
        </div>
      </div>
    );
  };

  const renderHeader = () => {
    return (
      <div className="">
        <div>
          <div className="flex  justify-end gap-x-2">
            <div className="pt-2">
              <button
                onClick={() => setShowBonusPolicy(!showBonusPolicy)}
                className="flex items-center gap-x-2  px-2 py-2.5 bg-cyan-700 text-white rounded-md"
              >
                <span className="text-base font-semibold">
                  <AiOutlinePlus />
                </span>{" "}
                <span className="text-base font-medium">Bonus policy</span>{" "}
              </button>
            </div>
            <div className="pt-2">
              <button
                onClick={() => setShowBonusTypeModal(!showBonusTypeModal)}
                className="flex items-center gap-x-2  px-2 py-2.5 bg-cyan-700 text-white rounded-md "
              >
                <span className="text-base font-semibold">
                  <AiOutlinePlus />
                </span>{" "}
                <span className="text-base font-medium">Bonus Type</span>{" "}
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  };
  const header = renderHeader();

  const bonusPolicyModalData = {
    showBonusPolicy,
    bonusType,
    isSearchable,
    isClearable,
    update,
    setUpdate,
    setShowBonusPolicy,
  };
  const bonusTypeModal = {
    showBonusTypeModal,
    isSearchable,
    isClearable,
    bonusType,
    setShowBonusTypeModal,
    bonusUpdate, setBonusUpdate
  };

  const updateMod = {
    updateModal,
    setUpdateModal,
    updateData,
    update, setUpdate
  }

  const DeleteUrl = "api/v1/bonus/policies";
  const DeleteData = {
    showDelete,
    setShowDelete,
    update,
    setUpdate,
    selectData,
    DeleteUrl,
  };

  return (
    <div>
      {isLoader ? (
        <Loader />
      ) : (
        <DataTable
          header={header}
          value={policyData}
          paginator
          rows={5}
          rowsPerPageOptions={[5, 10, 25, 50]}
          selectionMode="single"
          selection={selectedBonusPolicy}
          size={"small"}
          onSelectionChange={(e) => setSelectedBonusPolicy(e.value)}
          dataKey="id"
          tableStyle={{ Width: "100%" }}
        >
          <Column field="Name" body={bonusNameTemplate} header="Name"></Column>
          <Column
            field="Name"
            body={PercentageTemplate}
            header="Percentage"
          ></Column>
          <Column field="Name" body={ActionTemplate} header="Action"></Column>
        </DataTable>
      )}

      {<BonusPolicyModal data={bonusPolicyModalData} />}
      {<BonusTypeModal data={bonusTypeModal}/>}
      {<UpdateModal updateMod={updateMod}/>}
      <DeleteModal DeleteData={DeleteData}/>
    </div>
  );
};

export default BonusPolicy;

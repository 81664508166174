import React, { useEffect, useState } from 'react';
import Rodal from 'rodal';
import Select from 'react-select';
import { toast } from 'react-toastify';
import { MainUrl } from '../../utils/helper';
import useData from '../../ContextApi/UseData/useData';

const AddLeavePolicyModal = ({ modalData }) => {
    const [leaveType, setLeaveType] = useState([])
    const [leavePayStatus, setLeavePayStatus] = useState([])
    const [isClearable] = useState(true);
    const [isSearchable] = useState(true);
    const {token} = useData().user
    const { showModal, setShowModal, update, setUpdate } = modalData;

    useEffect(() => {
        if(!token){
            return
        }
        const fetchData = async () => {
            const res = await fetch(`${MainUrl.url}/api/v1/leave/leave_types`, {
                method: 'get',
                headers:{
                    Authorization:token
                }
            })

            const data = await res.json()
            if (res.ok) {
                setLeaveType(data?.data)
            }
            else {
                toast.error(data.message)
                console.error(data.message)
            }
        }
        fetchData()
    }, [token])

    useEffect(() => {
        if(!token){
            return
        }
        const fetchData = async () => {
            const res = await fetch(`${MainUrl.url}/api/v1/leave/leave_pay_status`, {
                method: 'get',
                headers:{
                    Authorization:token
                }
            })

            const data = await res.json()
            if (res.ok) {
                setLeavePayStatus(data?.data)
            }
            else {
                toast.error(data.message)
                console.error(data.message)
            }
        }
        fetchData()
    }, [token])


    const types = leaveType?.map((user) => {
        let obj = {}
        obj.label = user?.name
        obj.value = user?.id
        return obj;
    })

    const status = leavePayStatus?.map((user) => {
        let obj = {}
        obj.label = user?.name
        obj.value = user?.id
        return obj;
    })

    const handleAddLeavePolicy = (e) => {
        e.preventDefault()
        const form = e.target;
        const type = form.type.value;
        const status = form.status.value;
        const day = form.day.value;
        const fromData = {
            leave_type_id: type,
            leave_pay_status_id: status,
            day_count: day
        }
        const postModalData = async () => {
            const res = await fetch(`${MainUrl.url}/api/v1/leave/policies`, {
                method: "Post",
                headers: {
                    "Content-Type": "application/json",
                    Authorization:token
                },
                body: JSON.stringify(fromData),
            })
            const resData = await res.json();
            if (res.ok) {
                toast.success(resData.status)
                setUpdate(!update)
            }
            else {
                toast.error(resData.message)
            }
        };
        postModalData()
    }

    return (
        <div>
            <Rodal
                visible={showModal}
                enterAnimation="flip"
                animation="fade"
                onClose={() => setShowModal(!showModal)}
                customStyles={{
                    height: "auto",
                    width: "40%",
                    bottom: "auto",
                    top: "10%",
                    transform: "translateY(-50%)",
                }}
            >
                <h5 className="text-xl border-b py-3">Create Leave Policy</h5>

                <div>
                    <form onSubmit={handleAddLeavePolicy} className='pt-4' >
                        <div className='flex flex-col '>
                            <label>Select Leave Type:</label>
                            <Select
                                className="basic-single"
                                classNamePrefix="select"
                                isClearable={isClearable}
                                isSearchable={isSearchable}
                                name="type"
                                options={types}
                                required
                            />
                        </div>
                        <div className='flex flex-col '>
                            <label>Select Leave Status:</label>
                            <Select
                                className="basic-single"
                                classNamePrefix="select"
                                isClearable={isClearable}
                                isSearchable={isSearchable}
                                name="status"
                                options={status}
                                required
                            />
                        </div>
                        <div className='flex flex-col '>
                            <label> Day Count:</label>
                            <input type='number' className='border-2 border-gray-200 ps-2 py-0.5 rounded-sm  focus-visible:outline-none' name='day' placeholder='Day Count' required />
                        </div>
                        <input className='cursor-pointer px-4 py-2 mt-4 bg-cyan-700 rounded-md text-white font-medium text-sm' type='submit' value="Submit" />
                    </form>
                </div>
            </Rodal>
        </div>
    );
};

export default AddLeavePolicyModal;
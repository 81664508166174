import React, { useEffect, useState } from "react";
import logo from "../../Assets/logo.png";
import { toast } from "react-toastify";
import useData from "../../ContextApi/UseData/useData";
import { MainUrl } from "../../utils/helper";
import crypto from "crypto-js";
import { useNavigate } from "react-router-dom";
import { BsEye, BsEyeSlash } from "react-icons/bs";

const LogIn = () => {
  const { setToken } = useData().user;
  const [tokenEncrypt, setTokenEncrypt] = useState(null);
  const [userEncrypt, setUserEncrypt] = useState(null);
  const navigate = useNavigate();
  const [loginLoader, setLoginLoader] = useState(false);
  const [view, setView] = useState(false);

  const loader = (
    <img
      src="https://i.gifer.com/ZKZg.gif"
      className="w-5 h-5 ml-2"
      alt="loader"
    />
  );

  useEffect(() => {

    if (!userEncrypt) {
      return;
    }

    const logIn = async () => {
      const res = await fetch(`${MainUrl.url}/api/v1/login`, {
        method: "Post",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ data: userEncrypt }),
      });
      const resData = await res.json();
      if (res.ok) {
        toast.success(resData.status);
        setTokenEncrypt(resData.accessToken);
      } else {
        toast.error(resData.message);
        setLoginLoader(false)
      }
    };
    logIn();
  }, [userEncrypt]);

  useEffect(() => {
    if (!tokenEncrypt) {
      return;
    }
    const getSaltKey2 = async () => {
      const res = await fetch(`${MainUrl.url}/api/v1/get_salt?key=2`, {
        method: "Get",
        credentials: "include",
      });
      const resData = await res.json();
      if (res.ok) {
        const bytes = await crypto.AES.decrypt(
          `${tokenEncrypt}`,
          resData.data.salt
        );
        setToken(bytes.toString(crypto.enc.Utf8));
        navigate("/");
      }
      setLoginLoader(false);
    };
    getSaltKey2();
  }, [tokenEncrypt, setToken, navigate]);

  const handleSubmit = (e) => {
    setLoginLoader(true);
    e.preventDefault();
    const form = e.target;
    const Email = form.Email.value;
    const Password = form.Password.value;
    const formData = {
      email: Email,
      password: Password,
    };
    const getSaltKey = async () => {
      const res = await fetch(`${MainUrl.url}/api/v1/get_salt?key=1`, {
        method: "Get",
      });
      const resData = await res.json();
      if (res.ok) {
        setUserEncrypt(
          crypto.AES.encrypt(
            JSON.stringify(formData),
            resData.data.salt
          )?.toString()
        );
      }
    };
    getSaltKey();
  };

  return (
    <div>
      <div className="grid grid-cols-1">
        <div className="h-screen bgRight">
          <div className="flex justify-center items-center h-full w-full">
            <form
              onSubmit={handleSubmit}
              className="shadow-lg xl:w-[30%] lg:w-2/3 md:w-8/12 w-11/12 lg:p-8 p-4 bg-slate-800 rounded"
            >
              <div className="flex justify-center w-full">
                <div className="h-1/2 w-[50%] mb-8">
                  <img src={logo} alt="" className="h-full w-full " />
                </div>
              </div>
              <div className="flex flex-col">
                <label className="pb-2 text-white">E-mail</label>
                <input
                  type="email"
                  defaultValue="mark@gmail.com"
                  className="rounded py-2 ps-3 focus-visible:outline-none"
                  name="Email"
                  placeholder="example@gmail.com"
                />
              </div>
              <div className="flex flex-col pt-4 ">
                <label className="pb-2 text-white">Password</label>
                <div className="relative">
                  <input
                    type={!view ? "password" : "text"}
                    defaultValue="helloworld"
                    className="rounded py-2 ps-3 focus-visible:outline-none w-full"
                    name="Password"
                    placeholder="******"
                  
                  />
                  <div
                    className="absolute top-3 right-4 cursor-pointer"
                    onClick={() => setView(!view)}
                  >
                    {!view ? <BsEyeSlash /> : <BsEye />}
                  </div>
                </div>
              </div>
              <div className="w-[150px]">
                <button
                  type="submit"
                  className="w-full p-2 focus:outline-none rounded bg-cyan-700 transition-all duration-300 text-white font-medium mt-4 cursor-pointer flex items-center justify-center"
                >
                  Login {loginLoader && loader}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LogIn;

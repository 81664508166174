import { Editor } from "@tinymce/tinymce-react";
import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import Select from "react-select";
import { RadioButton } from "primereact/radiobutton";
import { MainUrl } from "../../utils/helper";
import useData from "../../ContextApi/UseData/useData";

const ApplyProjectProposal = () => {
  const [allClients, setAllClients] = useState(null);
  const [isClearable] = useState(true);
  const [ingredient, setIngredient] = useState("");
  const [ClientID, setClientID] = useState("");
  const { token } = useData().user;

  useEffect(() => {
    if(!token){
      return
    }
    const fetchData = async () => {
      try {
        const res = await fetch(
          `${MainUrl.url}/api/v1/clients/all/99999/0`,
          {
            method: "Get",
            headers:{
              "Authorization" : token
            }
          }
        );
        const data = await res.json();
        if (res.ok) {
          setAllClients(data.data?.records);
        }
      } catch (error) {
        toast.error(error.message);
      }
    };
    fetchData();
  }, [token]);

  const client = allClients?.map((user) => {
    let obj = {};
    obj.label = user?.name;
    obj.value = user?.id;
    return obj;
  });

  const editorRef = useRef(null);
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();
  const [file, setFile] = useState(null);


  const getEditorData = () => {
    if (editorRef.current) {
      return editorRef.current.getContent();
    }
  };
  const onSubmit = async (data) => {
    data.details = getEditorData();
    let formData = new FormData();
    formData.append("project_title", data.project_title);
    formData.append("client_id", ClientID);
    formData.append("proposed_duration", data.proposed_duration);
    formData.append("duration_type", ingredient );
    formData.append("proposed_amount", data.proposed_amount);
    formData.append("opex", data.opex);
    formData.append("capex", data.capex);
    formData.append("estimated_profit", data.estimated_profit);
    formData.append("deadline", data.deadline);
    formData.append("details", data.details);
    if (file === null) {
      formData.append("cost_breakdown", {});
    } else {
      formData.append("cost_breakdown", file[0]);
    }
    let applyProposal = async () => {
      const res = await fetch(`${MainUrl.url}/api/v1/project_proposal/create`, {
        method: "post",
        headers:{
          "Authorization":token
        },
        body: formData,
      });
      const resData = await res.json();
      if (res.ok) {
        toast.success("successfully add New project  Proposal");
        reset()
      } else {
        toast.error(resData.message);
      }
    };
    applyProposal();
  };

  return (
    <div className="my-5 text-gray-600 bg-white rounded-md shadow-sm border">
      <div className="px-4 pt-4 pb-4 md:px-8 md:pt-8">
        <h1 className="mb-1 text-xl font-bold common-text-color-body">
          Apply for project proposal
        </h1>
        <span className="text-sm text-gray-500">
          Request your project proposal details down below.
        </span>
      </div>
      <form className="pb-5" action="" onSubmit={handleSubmit(onSubmit)}>
        <div className="px-4 pt-3 w-full md:px-8">
          <div className="flex justify-between items-center gap-x-4">
            {/* project name  */}
            <div className="flex flex-col gap-3 mb-3 md:flex-row md:items-center md:gap-24 w-full">
              <span className="font-medium w-[157px] text-[13px]">
                Project Name
              </span>
              <div className="w-full">
                <input
                  className="border py-2 pl-3 pr-3 w-full  rounded-md focus:outline-none dark:text-black text-[13px]"
                  type="input"
                  placeholder="Project Name"
                  name=""
                  id=""
                  required
                  {...register("project_title", {
                    required: "This field is required",
                  })}
                />
                <p className={`text-red-500`}>
                  {errors.project_title?.message}
                </p>
              </div>
            </div>

            {/* client information */}
            <div className="flex flex-col gap-3 mb-3 md:flex-row md:items-center md:gap-x-2 w-full">
              <span className="font-medium w-[150px] text-[13px]">
                Client Name
              </span>
              <div className="relative w-full pb-2">
                <Select
                  className="basic-single z-30"
                  classNamePrefix="select"
                  isClearable={isClearable}
                  onChange={(e) => setClientID(e?.value)}
                  options={client}
                />
              </div>
            </div>
          </div>

          {/* Project Duration  */}
          <div className="flex flex-col gap-2 w-full md:flex-row md:items-center md:gap-24">
            <span className="font-medium w-32 text-[13px]">
              Project Duration
            </span>
            <div className="flex flex-col gap-2 items-center w-full md:flex-row md:gap-16">
              <input
                className="border py-3 pl-3 pr-3 w-full  rounded-md mb-3 focus:outline-none dark:text-black text-[13px]"
                type="number"
                min={1}
                defaultValue={1}
                name=""
                id=""
                step="any"
                required
                pattern="\d{4}-\d{2}-\d{2}"
                {...register("proposed_duration", {
                  required: "This field is required",
                })}
              />

              <div className="card flex justify-content-center">
                <div className="flex justify-between gap-3">
                  <div className="flex align-items-center">
                    <RadioButton
                      inputId="ingredient1"
                      {...register("duration_type")}
                      value="month"
                      onChange={(e) => setIngredient(e.value)}
                      checked={ingredient === "month"}
                    />
                    <label htmlFor="ingredient1" className="ml-2">
                      Month
                    </label>
                  </div>
                  <div className="flex align-items-center">
                    <RadioButton
                      inputId="ingredient2"
                      {...register("duration_type")}
                      value="year"
                      onChange={(e) => setIngredient(e.value)}
                      checked={ingredient === "year"}
                    />
                    <label htmlFor="ingredient2" className="ml-2">
                      Year
                    </label>
                  </div>
                </div>
              </div>

              <p className={`text-red-500`}>{errors.name?.message}</p>
            </div>
          </div>

          {/* date line */}
          <div className="flex flex-col gap-5 items-center w-full xl:flex-row">
            <div className="flex flex-col gap-3 mb-3 w-full md:flex-row md:items-center md:gap-24">
              <span className="font-medium w-32 text-[13px]">
                Deadline Date
              </span>
              <div className="w-full">
                <input
                  className="border py-3 pl-3 pr-3 w-full lg:w-60 rounded-md focus:outline-none dark:text-black text-[13px]"
                  type="date"
                  name=""
                  id=""
                  required
                  pattern="\d{4}-\d{2}-\d{2}"
                  {...register("deadline", {
                    required: "This field is required",
                  })}
                />
                <p className={`text-red-500`}>{errors.name?.message}</p>
              </div>
            </div>
          </div>

          {/* cast margin */}
          <div className="flex flex-col items-center mb-3 xl:flex-row">
            {/* proposed amount */}
            <div className="flex flex-col md:flex-row md:items-center w-full gap-3 mb-3 md:gap-[81px] xl:gap-x-2 xl:mb-0">
              <span className="font-medium w-[271px] text-[13px]">
                Proposed Amount
              </span>
              <div className="w-full">
                <input
                  className="border py-3 pl-3 pr-3 w-full  rounded-md focus:outline-none dark:text-black text-[13px]"
                  type="number"
                  min={2000}
                  name=""
                  id=""
                  required
                  pattern="\d{4}-\d{2}-\d{2}"
                  {...register("proposed_amount", {
                    required: "This field is required",
                  })}
                />
                <p className={`text-red-500`}>{errors.name?.message}</p>
              </div>
            </div>

            {/* Operational expense */}
            <div className="flex flex-col md:flex-row md:items-center gap-3 md:gap-[81px] xl:gap-x-2 w-full xl:ml-4 md:mb-3 xl:mb-0 ">
              <span className="font-medium w-[150px] text-[13px]">
                Operational expense
              </span>
              <div className="w-full">
                <input
                  className="border py-3 pl-3 pr-3 w-full rounded-md focus:outline-none dark:text-black text-[13px]"
                  type="number"
                  min={2000}
                  name=""
                  id=""
                  required
                  pattern="\d{4}-\d{2}-\d{2}"
                  {...register("opex", {
                    required: "This field is required",
                  })}
                />
                <p className={`text-red-500`}>{errors.name?.message}</p>
              </div>
            </div>
          </div>

          <div className="flex flex-col items-center mb-3 xl:flex-row">
            {/* Capital expense */}
            <div className="flex flex-col md:flex-row md:items-center gap-3 md:gap-[81px] xl:gap-x-2 w-full ">
              <span className="font-medium w-[271px] text-[13px]">
                Capital expense
              </span>
              <div className="w-full">
                <input
                  className="border py-3 pl-3 pr-3 w-full rounded-md focus:outline-none dark:text-black text-[13px]"
                  type="number"
                  min={2000}
                  name=""
                  id=""
                  required
                  pattern="\d{4}-\d{2}-\d{2}"
                  {...register("capex", {
                    required: "This field is required",
                  })}
                />
                <p className={`text-red-500`}>{errors.name?.message}</p>
              </div>
            </div>

            {/* Estimated Profit*/}
            <div className="flex flex-col md:flex-row md:items-center gap-3 md:gap-[81px] xl:gap-2 w-full xl:ml-4">
              <span className="font-medium w-[150px] text-[13px]">
                Estimated Profit
              </span>
              <div className="w-full">
                <input
                  className="border py-3 pl-3 pr-3 w-full rounded-md focus:outline-none dark:text-black text-[13px]"
                  type="number"
                  min={2000}
                  name=""
                  id=""
                  required
                  pattern="\d{4}-\d{2}-\d{2}"
                  {...register("estimated_profit", {
                    required: "This field is required",
                  })}
                />
                <p className={`text-red-500`}>{errors.name?.message}</p>
              </div>
            </div>
          </div>



          {/* Details */}
          <div className="flex flex-col md:flex-row gap-3 mb-3 md:gap-[81px] w-full">
            <span className="font-medium w-36 text-[13px] mt-5">Details</span>
            <div className="w-full">
              <Editor
                apiKey={process.env.REACT_APP_EDITOR_KEY}
                onInit={(evt, editor) => (editorRef.current = editor)}
                {...register("details")}
                initialValue=""
                init={{
                  height: 300,
                  menubar: false,
                  plugins: [
                    "advlist",
                    "autolink",
                    "lists",
                    "link",
                    "image",
                    "charmap",
                    "preview",
                    "anchor",
                    "searchreplace",
                    "visualblocks",
                    "code",
                    "fullscreen",
                    "insertdatetime",
                    "media",
                    "table",
                    "code",
                    "help",
                    "wordcount",
                  ],
                  toolbar:
                    "undo redo | blocks | " +
                    "bold italic forecolor media | alignleft aligncenter " +
                    "alignright bullist numlist outdent indent removeformat",
                  content_style:
                    "body { font-family: -apple-system, BlinkMacSystemFont, Spectral, Georgia, serif; font-size:16px }",
                }}
              />
            </div>
          </div>

          {/* File upload */}
          <div className="flex flex-col gap-3 md:flex-row md:gap-12 lg:gap-14 xl:gap-16">
            <span className="font-medium w-36 mt-3 text-[13px]">
              Upload a file
            </span>
            <div
              className={`flex relative flex-col justify-center items-center p-8 border-2 border-dashed md:w-96 border-grey-light ${
                file === null ? "border-grey-light" : "  border-[#34c38f]"
              }`}
            >
              <svg
                className="mb-4 w-8 h-8 text-black"
                width="48"
                height="48"
                viewBox="0 0 24 24"
              >
                <path
                  fill={`${file === null ? "currentColor" : "#34c38f"}`}
                  d="M13,9V3.5L18.5,9M6,2C4.89,2 4,2.89 4,4V20A2,2 0 0,0 6,22H18A2,2 0 0,0 20,20V8L14,2H6Z"
                />
              </svg>
              <div className="mb-1 text-sm font-semibold text-center text-black">
                {file === null
                  ? "Click here to upload your file."
                  : `${file[0]?.name}`}
              </div>
              <input
                className={`absolute top-0 left-0 w-full h-full rounded-full opacity-0`}
                type="file"
                accept="image/jpeg,image/png,application/pdf"
                onChange={(e) => setFile(e.target.files)}
              />
            </div>
          </div>
        </div>

        <div className="px-4 pt-4 pb-4 md:px-8 md:pt-8">
          <div className="flex flex-col gap-3 md:flex-row md:gap-20">
            <div className="w-32"></div>
            <button className="bg-green-500 py-2 px-6 text-white rounded font-medium">Submit</button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default ApplyProjectProposal;
import React from "react";
import Breadcrumbs from "../Breadcrumbs/Breadcrumbs";
import UserProfile from "../UserProfile/UserProfile";

const ProfilePage = () => {
  const data = [
    {
      name: "Profile",
    },
  ];
  return (
    <div>
      <Breadcrumbs data={data} />
      <UserProfile />
    </div>
  );
};

export default ProfilePage;

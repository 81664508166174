import React from "react";
import { useState } from "react";
import { toast } from "react-toastify";
import { MainUrl } from "../../utils/helper";
import useData from "../../ContextApi/UseData/useData";
import { Dialog } from "primereact/dialog";

const UpdateProvidentPolicy = ({ updateModal }) => {
  const { updateShow, setUpdateShow, updateModalData, update, setUpdate } =
    updateModal;
  const [packName, setPackName] = useState("");
  const [percent, setPercent] = useState("");
  const [duration, setDuration] = useState("");
  const { token } = useData().user;

  const handleSubmit = (e) => {
    e.preventDefault();
    const pack = packName || updateModalData?.package;
    const percentage = percent || updateModalData?.percentage;
    const time = duration || updateModalData.duration;

    const formData = {
      package: pack,
      percentage: percentage,
      duration: time,
    };

    let updateNote = async () => {
      const res = await fetch(
        `${MainUrl.url}/api/v1/provident_fund/policies/${updateModalData?.id}`,
        {
          method: "Put",
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
          body: JSON.stringify(formData),
        }
      );
      const resData = await res.json();
      if (res.ok) {
        setUpdate(!update);
        toast.success("successfully updated");
        setUpdateShow(!updateShow);
      } else {
        toast.error(resData.message);
        setUpdateShow(!updateShow);
      }
    };
    updateNote();
  };
  return (
    <div>
      <Dialog
        visible={updateShow}
        header="Update Provident Policy"
        onHide={() => setUpdateShow(!updateShow)}
        style={{ width: "50vw" }}
        breakpoints={{ "960px": "75vw", "641px": "100vw" }}
        resizable={false}
        draggable={false}
      >
        {/* <h5 className="text-xl border-b py-3">Create Provident Policy</h5> */}

        <div>
          <form onSubmit={handleSubmit} className="pt-4">
            <div className="flex flex-col ">
              <label> Package Name:</label>
              <input
                onChange={(e) => setPackName(e.target.value)}
                type="text"
                className="border-2 border-gray-200 ps-2 py-0.5 rounded-sm  focus-visible:outline-none"
                defaultValue={updateModalData?.package}
                name="package"
                placeholder="PackAge Name"
                required
              />
            </div>
            <div className="flex flex-col pt-2">
              <label>Percentage:</label>
              <input
                onChange={(e) => setPercent(e.target.value)}
                type="number"
                className="border-2 border-gray-200 ps-2 py-0.5 rounded-sm  focus-visible:outline-none"
                defaultValue={updateModalData?.percentage}
                name="percent"
                placeholder="Percentage"
                step="any"
                required
              />
            </div>

            <div className="flex flex-col pt-2">
              <label> Duration:</label>
              <input
                onChange={(e) => setDuration(e.target.value)}
                type="number"
                className="border-2 border-gray-200 ps-2 py-0.5 rounded-sm  focus-visible:outline-none"
                defaultValue={updateModalData?.duration}
                name="duration"
                placeholder="Duration"
                required
              />
            </div>
            <input
              className="cursor-pointer px-4 py-2 mt-4 bg-cyan-700 rounded-md text-white font-medium text-sm"
              type="submit"
              value="Submit"
            />
          </form>
        </div>
      </Dialog>
    </div>
  );
};

export default UpdateProvidentPolicy;

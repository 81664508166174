import React, { useState } from "react";
import { toast } from "react-toastify";
import { MainUrl } from "../../utils/helper";
import useData from "../../ContextApi/UseData/useData";
import { Dialog } from "primereact/dialog";

const UpdateClient = ({ UpdateData }) => {
  const {
    updateModal,
    setUpdateModal,
    update,
    setUpdate,
    updateClintData,
    setUpdateClientData,
  } = UpdateData;
  const { token } = useData().user;

  const [changeValue, setChangeValue] = useState({});

  const setValue = (e) => {
    setChangeValue({
      ...changeValue,
      [e?.target?.name]: e?.target?.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!token) {
      return;
    }
    const form = e.target;
    if (changeValue?.name?.length < 4) {
      return toast.warn("The minimum length of name has to be 5");
    }

    if(changeValue?.email){
      return toast.warn("Please provide a valid email address")
    }

    if (changeValue?.contact_number?.length < 14) {
      return toast.warn("The minimum length of contact number has to be 5");
    }

    if (changeValue?.address?.length < 20) {
      return toast.warn("The minimum length of address has to be 5");
    }

    const data = {
      name: changeValue.name ? changeValue.name : updateClintData.name,
      email: changeValue.email ? changeValue.email : updateClintData.email,
      contact_number: changeValue.contact_number
        ? changeValue.contact_number
        : updateClintData.contact_number,
      address: changeValue.address
        ? changeValue.address
        : updateClintData?.address,
    };

    const UpdateClientInfo = async () => {
      const res = await fetch(
        `${MainUrl.url}/api/v1/client/one/${updateClintData?.id}`,
        {
          method: "put",
          headers: {
            "Content-type": "application/json",
            Authorization: token,
          },
          body: JSON.stringify(data),
        }
      );
      const resData = await res.json();
      if (res.ok) {
        toast.success("successfully Update Client");
        setUpdate(!update);
        setUpdateModal(!updateModal);
        form.reset();
      } else {
        toast.error(resData.message);
      }
    };
    UpdateClientInfo();
  };

  return (
    <div>
      <Dialog
        header="Update Client"
        visible={updateModal}
        onHide={() => {
          setUpdateModal(!updateModal);
          setUpdateClientData(null);
          setChangeValue({});
        }}
        style={{ width: "30vw" }}
        breakpoints={{ "960px": "75vw", "641px": "100vw" }}
        draggable={false}
        resizable={false}
      >
        <div>
          <form onSubmit={handleSubmit} className="">
            <div className="w-full">
              <label className="">Client Name</label>

              <input
                type="text"
                className="border-2 ps-2 py-1 mt-2 rounded-s-md border-gray-200 focus-visible:outline-none w-full"
                defaultValue={updateClintData?.name}
                onChange={(e) => setValue(e)}
                name="name"
                // {...register("name", {
                //   required: "This field is required",
                //   message: "This field is required",
                // })}
              />

              {/* {errors?.name && (
                <p className="text-red-400 text-xs ">{errors?.name?.message}</p>
              )} */}
            </div>
            <div className="w-full pt-3">
              <label className="">Client Email</label>
              <input
                type="email"
                className="border-2 ps-2 py-1 mt-2 rounded-s-md border-gray-200 focus-visible:outline-none w-full"
                defaultValue={updateClintData?.email}
                onChange={(e) => setValue(e)}
                name="email"
                // {...register("email", {
                //   required: "This field is required",
                //   message: "This field is required",
                // })}
              />
              {/* {errors?.email && (
                <p className="text-red-400 text-xs">{errors?.email?.message}</p>
              )} */}
            </div>
            <div className="w-full pt-3">
              <label className="">Contact Number</label>
              <input
                type="text"
                className="border-2 ps-2 py-1 mt-2 rounded-s-md border-gray-200 focus-visible:outline-none w-full"
                // placeholder="Contact Number"
                defaultValue={updateClintData?.contact_number}
                onChange={(e) => setValue(e)}
                name="contact_number"
                // {...register("contact_number", {
                //   required: "This field is required",
                //   pattern: {
                //     value: /(^(\+8801))[|3-9]{1}(\d){8}$/i,
                //     message: "Invalid Phone Number",
                //   },
                // })}
              />
              {/* {errors?.contact_number && (
                <p className="text-red-400 text-xs ">
                  {errors?.contact_number?.message}
                </p>
              )} */}
            </div>
            <div className="w-full pt-3">
              <div>
                <label className="">Address</label>
                <input
                  type="text"
                  className="border-2 ps-2 py-1 mt-2 rounded-s-md border-gray-200 focus-visible:outline-none w-full"
                  // placeholder="Address"
                  defaultValue={updateClintData?.address}
                  onChange={(e) => setValue(e)}
                  name="address"
                  // {...register("address", {
                  //   required: "This field is required",
                  //   minLength: {
                  //     value: 20,
                  //     message: "provide address in minimum 20 letter",
                  //   },
                  //   maxLength: {
                  //     value: 300,
                  //     message: "provide address in  maximum 300 letter",
                  //   },
                  // })}
                />
              </div>
              {/* {errors?.address?.type === "minLength" && (
                <p className="text-red-400 text-xs pl-[125px]">
                  {errors?.address?.message}
                </p>
              )}
              {errors?.address?.type === "maxLength" && (
                <p className="text-red-400 text-xs pl-[125px]">
                  {errors?.address?.message}
                </p>
              )} */}
            </div>
            <input
              className="cursor-pointer px-4 py-2 mt-4 rounded-md bg-cyan-700  text-white font-medium text-sm"
              type="submit"
              value="Submit"
            />
          </form>
        </div>
      </Dialog>
    </div>
  );
};

export default UpdateClient;

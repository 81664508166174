import React from "react";
import Breadcrumbs from "../Breadcrumbs/Breadcrumbs";
import DeductionType from "../DeductionType/DeductionType";

const GenDeductionTypePage = () => {
  const data = [
    {
      name: "Deduction Type",
    },
  ];
  return (
    <>
      <Breadcrumbs data={data} />
      <DeductionType />
    </>
  );
};

export default GenDeductionTypePage;

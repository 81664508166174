import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { AiOutlinePlus } from "react-icons/ai";
import { RxCross1 } from "react-icons/rx";
import Rodal from "rodal";
import Select from "react-select";
import { toast } from "react-toastify";
import { MainUrl } from "../../utils/helper";
import useData from "../../ContextApi/UseData/useData";
import { InputText } from "primereact/inputtext";


const TeamCreate = ({ teamModal }) => {
  const {
    showTeam,
    setShowTeam,
    role,
    allUser,
    update,
    setUpdate,
    teamData,
  } = teamModal;
  // console.log("teamData", teamData);
  // console.log("team-->", teamData?.team);

  const [isClearable] = useState(true);

  const { token } = useData().user;

  const [team, setTeam] = useState([
    { id: 1, user_id: "", project_role_id: "" },
  ]);

  console.log("team----->111", team);

  const { handleSubmit, reset } = useForm();

  const handleminus = (id) => {
    let FilterData = team?.filter((f) => f.id !== id);
    setTeam(FilterData);
  };

  const setTeamValue = (id, value, no) => {
    const filteredData = team?.filter((t) => t.id !== id);
    let data = team?.find((t) => t.id === id);
    data[no] = value;
    const newData = [...filteredData, data];
    setTeam(newData);
  };

  const onSubmit = (data) => {
    if (!token) {
      return;
    }

    data.team = team;
    const formData = {
      project_progress_id: teamData?.project?.id,
      team,
    };

    let CreateTeam = async () => {
      const res = await fetch(`${MainUrl.url}/api/v1/project/team`, {
        method: "post",
        headers: {
          "content-type": "application/json",
          Authorization: token,
        },
        body: JSON.stringify(formData),
      });
      const resData = await res.json();
      if (res.ok) {
        setUpdate(!update);
        toast.success("successfully Create a New team");
        setShowTeam(!showTeam);
        reset();
      } else {
        toast.error(resData.message);
        setShowTeam(!showTeam);
        reset();
      }
    };
    CreateTeam();
  };

  const users = allUser?.map((user) => {
    let obj = {};
    obj.label = user?.user_profile?.name;
    obj.value = user?.user_profile?.id;
    return obj;
  });

  const roles = role?.map((singleRole) => {
    let obj = {};
    obj.label = singleRole?.name;
    obj.value = singleRole?.id;
    return obj;
  });

  return (
    <div>
      <Rodal
        visible={showTeam}
        enterAnimation="flip"
        animation="slideRight"
        onClose={() => setShowTeam(!showTeam)}
        customStyles={{
          height: "auto",
          width: "40%",
          bottom: "auto",
          top: "10%",
          transform: "translateY(-50%)",
        }}
      >
        <form action="" onSubmit={handleSubmit(onSubmit)} className="common">
          <div>
            <div>
              <div className="">
                <div className="mb-2 w-full">
                  <span className="text-base font-[500]">Select Project</span>
                  <div className="w-full">
                    <InputText
                      className="w-full"
                      value={teamData?.project?.project_title}
                      disabled
                      placeholder="Disabled"
                    />
                  </div>
                </div>
              </div>

              <div>
                <label htmlFor="" className="flex gap-3 items-center mt-4">
                  <span className="text-base   font-[500]">Team Members</span>
                  <AiOutlinePlus
                    size={25}
                    onClick={() => {
                      const newData = [
                        ...team,
                        {
                          id: team?.length + 1,
                          user_id: "",
                          project_role_id: "",
                        },
                      ];
                      setTeam(newData);
                    }}
                    className="p-0.5 bg-[#34c38f] hover:bg-[#2ca67a] text-white font-semibold text-center rounded-md cursor-pointer"
                  />
                </label>
              </div>

              {teamData?.team?.map((data) => {
                return (
                  <div className="flex justify-between gap-x-2 py-4">
                    <InputText
                      className="w-full p-inputtext-sm"
                      value={data?.user?.user_profile?.name}
                    />
                    <InputText
                      className="w-full"
                      value={data?.project_role?.name}
                    />
                  </div>
                );
              })}

              <div
                className={`${
                  team?.length > 3 ? "overflow-y-scroll h-80" : ""
                } mb-2`}
              >
                {team?.map((t, i) => (
                  <div key={i}>
                    <div className="flex justify-end ">
                      <RxCross1
                        onClick={() => handleminus(t.id)}
                        className="flex justify-end cursor-pointer"
                        size={16}
                      />
                    </div>

                    <div className="flex justify-between gap-x-2">
                      <div className="mb-2 w-full">
                        <span className="text-base  font-[500]">
                          Select member
                        </span>
                        <div className="w-full">
                          <Select
                            className="basic-single"
                            classNamePrefix="select"
                            isClearable={isClearable}
                            onChange={(e) =>
                              setTeamValue(t.id, e?.value, "user_id")
                            }
                            options={users}
                          />
                        </div>
                      </div>

                      <div className="mb-2 w-full">
                        <span className="text-base   font-[500]">
                          Select Role
                        </span>
                        <div className="w-full">
                          <Select
                            className="basic-single"
                            classNamePrefix="select"
                            isClearable={isClearable}
                            onChange={(e) =>
                              setTeamValue(t.id, e?.value, "project_role_id")
                            }
                            options={roles}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <input
            type="submit"
            value="Submit"
            className="text-white bg-cyan-700 font-medium text-xl  px-4 py-1 rounded cursor-pointer"
          />
        </form>
      </Rodal>
    </div>
  );
};

export default TeamCreate;

import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { AiFillEdit, AiOutlineDelete, AiOutlinePlus } from "react-icons/ai";
import CreateDeductionType from "./CreateDeductionType";
import UpdateDeductionType from "./UpdateDeductionType";
import { MainUrl } from "../../utils/helper";
import useData from "../../ContextApi/UseData/useData";
import DeleteModal from "../AllEmployee/DeleteModal/DeleteModal";
import Loader from "../Loader/Loader";

const DeductionType = () => {
  const { token } = useData().user;
  const [deductionTypeData, setDeductionTypeData] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [modalData, setModalData] = useState([]);
  const [update, setUpdate] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [selectData, setSelectData] = useState(null);
  const [isLoader, setIsLoader] = useState(false);

  useEffect(() => {
    if (!token) {
      return;
    }
    const fetchData = async () => {
      setIsLoader(true);
      const res = await fetch(`${MainUrl.url}/api/v1/deduction_types`, {
        method: "get",
        headers: {
          Authorization: token,
        },
      });

      const data = await res.json();
      if (res.ok) {
        setDeductionTypeData(data?.data);
        setIsLoader(false);
      } else {
        toast.error(data.message);
        console.error(data.message);
        setIsLoader(false);
      }
    };
    fetchData();
  }, [update, token]);

  const ActionTemplate = (data) => {
    return (
      <div className=" flex items-center gap-x-2">
        <div
          className="bg-cyan-700 h-8 w-8 flex items-center justify-center rounded-full cursor-pointer"
          onClick={() => {
            setModalData(data);
            setShowUpdateModal(!showUpdateModal);
          }}
        >
          <AiFillEdit className="text-white" />
        </div>
        <div
          className="bg-red-400 h-8 w-8 flex items-center justify-center rounded-full cursor-pointer"
          onClick={() => {
            setSelectData(data);
            setShowDelete(!showDelete);
          }}
        >
          <AiOutlineDelete className="text-white" />
        </div>
      </div>
    );
  };

  const NameTemplate = (data) => {
    return <p className="text-base ">{data?.name}</p>;
  };
  const ModifyTimeTemplate = (data) => {
    const date = data?.modified_at;
    let formatDate = new Date(date)?.toLocaleDateString("en-GB", {
      year: "numeric",
      month: "numeric",
      day: "numeric",
    });
    return <p className="text-base ">{formatDate}</p>;
  };

  const renderHeader = () => {
    return (
      <div className="flex justify-end">
        <div className="pt-2">
          <button
            onClick={() => setShowModal(!showModal)}
            className="flex items-center gap-x-2  px-2 py-2.5 bg-cyan-700 text-white rounded-md"
          >
            <span className="text-base ">
              <AiOutlinePlus />
            </span>{" "}
            <span className="text-base ">Deduction Type</span>{" "}
          </button>
        </div>
      </div>
    );
  };
  const header = renderHeader();

  const data = {
    update,
    setUpdate,
    showModal,
    setShowModal,
  };
  const updateRodal = {
    showUpdateModal,
    setShowUpdateModal,
    modalData,
    update,
    setUpdate,
  };

  const DeleteUrl = "api/v1/deduction_types/one";
  const DeleteData = {
    showDelete,
    setShowDelete,
    update,
    setUpdate,
    selectData,
    DeleteUrl,
  };

  return (
    <div>
      {isLoader ? (
        <Loader />
      ) : (
        <DataTable
          header={header}
          value={deductionTypeData}
          paginator
          rows={5}
          rowsPerPageOptions={[5, 10, 25, 50]}
          selectionMode="single"
          size={"small"}
          dataKey="id"
          tableStyle={{ Width: "100%" }}
        >
          <Column field="Name" body={NameTemplate} header="Name"></Column>
          <Column
            field="Name"
            body={ModifyTimeTemplate}
            header="Modified"
          ></Column>
          <Column
            field="Name"
            body={ActionTemplate}
            className=""
            header="Action"
          ></Column>
        </DataTable>
      )}

      {<CreateDeductionType data={data} />}
      {<UpdateDeductionType updateRodal={updateRodal} />}
      {<DeleteModal DeleteData={DeleteData} />}
    </div>
  );
};

export default DeductionType;

import React from 'react';
import "./Loader.css"

const Loader = () => {
    return (
        <div id="loading-bar-spinner" className="spinner">
            <div className="spinner-icon"></div>
        </div>
    );
};

export default Loader;
import React from 'react'
import DailyOverTime from '../OverTime/DailyOverTime'
import Breadcrumbs from '../Breadcrumbs/Breadcrumbs'

const AdminOverTimeLogPage = () => {
    const data = [
        {
          name: "Daily Offer-Time Table",
        },
      ];
  return (
    <>
    <Breadcrumbs data={data}/>
    <DailyOverTime/>
    </>
  )
}

export default AdminOverTimeLogPage
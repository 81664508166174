import React from "react";
import Breadcrumbs from "../Breadcrumbs/Breadcrumbs";
import Eligibility from "../Incentive/Eligibility";

const EligibilityPage = () => {
  const data = [
    {
      name: "My Account",
    },
  ];
  return (
    <>
      <Breadcrumbs data={data} />
      <Eligibility />
    </>
  );
};

export default EligibilityPage;

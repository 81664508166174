import React from "react";

import { MainUrl, showDummyImage } from "../../utils/helper";
import { Dialog } from "primereact/dialog";
import moment from "moment";

const DeductionDetailsModal = ({
  deductionDetails,
  firstDate,
  setFirstDate,
  show,
  setShow,
  setUserInfos,
  setLastDate,
  lastDate,
}) => {
  const { user, total_deduction } = deductionDetails[0];
  const fromDate = moment(firstDate).format("DD-MM-YY");
  const EndDate = moment(lastDate).format("DD-MM-YY");

  console.log("setFormMonth", firstDate);
  return (
    <div className="deductionDetailsModal">
      <Dialog
        header="Deduction Details"
        visible={show}
        onHide={() => {
          setShow(!show);
          setUserInfos(null);
          setFirstDate(null);
          setLastDate(null);
        }}
        style={{ width: "30vw" }}
        breakpoints={{ "960px": "75vw", "641px": "100vw" }}
        draggable={false}
        resizable={false}
      >
        {/* <h5 className="text-xl border-b py-3">Deduction Details</h5> */}

        <div className="grid grid-cols-12 pt-4 items-center">
          <div className="w-[150px] h-[150px] col-span-4">
            <img
              className="h-full w-full"
              src={`${MainUrl.url}${user?.user_profile?.profile_photo}`}
              onError={({ currentTarget }, imageSize = "100x100") =>
                showDummyImage({ currentTarget }, imageSize)
              }
              alt="user"
            />
          </div>
          <div className="col-span-8">
            <p>
              <span className="text-lg font-medium">Name:</span>{" "}
              <span className="text-lg font-medium">
                {user?.user_profile?.name}
              </span>
            </p>
            <p>
              <span className="text-lg font-medium">From:</span>{" "}
              <span className="text-lg font-medium">{fromDate}</span>
            </p>
            <p>
              <span className="text-lg font-medium">To:</span>{" "}
              <span className="text-lg font-medium">{EndDate}</span>
            </p>
            <p>
              <span className="text-lg font-medium">Total:</span>{" "}
              <span className="text-lg font-medium">{total_deduction}</span>
            </p>
          </div>
        </div>
      </Dialog>
    </div>
  );
};

export default DeductionDetailsModal;

import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import React, { useEffect, useState } from "react";
import {
  AiFillEdit,
  AiOutlineDelete,
  AiOutlinePlus,
  AiOutlineUser,
} from "react-icons/ai";
import { RiProfileLine } from "react-icons/ri";
import { IoIosRefresh } from "react-icons/io";
import { toast } from "react-toastify";
import { Link, useLocation, useSearchParams } from "react-router-dom";
import Loader from "../Loader/Loader";
import AccountInfoModal from "./AccountInfoModal";
import ProfileModal from "./ProfileModal";
import { MainUrl, showDummyImage } from "../../utils/helper";
import useData from "../../ContextApi/UseData/useData";
import Pagination from "../Pagination/Pagination";

const AllEmployee = () => {
  const { token } = useData().user;
  // const [pages, setPages] = useState("");
  const [allUser, setAllUser] = useState(null);
  const [update, setUpdate] = useState(false);
  const [totalData, setTotalData] = useState(null);
  const [isLoader, setIsLoader] = useState(false);
  const [showAccountModal, setShowAccountModal] = useState(false);
  const [accountData, setAccountData] = useState(null);
  const [showProfile, setShowProfile] = useState(false);
  const [profileData, setProfileData] = useState(null);
  const [changeValue, setChangeValue] = useState({});
  const [filterData, setFilterData] = useState({
    name: "",
    office_id: "",
  });

  const [pageNumber, setPageNumber] = useState(1);
  const [viewData, setViewData] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchParams, setSearchParams] = useSearchParams();
  const [pageCount, setPageCount] = useState();
  const location = useLocation();


  // get Table data
  useEffect(() => {
    if (!token) {
      return;
    }
    const page = location?.search?.split("=")[1];
    if (page) {
      setPageNumber(+page);
      setCurrentPage(+page);
    }
    
    const fetchData = async () => {
      try {
        setIsLoader(true);
        const res = await fetch(
          `${MainUrl.url}/api/v1/all_users/${viewData}/${pageNumber}?${
            filterData.name && `name=${filterData.name}`
          }${filterData.office_id && `&office_id=${filterData?.office_id}`}`,
          {
            method: "get",
            headers: {
              Authorization: token,
            },
          }
        );

        const data = await res.json();
        if (res.ok) {
          setAllUser(data?.data?.results);
          setTotalData(data.data?.totalCount);
          setIsLoader(false);
        }
      } catch (error) {
        toast.message(error.message);
        setIsLoader(false);
      }
    };
    fetchData();
  }, [
    update,
    pageNumber,
    viewData,
    token,
    filterData,
    location,
    searchParams,
    currentPage,
  ]);

  useEffect(() => {
    const pages = Math.ceil(totalData / viewData);
    setPageCount(pages);
  }, [totalData, viewData]);

  const handleDelete = () => {
    toast.warn(
      "The delete feature is kept off due to foreign key constraint. This will be solved soon"
    );
  };

  const EmployeeNameTemplate = (data) => {
    return (
      <div className="flex items-center gap-x-4">
        <div className="h-10 w-10  ">
          <img
            src={`${MainUrl.url}/${data?.user_profile?.profile_photo}`}
            onError={({ currentTarget }, imageSize = "100x100") =>
              showDummyImage({ currentTarget }, imageSize)
            }
            alt=""
            className=" h-full w-full rounded-full"
          />
        </div>
        <h3 className="text-base">{data?.user_profile?.name}</h3>
      </div>
    );
  };

  const OfficeIdTemplate = (data) => {
    return <p className="text-base">{data?.office_id}</p>;
  };

  const AccountTemplate = (data) => {
    return (
      <div>
        <p
          onClick={() => {
            setShowAccountModal(!showAccountModal);
            setAccountData(data);
          }}
          className="flex gap-x-1 items-center hover:text-green-400 duration-150"
        >
          <span>
            <AiOutlineUser className="text-2xl" />
          </span>
          <span>Account</span>
        </p>
      </div>
    );
  };

  const profileTemplate = (data) => {
    return (
      <div>
        <p
          onClick={() => {
            setProfileData(data);
            setShowProfile(!showProfile);
          }}
          className="flex gap-x-1 items-center hover:text-green-400 duration-150"
        >
          <span>
            <RiProfileLine className="text-2xl" />
          </span>
          <span>Profile</span>
        </p>
      </div>
    );
  };

  const ActionTemplate = (data) => {
    const id = data?.id;
    const OfficeId = data?.office_id;
    return (
      <div className=" flex items-center gap-x-2">
        <Link to={`/updateProfile/${id}`}>
          <div className="bg-cyan-700 h-8 w-8 flex items-center justify-center rounded-full cursor-pointer">
            <AiFillEdit className="text-white" />
          </div>
        </Link>
        <div
          className="bg-red-400 h-8 w-8 flex items-center justify-center rounded-full cursor-pointer"
          onClick={() => handleDelete(id, OfficeId)}
        >
          <AiOutlineDelete className="text-white" />
        </div>
      </div>
    );
  };

  const renderHeader = () => {
    const setValue = (e) => {
      setChangeValue({
        ...changeValue,
        [e.target.name]: e.target.value,
      });
    };

    const handleSearch = async (e) => {
      e.preventDefault();

      const data = {
        name: changeValue.name ? changeValue.name : "",
        office_id: changeValue.office_id ? changeValue.office_id : "",
      };
      setFilterData(data);
    };

    const handleFresh = () => {
      setUpdate(!update);
      setChangeValue({
        name: "",
        office_id: "",
      });
      setFilterData({
        name: "",
        office_id: "",
      });
    };

    return (
      <div className="">
        <div>
          <div className="grid grid-cols-12 items-center justify-between">
            <div className="col-span-8">
              <div className="grid grid-cols-12 gap-x-2 items-center">
                <div className="col-span-10">
                  <form
                    className="flex gap-x-3  w-full myInput"
                    onSubmit={handleSearch}
                  >
                    <div className="w-full">
                      <input
                        onChange={(e) => setValue(e)}
                        className="ps-3 py-1.5 border border-gray-300 rounded-lg inputShadow w-full"
                        placeholder="Search by Name"
                        value={changeValue.name}
                        name="name"
                      />
                    </div>
                    <div className="w-full">
                      <input
                        onChange={(e) => setValue(e)}
                        className="ps-3 py-1.5 border border-gray-300 rounded-lg inputShadow w-full"
                        placeholder="Search by Office Id"
                        value={changeValue.office_id}
                        name="office_id"
                      />
                    </div>

                    <div className="">
                      <input
                        type="submit"
                        value="Search"
                        className="cursor-pointer px-4 py-2 w-full bg-cyan-700 rounded-md text-white font-medium text-sm submit"
                      />
                    </div>
                  </form>
                </div>
                <div className="col-span-2 gap-x-2">
                  <div>
                    <span
                      onClick={handleFresh}
                      className="bg-cyan-700 text-white h-8 w-8 flex items-center justify-center rounded-full font-medium cursor-pointer"
                    >
                      <IoIosRefresh />
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-span-4">
              <div className="flex items-end justify-end">
                <Link to="/createUser">
                  <button className="flex items-center gap-x-2  px-2 py-2.5 bg-cyan-700 text-white rounded-md">
                    <span className="text-base font-semibold">
                      <AiOutlinePlus />
                    </span>
                    <span className="text-base font-medium">Account</span>
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  const header = renderHeader();


  const AccountModalData = {
    showAccountModal,
    accountData,
    setShowAccountModal,
  };

  const profileModal = {
    showProfile,
    profileData,
    setShowProfile,
  };

  return (
    <div className="bg-white pb-4 mb-4">
      {isLoader ? (
        <Loader />
      ) : (
        <DataTable
          size="small"
          header={header}
          value={allUser}
          // footer={footerTemplate}
          selectionMode="single"
          dataKey="id"
          tableStyle={{ Width: "100%" }}
        >
          <Column
            field="user_profile.name"
            body={EmployeeNameTemplate}
            header="Employee Name"
            sortable
            className=""
          ></Column>
          <Column
            field="office_id"
            body={OfficeIdTemplate}
            header="Office Id"
            sortable
            className=""
          ></Column>
          <Column
            field="Name"
            body={AccountTemplate}
            header="Account"
            className="w"
          ></Column>
          <Column
            field="Name"
            body={profileTemplate}
            header="Profile"
            className=""
          ></Column>
          <Column
            field="Name"
            body={ActionTemplate}
            header="Action"
            className="w-[15%]"
          ></Column>
        </DataTable>
      )}

      <div className="mt-4">
        {allUser?.length > 0 && (
          <Pagination
            currentPage={currentPage}
            pageNumber={pageNumber}
            setCurrentPage={setCurrentPage}
            setSearchParams={setSearchParams}
            pageCount={pageCount}
            setViewData={setViewData}
            viewData={viewData}
            setPageNumber={setPageNumber}
          />
        )}
      </div>
      {<AccountInfoModal AccountModalData={AccountModalData} />}
      {<ProfileModal profileModal={profileModal} />}
    </div>
  );
};

export default AllEmployee;

import React from "react";
import ApplyProjectProposal from "../ProjectManagement/ApplyProjectProposal";
import Breadcrumbs from "../Breadcrumbs/Breadcrumbs";
import { useParams } from "react-router-dom";

const ApplyProjectProposalPage = () => {
  const { id } = useParams();
  console.log("id---->", id)
    const data = [
        {
          name: "Apply Project Proposal",
        },
      ];

      const data2 = [
        {
          name: "Update Project Proposal",
        },
      ];
  return (
    <>
      <Breadcrumbs data={id? data2 : data}/>
      <ApplyProjectProposal />
    </>
  );
};

export default ApplyProjectProposalPage;

import { Dialog } from "primereact/dialog";
import React, { useEffect, useState } from "react";
import { MainUrl, showDummyImage } from "../../utils/helper";
import { InputTextarea } from "primereact/inputtextarea";
import { InputText } from "primereact/inputtext";
import useData from "../../ContextApi/UseData/useData";
import "./UpdateModal.css";
import CreatableSelect from "react-select/creatable";
import { toast } from "react-toastify";

const NoticeUpdateModal = ({ UpdateData }) => {
  const { ShowUpdate, setShowUpdate, selectData } = UpdateData;
  const [types, setTypes] = useState(null);
  const [description, setDescription] = useState(null);
  const [heading, setHeading] = useState(null);
  const [selectNotice, setSelectNotice] = useState("");
  const { token } = useData().user;

  const [image, setImage] = useState(null);
  const [selectImg, setSelectImg] = useState(null);

  useEffect(() => {
    if (!token) {
      return;
    }
    const fetchData = async () => {
      try {
        const res = await fetch(`${MainUrl.url}/api/v1/notice/type`, {
          method: "Get",
          headers: {
            Authorization: token,
          },
        });
        const resData = await res.json();
        if (res.ok) {
          setTypes(resData?.data);
        }
      } catch (error) {
        console.error(error.message);
      }
    };

    fetchData();
  }, [token]);

  const noticeTypes = types?.map((type) => {
    let obj = {};
    obj.label = type?.name;
    obj.value = type?.id;
    return obj;
  });

  const imgChange = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(e.target.files[0]);
      fileReader.onload = () => {
        setImage(fileReader.result);
        setSelectImg(e.target.files[0]);
      };
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const data = {
      headline: heading ? heading : selectData?.headline,
      notice_type: selectNotice ? selectNotice : selectData?.notice_type?.id,
      notice_file: selectImg ? selectImg : selectData?.notice_file,
      description: description ? description : selectData?.description,
    };

    let formData = new FormData();
    for (const i in data) {
      const element = data[i];
      formData.append(`${i}`, element);
    }
    const postData = async () => {
      const res = await fetch(`${MainUrl.url}/api/v1/notice/one/${selectData.id}`, {
        method: "Put",
        headers: {
          Authorization: token,
        },
        body: formData,
      });

      // console.log("res", res);
      if (res.ok) {
        const resData = await res.json();
        toast.success(resData.message)
      } else {
        const resData = await res.json();
        toast.warn(resData.message)
      }
    };
    postData();
  };

  return (
    <Dialog
      header="Update Notification"
      position={"top"}
      visible={ShowUpdate}
      draggable={false}
      modal={false}
      style={{ width: "35vw", height: "80vh" }}
      onHide={() => setShowUpdate(!ShowUpdate)}
    >
      <form
        onSubmit={handleSubmit}
        className="border-t border-dashed notice-update pt-2"
      >
        <div className="flex flex-col gap-2">
          <label className="ps-2">Heading</label>
          <InputText
            defaultValue={selectData?.headline}
            onChange={(e) => setHeading(e.target.value)}
            placeholder="Header"
          />
        </div>
        <div className="flex flex-col gap-2 pt-2">
          <label className="ps-2">Notice Type</label>
          <CreatableSelect
            onChange={(e) => setSelectNotice(e?.value)}
            isClearable
            options={noticeTypes}
          />
        </div>
        <div className="mt-4 h-80 w-full border border-dashed relative">
          <img
            src={`${
              image ? image : `${MainUrl.url}/${selectData?.notice_file}`
            }`}
            alt=""
            onError={({ currentTarget }, imageSize = "160x160") =>
              showDummyImage({ currentTarget }, imageSize)
            }
            className=" h-full w-full"
          />

          <input
            type="file"
            className={`absolute top-0 left-0 w-full h-full opacity-0`}
            onChange={imgChange}
          />
        </div>
        <div className="pt-4">
          <label className="ps-2">Description</label>
          <InputTextarea
            autoResize
            value={description}
            defaultValue={selectData?.description}
            onChange={(e) => setDescription(e.target.value)}
            placeholder="Description"
            rows={5}
            className="w-full"
          />
        </div>
        <button className="bg-cyan-600 text-white px-4 py-2 rounded">
          Update
        </button>
      </form>
    </Dialog>
  );
};

export default NoticeUpdateModal;

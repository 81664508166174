import React from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import Rodal from "rodal";
import "rodal/lib/rodal.css";
import { MainUrl } from "../../utils/helper";
import useData from "../../ContextApi/UseData/useData";

const CreateEligibility = ({ CreateData }) => {
  const { showEligibility, setShowEligibility, update, setUpdate } = CreateData;
  const {token} = useData().user

  //   const handleCreateDeductionType = (e) => {
  //     e.preventDefault();
  //     const form = e.target;
  //     const name = form.name.value;
  //     if (!name) {
  //       return;
  //     }

  //     const fromData = {
  //       name: name,
  //     };
  //     const postModalData = async () => {
  //       const res = await fetch(
  //         `http://192.168.68.125:3007/api/v1/incentive/eligibility`,
  //         {
  //           method: "Post",
  //           headers: {
  //             "Content-Type": "application/json",
  //           },
  //           body: JSON.stringify(fromData),
  //         }
  //       );
  //       const resData = await res.json();
  //       if (res.ok) {
  //         toast.success(resData.status);
  //         setUpdate(!update);
  //         setShowEligibility(!showEligibility);
  //       } else {
  //         toast.error(resData.message);
  //         setShowEligibility(!showEligibility);
  //       }
  //     };
  //     postModalData();
  //   };

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    const postModalData = async () => {
      const res = await fetch(
        `${MainUrl.url}/api/v1/incentive/eligibility`,
        {
          method: "Post",
          headers: {
            "Content-Type": "application/json",
            Authorization:token
          },
          body: JSON.stringify(data),
        }
      );
      const resData = await res.json();
      if (res.ok) {
        toast.success(resData.status);
        setUpdate(!update);
        setShowEligibility(!showEligibility);
      } else {
        toast.error(resData.message);
        setShowEligibility(!showEligibility);
      }
    };
    postModalData();
  };

  return (
    <Rodal
      visible={showEligibility}
      enterAnimation="flip"
      animation="fade"
      onClose={() => setShowEligibility(!showEligibility)}
      customStyles={{
        height: "auto",
        width: "40%",
        bottom: "auto",
        top: "10%",
        transform: "translateY(-50%)",
      }}
    >
      <h5 className="text-xl border-b py-3">Create Eligibility</h5>

      <div>
        <form onSubmit={handleSubmit(onSubmit)} className="pt-4">
          <div className="flex w-full ">
            <input
              type="text"
              className="border-2 ps-2 py-1 rounded-s-md border-gray-200 focus-visible:outline-none w-4/5"
              placeholder="Name"
              {...register(
                "name",
                {
                  required: true,
                  message: "This field is Required",
                  minLength: {
                    value: 5,
                    message: "provide Name in minimum 5 letter",
                  },
                  maxLength: {
                    value: 50,
                    message: "provide Name in  maximum 50 letter",
                  },
                },
                {
                  pattern: /^[a-zA-Z]+( [a-zA-Z]+)*$/,
                  message: "provide just text not any number",
                }
              )}
            />

            <input
              className="cursor-pointer px-4 rounded-e-md bg-cyan-700  text-white font-medium text-sm w-1/5"
              type="submit"
              value="Submit"
            />
          </div>

          {errors?.name?.type === "minLength" && (
            <p className="text-red-400 text-base pl-[15px]">
              {errors?.name?.message}
            </p>
          )}

          {errors?.name?.type === "maxLength" && (
            <p className="text-red-400 text-xs pl-[15px]">
              {errors?.name?.message}
            </p>
          )}

          {errors?.name?.type === "pattern" && (
            <p className="text-red-400 text-xs pl-[15px]">
              {errors?.name?.message}
            </p>
          )}
        </form>
      </div>
    </Rodal>
  );
};

export default CreateEligibility;

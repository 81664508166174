import React from "react";
import Breadcrumbs from "../Breadcrumbs/Breadcrumbs";
import LeaveApplication from "./LeaveApplication";

const LeaveApplicationPage = () => {
  const data = [
    {
      name: "Leave Application",
    },
  ];
  return (
    <>
      <Breadcrumbs data={data} />
      <LeaveApplication />
    </>
  );
};

export default LeaveApplicationPage;

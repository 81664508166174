import React from "react";
import { toast } from "react-toastify";
import { MainUrl } from "../../utils/helper";
import useData from "../../ContextApi/UseData/useData";
import { Dialog } from "primereact/dialog";

const CreateProvidentPolicyModal = ({ modalData }) => {
  const { showProvidentPolicy, setShowProvidentPolicy, update, setUpdate } =
    modalData;
  const { token } = useData().user;
  const handleSubmit = (e) => {
    e.preventDefault();
    const form = e.target;
    const pack = form.package.value;
    const duration = form.duration.value;
    const percent = form.percent.value;
    const formData = {
      package: pack,
      duration: duration,
      percentage: percent,
    };
    const postModalData = async () => {
      const res = await fetch(`${MainUrl.url}/api/v1/provident_fund/policies`, {
        method: "Post",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
        body: JSON.stringify(formData),
      });
      const resData = await res.json();
      if (res.ok) {
        toast.success(resData.status);
        setShowProvidentPolicy(!showProvidentPolicy)
        setUpdate(!update);
      } else {
        toast.error(resData.message);
        setShowProvidentPolicy(!showProvidentPolicy)
      }
    };
    postModalData();
  };

  return (
    <div>
      <Dialog
        header="Create Provident Policy"
        visible={showProvidentPolicy}
        onHide={() => setShowProvidentPolicy(!showProvidentPolicy)}
        style={{ width: "50vw" }}
        breakpoints={{ "960px": "75vw", "641px": "100vw" }}
        resizable={false}
        draggable={false}
      >
        {/* <h5 className="text-xl border-b py-3"></h5> */}

        <div>
          <form onSubmit={handleSubmit} className="pt-4">
            <div className="flex flex-col ">
              <label> Package Name:</label>
              <input
                type="text"
                className="border-2 border-gray-200 ps-2 py-0.5 rounded-sm  focus-visible:outline-none"
                name="package"
                placeholder="Package Name"
                required
              />
            </div>
            <div className="flex flex-col pt-2">
              <label>Percentage:</label>
              <input
                type="number"
                className="border-2 border-gray-200 ps-2 py-0.5 rounded-sm  focus-visible:outline-none"
                name="percent"
                placeholder="Percentage"
                required
              />
            </div>

            <div className="flex flex-col pt-2">
              <label> Duration:</label>
              <input
                type="number"
                className="border-2 border-gray-200 ps-2 py-0.5 rounded-sm  focus-visible:outline-none"
                name="duration"
                placeholder="Duration"
                required
              />
            </div>
            <input
              className="cursor-pointer px-4 py-2 mt-4 bg-cyan-700 rounded-md text-white font-medium text-sm"
              type="submit"
              value="Submit"
            />
          </form>
        </div>
      </Dialog>
    </div>
  );
};

export default CreateProvidentPolicyModal;

import React, { useState } from "react";
import { toast } from "react-toastify";
import Rodal from "rodal";
import { MainUrl } from "../../utils/helper";
import useData from "../../ContextApi/UseData/useData";

const UpdateEligibility = ({ UpdateData }) => {
  const { show, setShow, update, setUpdate, selectData } = UpdateData;

  const [eligibilityName, setEligibilityName] = useState(null);
  const {token} = useData().user

  const handleCreateDeductionType = (e) => {
    e.preventDefault();
    if (!eligibilityName) {
      toast.error("please Change Name or close Modal");
      return;
    }

    const fromData = {
      name: eligibilityName,
    };
    const postModalData = async () => {
      const res = await fetch(
        `${MainUrl.url}/api/v1/incentive/eligibility/${selectData.id}`,
        {
          method: "put",
          headers: {
            "Content-Type": "application/json",
            Authorization:token
          },
          body: JSON.stringify(fromData),
        }
      );
      const resData = await res.json();
      if (res.ok) {
        setUpdate(!update);
        toast.success(resData.status);
        setShow(!show);
      } else {
        toast.error(resData.message);
        setShow(!show);
      }
    };
    postModalData();
  };

  return (
    <div>
      <Rodal
        visible={show}
        onClose={() => setShow(!show)}
        customStyles={{
          height: "auto",
          bottom: "auto",
          top: "30%",
          transform: "translateY(-50%)",
        }}
      >
        <h5 className="text-xl border-b py-3">Update Eligibility</h5>
        <form onSubmit={handleCreateDeductionType} className="pt-4">
          <div className="flex w-full ">
            <input
              type="text"
              className="border-2 ps-2 py-1 rounded-s-md border-gray-200 focus-visible:outline-none w-4/5"
              defaultValue={selectData?.name}
              onChange={(e) => setEligibilityName(e.target.value)}
            />
            <input
              className="cursor-pointer px-4 rounded-e-md bg-cyan-700  text-white font-medium text-sm w-1/5"
              type="submit"
              value="Submit"
            />
          </div>
        </form>
      </Rodal>
    </div>
  );
};

export default UpdateEligibility;

import React, { useEffect, useState } from "react";
import Rodal from "rodal";
import Select from "react-select";
import { toast } from "react-toastify";
import 'rodal/lib/rodal.css';
import useData from "../../ContextApi/UseData/useData";
import { MainUrl } from "../../utils/helper";

const UpDateIncentive = ({ UpdateIn }) => {
  const {token} = useData().user
  const { showUpdate, setShowUpdate, update, setUpdate, UpdateData, allUser } = UpdateIn;


  const [isClearable] = useState(true);
  const [isSearchable] = useState(true);
  const [eligibility, setEligibility] = useState(null);
  const [selectUser, setSelectUser] = useState("")
  // get all user


  // selcet modify user data
  const userInfo = allUser?.map((user) => {
    let obj = {};
    obj.label = user?.user_profile?.name;
    obj.value = user?.id;
    return obj;
  });

  // get eligibility data
  useEffect(() => {
    const fetchEligibility = async () => {
      try {
        const res = await fetch(
          `${MainUrl.url}/api/v1/incentive/eligibility`,
          {
            method: "get",
            headers:{
              "Authorization":token
            }
          }
        );
        const data = await res.json();
        if (res.ok) {
          setEligibility(data.data);
        }
      } catch (error) {
        toast.error(error?.message);
      }
    };
    fetchEligibility();
  }, [token]);

  const eligiable = eligibility?.map((data) => {
    let obj = {};
    obj.label = data?.name;
    obj.value = data?.id;
    return obj;
  });



  const handleSubmit = (e)=>{
    e.preventDefault();
    const form = e.target;
    const percent = form.percent.value;
    // const name = form.department_head_id.value;
    const status = form.incentive_eligibility_id.value

    const formData = {
      user_id: selectUser || UpdateData?.user?.id,
      increment_percentage:percent || UpdateData?.increment_percentage,
      incentive_eligibility_id : status || UpdateData?.incentive_eligibility?.id,
    }
    let updateIncentive = async () => {
      const res = await fetch(`${MainUrl.url}/api/v1/incentive/one/${UpdateData?.id}`, {
        method: "put",
        headers: {
          "Content-Type": "application/json",
          Authorization:token
        },
        body: JSON.stringify(formData),
      });
      const resData = await res.json();
      if (res.ok) {
        setUpdate(!update);
        toast.success("successfully updated");
        setShowUpdate(!showUpdate)
        form.reset()
      } else {
        toast.error(resData.message);
        setShowUpdate(!showUpdate)
        form.reset()
      }
    };

    updateIncentive();
  }


  return (

      <Rodal
        visible={showUpdate}
        enterAnimation="flip"
        animation="fade"
        onClose={() => setShowUpdate(!showUpdate)}
        customStyles={{
          height: "auto",
          width: "40%",
          bottom: "auto",
          top: "10%",
          transform: "translateY(-50%)",
        }}
      >
        <h5 className="text-xl border-b py-3">Update Incentive</h5>
        <form onSubmit={handleSubmit}>
          <div className="flex flex-col mb-2 ">
            <label className="pb-1 ps-1">Select Employee</label>
            <Select
              className="basic-single"
              classNamePrefix="select"
              isClearable={isClearable}
              isSearchable={isSearchable}
              // name="department_head_id"
              onChange={(e)=>setSelectUser(e?.value)}
              // value={selectUser}
              options={userInfo}
              placeholder={UpdateData?.user?.user_profile?.name}
            />
          </div>

          <div className="flex flex-col mb-2 ">
            <label className="pb-1 ps-1">Select Eligibility Status</label>
            <Select
              className="basic-single"
              classNamePrefix="select"
              isClearable={isClearable}
              isSearchable={isSearchable}
              name="incentive_eligibility_id"
       
              options={eligiable}
              placeholder={UpdateData?.incentive_eligibility?.name}
            />
          </div>

          <div className="flex flex-col mb-2 pt-4 ">
            <label className="pb-1 ps-1">Increment percentage</label>
            <input
              type="number"
              name="percent"
              className="w-full py-1.5 pl-3 mb-2 border rounded-md focus:outline-none text-small "
              placeholder={UpdateData?.increment_percentage}
            />
          </div>
          <div>
            <input
              type="submit"
              value="Submit"
              className="bg-cyan-700 text-white rounded px-4 py-2"
            />
          </div>
        </form>
      </Rodal>

  );
};

export default UpDateIncentive;

import React from 'react'
import AllEmployee from './AllEmployee'
import Breadcrumbs from '../Breadcrumbs/Breadcrumbs'

const AllEmployeePage = () => {
    const data = [
        {
          name: "All-Employee-Table",
        },
      ];
  return (
    <div>
        <Breadcrumbs data={data}/>
        <AllEmployee/>
    </div>
  )
}

export default AllEmployeePage
import React from "react";
import LeaveApply from "../LeaveApply/LeaveApply";
import Breadcrumbs from "../Breadcrumbs/Breadcrumbs";
import { useParams } from "react-router-dom";

const LeaveApplyPage = () => {
  const { id } = useParams();

  const data = [
    {
      name: "Leave Application Update",
    },
  ];

  const data2 = [
    {
      name: "Leave Apply",
    },
  ];

  return (
    <div>
      <Breadcrumbs data={id ? data : data2} />
      <LeaveApply />
    </div>
  );
};

export default LeaveApplyPage;

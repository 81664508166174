import React from "react";
import Breadcrumbs from "../Breadcrumbs/Breadcrumbs";
import AllIncentive from "../Incentive/AllIncentive";

const AllIncentivePage = () => {
  const data = [
    {
      name: "All Incentive",
    },
  ];
  return (
    <>
      <Breadcrumbs data={data} />
      <AllIncentive />
    </>
  );
};

export default AllIncentivePage;

import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { MainUrl } from "../../utils/helper";
import useData from "../../ContextApi/UseData/useData";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import ".././Common.css";

const CreateProvidentFundModal = ({ createProvident }) => {
  const { showCreateModal, setShowCreateModal, update, setUpdate } =
    createProvident;
  const [allPackage, setAllPackage] = useState(null);
  const [selectUser, setSelectUser] = useState(null);
  const [selectPack, setSelectPack] = useState(null);
  const { token, allUser } = useData().user;
  // const [selectedUser, setSelectedUser] = useState(null);

  // get Package
  useEffect(() => {
    if (!token) {
      return;
    }
    const fetchData = async () => {
      try {
        const res = await fetch(
          `${MainUrl.url}/api/v1/provident_fund/policies`,
          {
            method: "Get",
            headers: {
              Authorization: token,
            },
          }
        );
        const data = await res.json();
        if (res.ok) {
          setAllPackage(data.data);
        }
      } catch (error) {
        toast.error(error?.message);
      }
    };
    fetchData();
  }, [token]);

  const userInfo = allUser?.map((user) => {
    let obj = {};
    obj.label = user?.user_profile?.name;
    obj.value = user?.id;
    return obj;
  });

  const packageInfo = allPackage?.map((pack) => {
    let obj = {};
    obj.label = pack.package;
    obj.value = pack.id;
    return obj;
  });

  const handlePost = (e) => {
    e.preventDefault();

    const data = {
      user_id: selectUser,
      policy_id: selectPack,
    };
    const postFund = async () => {
      if (!token) {
        return;
      }
      const res = await fetch(`${MainUrl.url}/api/v1/provident_fund/accounts`, {
        method: "post",
        headers: {
          "Content-type": "application/json",
          Authorization: token,
        },
        body: JSON.stringify(data),
      });
      const resData = await res.json();
      if (res.ok) {
        toast.success("successfully updated");
        setShowCreateModal(false);
        setUpdate(!update);
        setSelectUser(null);
        setSelectPack(null);
      } else {
        toast.error(resData.message);
        setShowCreateModal(false);
        setSelectUser(null);
        setSelectPack(null);
      }
    };
    postFund();
  };

  return (
    <div>
      <Dialog
        visible={showCreateModal}
        header="Add New Provident Found"
        onHide={() => {
          setShowCreateModal(!showCreateModal);
          setSelectPack(null);
          setSelectUser(null);
        }}
        style={{ width: "30vw" }}
        breakpoints={{ "960px": "20vw", "641px": "100vw" }}
        draggable={false}
        resizable={false}
      >
        <div>
          <form onSubmit={handlePost} className="common">
            <div className="flex flex-col common">
              <label className="pb-1 ps-1">Select Employee</label>
              <Dropdown
                value={selectUser}
                onChange={(e) => setSelectUser(e.value)}
                options={userInfo}
                optionLabel="label"
                placeholder="Select a User"
                filter
                showClear
                className="w-full md:w-14rem "
              />
            </div>

            <div className="flex flex-col mt-2 common">
              <label className="pb-1 ps-1">Select Package</label>
              {/* <Select
                className="basic-single"
                classNamePrefix="select"
                isClearable={isClearable}
                isSearchable={isSearchable}
                name="pack"
                options={packageInfo}
              /> */}
              <Dropdown
                value={selectPack}
                onChange={(e) => setSelectPack(e.value)}
                options={packageInfo}
                optionLabel="label"
                placeholder="Select a Package"
                filter
                showClear
                className="w-full md:w-14rem"
              />
            </div>
            <input
              type="Submit"
              className="px-2 py-1 bg-cyan-700 mt-4 text-white rounded-md"
            />
          </form>
        </div>
      </Dialog>
    </div>
  );
};

export default CreateProvidentFundModal;

import React, { useState } from "react";
import { toast } from "react-toastify";
import Select from "react-select";
import { MainUrl } from "../../utils/helper";
import useData from "../../ContextApi/UseData/useData";
import { Dialog } from "primereact/dialog";

const UpdateDepartmentHead = ({ updateHead }) => {
  const { token, allUser } = useData().user;
  const [department, setDepartment] = useState();
  const [isClearable] = useState(true);
  const [isSearchable] = useState(true);
  const [DepartmentHeadName, setDepartmentHeadName] = useState("");
  const { show, updateData, setShow, update, setUpdate } = updateHead;

  // onChange Head Name
  const HeadName = (e) => {
    if (e?.value) {
      setDepartmentHeadName(e?.value);
    }
  };
  // selcet modify data
  const userInfo = allUser?.map((user) => {
    let obj = {};
    obj.label = user?.user_profile?.name;
    obj.value = user?.id;
    return obj;
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    const name = department || updateData?.name;
    const department_head =
      DepartmentHeadName || updateData?.department_head[0]?.user_profile?.id;
    const formData = {
      name: name,
      department_head_id: department_head,
    };
    let updateNote = async () => {
      if (!token) {
        return;
      }
      const res = await fetch(
        `${MainUrl.url}/api/v1/department/${updateData.id}`,
        {
          method: "Put",
          headers: {
            "Content-type": "application/json",
            Authorization: token,
          },
          body: JSON.stringify(formData),
        }
      );
      const resData = await res.json();
      if (res.ok) {
        setUpdate(!update);
        toast.success("successfully updated");
        setShow(!show);
      } else {
        toast.error(resData.message);
        setShow(!show);
      }
    };

    updateNote();
  };

  return (
    <div className="">
      <Dialog
      header="Update Department"
        visible={show}
        onHide={() => setShow(!show)}
        style={{ width: "25vw" }}
        breakpoints={{ "960px": "75vw", "641px": "100vw" }}
        draggable={false}
        resizable={false}
      >
        {/* <h5 className="text-xl border-b pb-3">Update Department</h5> */}

        <div>
          <form onSubmit={handleSubmit} className="">
            <div className="flex flex-col ">
              <label className="pb-1 ps-1">Select Employee</label>
              <Select
                className="basic-single"
                classNamePrefix="select"
                isClearable={isClearable}
                isSearchable={isSearchable}
                onChange={HeadName}
                options={userInfo}
                placeholder={
                  updateData &&
                  updateData?.department_head[0]?.user_profile?.name
                }
              />
            </div>

            <div className="flex flex-col pt-4">
              <label className="text-base font-medium">Department Name</label>
              <input
                onChange={(e) => setDepartment(e.target.value)}
                type="text"
                className="border-2 ps-2 py-1 mt-2 rounded-sm border-gray-200 focus-visible:outline-none w-full"
                defaultValue={updateData?.name}
                required
              />
            </div>
            <input
              className="cursor-pointer px-4 py-2 rounded-sm bg-cyan-700  text-white font-medium text-sm w-1/5 mt-4"
              type="submit"
              value="Submit"
            />
          </form>
        </div>
      </Dialog>
    </div>
  );
};

export default UpdateDepartmentHead;

import React, { createContext } from "react";
import useUserContext from "./useUserContext";
export const UserContext = createContext();

export const ContextApi = ({ children }) => {
  const user = useUserContext()
  return <UserContext.Provider value={{user: user}}>{children}</UserContext.Provider>;
};

export default ContextApi;

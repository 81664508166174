import React from 'react'
import Breadcrumbs from '../Breadcrumbs/Breadcrumbs'
import ProjectProgress from '../ProjectManagement/ProjectProgress'

const ProjectProgressPage = () => {
    const data = [
        {
          name: "Project Progress",
        },
      ];
  return (
    <>
    <Breadcrumbs data={data}/>
    <ProjectProgress/>
    </>
  )
}

export default ProjectProgressPage
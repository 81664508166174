import React, { useState } from "react";
import { toast } from "react-toastify";
import { MainUrl } from "../../utils/helper";
import useData from "../../ContextApi/UseData/useData";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";

const UpdateBonusLog = (updateModaldata) => {
  const {
    showUpdateModal,
    setShowUpdateModal,
    update,
    setUpdate,
    allUser,
    bonusType,
    updateBonusModal,
  } = updateModaldata?.data;
  const [selectUser, setSelectUser] = useState(null);
  const [selectBonus, setSelectBonus] = useState(null);
  const { token } = useData().user;

  console.log("updateBonusModal", updateBonusModal);

  const users = allUser?.map((user) => {
    let obj = {};
    obj.label = user?.user_profile?.name;
    obj.value = user?.id;
    return obj;
  });

  const bonus = bonusType?.map((user) => {
    let obj = {};
    obj.label = user?.name;
    obj.value = user?.id;
    return obj;
  });

  const handleUpdateBonusLog = (e) => {
    e.preventDefault();

    const updateData = {
      user_id: selectUser ? selectBonus : updateModaldata?.user?.id,
      bonus_type_id: selectBonus ? selectBonus : updateModaldata?.bonus_type?.id,
    };

    let updateNote = async () => {
      const res = await fetch(
        `${MainUrl.url}/api/v1/update_bonus/${updateBonusModal?.id}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
          body: JSON.stringify(updateData),
        }
      );
      const resData = await res.json();
      if (res.ok) {
        setUpdate(!update);
        toast.success("successfully updated");
        setShowUpdateModal(false);
      } else {
        toast.error(resData.message);
        setShowUpdateModal(false);
      }
    };
    updateNote();
  };

  return (
    <div>
      <Dialog
        header="Update Bonus"
        visible={showUpdateModal}
        onHide={() => setShowUpdateModal(!showUpdateModal)}
        style={{ width: "50vw" }}
        breakpoints={{ "960px": "75vw", "641px": "100vw" }}
        draggable={false}
        resizable={false}
      >
        {/* <h5 className="text-xl border-b py-3">Update Bonus</h5> */}

        <div>
          <form onSubmit={handleUpdateBonusLog} className="pt-4 common">
            <div className="pt-2 flex flex-col">
              <label>Select Employee</label>
              <Dropdown
                value={selectUser}
                onChange={(e) => setSelectUser(e.value)}
                options={users}
                optionLabel="label"
                placeholder={updateBonusModal ? updateBonusModal?.user_name: "Select User"}
                filter
                className="w-full md:w-14rem"
              />
            </div>

            <div className="pt-2 flex flex-col">
              <label>Select Bonus Type</label>


              <Dropdown
                value={selectBonus}
                onChange={(e) => setSelectBonus(e.value)}
                options={bonus}
                optionLabel="label"
                placeholder={updateBonusModal?  updateBonusModal?.bonus_type?.name: "Select Bonus Type"}
                filter
                className="w-full md:w-14rem"
              />
            </div>
            <input
              className="cursor-pointer px-4 py-2 mt-4 bg-cyan-700 rounded-md text-white font-medium text-sm"
              type="submit"
              value="Submit"
            />
          </form>
        </div>
      </Dialog>
    </div>
  );
};

export default UpdateBonusLog;

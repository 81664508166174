import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import React, { useEffect, useState } from "react";
import { AiFillEdit, AiOutlineDelete, AiOutlinePlus } from "react-icons/ai";
import UpdateDepartmentHead from "./UpdateDepartmentHead";
import CreateNewDepartment from "./CreateNewDepartment";
import { toast } from "react-toastify";
import { MainUrl, showDummyImage } from "../../utils/helper";
import useData from "../../ContextApi/UseData/useData";
import Loader from "../Loader/Loader";
import DeleteModal from "../AllEmployee/DeleteModal/DeleteModal";

const DepartmentAll = () => {
  const [allDepartment, setAllDepartment] = useState(null);
  const [update, setUpdate] = useState(false);
  const [show, setShow] = useState(false);
  const [createShow, setCreateShow] = useState(false);
  const [updateData, setUpdateData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [selectData, setSelectDate] = useState(null);
  const { token } = useData().user;

  useEffect(() => {
    if (!token) {
      return;
    }

    const fetchData = async () => {
      setIsLoading(true);
      try {
        const res = await fetch(`${MainUrl.url}/api/v1/department`, {
          method: "get",
          headers: {
            Authorization: token,
          },
        });
        const data = await res.json();
        if (res.ok) {
          setAllDepartment(data?.data);
          setIsLoading(false);
        }
      } catch (error) {
        toast.error(error.message);
        setIsLoading(false);
      }
    };

    fetchData();
  }, [update, token]);

  const DepartmentHeadTemplate = (data) => {
    // console.log("data",  data?.department_head[0]?.user_profile?.profile_photo);
    const image = data?.department_head[0]?.user_profile?.profile_photo;
    if(image === undefined){
      return
    }

    return (
      <div className="flex items-center gap-x-4">
        <div className="h-10 w-10  ">
          <img
            src={`${MainUrl.url}/${
              data?.department_head[0]?.user_profile?.profile_photo}`}
            onError={({ currentTarget }, imageSize = "100x100") =>
              showDummyImage({ currentTarget }, imageSize)
            }
            alt=""
            className=" h-full w-full rounded-full"
          />
        </div>
        <h3 className="text-base ">
          {data.department_head.length
            ? data?.department_head[0]?.user_profile?.name
            : "TBA"}
        </h3>
      </div>
    );
  };

  const DepartmentnameTemplate = (data) => {
    return <p className="text-base myName">{data?.name}</p>;
  };

  const ActionTemplate = (data) => {
    return (
      <div className=" flex items-center gap-x-2">
        <div
          className="bg-cyan-700 h-8 w-8 flex items-center justify-center rounded-full cursor-pointer"
          onClick={() => {
            setUpdateData(data);
            setShow(!show);
          }}
        >
          <AiFillEdit className="text-white" />
        </div>
        <div
          className="bg-red-400 h-8 w-8 flex items-center justify-center rounded-full cursor-pointer"
          onClick={() => {
            setShowDelete(!showDelete);
            setSelectDate(data);
          }}
        >
          <AiOutlineDelete className="text-white" />
        </div>
      </div>
    );
  };

  const renderHeader = () => {
    return (
      <div className="">
        <div className="flex items-end justify-end w-full">
          <button
            onClick={() => setCreateShow(!createShow)}
            className="flex items-center gap-x-2  px-2 py-2.5 bg-cyan-700 text-white rounded-md"
          >
            <span className="text-base font-semibold">
              <AiOutlinePlus />
            </span>
            <p className="text-base font-medium">Department</p>
          </button>
        </div>
      </div>
    );
  };

  const header = renderHeader();

  const updateHead = {
    show,
    updateData,
    update,
    setUpdate,
    setShow,
  };

  const createData = {
    createShow,
    setCreateShow,
    update,
    setUpdate,
  };

  // const DeleteUrl = "api/v1/department";
  const DeleteData = {
    showDelete,
    setShowDelete,
    update,
    setUpdate,
    selectData,
    // DeleteUrl,
  };

  return (
    <div className="overflow-auto">
      {isLoading ? (
        <Loader />
      ) : (
        <DataTable
          size={"small"}
          value={allDepartment}
          header={header}
          dataKey="id"
          paginator
          rows={5}
          rowsPerPageOptions={[5, 10, 25, 50]}
          emptyMessage="No employees found."
          style={{ minWidth: "50rem" }}
        >
          <Column
            field="user.user_profile.name"
            header="Department Head"
            body={DepartmentHeadTemplate}
            style={{ minWidth: "10%" }}
            filterPlaceholder="Search by Employee Name"
          />
          <Column
            field="name"
            body={DepartmentnameTemplate}
            header="Department Name"
            sortable
            style={{ minWidth: "10%" }}
            filterPlaceholder="Search by Employee Name"
          />
          <Column
            field=""
            body={ActionTemplate}
            header="Action"
            style={{ minWidth: "10%" }}
            filterPlaceholder="Search by Employee Name"
          />
        </DataTable>
      )}
      {<DeleteModal DeleteData={DeleteData} />}
      {<UpdateDepartmentHead updateHead={updateHead} />}
      <CreateNewDepartment createData={createData} />
    </div>
  );
};

export default DepartmentAll;

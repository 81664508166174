import React, { useEffect, useState } from "react";
import { Calendar } from "primereact/calendar";
import "./CreateUser.css";
import Select from "react-select";
import { toast } from "react-toastify";
import { TreeSelect } from "primereact/treeselect";
import CreateUserProfile from "./CreateUserProfile";
import { useForm } from "react-hook-form";
import { MainUrl } from "../../utils/helper";
import useData from "../../ContextApi/UseData/useData";
import Loader from "../Loader/Loader";

const CreateUser = () => {
  const [departments, setDepartments] = useState(null);
  const [designation, setDesignation] = useState([]);
  const [designationId, setDesignationId] = useState("");
  const [isClearable] = useState(true);
  const [isSearchable] = useState(true);
  const [departmentName, setDepartmentName] = useState(null);
  const [userId, setUserId] = useState("");
  const [RoleId, setRoleId] = useState("");
  const [createLoader, setCreateLoader] = useState(false);
  const { token } = useData().user;

  const Role = (e) => {
    if (e?.value) {
      setRoleId(e?.value);
    }
  };

  const DesignationName = (e) => {
    if (e?.value) {
      setDesignationId(e?.value);
    }
  };

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  // get department name
  useEffect(() => {
    const fetchDepartment = async () => {
      const res = await fetch(`${MainUrl.url}/api/v1/department`, {
        method: "get",
        headers: {
          Authorization: token,
        },
      });

      const data = await res.json();
      if (res.ok) {
        setDepartments(data.data);
      } else {
        toast.error(data.message);
        console.error(data.message);
      }
    };
    fetchDepartment();
  }, [token]);

  // get user Designation
  useEffect(() => {
    if (!token) {
      return;
    }
    const fetchDesignation = async () => {
      const res = await fetch(`${MainUrl.url}/api/v1/user/designations`, {
        method: "get",
        headers: {
          Authorization: token,
        },
      });

      const data = await res.json();
      if (res.ok) {
        setDesignation(data.data);
      } else {
        toast.error(data.message);
        console.error(data.message);
      }
    };
    fetchDesignation();
  }, [token]);

  // modify for tree select
  const jobTitle = departments?.map((department) => {
    return {
      data: department?.id,
      label: department?.name,
      key: department?.id,
      children: department?.sub_departments?.map((sub) => {
        return {
          data: sub?.id,
          label: sub?.name,
          key: sub?.id,
        };
      }),
    };
  });

  console.log("job", jobTitle);

  const designationType = designation?.map((user) => {
    let obj = {};
    obj.label = user?.name;
    obj.value = user?.id;
    return obj;
  });

  const RoleName = [
    { label: "Admin", value: 0 },
    { label: "Manager", value: 1 },
    { label: "User", value: 2 },
  ];

  const onSubmit = (data) => {
    if (!token) {
      return;
    }

    data.department_id = departmentName;
    data.role_id = RoleId;
    data.designation = designationId;

    const CrateUser = async () => {
      setCreateLoader(true);
      const res = await fetch(`${MainUrl.url}/api/v1/user`, {
        method: "post",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
        body: JSON.stringify(data),
      });
      const resData = await res.json();
      if (res.ok) {
        toast.success("successfully Crate a New User");
        setUserId(resData?.data?.id);
        reset();
        setCreateLoader(false);
      } else {
        toast.error(resData.message);
        setCreateLoader(false);
      }
    };
    CrateUser();
  };

  if (createLoader) {
    return <Loader />;
  }

  return (
    <>
      <div className=" py-5 px-8  bg-white rounded">
        <h4 className="text-xl font-medium">Create User</h4>
        <p className="text-base pt-2">
          Request User Account details down below.
        </p>
        <form onSubmit={handleSubmit(onSubmit)} className="pt-10 createUser">
          <div className="grid grid-cols-2 gap-x-4">
            <div>
              <div>
                <div className="flex gap-x-2 w-full items-center">
                  <p className="text-lg w-1/5">Email:</p>
                  <input
                    className="border-2 ps-2 py-1 rounded-s-md border-gray-200 focus-visible:outline-none w-full"
                    placeholder="Email"
                    type="email"
                    {...register("email", {
                      required: "This field is required",
                      message: "Invalid Email",
                    })}
                  />
                </div>
                {errors.email && (
                  <p className="text-red-400 text-xs pl-[125px]">
                    {errors?.email?.message}
                  </p>
                )}
              </div>
              <div className="flex gap-x-2 w-full items-center pt-5">
                <p className="text-base w-1/5">Department Name:</p>
                <TreeSelect
                  value={departmentName}
                  onChange={(e) => setDepartmentName(e.value)}
                  options={jobTitle}
                  className="md:w-20rem w-full"
                  placeholder="Select Department"
                  required
                ></TreeSelect>
              </div>

              <div>
                <div className="flex gap-x-2 w-full items-center pt-5">
                  <p className="text-lg w-1/5">Account:</p>
                  <input
                    className="border-2 ps-2 py-1 rounded-s-md border-gray-200 focus-visible:outline-none w-full"
                    placeholder="Account Number"
                    {...register("account_no", {
                      required: "This field is required",
                      message: "Invalid Account",
                    })}
                  />
                </div>
                {errors.account_no && (
                  <p className="text-red-400 text-xs pl-[125px]">
                    I{errors.account_no.message}
                  </p>
                )}
              </div>
              <div>
                <div className="flex gap-x-2 w-full items-center pt-5">
                  <p className="text-lg w-1/5">Salaries:</p>
                  <input
                    className="border-2 ps-2 py-1 rounded-s-md border-gray-200 focus-visible:outline-none w-full"
                    placeholder="Salary"
                    {...register("salary_basic", {
                      required: "This field is required",
                      message: "This field is required *",
                    })}
                  />
                </div>
                {errors.salary_basic && (
                  <p className="text-red-400 text-xs pl-[125px]">
                    {errors.salary_basic.message}
                  </p>
                )}
              </div>

              <div>
                <div className="flex gap-x-2 w-full items-center pt-5">
                  <p className="text-lg w-1/5">Joining Date:</p>
                  <Calendar
                    dateFormat="yy-mm-dd"
                    value={"fromDate"}
                    className="p-inputtext-sm w-full date"
                    placeholder="JoinIng Date"
                    {...register("joining_date", {
                      required: "This field is required",
                      message: "This field is required*",
                    })}
                  />
                </div>
                {errors.joining_date && (
                  <p className="text-red-400 text-xs pl-[125px]">
                    {errors.joining_date.message}
                  </p>
                )}
              </div>

              <input
                type="submit"
                className="cursor-pointer mt-10 py-2 px-6 rounded-md bg-cyan-700  text-white font-medium text-sm"
              />
            </div>

            <div>
              <div>
                <div className="flex gap-x-2 w-full items-center">
                  <p className="text-lg w-1/5">password:</p>
                  <input
                    className="border-2 ps-2 py-1 rounded-s-md border-gray-200 focus-visible:outline-none w-full"
                    placeholder="Password"
                    type="text"
                    {...register("password", {
                      required: "This field is required",
                      pattern: {
                        value:
                          /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,20}$/,
                        message:
                          "password between 7 to 15 characters which contain at least one numeric digit and a special character",
                      },
                    })}
                  />
                </div>
                {errors.password && (
                  <p className="text-red-400 text-xs pl-[125px]">
                    {errors?.password?.message}
                  </p>
                )}
              </div>

              <div className="flex gap-x-2 w-full items-center pt-5">
                <p className="text-lg w-1/5">Designation:</p>
                <Select
                  className="basic-single w-full"
                  classNamePrefix="select"
                  isClearable={isClearable}
                  isSearchable={isSearchable}
                  options={designationType}
                  onChange={DesignationName}
                  required
                />
              </div>

              <div>
                <div className="flex gap-x-2 w-full items-center pt-5">
                  <p className="text-lg w-1/5">Tin No:</p>
                  <input
                    className="border-2 ps-2 py-1 rounded-s-md border-gray-200 focus-visible:outline-none w-full"
                    placeholder="Tin Number"
                    {...register("tin", {
                      required: "This field is required",
                      pattern: {
                        value: /^[0-9]{12}$/,
                        message: "Invalid Tin Number",
                      },
                    })}
                  />
                </div>
                {errors.tin && (
                  <p className="text-red-400 text-xs pl-[125px]">
                    {errors.tin.message}
                  </p>
                )}
              </div>

              <div className="flex gap-x-2 w-full items-center pt-5">
                <p className="text-lg w-1/5">Role:</p>
                <Select
                  className="basic-single w-full"
                  classNamePrefix="select"
                  isClearable={isClearable}
                  isSearchable={isSearchable}
                  options={RoleName}
                  onChange={Role}
                  required
                />
              </div>
            </div>
          </div>
        </form>
      </div>

      <div className="pt-20">
        <CreateUserProfile userId={userId} />
      </div>
    </>
  );
};

export default CreateUser;

import React, { useState } from "react";
import { useForm } from "react-hook-form";

// import { RxCross1 } from "react-icons/rx";
// import { AiOutlinePlus } from "react-icons/ai";
import { toast } from "react-toastify";
import { MainUrl } from "../../utils/helper";
import useData from "../../ContextApi/UseData/useData";
import { Dropdown } from "primereact/dropdown";
import ".././Common.css";
import { Dialog } from "primereact/dialog";

const CreateNewDepartment = ({ createData }) => {
  const { createShow, setCreateShow, update, setUpdate } = createData;
  const { token, allUser } = useData().user;
  const [selectUser, setSelectUser] = useState(null);

  // selcet modify data
  const userInfo = allUser?.map((user) => {
    let obj = {};
    obj.label = user?.user_profile?.name;
    obj.value = user?.id;
    return obj;
  });

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  const submitDeptName = (data) => {
    const postData = {
      name: data?.name,
      department_head_id: selectUser ? selectUser : null,
    };
    let CreactePostDepartment = async () => {
      const res = await fetch(`${MainUrl.url}/api/v1/department`, {
        method: "post",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
        body: JSON.stringify(postData),
      });
      const resData = await res.json();
      if (res.ok) {
        setUpdate(!update);
        toast.success("successfully updated");
        setCreateShow(!createShow);
        setSelectUser(null);
        reset();
      } else {
        toast.error(resData.message);
        setCreateShow(!createShow);
        reset();
        setSelectUser(null);
      }
    };
    CreactePostDepartment();
  };

  return (
    <div>
      <Dialog
        visible={createShow}
        header="Add New Department"
        onHide={() => {
          setCreateShow(!createShow);
          setSelectUser(null);
        }}
        style={{ width: '30vw' }}
        breakpoints={{ "960px": "50vw", "641px": "100vw" }}
        draggable={false}
        resizable={false}
      >
        <div>
          <form
            action=""
            onSubmit={handleSubmit(submitDeptName)}
            className="common pt-2"
          >
            <label htmlFor="" className="text-small ps-2 pb-1">
              Department
            </label>
            <input
              type="text"
              className="w-full py-1 pl-3 mb-2 border rounded-md focus:outline-none text-small "
              placeholder="Department Name"
              {...register("name", { required: true })}
            />
            {errors.name && (
              <p className="text-red-400 pl-0.5 text-[12px] ">
                This field is required *
              </p>
            )}

            <div className="flex flex-col mb-2 ">
              <label className="ps-2 pb-1">Select Employee</label>
              <Dropdown
                value={selectUser}
                onChange={(e) => setSelectUser(e.value)}
                options={userInfo}
                optionLabel="label"
                placeholder="Select a User"
                filter
                showClear
                className="w-full md:w-14rem"
              />
            </div>
            <div className="flex justify-between">
              <button className=" bg-cyan-700 px-4 py-2 text-white rounded">
                Submit
              </button>
            </div>
          </form>
        </div>
      </Dialog>
    </div>

    
  );
};

export default CreateNewDepartment;

import React, { useState } from "react";
import { toast } from "react-toastify";
import { MainUrl } from "../../utils/helper";
import useData from "../../ContextApi/UseData/useData";
import { Dialog } from "primereact/dialog";

const UpdateDesignation = ({ data }) => {
  const {
    update,
    setUpdate,
    showUpdateModal,
    setShowUpdateModal,
    updateModalData,
  } = data;
  const [designation, setDesignation] = useState();
  const { token } = useData().user;

  const handleSubmit = (e) => {
    e.preventDefault();

    const name = designation || updateModalData?.name;
    const formData = {
      name: name,
    };

    let updateNote = async () => {
      const res = await fetch(
        `${MainUrl.url}/api/v1/user/designation/${updateModalData?.id}`,
        {
          method: "Put",
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
          body: JSON.stringify(formData),
        }
      );
      const resData = await res.json();
      if (res.ok) {
        setUpdate(!update);
        toast.success("successfully updated");
        setShowUpdateModal(!showUpdateModal);
      } else {
        toast.error(resData.message);
        setShowUpdateModal(!showUpdateModal);
      }
    };

    updateNote();
  };

  return (
    <div>
      <Dialog
        visible={showUpdateModal}
        header="Update Designation"
        onHide={() => setShowUpdateModal(!showUpdateModal)}
        style={{ width: "50vw" }}
        breakpoints={{ "960px": "75vw", "641px": "100vw" }}
      >
        {/* <h5 className="text-xl border-b py-3"></h5> */}

        <div>
          <form onSubmit={handleSubmit} className="pt-4">
            <div className="flex w-full ">
              <input
                onChange={(e) => setDesignation(e.target.value)}
                type="text"
                className="border-2 ps-2 py-1 rounded-s-md border-gray-200 focus-visible:outline-none w-4/5"
                defaultValue={updateModalData?.name}
                name="type"
              />
              <input
                className="cursor-pointer px-4 rounded-e-md bg-cyan-700  text-white font-medium text-sm w-1/5"
                type="submit"
                value="Submit"
              />
            </div>
          </form>
        </div>
      </Dialog>
    </div>
  );
};

export default UpdateDesignation;

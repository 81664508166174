import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import React, { useEffect, useState } from "react";
import { AiFillEdit, AiOutlineDelete, AiOutlinePlus } from "react-icons/ai";
import { IoIosRefresh } from "react-icons/io";
import { toast } from "react-toastify";
import CreateIncentive from "./CreateIncentive";
import UpDateIncentive from "./UpDateIncentive";
import useData from "../../ContextApi/UseData/useData";
import { MainUrl, showDummyImage } from "../../utils/helper";
import Loader from "../Loader/Loader";
import DeleteModal from "../AllEmployee/DeleteModal/DeleteModal";
import { useLocation, useSearchParams } from "react-router-dom";
import Pagination from "../Pagination/Pagination";

const AllIncentive = () => {
  const { token, allUser } = useData().user;
  const [allIncentive, setAllIncentive] = useState(null);
  const [update, setUpdate] = useState(false);
  const [totalData, setTotalData] = useState(null);
  const [newIncentive, setNewIncentive] = useState(false);
  const [showUpdate, setShowUpdate] = useState(false);
  const [UpdateData, setUpdateData] = useState([]);
  const [isLoader, setIsLoader] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [selectData, setSelectData] = useState(null);
  const [filterData, setFilterData] = useState({
    name: "",
    office_id: "",
  });
  const [changeValue, setChangeValue] = useState({});
  const [viewData, setViewData] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchParams, setSearchParams] = useSearchParams();
  const [pageCount, setPageCount] = useState();
  const location = useLocation();

  // get dataTable Value
  useEffect(() => {
    if (!token) {
      return;
    }
    const page = location?.search?.split("=")[1];
    if (page) {
      setPageNumber(+page);
      setCurrentPage(+page);
    }
    const fetchData = async () => {
      try {
        setIsLoader(true);
        const res = await fetch(
          `${MainUrl.url}/api/v1/incentive/all/${viewData}/${pageNumber}?${
            filterData.name && `name=${filterData.name}`
          }${filterData.office_id && `&office_id=${filterData.office_id}`}`,
          {
            method: "get",
            headers: {
              Authorization: token,
            },
          }
        );
        const resData = await res.json();
        if (res.ok) {
          setAllIncentive(resData.data.results);
          setTotalData(resData.data?.totalCount);
          setIsLoader(false);
        }
      } catch (error) {
        toast.error(error?.message);
        setIsLoader(false);
      }
    };
    fetchData();
  }, [update, pageNumber, viewData, token, filterData,location, searchParams,currentPage]);

  useEffect(() => {
    const pages = Math.ceil(totalData / viewData);
    setPageCount(pages);
  }, [totalData, viewData]);

  // const handleDelete = async (id, name) => {

  //   if (!token) {
  //     return;
  //   }
  //   const agree = window.confirm(`are you sure to delete${name}`);
  //   if (agree) {
  //     try {
  //       const res = await fetch(
  //         `${MainUrl.url}/api/v1/incentive/one/${id}`,
  //         {
  //           method: "Delete",
  //           headers: {
  //             Authorization: token,
  //           },
  //         }
  //       );
  //       if (res.ok) {
  //         setUpdate(!update);
  //       } else if (res.status === 422) {
  //         const err = await res.json();
  //         toast.error(err.message);
  //       }
  //     } catch (error) {
  //       toast.error(error.message);
  //     }
  //   }
  // };

  const ActionTemplate = (data) => {
    return (
      <div className=" flex items-center gap-x-2">
        <div
          className="bg-cyan-700 h-8 w-8 flex items-center justify-center rounded-full cursor-pointer"
          onClick={() => {
            setShowUpdate(!showUpdate);
            setUpdateData(data);
          }}
        >
          <AiFillEdit className="text-white" />
        </div>
        <div
          className="bg-red-400 h-8 w-8 flex items-center justify-center rounded-full cursor-pointer"
          onClick={() => {
            setSelectData(data);
            setShowDelete(!showDelete);
          }}
        >
          <AiOutlineDelete className="text-white" />
        </div>
      </div>
    );
  };

  const EmployeeNameTemplate = (data) => {
    return (
      <div className="flex items-center gap-x-4">
        <div className="h-10 w-10  ">
          <img
            src={`${MainUrl.url}/${data?.user?.user_profile?.profile_photo}`}
            onError={({ currentTarget }, imageSize = "100x100") =>
              showDummyImage({ currentTarget }, imageSize)
            }
            alt=""
            className=" h-full w-full rounded-full"
          />
        </div>
        <h3 className="text-lg font-medium">{data.user?.user_profile?.name}</h3>
      </div>
    );
  };

  const UserIdTemplate = (data) => {
    return <p className="text-base font-medium">{data?.user?.office_id}</p>;
  };

  const EligibilityTemplate = (data) => {
    return (
      <p className="text-base font-medium">
        {data?.incentive_eligibility?.name}
      </p>
    );
  };

  const PREVBasicTemplate = (data) => {
    return <p className="text-base font-medium">{data?.previous_basic}</p>;
  };

  const PercentTemplate = (data) => {
    return (
      <p className="text-base font-medium">{data?.increment_percentage}</p>
    );
  };
  const IssueDateTemplate = (data) => {
    return <p className="text-base font-medium">{data?.issue_date}</p>;
  };

  const StatusTemplate = (data) => {
    return (
      <p className="text-base font-medium">
        {data?.completion_status === 0 ? (
          <span className="text-yellow-300">Pending</span>
        ) : (
          <span className="text-green-400">Complete</span>
        )}
      </p>
    );
  };


  const renderHeader = () => {
    const setValue = (e) => {
      setChangeValue({
        ...changeValue,
        [e?.target?.name]: e?.target?.value,
      });
    };

    const handleSearch = async (e) => {
      e.preventDefault();
      const data = {
        name: changeValue.name ? changeValue.name : "",
        office_id: changeValue.office_id ? changeValue.office_id : "",
      };
      setFilterData(data);
    };

    const handleFresh = () => {
      setFilterData({
        name: "",
        office_id: "",
      });
      setChangeValue({
        name: "",
        office_id: "",
      });
      setUpdate(!update);
    };
    return (
      <div className="">
        <div>
          <div className="grid grid-cols-12 items-center justify-between">
            <div className="col-span-8">
              <div className="grid grid-cols-12 gap-x-2 items-center">
                <div className="col-span-10">
                  <form
                    className="flex gap-x-3  w-full myInput"
                    onSubmit={handleSearch}
                  >
                    <div className="w-full">
                      <input
                        onChange={(e) => setValue(e)}
                        className="ps-3 py-1.5 border border-gray-300 rounded-lg inputShadow w-full"
                        placeholder="Search by Name"
                        value={changeValue.name}
                        name="name"
                      />
                    </div>
                    <div className="w-full">
                      <input
                        onChange={(e) => setValue(e)}
                        className="ps-3 py-1.5 border border-gray-300 rounded-lg inputShadow w-full"
                        placeholder="Search by Office Id"
                        value={changeValue.office_id}
                        name="office_id"
                      />
                    </div>

                    <div className="">
                      <input
                        type="submit"
                        value="Search"
                        className="cursor-pointer px-4 py-2 w-full bg-cyan-700 rounded-md text-white font-medium text-sm submit"
                      />
                    </div>
                  </form>
                </div>
                <div className="col-span-2 gap-x-2">
                  <div>
                    <span
                      onClick={handleFresh}
                      className="bg-cyan-700 text-white h-8 w-8 flex items-center justify-center rounded-full font-medium cursor-pointer"
                    >
                      <IoIosRefresh />
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-span-4">
              <div className="flex items-end justify-end">
                <button
                  onClick={() => setNewIncentive(!newIncentive)}
                  className="flex items-center gap-x-2  px-2 py-2.5 bg-cyan-700 text-white rounded-md"
                >
                  <span className="text-base font-semibold">
                    <AiOutlinePlus />
                  </span>
                  <span className="text-base font-medium">Incentive</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const header = renderHeader();

  const UpdateIn = {
    showUpdate,
    setShowUpdate,
    update,
    setUpdate,
    UpdateData,
    allUser,
  };

  const createIN = {
    newIncentive,
    setNewIncentive,
    update,
    setUpdate,
    allUser,
  };

  const DeleteUrl = "api/v1/incentive/one";
  const DeleteData = {
    showDelete,
    setShowDelete,
    update,
    setUpdate,
    selectData,
    DeleteUrl,
  };

  return (
    <div className="bg-white pb-4 mb-4">
      {isLoader ? (
        <Loader />
      ) : (
        <DataTable
          size="small"
          header={header}
          value={allIncentive}
          selectionMode="single"
          dataKey="id"
          tableStyle={{ Width: "100%" }}
        >
          <Column
            field="provident_fund_account?.user?.office_id"
            header="Name"
            body={EmployeeNameTemplate}
            className="w-[20%]"
          ></Column>
          <Column
            field="provident_fund_account?.user?.office_id"
            header="Office Id"
            body={UserIdTemplate}
            sortable
            className=""
          ></Column>
          <Column
            field="provident_fund_account?.user?.office_id"
            header="Eligibility"
            body={EligibilityTemplate}
            sortable
            className=""
          ></Column>
          <Column
            field="provident_fund_account?.user?.office_id"
            header="PREV Basic"
            body={PREVBasicTemplate}
            sortable
            className=""
          ></Column>
          <Column
            field="provident_fund_account?.user?.office_id"
            header="Percent"
            body={PercentTemplate}
            sortable
            className=""
          ></Column>
          <Column
            field="provident_fund_account?.user?.office_id"
            header="Issue Date"
            sortable
            body={IssueDateTemplate}
            className=""
          ></Column>
          <Column
            field="provident_fund_account?.user?.office_id"
            header="Status"
            sortable
            body={StatusTemplate}
            className=""
          ></Column>
          <Column
            field="provident_fund_account?.user?.office_id"
            header="Action"
            body={ActionTemplate}
            className="w-[5%]"
          ></Column>
        </DataTable>
      )}

      <div className="mt-4">
        {allIncentive?.length > 0 && (
          <Pagination
            currentPage={currentPage}
            pageNumber={pageNumber}
            setCurrentPage={setCurrentPage}
            setSearchParams={setSearchParams}
            pageCount={pageCount}
            setViewData={setViewData}
            viewData={viewData}
            setPageNumber={setPageNumber}
          />
        )}
      </div>

      {<UpDateIncentive UpdateIn={UpdateIn} />}
      {<CreateIncentive createIN={createIN} />}
      {<DeleteModal DeleteData={DeleteData} />}
    </div>
  );
};

export default AllIncentive;

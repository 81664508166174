import React from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import Rodal from "rodal";
import { MainUrl } from "../../utils/helper";
import useData from "../../ContextApi/UseData/useData";

const CreateModal = ({ modaldata }) => {
  const {
    setShow,
    show,
    update,
    setUpdate,
    CreateURL,
    createTham,
    CreatePlaceholder,
    successMessage,
  } = modaldata;
  const { token } = useData().user;
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  const onSubmit = (data) => {
    if (!token) {
      return;
    }
    const postModalData = async () => {
      const res = await fetch(`${MainUrl.url}/api/v1/${CreateURL}`, {
        method: "Post",
        headers: {
          "Content-type": "application/json",
          Authorization: token,
        },
        body: JSON.stringify(data),
      });
      const resData = await res.json();
      if (res.ok) {
        toast.success(successMessage);
        setUpdate(!update);
        setShow(!show);
        reset();
      } else {
        toast.error(resData.message);
        setShow(!show);
      }
    };
    postModalData();
  };
  return (
    <div>
      <Rodal
        visible={show}
        enterAnimation="flip"
        animation="fade"
        onClose={() => setShow(!show)}
        customStyles={{
          height: "auto",
          width: "40%",
          bottom: "auto",
          top: "10%",
          transform: "translateY(-50%)",
        }}
      >
        <h5 className="text-xl border-b py-3">{createTham}</h5>

        <div>
          <form onSubmit={handleSubmit(onSubmit)} className="pt-4">
            <div className="flex w-full ">
              <input
                type="text"
                className="border-2 ps-2 py-1 rounded-s-md border-gray-200 focus-visible:outline-none w-4/5"
                placeholder={CreatePlaceholder}
                {...register(
                  "name",
                  {
                    required: true,
                    message: "This field is Required",
                    minLength: {
                      value: 5,
                      message: "provide Name in minimum 5 letter",
                    },
                    maxLength: {
                      value: 50,
                      message: "provide Name in  maximum 50 letter",
                    },
                  },
                  {
                    pattern: /^[a-zA-Z]+( [a-zA-Z]+)*$/,
                    message: "provide just text not any number",
                  }
                )}
              />

              <input
                className="cursor-pointer px-4 rounded-e-md bg-cyan-700  text-white font-medium text-sm w-1/5"
                type="submit"
                value="Submit"
              />
            </div>

            {errors?.name?.type === "minLength" && (
              <p className="text-red-400 text-base pl-[15px]">
                {errors?.name?.message}
              </p>
            )}

            {errors?.name?.type === "maxLength" && (
              <p className="text-red-400 text-xs pl-[15px]">
                {errors?.name?.message}
              </p>
            )}

            {errors?.name?.type === "pattern" && (
              <p className="text-red-400 text-xs pl-[15px]">
                {errors?.name?.message}
              </p>
            )}
          </form>
        </div>
      </Rodal>
    </div>
  );
};

export default CreateModal;

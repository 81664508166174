import { Menu, MenuButton, MenuItem } from "@szhsin/react-menu";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import React, { useEffect, useState } from "react";

import {
  AiOutlineCheck,
  AiOutlineClose,
  AiOutlineDelete,
  AiOutlineProfile,
} from "react-icons/ai";
import { BsThreeDotsVertical } from "react-icons/bs";
import { IoIosRefresh } from "react-icons/io";
import { toast } from "react-toastify";
import { Image } from "primereact/image";
import Details from "./Details";
import { MainUrl } from "../../utils/helper";
import useData from "../../ContextApi/UseData/useData";
import DeleteModal from "../AllEmployee/DeleteModal/DeleteModal";
import { useLocation, useSearchParams } from "react-router-dom";
import Pagination from "../Pagination/Pagination";
import Loader from "../Loader/Loader";

const ProjectProposal = () => {
  const { token } = useData().user;
  const [expandedRows, setExpandedRows] = useState([]);
  const [proposal, setProposal] = useState(null);
  const [update, setUpdate] = useState(false);
  const [totalData, setTotalData] = useState(null);
  const [showDetails, setShowDetails] = useState(false);
  const [details, setDetails] = useState(null);
  const [changeValue, setChangeValue] = useState({});
  const [showDelete, setShowDelete] = useState(false);
  const [selectData, setSelectData] = useState(null);
  const [filterData, setFilterData] = useState({
    project_title: "",
  });
  const [isLoader, setIsLoader] = useState(false);
  const [viewData, setViewData] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchParams, setSearchParams] = useSearchParams();
  const [pageCount, setPageCount] = useState();
  const location = useLocation();

  // GET TABLE DATA
  useEffect(() => {
    if (!token) {
      return;
    }

    const page = location?.search?.split("=")[1];
    if (page) {
      setPageNumber(+page);
      setCurrentPage(+page);
    }

    const fetchData = async () => {
      try {
        setIsLoader(true);
        const res = await fetch(
          `${MainUrl.url}/api/v1/project/${viewData}/${pageNumber}?${
            filterData.project_title &&
            `project_title=${filterData.project_title}`
          }`,
          {
            method: "get",
            headers: {
              Authorization: token,
            },
          }
        );
        const data = await res.json();
        if (res.ok) {
          setProposal(data.data?.result);
          setTotalData(data.data?.totalCount);
          setIsLoader(false);
        }
      } catch (error) {
        toast.error(error?.message);
        setIsLoader(false);
      }
    };
    fetchData();
  }, [
    update,
    token,
    viewData,
    pageNumber,
    filterData,
    location,
    currentPage,
    searchParams,
  ]);

  useEffect(() => {
    const pages = Math.ceil(totalData / viewData);
    setPageCount(pages);
  }, [totalData, viewData]);

  const allowExpansion = (rowData) => {
    return rowData;
  };

  const onRowToggle = (event) => {
    setExpandedRows(event.data);
  };

  // const handleDelete = async (id, name) => {
  //   if(!token){
  //     return
  //   }
  //   const agree = window.confirm(`are you sure to delete ${id} and ${name}`);
  //   if (agree) {
  //     try {
  //       const res = await fetch(
  //         `${MainUrl.url}/api/v1/project/proposal/${id}`,
  //         { method: "delete",
  //         headers:{
  //           "Authorization":token
  //         }

  //       }
  //       );

  //       if (res.ok) {
  //         toast.success("successfully deleted");
  //         setUpdate(!update);
  //       } else {
  //         const data = await res.json();
  //         toast.error(data?.message);
  //       }
  //     } catch (error) {
  //       toast.error(error.message);
  //     }
  //   }
  // };

  // Approval Status Update

  const handleStatusUpdate = (id, data) => {
    if (!token) {
      return;
    }
    const approval_status = data;

    const formData = {
      approval_status,
    };

    const statusUpdate = async () => {
      try {
        const res = await fetch(`${MainUrl.url}/api/v1/project/approve/${id}`, {
          method: "put",
          headers: {
            "Content-type": "application/json",
            Authorization: token,
          },
          body: JSON.stringify(formData),
        });
        if (res.ok) {
          toast.success("Successfully updated Approve Status");
          setUpdate(!update);
        }
      } catch (error) {
        toast.error(error.message);
      }
    };
    statusUpdate();
  };

  //  Table Column template start

  const ProjectTitleTemplate = (data) => {
    return <p className="text-base ">{data?.project_title}</p>;
  };

  const ClientNameTemplate = (data) => {
    return (
      <p className=" text-base">
        {data?.client?.name ? data?.client?.name : "N/A"}
      </p>
    );
  };

  const ProjectDurationTemplate = (data) => {
    return <p className=" text-base">{data?.proposed_duration} Year</p>;
  };

  const DeadlineTemplate = (data) => {
    return <p className=" text-base">{data?.deadline}</p>;
  };

  const ApprovalStatusTemplate = (data) => {
    return (
      <p className=" text-base">
        {data?.approval_status === 0 ? (
          <span className="text-yellow-400">Pending</span>
        ) : (
          <span className="text-green-400">Approved</span>
        )}
      </p>
    );
  };

  const ActionTemplate = (data) => {
    console.log("data", data.id);
    const id = data;
    const Approval = 1;
    const pending = 0;
    return (
      <Menu
        direction="right"
        align="center"
        menuButton={
          <MenuButton>
            <BsThreeDotsVertical />
          </MenuButton>
        }
        transition
      >
        {/* pore uncomment kore kaj korbo */}
        {/* {data.approval_status === 0 && (
          <MenuItem className="hover:text-red-400">
            <Link
              className="flex justify-between items-center"
              to={`/update-project-proposal/${data?.id}`}
            >
              <span className="mr-2">
                <AiFillEdit />
              </span>
              Update
            </Link>
          </MenuItem>
        )} */}
        <MenuItem
          className="hover:text-red-400"
          onClick={() => {
            setSelectData(data);
            setShowDelete(!showDelete);
          }}
        >
          <span>
            <AiOutlineDelete className="mr-2" />
          </span>
          Delete
        </MenuItem>

        {data?.approval_status === 1 ? (
          <MenuItem
            className="hover:text-red-400"
            onClick={() => handleStatusUpdate(id, pending)}
          >
            <span>
              <AiOutlineClose className="mr-2" />
            </span>
            Decline
          </MenuItem>
        ) : (
          <MenuItem
            className="hover:text-red-400"
            onClick={() => handleStatusUpdate(id, Approval)}
          >
            <span>
              <AiOutlineCheck className="mr-2" />
            </span>
            Approve
          </MenuItem>
        )}

        <MenuItem
          className="hover:text-red-400"
          onClick={() => {
            setShowDetails(!showDetails);
            setDetails(data);
          }}
        >
          <span>
            <AiOutlineProfile className="mr-2" />
          </span>
          Details
        </MenuItem>
      </Menu>
    );
  };

  const OperationCastTemplate = (data) => {
    return <p className=" text-base">{data.opex}</p>;
  };
  const CapitalcastTemplate = (data) => {
    return <p className=" text-base">{data.capex}</p>;
  };

  const EstimatedTemplate = (data) => {
    return <p className=" text-base">{data.estimated_profit}</p>;
  };

  const BreakdownTemplate = (data) => {
    return (
      <div className="z-[100] ">
        <Image
          src={`${MainUrl.url}/${data?.cost_breakdown}`}
          zoomSrc={`${MainUrl.url}${data?.cost_breakdown}`}
          alt="Image"
          width="30"
          height="30"
          preview
          className=""
        />
      </div>
    );
  };

  //  Table Column template end

  const renderHeader = () => {
    const setValue = (e) => {
      setChangeValue({
        ...changeValue,
        [e.target?.name]: e.target.value,
      });
    };

    const handleSearch = async (e) => {
      e.preventDefault();
      const data = {
        project_title: changeValue.project_title
          ? changeValue.project_title
          : "",
      };
      setFilterData(data);
    };

    const handleFresh = () => {
      setFilterData({
        project_title: "",
      });
      setChangeValue({
        project_title: "",
      });
      setUpdate(!update);
    };
    return (
      <div>
        <form onSubmit={handleSearch} className="flex justify-between">
          <div>
            <input
              onChange={(e) => setValue(e)}
              className="ps-3 py-1.5 border border-gray-300 rounded-lg inputShadow w-full"
              placeholder="Search by Name"
              value={changeValue.project_title}
              name="project_title"
            />
          </div>

          <div className="flex justify-between items-center gap-x-4">
            <button className="px-4 py-2 bg-cyan-700 rounded text-white">
              Search
            </button>

            <div>
              <span
                onClick={handleFresh}
                className="bg-cyan-700 text-white h-8 w-8 flex items-center justify-center rounded-full font-medium cursor-pointer"
              >
                <IoIosRefresh />
              </span>
            </div>
          </div>
        </form>
      </div>
    );
  };
  const header = renderHeader();

  const rowExpansionTemplate1 = (data) => {
    let createArrey = [];
    createArrey.push(data);
    return (
      <div className="px-10">
        <DataTable
          size={"small"}
          value={createArrey}
          className=" text-black w-full"
        >
          <Column
            field=""
            header="Operational Cost"
            body={OperationCastTemplate}
            className=""
          ></Column>
          <Column
            field=""
            header="Capital Cost"
            body={CapitalcastTemplate}
            className=""
          ></Column>
          <Column
            field=""
            header="Total Estimated Cost"
            body={EstimatedTemplate}
            className=""
          ></Column>
          <Column
            field=""
            header="Cost Breakdown"
            body={BreakdownTemplate}
            className=""
          ></Column>
        </DataTable>
      </div>
    );
  };

  const detailsData = {
    showDetails,
    setShowDetails,
    details,
  };
  const DeleteUrl = "api/v1/project_proposal/one";
  const DeleteData = {
    showDelete,
    setShowDelete,
    update,
    setUpdate,
    selectData,
    DeleteUrl,
  };

  return (
    <div className="bg-white pb-4 mb-4">
      {isLoader ? (
        <Loader />
      ) : (
        <DataTable
          size="small"
          header={header}
          value={proposal}
          // footer={footerTemplate}
          expandedRows={expandedRows}
          onRowToggle={onRowToggle}
          rowExpansionTemplate={rowExpansionTemplate1}
          selectionMode="single"
          dataKey="id"
          tableStyle={{ Width: "100%" }}
        >
          <Column expander={allowExpansion}></Column>
          <Column
            field=""
            header="Project Title"
            body={ProjectTitleTemplate}
            className=""
          ></Column>
          <Column
            field=""
            header="Client Name"
            body={ClientNameTemplate}
            className=""
          ></Column>
          <Column
            field=""
            header="Project Duration"
            body={ProjectDurationTemplate}
            className=""
          ></Column>

          <Column
            field="deadline"
            header="Deadline"
            sortable
            body={DeadlineTemplate}
            className=""
          ></Column>

          <Column
            field="approval_status"
            header="Approval"
            body={ApprovalStatusTemplate}
            sortable
            className=""
          ></Column>
          <Column
            field=""
            header="Action"
            className="w-[5%] "
            body={ActionTemplate}
          ></Column>
        </DataTable>
      )}

      <div className="mt-4">
        {proposal?.length > 0 && (
          <Pagination
            currentPage={currentPage}
            pageNumber={pageNumber}
            setCurrentPage={setCurrentPage}
            setSearchParams={setSearchParams}
            pageCount={pageCount}
            setViewData={setViewData}
            viewData={viewData}
            setPageNumber={setPageNumber}
          />
        )}
      </div>

      {<Details detailsData={detailsData} />}
      {<DeleteModal DeleteData={DeleteData} />}
    </div>
  );
};

export default ProjectProposal;

import React from "react";
import OverTimePolicy from "../OverTimePolicy/OverTimePolicy";
import Breadcrumbs from "../Breadcrumbs/Breadcrumbs";

const OverTimePolicyPage = () => {
  const data = [
    {
      name: "Over Time Policy",
    },
  ];
  return (
    <>
      <Breadcrumbs data={data} />
      <OverTimePolicy />
    </>
  );
};

export default OverTimePolicyPage;

import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import React, { useEffect, useState } from "react";
import { AiFillEdit, AiOutlineDelete, AiOutlinePlus } from "react-icons/ai";
import CreateSubDepartment from "./CreateSubDepartment";
import { MainUrl } from "../../utils/helper";
import useData from "../../ContextApi/UseData/useData";
import UpDateSubDepartment from "./UpDateSubDepartment";
import Loader from "../Loader/Loader";
import DeleteModal from "../AllEmployee/DeleteModal/DeleteModal";

const SubDepartment = () => {
  const [update, setUpdate] = useState(false);
  const [show, setShow] = useState(false);
  const [showUpdate, setShowUpdate] = useState(false);
  const [selectedData, setSelectedData] = useState("");
  const [allDepartment, setAllDepartment] = useState();
  const [isLoader, setIsLoader] = useState(false);
  const [selectData, setSelectDate] = useState(null)
  const [showDelete, setShowDelete] = useState(false)
  const { token } = useData().user;


  // get sub department details
  useEffect(() => {
    if (!token) {
      return;
    }
    const fetchData = async () => {
      setIsLoader(true);
      const res = await fetch(`${MainUrl.url}/api/v1/sub_department`, {
        method: "Get",
        headers: {
          Authorization: token,
        },
      });
      const data = await res.json();
      if (res.ok) {
        setAllDepartment(data?.data);

        setIsLoader(false);
      }
    };
    fetchData();
  }, [update, token]);

  const SubDepartmentNameTemplate = (data) => {
    return (
      <div className="flex items-center gap-x-4">
        <h3 className="text-base">{data?.name ? data?.name : "TBA"}</h3>
      </div>
    );
  };

  const subDepartmentParentNameTemplate = (data) => {
    return (
      <div className="flex items-center gap-x-4">
        <h3 className="text-base">
          {data.parent_department ? data?.parent_department?.name : "TBA"}
        </h3>
      </div>
    );
  };

  const SubDepartmentEmployee = (data) => {
    return (
      <p className="text-base">
        {data.total_employees ? data?.total_employees : "N/A"}
      </p>
    );
  };

  const ActionTemplate = (data) => {
    return (
      <div className=" flex items-center gap-x-2">
        <div
          className="bg-cyan-700 h-8 w-8 flex items-center justify-center rounded-full cursor-pointer"
          onClick={() => {
            setSelectedData(data);
            setShowUpdate(!showUpdate);
          }}
        >
          <AiFillEdit className="text-white" />
        </div>
        <div
          className="bg-red-400 h-8 w-8 flex items-center justify-center rounded-full cursor-pointer"
          onClick={() => {
            setSelectDate(data);
            setShowDelete(!showDelete)
          }}
        >
          <AiOutlineDelete className="text-white" />
        </div>
      </div>
    );
  };


  const renderHeader = () => {
    return (
      <div className="">
        <div className="flex items-end justify-end w-full">
          <button
            onClick={() => setShow(!show)}
            className="flex items-center gap-x-2  px-2 py-2.5 bg-cyan-700 text-white rounded-md"
          >
            <span className="text-base font-semibold">
              <AiOutlinePlus />
            </span>
            <span className="text-base font-medium">Sub-Department</span>
          </button>
        </div>
      </div>
    );
  };

  const header = renderHeader();
  const CreateModal = {
    show,
    setShow,
    update,
    setUpdate,
  };

  const UpdateModal = {
    selectedData,
    showUpdate,
    setShowUpdate,
    update,
    setUpdate,
  };

  const DeleteUrl = "/api/v1/sub_department/one";
  const DeleteData = {
    showDelete,
    setShowDelete,
    update,
    setUpdate,
    selectData,
    DeleteUrl,
  };

  return (
    <div className="overflow-auto">
      {isLoader ? (
        <Loader />
      ) : (
        <DataTable
          size={"small"}
          value={allDepartment}
          header={header}
          dataKey="id"
          paginator
          rows={5}
          rowsPerPageOptions={[5, 10, 25, 50]}
          // footer={footerTemplate}
          emptyMessage="No employees found."
          style={{ minWidth: "50rem" }}
        >
          <Column
            field=""
            body={SubDepartmentNameTemplate}
            header="Sub-Department Name"
          ></Column>
          <Column
            field=""
            body={subDepartmentParentNameTemplate}
            header="Department"
          ></Column>
          <Column
            field=""
            body={SubDepartmentEmployee}
            header="Total Employee"
          ></Column>
          <Column field="" body={ActionTemplate} header="Action"></Column>
        </DataTable>
      )}

      {<CreateSubDepartment CreateModal={CreateModal} />}
      {<UpDateSubDepartment UpdateModal={UpdateModal} />}
      <DeleteModal DeleteData={DeleteData}/>
    </div>
  );
};

export default SubDepartment;

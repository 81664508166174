import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { AiFillEdit, AiOutlineDelete, AiOutlinePlus } from "react-icons/ai";
import CreateDesignation from "./CreateDesignation";
import UpdateDesignation from "./UpdateDesignation";
import { MainUrl } from "../../utils/helper";
import useData from "../../ContextApi/UseData/useData";
import Loader from "../Loader/Loader";
import DeleteModal from "../AllEmployee/DeleteModal/DeleteModal";

const Designations = () => {
  const { token } = useData().user;
  const [update, setUpdate] = useState(false);
  const [designation, setDesignation] = useState(null);
  const [show, setShow] = useState(false);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [updateModalData, setUpdateModalData] = useState();
  const [isLoader, setIsLoader] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [selectData, setSelectData] = useState(null);

  useEffect(() => {
    if (!token) {
      return;
    }
    const fetchData = async () => {
      setIsLoader(true);
      const res = await fetch(`${MainUrl.url}/api/v1/user/designations`, {
        method: "get",
        headers: {
          Authorization: token,
        },
      });

      const data = await res.json();
      if (res.ok) {
        setDesignation(data.data);
        setIsLoader(false);
      } else {
        toast.error(data.message);
        console.error(data.message);
        setIsLoader(false);
      }
    };
    fetchData();
  }, [update, token]);

  const ActionTemplate = (data) => {
    return (
      <div className=" flex items-center gap-x-2">
        <div
          className="bg-cyan-700 h-8 w-8 flex items-center justify-center rounded-full cursor-pointer"
          onClick={() => {
            setShowUpdateModal(!showUpdateModal);
            setUpdateModalData(data);
          }}
        >
          <AiFillEdit className="text-white" />
        </div>
        <div
          className="bg-red-400 h-8 w-8 flex items-center justify-center rounded-full cursor-pointer"
          onClick={() => {
            setSelectData(data);
            setShowDelete(!showDelete);
          }}
        >
          <AiOutlineDelete className="text-white" />
        </div>
      </div>
    );
  };

  const NameTemplate = (data) => {
    return <p className="text-base">{data?.name}</p>;
  };

  const renderHeader = () => {
    return (
      <div className="flex justify-end">
        <div className="pt-2">
          <button
            onClick={() => setShow(!show)}
            className="flex items-center gap-x-2  px-2 py-2.5 bg-cyan-700 text-white rounded-md"
          >
            <span className="text-base">
              <AiOutlinePlus />
            </span>{" "}
            <span className="text-base font-medium">User Designations</span>{" "}
          </button>
        </div>
      </div>
    );
  };
  const header = renderHeader();

  const modalData = {
    show,
    setShow,
    update,
    setUpdate,
  };
  const data = {
    update,
    setUpdate,
    showUpdateModal,
    setShowUpdateModal,
    updateModalData,
  };

  const DeleteUrl = "api/v1/user/designation";
  const DeleteData = {
    showDelete,
    setShowDelete,
    update,
    setUpdate,
    selectData,
    DeleteUrl,
  };

  return (
    <div>
      {isLoader ? (
        <Loader />
      ) : (
        <DataTable
          header={header}
          value={designation}
          paginator
          rows={5}
          rowsPerPageOptions={[5, 10, 25, 50]}
          selectionMode="single"
          size={"small"}
          dataKey="id"
          tableStyle={{ Width: "100%" }}
        >
          <Column
            field="Name"
            body={NameTemplate}
            header="Designation Name"
          ></Column>
          <Column
            field="Name"
            body={ActionTemplate}
            className="w-[20%]"
            header="Action"
          ></Column>
        </DataTable>
      )}
      {<CreateDesignation modalData={modalData} />}
      {<UpdateDesignation data={data} />}
      {<DeleteModal DeleteData={DeleteData} />}
    </div>
  );
};

export default Designations;

import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import React, { useState } from "react";
import { MainUrl } from "../../utils/helper";
import useData from "../../ContextApi/UseData/useData";
import { toast } from "react-toastify";

const UpdateModal = ({ updateMod }) => {
  const { updateModal, setUpdateModal, updateData, update, setUpdate } =
    updateMod;
  console.log("updateData", updateData);
  const [changePer, setChangePer] = useState(null);
  const { token } = useData().user;

  console.log("change", changePer);

  const handleUpdateLeavePolicy = (e) => {
    e.preventDefault();

    const formData = {
      bonus_type_id: updateData.bonus_type.id,
      percentage: changePer ? changePer : updateData.percentage,
    };

    let updateNote = async () => {
      const res = await fetch(
        `${MainUrl.url}/api/v1/bonus/policies/${updateData?.id}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
          body: JSON.stringify(formData),
        }
      );
      const resData = await res.json();
      if (res.ok) {
        toast.success("successfully updated");
        setUpdateModal(!updateData);
        setUpdate(!update);
      } else {
        setUpdateModal(!updateData);
        toast.error(resData.message);
      }
    };
    updateNote();
  };

  return (
    <Dialog
      header="Update Bonus Policy"
      visible={updateModal}
      onHide={() => setUpdateModal(!updateData)}
      style={{ width: "30vw" }}
      breakpoints={{ "960px": "75vw", "641px": "100vw" }}
    >
      <form className="common" onSubmit={handleUpdateLeavePolicy}>
        <InputText
          className="w-full"
          disabled
          placeholder="Disabled"
          value={updateData?.bonus_type?.name}
        />
        <div className="flex flex-col pt-2">
          <label htmlFor="Percentage" className="ps-1 pb-1">
            Percentage
          </label>
          <InputText
            type="number"
            className="p-inputtext-sm"
            placeholder="Percentage"
            defaultValue={updateData?.percentage}
            onChange={(e) => setChangePer(e.target.value)}
          />
        </div>

        <input
          className="cursor-pointer px-4 py-2 mt-4 bg-cyan-700 rounded-md text-white font-medium text-sm"
          type="submit"
          value="Submit"
        />
      </form>
    </Dialog>
  );
};

export default UpdateModal;

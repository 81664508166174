import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import React, { useEffect, useState } from "react";
import { MainUrl } from "../../utils/helper";
import useData from "../../ContextApi/UseData/useData";
import { toast } from "react-toastify";

const CreateTransportPolicy = ({ CreateData }) => {
  const { showModal, setShowModal, update, setUpdate,     selectData,
    setSelectData } =
    CreateData;
  const { token } = useData().user;
  const [allDesignation, setAllDesignation] = useState(null);
  const [selectDesignation, setSelectDesignation] = useState(null);
  const [transportType, setTransportTypes] = useState(null);
  const [selectTransportType, setSelectTransportType] = useState(null);
  const [selectTravelType, setSelectTravelType] = useState(null);
  const [amount, setAmount] = useState("");

  console.log("selectData", selectData);

  console.log("selectDesignation", selectDesignation?.id);

  // get designations
  useEffect(() => {
    const fetchData = async () => {
      const res = await fetch(`${MainUrl.url}/api/v1/user/designations`, {
        method: "get",
        headers: {
          Authorization: token,
        },
      });
      const resData = await res.json();
      if (res.ok) {
        setAllDesignation(resData.data);
      }
    };

    fetchData();
  }, [token]);

  // get Transport Types
  useEffect(() => {
    if (!token) {
      return;
    }
    const fetchData = async () => {
      try {
        const res = await fetch(`${MainUrl.url}/api/v1/transport/types`, {
          method: "Get",
          headers: {
            Authorization: token,
          },
        });
        const resData = await res.json();
        if (res.ok) {
          setTransportTypes(resData.data);
        }
      } catch (error) {
        toast.error(error.message);
      }
    };
    fetchData();
  }, [update, token]);

  const TravelTypes = [
    {
      id: 0,
      name: "In City",
    },
    {
      id: 1,
      name: "Out City",
    },
  ];

  const handlePostData = (e) => {
    e.preventDefault();
    const form = e.target;
    const data = {
      user_designation_id:
        selectDesignation?.id || selectData.user_designation.id,
      transport_type_id:
        selectTransportType?.id || selectData.transport_type.id,
      in_city: selectTravelType?.id || selectData.in_city,
      allowance_per_km: amount || selectData.allowance_per_km,
    };
    const postData = async () => {
      if (selectData.id) {
        let res = await fetch(
          `${MainUrl.url}/api/v1//transport/policy/${selectData.id}`,
          {
            method: "put",
            headers: {
              "Content-type": "application/json",
              Authorization: token,
            },
            body: JSON.stringify(data),
          }
        );
        if (res.ok) {
          toast.success("successfully updated");
          setShowModal(!showModal);
          setUpdate(!update);
          setSelectData(null)
          form.reset();
        } else {
          const resData = await res.json();
          toast.error(resData.message);
          setShowModal(!showModal);
          form.reset();
        }
      } else {
        let res = await fetch(`${MainUrl.url}/api/v1/transport/policies`, {
          method: "post",
          headers: {
            "Content-type": "application/json",
            Authorization: token,
          },
          body: JSON.stringify(data),
        });
        if (res.ok) {
          toast.success("successfully updated");
          setShowModal(!showModal);
          setUpdate(!update);
          form.reset();
        } else {
          const resData = await res.json();
          toast.error(resData.message);
          setShowModal(!showModal);
          form.reset();
        }
      }
    };
    postData();
  };

  return (
    <>
      <Dialog
        header="Create Transport Allowance"
        visible={showModal}
        onHide={() => {
          setShowModal(!showModal)
          setSelectData(null)
        }}
        style={{ width: "30vw" }}
        breakpoints={{ "960px": "75vw", "641px": "100vw" }}
        draggable={false}
        resizable={false}
      >
        <form onSubmit={handlePostData} className="common">
          <div className="flex flex-col mb-2">
            <label className="pb-1 ps-1"> Select Designation</label>
            <div>
              <Dropdown
                value={selectDesignation}
                onChange={(e) => setSelectDesignation(e.value)}
                options={allDesignation}
                optionLabel="name"
                placeholder={
                  selectData?.user_designation.name
                    ? selectData?.user_designation?.name
                    : "Select Designation"
                }
                filter
                showClear
                className="w-full md:w-14rem"
              />
            </div>
          </div>

          <div className="flex flex-col mb-2">
            <label className="pb-1 ps-1"> Select Transport Type</label>
            <div>
              <Dropdown
                value={selectTransportType}
                onChange={(e) => setSelectTransportType(e.value)}
                options={transportType}
                optionLabel="name"
                placeholder={
                  selectData?.transport_type?.name
                    ? selectData?.transport_type?.name
                    : "Select Transport Type"
                }
                className="w-full md:w-14rem"
                showClear
              />
            </div>
          </div>

          <div className="flex flex-col mb-2">
            <label className="pb-1 ps-1"> Select Travel Type</label>
            <div>
              <Dropdown
                value={selectTravelType}
                onChange={(e) => setSelectTravelType(e.value)}
                options={TravelTypes}
                optionLabel="name"
                // placeholder="Select a Travel Type"
                placeholder={
                  selectData?.in_city === 0
                    ? "In City"
                    : selectData?.in_city === 1
                    ? "Out City"
                    : "Select a Travel Type"
                }
                className="w-full md:w-14rem"
                showClear
              />
            </div>
          </div>

          <div className="flex flex-col mb-2">
            <label className="pb-1 ps-1"> Allowance Amount</label>
            <input
              type="text"
              className="border-2 ps-2 py-1 rounded border-gray-200 focus-visible:outline-none w-full"
              onChange={(e) => setAmount(e.target?.value)}
              defaultValue={selectData?.allowance_per_km && selectData?.allowance_per_km}
         
              placeholder="Amount"
              name="amount_bdt"
            />
          </div>
          <input
            className="cursor-pointer px-4 py-1 rounded-md bg-cyan-700  text-white text-base font-medium "
            type="submit"
            value="Submit"
          />
        </form>
      </Dialog>
    </>
  );
};

export default CreateTransportPolicy;

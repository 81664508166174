import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import Select from "react-select";
import { toast } from "react-toastify";
import "../../App.css";
import { MainUrl, showDummyImage } from "../../utils/helper";
import useData from "../../ContextApi/UseData/useData";
import { Calendar } from "primereact/calendar";
import moment from "moment";
import Loader from "../Loader/Loader";

const ProfileUpdate = () => {
  const { id } = useParams();
  const [isClearable] = useState(true);
  const [isSearchable] = useState(true);
  const [relationShip, setRelationShip] = useState(null);
  const [bloodName, setBloodName] = useState("");
  const [selectMarital, setSelectMarital] = useState("");
  const [relationId, setRelationId] = useState("");
  const [profileData, setProfileData] = useState(null);
  const [userId, setUserId] = useState("");
  const { token, allDepartment, allDesignation } = useData().user;
  const [selectDepartment, setSelectDepartment] = useState("");
  const [selectDesignation, setSelectDesignation] = useState("");
  const [joinDate, setJoinDate] = useState(null);
  const [userRoles, setUserRoles] = useState(null);
  const [role, setRole] = useState("");
  const [update, setUpdate] = useState(false);
  const [selectImg, setSelectImg] = useState(null);
  const [changeValue, setChangeValue] = useState({});
  const [image, setImage] = useState(null);
  const [activeTab, setActiveTab] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const setValue = (e) => {
    setChangeValue({
      ...changeValue,
      [e?.target?.name]: e?.target?.value,
    });
  };

  // get users data
  useEffect(() => {
    if (!token) {
      return;
    }

    const fetchData = async () => {
      try {
        setIsLoading(true);
        const res = await fetch(`${MainUrl.url}/api/v1/user/one/${id}`, {
          method: "get",
          headers: {
            Authorization: token,
          },
        });
        const data = await res.json();
        if (res.ok) {
          setProfileData(data?.data);
          setUserId(data.data?.user_profile?.user_id);
          setIsLoading(false);
        }
      } catch (err) {
        toast.error(err.message);
        setIsLoading(false);
      }
    };
    fetchData();
  }, [id, token, update]);

  // get User Role
  useEffect(() => {
    if (!token) {
      return;
    }
    const fetchData = async () => {
      try {
        const res = await fetch(`${MainUrl.url}/api/v1/user/access_roles`, {
          method: "get",
          headers: {
            Authorization: token,
          },
        });
        const resData = await res.json();
        if (res.ok) {
          setUserRoles(resData.data);
        }
      } catch (err) {
        toast.error(err.message);
      }
    };
    fetchData();
  }, [token]);

  const BloodGroup = (e) => {
    if (e?.value) {
      setBloodName(e?.value);
    }
  };

  const MaritalStatus = (e) => {
    if (e?.value) {
      setSelectMarital(e?.value);
    }
  };

  const Relation = (e) => {
    if (e?.value) {
      setRelationId(e?.value);
    }
  };

  // get emergency person
  useEffect(() => {
    if (!token) {
      return;
    }
    const fetchData = async () => {
      const res = await fetch(`${MainUrl.url}/api/v1/user/emergency`, {
        method: "get",
        headers: {
          Authorization: token,
        },
      });
      const data = await res.json();
      if (res.ok) {
        setRelationShip(data?.data);
      } else {
        toast.error(data?.message);
        console.error(data?.message);
      }
    };
    fetchData();
  }, [token]);

  const bloodGroup = [
    { label: "A+", value: "A+" },
    { label: "A-", value: "A-" },
    { label: "B+", value: "B+" },
    { label: "AB+", value: "AB+" },
    { label: "Ab-", value: "AB-" },
    { label: "O+", value: "O+" },
    { label: "O-", value: "A-" },
  ];

  const maritalStatus = [
    { label: "Single", value: "0" },
    { label: "Married", value: "1" },
  ];

  const relation = relationShip?.map((user) => {
    let obj = {};
    obj.label = user?.name;
    obj.value = user?.id;
    return obj;
  });

  const departmentName = allDepartment?.map((depart) => {
    let obj = {};
    obj.label = depart.name;
    obj.value = depart.id;
    return obj;
  });

  const designation = allDesignation?.map((desig) => {
    let obj = {};
    obj.label = desig.name;
    obj.value = desig.id;
    return obj;
  });

  const userRoleId = userRoles?.map((role) => {
    let obj = {};
    obj.label = role?.name;
    obj.value = role?.id;
    return obj;
  });

  const imgChange = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(e.target.files[0]);
      fileReader.onload = () => {
        setImage(fileReader.result);
        setSelectImg(e.target.files[0]);
      };
    }
  };

  const handleSubAcc = (e) => {
    e.preventDefault();

    const postData = {
      blood_group: bloodName
        ? bloodName
        : profileData?.user_profile.blood_group,
      marital_status: selectMarital
        ? selectMarital
        : profileData?.user_profile?.marital_status,
      emergency_relationship_id: relationId
        ? relationId
        : profileData?.user_profile?.relationship?.id,
      name: changeValue.name
        ? changeValue.name
        : profileData?.user_profile?.name,
      phone_no: changeValue.phone_no
        ? changeValue.phone_no
        : profileData?.user_profile?.phone_no,
      present_address: changeValue.present_address
        ? changeValue.present_address
        : profileData?.user_profile?.present_address,
      permanent_address: changeValue.permanent_address
        ? changeValue.permanent_address
        : profileData?.user_profile?.permanent_address,
      fathers_name: changeValue.fathers_name
        ? changeValue.fathers_name
        : profileData?.user_profile?.fathers_name,
      mothers_name: changeValue.mothers_name
        ? changeValue.mothers_name
        : profileData?.user_profile?.mothers_name,
      emergency_contact: changeValue.emergency_contact
        ? changeValue.emergency_contact
        : profileData?.user_profile?.emergency_contact,
      emergency_phone: changeValue.emergency_phone
        ? changeValue.emergency_phone
        : profileData?.user_profile?.emergency_phone,
      profile_photo: selectImg
        ? selectImg
        : profileData?.user_profile?.profile_photo,
    };
    console.log("Data", postData);

    let formData = new FormData();
    for (const i in postData) {
      const element = postData[i];
      formData.append(`${i}`, element);
    }
    const CreateProfile = async () => {
      try {
        const res = await fetch(
          `${MainUrl.url}/api/v1/user/profile/${userId}`,
          {
            method: "Put",
            headers: {
              Authorization: token,
            },
            body: formData,
          }
        );
        // const resData = await res.json();
        if (res.ok) {
          toast.success("Profile updated successfully");
          setUpdate(!update);
        }
      } catch (err) {
        toast.error(err.message);
        console.error(err.message);
      }
    };
    CreateProfile();
  };

  const {
    register: registerAccount,
    handleSubmit: handleSubmitAccount,
    formState: { errors: errorsAccount },
  } = useForm();

  const onSubmitAccount = (data) => {
    const joinDateformat = moment("2023-04-14").format("YYYY-MM-DD");
    // console.log("joindate", joinDate);
    const formData = {
      designation: selectDesignation || profileData?.user_designation?.id,
      department_id: selectDepartment || profileData?.department?.id,
      role_id: role || profileData?.role?.id,
      email: data.email || profileData?.email,
      password: data.password,
      account_no: data.account_no || profileData?.account_no,
      salary_basic: data.salary_basic || profileData?.salary_basic,
      tin: data.tin || profileData?.tin,
      joining_date: profileData?.joining_date || joinDateformat,
    };

    const updateAccount = async () => {
      try {
        const res = await fetch(
          `${MainUrl.url}/api/v1/user/update_account/${userId}`,
          {
            method: "Put",
            headers: {
              "Content-Type": "Application/json",
              Authorization: token,
            },
            body: JSON.stringify(formData),
          }
        );
        if (res.ok) {
          toast.success("Account Successfully Updated");
          setUpdate(!update);
        }
      } catch (error) {
        console.log(error.message);
      }
    };
    updateAccount();
  };

  if(isLoading){
    return <Loader/>
  }

  return (
    <div className=" bg-white p-4">
      <div className="profileModal mt-4 ">
        <div>
          <div className=" flex gap-x-4 ">
            <button
              className={`p-2 rounded ${
                activeTab === 1 ? "bg-slate-800 text-white" : "bg-gray-200"
              }`}
              onClick={() => handleTabClick(1)}
            >
              Profile
            </button>
            <button
              className={`p-2 rounded ${
                activeTab === 2 ? "bg-slate-800 text-white" : "bg-gray-200"
              }`}
              onClick={() => handleTabClick(2)}
            >
              Account
            </button>
          </div>

          <div className="pt-4">
            {activeTab === 1 && (
              <form onSubmit={handleSubAcc} className="CreateUser">
                <h2 className="text-xl font-medium pb-4">
                  Employee Profile Update
                </h2>
                <div className="w-40 h-40 rounded-full relative mb-3">
                  <img
                    src={
                      image
                        ? image
                        : `${MainUrl.url}${profileData?.user_profile?.profile_photo}`
                    }
                    alt=""
                    onError={({ currentTarget }, imageSize = "160x160") =>
                      showDummyImage({ currentTarget }, imageSize)
                    }
                    className=" h-full w-full rounded-full"
                  />

                  <input
                    type="file"
                    className={`absolute top-0 left-0 w-48 h-full rounded-full opacity-0`}
                    name="profile_photo"
                    accept={`"image/*"`}
                    onChange={imgChange}
                  />
                </div>

                <div className="flex flex-col mb-3 md:flex-row md:items-center w-full">
                  <span className="w-24 md:w-28 lg:w-[172px] text-base">
                    Name :
                  </span>
                  <div className="w-full">
                    <input
                      name="name"
                      className="border py-1 pl-3 pr-3 w-full  rounded-md focus:outline-none dark:text-black placeholder:text-base"
                      type="text"
                      defaultValue={profileData?.user_profile?.name}
                      placeholder="Name"
                      onChange={(e) => setValue(e)}
                    />
                  </div>
                </div>

                <div className="flex gap-x-2 justify-center w-full lg:flex-row">
                  {/* Phone Number */}
                  <div className="flex flex-col mb-3 md:flex-row md:items-center w-full">
                    <span className="w-24 md:w-28 lg:w-[200px] text-base">
                      Phone Number :
                    </span>
                    <div className="w-full">
                      <input
                        className="border py-1 ps-3 w-full  rounded-md focus:outline-none dark:text-black placeholder:text-base"
                        type="text"
                        defaultValue={profileData?.user_profile?.phone_no}
                        placeholder="Phone Number Example: +8801*****11"
                        // {...registerProfile("", {
                        //   pattern: {
                        //     value:
                        //       /(^(\+8801|8801|01|008801))[1|3-9]{1}(\d){8}$/i,
                        //     message: "Invalid Phone Number",
                        //   },
                        // })}
                        name="phone_no"
                        onChange={(e) => setValue(e)}
                      />
                    </div>
                  </div>

                  {/* NID Number */}
                  <div className="flex mb-3 md:flex-row md:items-center w-full">
                    <span className="w-24 md:w-28 lg:w-[200px] text-base">
                      NID Number :
                    </span>
                    <div className="w-full">
                      <input
                        className="border py-1 ps-3 w-full  rounded-md focus:outline-none dark:text-black placeholder:text-base"
                        type="number"
                        defaultValue={profileData?.user_profile?.nid_no}
                        placeholder="Nid Number"
                        // {...registerProfile("", {
                        //   pattern: {
                        //     value: /^0|[1-9]\d*$/i,
                        //     message: "Only Number Accepted",
                        //   },
                        // })}
                        name="nid_no"
                        onChange={(e) => setValue(e)}
                      />
                    </div>
                  </div>
                </div>

                <div className="flex gap-x-2 justify-center w-full lg:flex-row">
                  {/* Present Address */}
                  <div className="flex flex-col mb-3 md:flex-row md:items-center w-full">
                    <span className="w-24 md:w-28 lg:w-[200px] text-base">
                      Present Address :
                    </span>
                    <div className="w-full">
                      <input
                        className="border py-1 ps-3 w-full  rounded-md focus:outline-none dark:text-black placeholder:text-base"
                        type="text"
                        defaultValue={
                          profileData?.user_profile?.present_address
                        }
                        name="present_address"
                        placeholder="Present Address"
                        onChange={(e) => setValue(e)}
                        // {...registerProfile("present_address", {})}
                      />
                    </div>
                  </div>

                  {/* Permanent Address */}
                  <div className="flex mb-3 md:flex-row md:items-center w-full">
                    <span className="w-24 md:w-28 lg:w-[200px] text-base">
                      Permanent Address :
                    </span>
                    <div className="w-full">
                      <input
                        className="border py-1 ps-3 w-full  rounded-md focus:outline-none dark:text-black placeholder:text-base"
                        type="text"
                        defaultValue={
                          profileData?.user_profile?.permanent_address === null
                            ? "N/A"
                            : profileData?.user_profile?.permanent_address
                        }
                        placeholder="Permanent Address"
                        name="permanent_address"
                        onChange={(e) => setValue(e)}
                      />
                    </div>
                  </div>
                </div>

                <div className="flex gap-x-2 justify-center w-full lg:flex-row">
                  {/* Father Name */}
                  <div className="flex flex-col mb-3 md:flex-row md:items-center w-full">
                    <span className="w-24 md:w-28 lg:w-[200px] text-base">
                      Father Name :
                    </span>
                    <div className="w-full">
                      <input
                        className="border py-1 ps-3 w-full  rounded-md focus:outline-none dark:text-black placeholder:text-base"
                        type="text"
                        defaultValue={profileData?.user_profile?.fathers_name}
                        placeholder="Father Name"
                        name="fathers_name"
                        onChange={(e) => setValue(e)}
                        // {...registerProfile("fathers_name", {})}
                      />
                    </div>
                  </div>

                  {/* Permanent Address */}
                  <div className="flex mb-3 md:flex-row md:items-center w-full">
                    <span className="w-24 md:w-28 lg:w-[200px] text-base">
                      Mother Name :
                    </span>
                    <div className="w-full">
                      <input
                        className="border py-1 ps-3 w-full  rounded-md focus:outline-none dark:text-black placeholder:text-base"
                        type="text"
                        defaultValue={profileData?.user_profile?.mothers_name}
                        placeholder="Mother Name"
                        name="mothers_name"
                        onChange={(e) => setValue(e)}
                        // {...registerProfile("mothers_name", {})}
                      />
                    </div>
                  </div>
                </div>

                <div className="flex gap-x-2 justify-center w-full lg:flex-row">
                  {/* Blood Group:*/}
                  <div className="flex flex-col mb-3 md:flex-row md:items-center w-full">
                    <span className="w-24 md:w-28 lg:w-[200px] text-base">
                      Blood Group:
                    </span>
                    <div className="w-full">
                      <span>
                        <Select
                          className="basic-single w-full"
                          classNamePrefix="select"
                          // defaultValue={[ groupName?.length ?  {label: "A+" , value:"A+"}:{label: "O+", value: "O+"}]}
                          isClearable={isClearable}
                          isSearchable={isSearchable}
                          onChange={BloodGroup}
                          options={bloodGroup}
                          placeholder={
                            <p className="text-lg text-black">
                              {profileData?.user_profile?.blood_group}
                            </p>
                          }
                        />
                      </span>
                    </div>
                  </div>

                  {/* Marital Status */}
                  <div className="flex mb-3 md:flex-row md:items-center w-full">
                    <span className="w-24 md:w-28 lg:w-[200px] text-base">
                      Marital Status :
                    </span>
                    <div className="w-full">
                      <Select
                        className="basic-single w-full"
                        classNamePrefix="select"
                        isClearable={isClearable}
                        isSearchable={isSearchable}
                        onChange={MaritalStatus}
                        options={maritalStatus}
                        placeholder={
                          <p className="text-lg text-black">
                            {profileData?.user_profile?.marital_status === 0
                              ? "Single"
                              : "Married"}
                          </p>
                        }
                      />
                    </div>
                  </div>
                </div>

                <p className="text-2xl mt-3">Emergency Contact</p>
                <div className="flex gap-x-2 justify-center w-full lg:flex-row mt-5">
                  {/* Name */}
                  <div className="flex flex-col mb-3 md:flex-row md:items-center w-full">
                    <span className="w-24 md:w-28 lg:w-[200px] text-base">
                      Name :
                    </span>
                    <div className="w-full">
                      <input
                        className="border py-1 pl-3 pr-3 w-full  rounded-md focus:outline-none dark:text-black placeholder:text-base"
                        type="text"
                        placeholder="Name"
                        defaultValue={
                          profileData?.user_profile?.emergency_contact
                        }
                        name="emergency_contact"
                        onChange={(e) => setValue(e)}
                      />
                    </div>
                  </div>

                  {/* Phone Number */}
                  <div className="flex flex-col mb-3 md:flex-row md:items-center w-full">
                    <span className="w-24 md:w-28 lg:w-[200px] text-base">
                      Phone Number :
                    </span>
                    <div className="w-full">
                      <input
                        className="border py-1 ps-3 w-full  rounded-md focus:outline-none dark:text-black placeholder:text-base"
                        type="text"
                        defaultValue={
                          profileData?.user_profile?.emergency_phone
                        }
                        placeholder="Phone Number Example: +8801*****11"
                        name="emergency_phone"
                        onChange={(e) => setValue(e)}
                      />
                    </div>
                  </div>
                </div>

                <div className="w-1/2">
                  <div className=" flex flex-col mb-3 md:flex-row md:items-center w-full">
                    <span className="w-24 md:w-28 lg:w-[200px] text-base">
                      RelationShip:
                    </span>
                    <div className="w-full selectPlaceholder">
                      <Select
                        className="basic-single w-full"
                        classNamePrefix="select"
                        isClearable={isClearable}
                        isSearchable={isSearchable}
                        onChange={Relation}
                        options={relation}
                        placeholder={
                          <p className="text-lg text-black">
                            {profileData?.user_profile?.relationship?.name}
                          </p>
                        }
                      />
                    </div>
                  </div>
                </div>

                <input
                  type="submit"
                  value={"Profile Update"}
                  className="cursor-pointer mt-5 py-2 px-6 rounded bg-cyan-700  text-white font-medium text-sm"
                />
              </form>
            )}
            {activeTab === 2 && (
              <form
                onSubmit={handleSubmitAccount(onSubmitAccount)}
                className=" account"
              >
                <h2 className="text-xl font-medium">
                  Employee Account Information Update
                </h2>
                <div className="pt-5">
                  <div className="grid grid-cols-2 gap-2">
                    <div className="flex flex-col gap-y-2">
                      <label className="ps-2">Select Department</label>
                      <Select
                        className="basic-single w-full"
                        classNamePrefix="select"
                        isClearable={isClearable}
                        isSearchable={isSearchable}
                        onChange={(e) => setSelectDepartment(e?.value)}
                        options={departmentName}
                        placeholder={`${profileData?.department?.name}`}
                      />
                    </div>

                    <div className="flex flex-col gap-y-2">
                      <label className="ps-2">Select Designation</label>
                      <Select
                        className="basic-single w-full"
                        classNamePrefix="select"
                        isClearable={isClearable}
                        isSearchable={isSearchable}
                        onChange={(e) => setSelectDesignation(e?.value)}
                        options={designation}
                        placeholder={`${profileData?.user_designation?.name}`}
                      />
                    </div>

                    <div className="flex flex-col gap-y-2">
                      <label className="ps-2">Select Role</label>
                      <Select
                        className="basic-single w-full"
                        classNamePrefix="select"
                        isClearable={isClearable}
                        isSearchable={isSearchable}
                        onChange={(e) => setRole(e?.value)}
                        options={userRoleId}
                        placeholder={`${profileData?.role?.name}`}
                      />
                    </div>
                  </div>

                  <div className="grid grid-cols-2 gap-x-2 pt-2">
                    <div className="flex flex-col gap-y-2 pb-2">
                      <label className="ps-2">Email</label>
                      <div className="w-full">
                        <input
                          className="border py-1 ps-3 w-full  rounded-md focus:outline-none dark:text-black placeholder:text-base"
                          type="text"
                          defaultValue={profileData?.email}
                          placeholder="email@gmail.com"
                          {...registerAccount("email", {
                            pattern: {
                              value: "[a-z0-9]+@[a-z]+.[a-z]{2,3}",
                              message: "Invalid email address",
                            },
                          })}
                        />
                        {errorsAccount.email && (
                          <p className="text-red-500 pl-0.5 text-[12px] pt-0.5">
                            {errorsAccount.email?.message}
                          </p>
                        )}
                      </div>
                    </div>

                    <div className="flex flex-col gap-y-2 pb-2">
                      <label className="ps-2">Password</label>
                      <div className="w-full">
                        <input
                          className="border py-1 ps-3 w-full  rounded-md focus:outline-none dark:text-black placeholder:text-base"
                          type="text"
                          placeholder="*******"
                          {...registerAccount("password", {
                            pattern: {
                              value: /^.{8,}$/,
                              message:
                                "Please enter a value that is at least 8 characters long",
                            },
                          })}
                        />
                        {errorsAccount.password && (
                          <p className="text-red-500 pl-0.5 text-[12px] pt-0.5">
                            {errorsAccount.password?.message}
                          </p>
                        )}
                      </div>
                    </div>

                    <div className="flex flex-col gap-y-2 pb-2">
                      <label className="ps-2">Account Number</label>
                      <div className="w-full">
                        <input
                          className="border py-1 ps-3 w-full  rounded-md focus:outline-none dark:text-black placeholder:text-base"
                          type="text"
                          defaultValue={profileData?.account_no}
                          placeholder="Account Number"
                          {...registerAccount("account_no", {
                            // required: "This field is required",
                            // message: "This field is required",
                          })}
                        />
                        {errorsAccount.account_no && (
                          <p className="text-red-500 pl-0.5 text-[12px] pt-0.5">
                            {errorsAccount.account_no?.message}
                          </p>
                        )}
                      </div>
                    </div>

                    <div className="flex flex-col gap-y-2 pb-2">
                      <label className="ps-2">Salary Basic</label>
                      <div className="w-full">
                        <input
                          className="border py-1 ps-3 w-full  rounded-md focus:outline-none dark:text-black placeholder:text-base"
                          type="text"
                          defaultValue={profileData?.salary_basic}
                          placeholder="Account Number"
                          {...registerAccount("salary_basic", {
                            // required: "This field is required",
                            message: "This field is required",
                          })}
                        />
                        {errorsAccount.salary_basic && (
                          <p className="text-red-500 pl-0.5 text-[12px] pt-0.5">
                            {errorsAccount.salary_basic?.message}
                          </p>
                        )}
                      </div>
                    </div>

                    <div className="flex flex-col gap-y-2 pb-2">
                      <label className="ps-2">Tin Number</label>
                      <div className="w-full">
                        <input
                          className="border py-1 ps-3 w-full  rounded-md focus:outline-none dark:text-black placeholder:text-base"
                          type="text"
                          defaultValue={profileData?.tin}
                          placeholder="Account Number"
                          {...registerAccount("tin", {
                            pattern: {
                              value: /^.{12}$/,
                              message: "Invalid Tin Number",
                            },
                          })}
                        />
                        {errorsAccount.tin && (
                          <p className="text-red-500 pl-0.5 text-[12px] pt-0.5">
                            {errorsAccount.tin?.message}
                          </p>
                        )}
                      </div>
                    </div>

                    <div className="flex flex-col gap-y-2 pb-2">
                      <label className="ps-2">Join Date</label>
                      <Calendar
                        value={joinDate}
                        onChange={(e) => setJoinDate(e?.value)}
                        dateFormat="dd/mm/yy"
                        placeholder={profileData?.joining_date}
                      />
                    </div>
                  </div>
                  <div className="mt-5">
                    <button className="bg-cyan-700 px-4 py-2 text-white rounded">
                      Account Update
                    </button>
                  </div>
                </div>
              </form>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileUpdate;

import React, { useState } from "react";
import { FiUsers } from "react-icons/fi";
import { GrLocation } from "react-icons/gr";
import { HiOutlineUsers } from "react-icons/hi2";
import { LuPhoneCall } from "react-icons/lu";
import { MdOutlineBloodtype } from "react-icons/md";
import useData from "../../ContextApi/UseData/useData";
import { MainUrl, showDummyImage } from "../../utils/helper";
import { BiHeart } from "react-icons/bi";
import { PiIdentificationCardThin } from "react-icons/pi";

const UserProfile = () => {
  const { profile } = useData().user
  
  const [activeTab, setActiveTab] = useState(1);
  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  return (
    <div className="grid grid-cols-12 gap-x-2">
      <div className="col-span-3 bg-white w-full p-4 flex justify-center">
        <div className="">
          <div className="h-60 w-60">
            <img
              src={`${MainUrl.url}/${profile?.profile_photo}`}
              onError={({ currentTarget }, imageSize = "100x100") =>
                showDummyImage({ currentTarget }, imageSize)
              }
              alt=""
              className=" h-full w-full rounded"
            />
          </div>
          <div className="text-base font-medium pt-4 text-center">
            <p>{profile?.name}</p>
            <p>{profile?.phone_no}</p>
          </div>
        </div>
      </div>

      <div className="col-span-9 bg-white p-4">
        <div className=" flex gap-x-4 ">
          <button
            className={`p-2 rounded ${
              activeTab === 1 ? "bg-slate-800 text-white" : "bg-gray-200"
            }`}
            onClick={() => handleTabClick(1)}
          >
            Personal
          </button>
          <button
            className={`p-2 rounded ${
              activeTab === 2 ? "bg-slate-800 text-white" : "bg-gray-200"
            }`}
            onClick={() => handleTabClick(2)}
          >
            Emergency
          </button>
        </div>

        <div className="pt-4">
          {activeTab === 1 && (
            <div>
              <div className="text-base font-medium">
                <div className="flex items-center gap-4 py-2">
                  <div className="flex items-center gap-x-2 ">
                    <span>
                      <PiIdentificationCardThin />{" "}
                    </span>
                    <p className="w-[170px]">NID Number</p>
                  </div>
                  <p>{profile?.nid_no ? profile?.nid_no : "N/A"}</p>
                </div>

                <div className="flex items-center gap-4">
                  <div className="flex items-center gap-x-2 ">
                    <span>
                      <MdOutlineBloodtype />
                    </span>
                    <p className="w-[170px]">Blood Group</p>
                  </div>
                  <p>{profile?.blood_group ? profile?.blood_group : "N/A"}</p>
                </div>

                <div className="flex items-center gap-4 pt-2">
                  <div className="flex items-center gap-x-2 ">
                    <span>
                      <GrLocation />
                    </span>
                    <p className="w-[170px]">Present Address</p>
                  </div>
                  <p>
                    {profile?.present_address
                      ? profile?.present_address
                      : "N/A"}
                  </p>
                </div>

                <div className="flex items-center gap-4 pt-2">
                  <div className="flex items-center gap-x-2 ">
                    <span>
                      <GrLocation />
                    </span>
                    <p className="w-[170px]">Permanent Address</p>
                  </div>
                  <p>
                    {profile?.permanent_address
                      ? profile?.permanent_address
                      : "N/A"}
                  </p>
                </div>

                <div className="flex items-center gap-4 pt-2">
                  <div className="flex items-center gap-x-2 ">
                    <span>
                      <HiOutlineUsers />{" "}
                    </span>
                    <p className="w-[170px]">Father Name</p>
                  </div>
                  <p>{profile?.fathers_name ? profile?.fathers_name : "N/A"}</p>
                </div>

                <div className="flex items-center gap-4 pt-2">
                  <div className="flex items-center gap-x-2 ">
                    <span>
                      <HiOutlineUsers />{" "}
                    </span>
                    <p className="w-[170px]">Mother Name</p>
                  </div>
                  <p>{profile?.Mothers_name ? profile?.Mothers_name : "N/A"}</p>
                </div>
              </div>
            </div>
          )}
          {activeTab === 2 && (
            <div>
              <div className="text-base font-medium">
                <div className="flex items-center gap-4 pt-1">
                  <div className="flex items-center gap-x-2 ">
                    <span>
                      <FiUsers />{" "}
                    </span>
                    <p className="w-[140px]">Name</p>
                  </div>
                  <p>
                    {profile?.emergency_contact
                      ? profile?.emergency_contact
                      : "N/A"}
                  </p>
                </div>

                <div className="flex items-center gap-4 pt-2  ">
                  <div className="flex items-center gap-x-2 ">
                    <span>
                      <LuPhoneCall />{" "}
                    </span>
                    <p className="w-[140px]">Phone Number</p>
                  </div>
                  <p>
                    {profile?.emergency_phone
                      ? profile?.emergency_phone
                      : "N/A"}
                  </p>
                </div>
                <div className="flex items-center gap-4 pt-2 ">
                  <div className="flex items-center gap-x-2 ">
                    <span>
                      <BiHeart />{" "}
                    </span>
                    <p className="w-[140px]">Relation</p>
                  </div>
                  <p>
                    {profile?.relationship?.name
                      ? profile?.relationship?.name
                      : "N/A"}
                  </p>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default UserProfile;

import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import React, { useEffect, useState } from "react";
import { RiDeleteBin7Line } from "react-icons/ri";
import { toast } from "react-toastify";
import { MainUrl } from "../../utils/helper";
import useData from "../../ContextApi/UseData/useData";
import Loader from "../Loader/Loader";
import DeleteModal from "../AllEmployee/DeleteModal/DeleteModal";
import { useLocation, useSearchParams } from "react-router-dom";
import Pagination from "../Pagination/Pagination";

const MyPaySlip = () => {
  const { token } = useData().user;
  const [mySlip, setMySlip] = useState(null);
  const [update, setUpdate] = useState(false);
  const [totalData, setTotalData] = useState(null);
  const [isLoader, setIsLoader] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [selectData, setSelectData] = useState(null);
  const [viewData, setViewData] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchParams, setSearchParams] = useSearchParams();
  const [pageCount, setPageCount] = useState();
  const location = useLocation();

  useEffect(() => {
    if (!token) {
      return;
    }
    const page = location?.search?.split("=")[1];
    if (page) {
      setPageNumber(+page);
      setCurrentPage(+page);
    }

    const fetchData = async () => {
      try {
        setIsLoader(true);
        const res = await fetch(
          `${MainUrl.url}/api/v1/provident_fund/payslips/all/${viewData}/${pageNumber}`,
          {
            method: "get",
            headers: {
              Authorization: token,
            },
          }
        );
        const resData = await res.json();
        if (res.ok) {
          setMySlip(resData.data?.results);
          setTotalData(resData.data?.totalCount);
          setIsLoader(false);
        }
      } catch (error) {
        toast.error(error?.message);
        setIsLoader(false);
      }
    };
    fetchData();
  }, [update, pageNumber, viewData, token, location, searchParams]);

  useEffect(() => {
    const pages = Math.ceil(totalData / viewData);
    setPageCount(pages);
  }, [totalData, viewData]);

  const DateOfWithdrawTemplate = (data) => {
    return <p>{data?.date_of_withdrawal}</p>;
  };

  const AmountTemplate = (data) => {
    return <p>{data?.withdrawn_amount}</p>;
  };

  const withdrawalStatus = (data) => {
    return <p>{data?.withdraw_status?.name}</p>;
  };

  const CompanyReturnTemplate = (data) => {
    return <p>{data.company_return}</p>;
  };

  const ApprovalStatusTemplate = (data) => {
    return (
      <div className="text-sm">
        {data?.approval_status === 1 ? (
          <button className="px-4 py-1 bg-cyan-700 text-white rounded cursor-default">
            Acknowledged
          </button>
        ) : (
          <button className="px-4 py-1 bg-yellow-400 text-white rounded cursor-default">
            Pending
          </button>
        )}
      </div>
    );
  };

  const ActionTemplate = (data) => {
    return (
      <div className=" flex items-center gap-x-2 text-sm">
        <p
          onClick={() => {
            setSelectData(data);
            setShowDelete(!showDelete);
          }}
          className="bg-red-400 text-white rounded flex gap-x-2 items-center py-1 px-3"
        >
          <span>
            <RiDeleteBin7Line size={16} />{" "}
          </span>{" "}
          Delete
        </p>
      </div>
    );
  };

  const renderHeader = () => {
    return (
      <div className="py-6">
        <p className="text-lg">My pay Slip</p>
      </div>
    );
  };
  const header = renderHeader();

  const DeleteUrl = "api/v1/provident_fund/payslip/one";
  const DeleteData = {
    showDelete,
    setShowDelete,
    update,
    setUpdate,
    selectData,
    DeleteUrl,
  };

  return (
    <div className="bg-white pb-4 mb-4">
      {isLoader ? (
        <Loader />
      ) : (
        <DataTable
          size="small"
          header={header}
          value={mySlip}
          // footer={footerTemplate}
          selectionMode="single"
          dataKey="id"
          tableStyle={{ Width: "100%" }}
        >
          <Column
            field="provident_fund_account?.user?.office_id"
            header="Date of withdrawal"
            sortable
            body={DateOfWithdrawTemplate}
            className=""
          ></Column>

          <Column
            field="provident_fund_account?.user?.office_id"
            header="Amount"
            sortable
            body={AmountTemplate}
            className=""
          ></Column>

          <Column
            field="provident_fund_account?.user?.office_id"
            header="Withdraw Status"
            sortable
            body={withdrawalStatus}
            className=""
          ></Column>

          <Column
            field="provident_fund_account?.user?.office_id"
            header="Company  Return"
            sortable
            body={CompanyReturnTemplate}
            className=""
          ></Column>
          <Column
            field="provident_fund_account?.user?.office_id"
            header="Approval"
            body={ApprovalStatusTemplate}
            className=""
          ></Column>

          <Column
            field="provident_fund_account?.user?.office_id"
            header="Action"
            className="w-[10%]"
            body={ActionTemplate}
          ></Column>
        </DataTable>
      )}
      <div className="mt-4">
        {mySlip?.length > 0 && (
          <Pagination
            currentPage={currentPage}
            pageNumber={pageNumber}
            setCurrentPage={setCurrentPage}
            setSearchParams={setSearchParams}
            pageCount={pageCount}
            setViewData={setViewData}
            viewData={viewData}
            setPageNumber={setPageNumber}
          />
        )}
      </div>
      {<DeleteModal DeleteData={DeleteData} />}
    </div>
  );
};

export default MyPaySlip;

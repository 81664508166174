import React from "react";
import Rodal from "rodal";
import { TabView, TabPanel } from "primereact/tabview";
import { MdOutlineBloodtype } from "react-icons/md";
import { GrLocation } from "react-icons/gr";
import { HiOutlineUsers } from "react-icons/hi2";
import { BiHeart } from "react-icons/bi";
import { FiUsers } from "react-icons/fi";
import { LuPhoneCall } from "react-icons/lu";
import { MainUrl, showDummyImage } from "../../utils/helper";

const ProfileModal = ({ profileModal }) => {
  const { showProfile, setShowProfile, profileData } = profileModal;

  return (
    <div className="profileModal text-gray-600">
      <Rodal
        visible={showProfile}
        enterAnimation="flip"
        animation="fade"
        className="h-[65%]"
        onClose={() => setShowProfile(!showProfile)}
        customStyles={{
          height: "auto",
          width: "40%",
          bottom: "auto",
          top: "10%",
          transform: "translateY(-50%)",
        }}
      >
        <h2 className="text-2xl font-medium">Employee Profile Information</h2>

        <div className="mt-3 grid grid-cols-12 items-center">
          <div className="col-span-3">
            <div className="h-32 w-32">
              <img
                src={`${MainUrl.url}/${profileData?.user_profile?.profile_photo}`}
                onError={({ currentTarget }, imageSize = "100x100") =>
                  showDummyImage({ currentTarget }, imageSize)
                }
                alt=""
                className=" h-full w-full rounded-full"
              />
            </div>
          </div>
          <div className="col-span-9 text-lg font-semibold">
            <div className="flex gap-2 items-center ">
              <p className="w-16">Name</p>
              <p>{profileData?.user_profile?.name}</p>
            </div>
            <div className="flex gap-2 items-center ">
              <p className="w-16">NID-No</p>
              <p>{profileData?.user_profile?.nid_no}</p>
            </div>
            <div className="flex gap-2 items-center ">
              <p className="w-16">Phone</p>
              <p>{profileData?.user_profile?.phone_no}</p>
            </div>
          </div>
        </div>

        <div className="profileModal mt-4 ">
          <TabView>
            <TabPanel header="Personal Info">
              <div className="text-lg font-semibold">
                <div className="flex items-center gap-4">
                  <div className="flex items-center gap-x-2 ">
                    <span>
                      <MdOutlineBloodtype />
                    </span>
                    <p className="w-[170px]">Blood Group</p>
                  </div>
                  <p>{profileData?.user_profile?.blood_group? profileData?.user_profile?.blood_group : "N/A"}</p>
                </div>

                <div className="flex items-center gap-4 pt-2">
                  <div className="flex items-center gap-x-2 ">
                    <span>
                      <GrLocation />
                    </span>
                    <p className="w-[170px]">Present Address</p>
                  </div>
                  <p>{profileData?.user_profile?.present_address ? profileData?.user_profile?.present_address : "N/A"}</p>
                </div>

                <div className="flex items-center gap-4 pt-2">
                  <div className="flex items-center gap-x-2 ">
                    <span>
                      <GrLocation />
                    </span>
                    <p className="w-[170px]">Permanent Address</p>
                  </div>
                  <p>
                    {profileData?.user_profile?.permanent_address
                      ? profileData?.user_profile?.permanent_address
                      : "N/A"}
                  </p>
                </div>

                <div className="flex items-center gap-4 pt-2">
                  <div className="flex items-center gap-x-2 ">
                    <span>
                      <HiOutlineUsers />{" "}
                    </span>
                    <p className="w-[170px]">Father Name</p>
                  </div>
                  <p>{profileData?.user_profile?.fathers_name ? profileData?.user_profile?.fathers_name : "N/A"}</p>
                </div>

                <div className="flex items-center gap-4 pt-2">
                  <div className="flex items-center gap-x-2 ">
                    <span>
                      <HiOutlineUsers />{" "}
                    </span>
                    <p className="w-[170px]">Mother Name</p>
                  </div>
                  <p>
                    {profileData?.user_profile?.Mothers_name
                      ? profileData?.user_profile?.Mothers_name
                      : "N/A"}
                  </p>
                </div>

                <div className="flex items-center gap-4 pt-2">
                  <div className="flex items-center gap-x-2 ">
                    <span>
                      <BiHeart />{" "}
                    </span>
                    <p className="w-[170px]">Material Status</p>
                  </div>
                  <p>
                    {profileData?.user_profile?.marital_status === 0
                      ? "Single"
                      : "Married"}
                  </p>
                </div>
              </div>
            </TabPanel>
            <TabPanel header="Emergency Info">
              <div className="text-lg font-semibold">
                <div className="flex items-center gap-4">
                  <div className="flex items-center gap-x-2 ">
                    <span>
                      <FiUsers />{" "}
                    </span>
                    <p className="w-[140px]">Name</p>
                  </div>
                  <p>{profileData?.user_profile?.emergency_contact ? profileData?.user_profile?.emergency_contact : "N/A"}</p>
                </div>

                <div className="flex items-center gap-4 pt-2  ">
                  <div className="flex items-center gap-x-2 ">
                    <span>
                      <LuPhoneCall />{" "}
                    </span>
                    <p className="w-[140px]">Phone Number</p>
                  </div>
                  <p>{profileData?.user_profile?.emergency_phone ?profileData?.user_profile?.emergency_phone : "N/A"}</p>
                </div>
                <div className="flex items-center gap-4 pt-2 ">
                  <div className="flex items-center gap-x-2 ">
                    <span>
                      <BiHeart />{" "}
                    </span>
                    <p className="w-[140px]">Relation</p>
                  </div>
                  <p>{profileData?.user_profile?.relationship?.name ?profileData?.user_profile?.relationship?.name :"N/A" }</p>
                </div>
              </div>
            </TabPanel>
          </TabView>
        </div>
        
      </Rodal>
    </div>
  );
};

export default ProfileModal;

import React from "react";
import Breadcrumbs from "../Breadcrumbs/Breadcrumbs";
import SubDepartment from "../Department/SubDepartment";

const AllSubDepartmentPage = () => {
  const data = [
    {
      name: "Department",
      slash: "/",
      route:null
    },
    {
        name: "Sub-Department Table",
        route:null
      },
  ];
  return (
    <>
      <Breadcrumbs data={data} />
      <SubDepartment />
    </>
  );
};

export default AllSubDepartmentPage;

import { Dialog } from "primereact/dialog";
import React, { useState } from "react";
import { MainUrl } from "../../utils/helper";
import useData from "../../ContextApi/UseData/useData";
import { toast } from "react-toastify";

const TransportAllowanceTypeModal = ({ modalData }) => {
  const { token } = useData().user;
  const [changeName, setChangeName] = useState(null);
  const {
    showModal,
    setShowModal,
    update,
    setUpdate,
    selectData,
    setSelectedData,
  } = modalData;

  console.log("selectData", selectData);

  const handlePost = (e) => {
    e.preventDefault();
    const form = e.target;
    const data = {
      name: changeName,
    };

    const postData = async () => {

      if (selectData?.id) {
        let res = await fetch(
          `${MainUrl.url}/api/v1/transport/type/${selectData?.id}`,
          {
            method: "Put",
            headers: {
              Authorization: token,
            },
            body: JSON.stringify(data),
          }
        );

        if (res.ok) {
          toast.success("successfully updated");
          setShowModal(!showModal);
          setUpdate(!update);
          form.reset();
        } else {
          const resData = await res.json();
          toast.error(resData.message);
          setShowModal(!showModal);
          form.reset();
        }
      }
      else{
      let res = await fetch(`${MainUrl.url}/api/v1/transport/types`, {
        method: "post",
        headers: {
          "Content-type": "application/json",
          Authorization: token,
        },
        body: JSON.stringify(data),
      });
      if (res.ok) {
        toast.success("successfully updated");
        setShowModal(!showModal);
        setUpdate(!update);
        form.reset();
      } else {
        const resData = await res.json();
        toast.error(resData.message);
        setShowModal(!showModal);
        form.reset();
      }
      }

    };
    postData();
  };

  return (
    <Dialog
      header="Create Transport Allowance"
      visible={showModal}
      onHide={() => {
        setShowModal(!showModal);
        setSelectedData(null);
      }}
      style={{ width: "30vw" }}
      breakpoints={{ "960px": "75vw", "641px": "100vw" }}
      draggable={false}
      resizable={false}
    >
      <form onSubmit={handlePost} className="pt-2">
        <div className="flex flex-col mb-2">
          <label className="pb-1 ps-1"> Transport Name</label>
          <input
            type="text"
            className="border-2 ps-2 py-1 rounded border-gray-200 focus-visible:outline-none w-full"
            // placeholder={`${selectedData?.department?.name}`}
            placeholder={`${
              selectData?.name ? selectData?.name : "Transport Type Name"
            }`}
            defaultValue={selectData?.name && selectData?.name}
            onChange={(e) => setChangeName(e.target.value)}
          />
        </div>

        <input
          className="cursor-pointer px-4 py-1 rounded-md bg-cyan-700  text-white text-base font-medium "
          type="submit"
          value="Submit"
        />
      </form>
    </Dialog>
  );
};

export default TransportAllowanceTypeModal;

import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import React, { useEffect, useState } from "react";
import {
  AiFillEdit,
  AiOutlineDelete,
  AiOutlinePlus,
  AiOutlineProfile,
} from "react-icons/ai";
import { IoIosRefresh } from "react-icons/io";
import { toast } from "react-toastify";
import { Image } from "primereact/image";
import { Menu, MenuButton, MenuItem } from "@szhsin/react-menu";
import { BsThreeDotsVertical } from "react-icons/bs";
import TeamCreate from "./TeamCreate";
import ProgressDetails from "./ProgressDetails";
import AddMembers from "./AddMembers";
import UpdateMember from "./UpdateMember";
import { MainUrl } from "../../utils/helper";
import useData from "../../ContextApi/UseData/useData";
import Loader from "../Loader/Loader";
import { useLocation, useSearchParams } from "react-router-dom";
import Pagination from "../Pagination/Pagination";

const ProjectProgress = () => {
  const [expandedRows, setExpandedRows] = useState(null);
  const [progressData, setProgressData] = useState(null);
  const [update, setUpdate] = useState(false);
  const [totalData, setTotalData] = useState(null);
  const [showTeam, setShowTeam] = useState(false);
  const [teamData, setTeamData] = useState(null)
  const [showAddMember, setShowAddMember] = useState(false);
  const [role, setRole] = useState(null);
  const [showDetails, setShowDetails] = useState(false);
  const [details, setDetails] = useState(null);
  const [projectData, setProjectData] = useState(null);
  const [showUpdateMember, setShowUpdateMember] = useState(false);
  const [isLoader, setIsLoader] = useState(false);
  const { token, allUser } = useData().user;
  const [filterData, setFilterData] = useState({
    name: "",
  });
  const [changeValue, setChangeValue] = useState({});

  const [viewData, setViewData] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchParams, setSearchParams] = useSearchParams();
  const [pageCount, setPageCount] = useState();
  const location = useLocation();

  // get table Data
  useEffect(() => {
    if (!token) {
      return;
    }

    const page = location?.search?.split("=")[1];
    if (page) {
      setPageNumber(+page);
      setCurrentPage(+page);
    }

    const fetchData = async () => {
      try {
        setIsLoader(true);
        const res = await fetch(
          `${MainUrl.url}/api/v1/project/progress/all/${viewData}/${pageNumber}`,
          {
            method: "get",
            headers: {
              Authorization: token,
            },
          }
        );
        const data = await res.json();
        if (res.ok) {
          setProgressData(data.data?.result);
          setTotalData(data.data?.total_count);
          setIsLoader(false);
        }
      } catch (error) {
        toast.error(error?.message);
        setIsLoader(false);
      }
    };
    fetchData();
  }, [
    update,
    pageNumber,
    viewData,
    token,
    location,
    currentPage,
    searchParams,
  ]);

  // get prooject role
  useEffect(() => {
    if (!token) {
      return;
    }
    const fetchData = async () => {
      try {
        const res = await fetch(`${MainUrl.url}/api/v1/project/roles`, {
          method: "Get",
          headers: {
            Authorization: token,
          },
        });
        const data = await res.json();
        if (res.ok) {
          setRole(data.data);
        }
      } catch (error) {
        toast.error(error.message);
      }
    };
    fetchData();
  }, [token]);

  const handleDelete = async (id, data) => {
    if (!token) {
      return;
    }
    const agree = window.confirm(
      `are you sure to delete ${data.project_role.name}`
    );
    if (agree) {
      try {
        const res = await fetch(`${MainUrl.url}/api/v1/project/member/${id}`, {
          method: "delete",
          headers: {
            Authorization: token,
          },
        });
        if (res.ok) {
          toast.success("successfully deleted data");
          setUpdate(!update);
        }
      } catch (error) {
        toast.error(error.message);
      }
    }
  };

  useEffect(() => {
    const pages = Math.ceil(totalData / viewData);
    setPageCount(pages);
  }, [totalData, viewData]);

  const allowExpansion = (rowData) => {
    return rowData.team?.length > 0;
  };

  //   Column template
  const ProjectTitleTemplate = (data) => {
    return <p className="text-lg">{data?.project?.project_title}</p>;
  };

  const ProjectDurationTemplate = (data) => {
    return <p className="text-lg">{data?.project?.proposed_duration} Year</p>;
  };

  const ProjectDeadLine = (data) => {
    return <p className="text-lg">{data?.project?.deadline}</p>;
  };

  const projectStatus = (data) => {
    console.log("Data", data);
    return <p className="text-lg">{data?.project_progress_status?.name}</p>;
  };

  const SpecificationTemplate = (data) => {
    return (
      <Image
        src={`${MainUrl.url}/${data?.project_specification}`}
        zoomSrc={`${MainUrl.url}${data?.project_specification}`}
        alt="Image"
        width="80"
        height="30"
        preview
      />
    );
  };

  const ActionTemplate = (data) => {
    return (
      <Menu
        direction="right"
        align="center"
        menuButton={
          <MenuButton>
            <BsThreeDotsVertical />
          </MenuButton>
        }
        transition
      >
        <MenuItem
          className="hover:text-red-400"
          onClick={() => {
            setShowUpdateMember(!showUpdateMember);
            setDetails(data);
          }}
        >
          <span className="mr-2">
            <AiFillEdit />
          </span>
          UPdate
        </MenuItem>

        <MenuItem
          className="hover:text-red-400"
          onClick={() => {
            setShowDetails(!showDetails);
            setDetails(data);
          }}
        >
          <span>
            <AiOutlineProfile className="mr-2" />
          </span>
          Details
        </MenuItem>

        {data.team ? (
          <MenuItem
            className="hover:text-red-400"
            onClick={() =>  {
              setShowTeam(!showTeam)
              setTeamData(data)
            } }
  
          >
            <span>
              <AiOutlinePlus className="mr-2" />
            </span>
            Add Members
          </MenuItem>
        ) : (
          <MenuItem
            className="hover:text-red-400"
            onClick={() => setShowAddMember(!showAddMember)}
         
          >
            <span>
              <AiOutlinePlus className="mr-2" />
            </span>
            Team
          </MenuItem>
        )}
      </Menu>
    );
  };

  //   expandedRows template

  const OfficeIdTemplate = (data) => {
    return <p className="text-lg">{data.user?.office_id}</p>;
  };

  const NameTemplate = (data) => {
    return <p className="text-lg">{data.user?.user_profile?.name}</p>;
  };

  const ProjectRoleTemplate = (data) => {
    return <p className="text-lg">{data?.project_role?.name}</p>;
  };

  const TeamAction = (data) => {
    const id = data?.id;
    // const name = data?.client?.name;
    return (
      <Menu
        direction="right"
        align="center"
        menuButton={
          <MenuButton>
            <BsThreeDotsVertical />
          </MenuButton>
        }
        transition
      >
        <MenuItem
          className="hover:text-red-400"
          onClick={() => {
            setShowUpdateMember(!showUpdateMember);
            setProjectData(data);
          }}
        >
          <span className="mr-2">
            <AiFillEdit />
          </span>
          UPdate
        </MenuItem>

        <MenuItem
          className="hover:text-red-400"
          onClick={() => handleDelete(id, data)}
        >
          <span>
            <AiOutlineDelete className="mr-2" />
          </span>
          Delete
        </MenuItem>
      </Menu>
    );
  };

  

  const renderHeader = () => {
    const setValue = (e) => {
      setChangeValue({
        ...changeValue,
        [e.target?.name]: e.target.value,
      });
    };

    const handleSearch = async (e) => {
      e.preventDefault();
      const data = {
        name: changeValue.name ? changeValue.name : "",
      };
      setFilterData(data);
    };

    const handleFresh = () => {
      setChangeValue({
        name: "",
      });
      setFilterData({
        name: "",
      });
      setUpdate(!update);
    };

    return (
      <div>
        <form onSubmit={handleSearch} className="flex justify-between">
          <div>
            <input
              onChange={(e) => setValue(e)}
              className="ps-3 py-1.5 border border-gray-300 rounded-lg inputShadow w-full"
              placeholder="Search by Name"
              value={filterData.name}
              name="name"
            />
          </div>

          <div className="flex justify-between items-center gap-x-4">
            <button className="px-4 py-2 bg-cyan-700 rounded text-white">
              Search
            </button>

            <div>
              <span
                onClick={handleFresh}
                className="bg-cyan-700 text-white h-8 w-8 flex items-center justify-center rounded-full font-medium cursor-pointer"
              >
                <IoIosRefresh />
              </span>
            </div>
          </div>
        </form>
      </div>
    );
  };
  const header = renderHeader();

  const rowExpansionTemplate1 = (data) => {
    const modify = data?.team;

    return (
      <div className="px-10">
        <DataTable size={"small"} value={modify} className=" text-black w-full">
          <Column
            field="user.office_id"
            header="Office Id"
            sortable
            body={OfficeIdTemplate}
            className=""
          ></Column>

          <Column
            field="user.user_profile.name"
            header="Name"
            body={NameTemplate}
            sortable
            className=""
          ></Column>

          <Column
            field=""
            header="Project Role"
            body={ProjectRoleTemplate}
            className=""
          ></Column>

          <Column
            field=""
            header="Action"
            body={TeamAction}
            className=""
          ></Column>
        </DataTable>
      </div>
    );
  };

  const teamModal = {
    showTeam,
    setShowTeam,
    role,
    allUser,
    progressData,
    update,
    setUpdate,
    teamData,
  };

  const detailsData = {
    showDetails,
    setShowDetails,
    details,
  };

  const member = {
    showAddMember,
    setShowAddMember,
    role,
    allUser,
    update,
    setUpdate,
    projectData,
  };
  const Updateteam = {
    showUpdateMember,
    setShowUpdateMember,
    role,
    allUser,
    projectData,
    update,
    setUpdate,
  };

  return (
    <div className="bg-white pb-4 mb-4">
      {isLoader ? (
        <Loader />
      ) : (
        <DataTable
          size="small"
          header={header}
          value={progressData}
          // footer={footerTemplate}
          expandedRows={expandedRows}
          onRowToggle={(e) => setExpandedRows(e.data)}
          rowExpansionTemplate={rowExpansionTemplate1}
          selectionMode="single"
          dataKey="id"
          tableStyle={{ Width: "100%" }}
        >
          <Column expander={allowExpansion}></Column>
          <Column
            field=""
            header="project Title"
            body={ProjectTitleTemplate}
            className=""
          ></Column>

          <Column
            field="project.proposed_duration"
            header="project Duration"
            sortable
            body={ProjectDurationTemplate}
            className=""
          ></Column>

          <Column
            field="project.deadline"
            header="Deadline"
            body={ProjectDeadLine}
            sortable
            className=""
          ></Column>

          <Column
            field="project_progress_status.id"
            header="Status"
            sortable
            body={projectStatus}
            className=""
          ></Column>

          <Column
            field=""
            header="Project Specification"
            body={SpecificationTemplate}
            className=""
          ></Column>

          <Column
            field=""
            header="Action"
            body={ActionTemplate}
            className=""
          ></Column>
        </DataTable>
      )}

      <div className="mt-4">
        {progressData?.length > 0 && (
          <Pagination
            currentPage={currentPage}
            pageNumber={pageNumber}
            setCurrentPage={setCurrentPage}
            setSearchParams={setSearchParams}
            pageCount={pageCount}
            setViewData={setViewData}
            viewData={viewData}
            setPageNumber={setPageNumber}
          />
        )}
      </div>

      {<TeamCreate teamModal={teamModal} />}
      {<ProgressDetails detailsData={detailsData} />}
      {<AddMembers member={member} />}
      {<UpdateMember Updateteam={Updateteam} />}
    </div>
  );
};

export default ProjectProgress;

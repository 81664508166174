import React, { useState } from "react";
import { toast } from "react-toastify";
import Select from "react-select";
import Rodal from "rodal";
import { MainUrl } from "../../utils/helper";
import useData from "../../ContextApi/UseData/useData";
import Loader from "../Loader/Loader";

const CreateBonusLog = (modalData) => {
  const { token } = useData().user;
  const [isLoader, setIsLoader] = useState(false)
  const newData = modalData.data;
  const { visible, setVisible, isSearchable, isClearable, setUpdate, update } = newData;

  const users = modalData?.data?.allUser?.map((user) => {
    let obj = {};
    obj.label = user?.user_profile?.name;
    obj.value = user?.id;
    return obj;
  });

  const bonus = modalData?.data?.bonusType?.map((user) => {
    let obj = {};
    obj.label = user?.name;
    obj.value = user?.id;
    return obj;
  });

  const handleCreateBonus = (e) => {
    e.preventDefault();
    const form = e.target;
    const user_id = form.employee.value;
    const bonus_type_id = form.bonus.value;
    const updateData = {
      user_id,
      bonus_type_id,
    };
    if (!token) {
      return;
    }
    let updateNote = async () => {
      setIsLoader(true)
      const res = await fetch(`${MainUrl.url}/api/v1/bonus/log/all/10/0`, {
        method: "post",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
        body: JSON.stringify(updateData),
      });
      const resData = await res.json();
      if (res.ok) {
        toast.success("successfully updated");
        setVisible(false);
        setUpdate(!update);
        form.reset();
        setIsLoader(false)
      } else {
        toast.error(resData.message);
        setVisible(false);
        setIsLoader(false)
      }
    };
    updateNote();
  };
  if(isLoader){
    return <Loader/>
  }
  return (
    <div>
      <Rodal
        visible={visible}
        enterAnimation="flip"
        animation="fade"
        onClose={() => setVisible(!visible)}
        customStyles={{
          height: "auto",
          width: "40%",
          bottom: "auto",
          top: "10%",
          transform: "translateY(-50%)",
        }}
      >
        <h5 className="text-xl border-b py-3">Create New Bonus</h5>

        <div>
          <form onSubmit={handleCreateBonus} className="pt-4">
            <div className="flex flex-col ">
              <label>Select Employee</label>
              <Select
                className="basic-single"
                classNamePrefix="select"
                isClearable={isClearable}
                isSearchable={isSearchable}
                name="employee"
                options={users}
              />
            </div>

            <div className="pt-2 flex flex-col">
              <label>Select Bonus Type</label>
              <Select
                className="basic-single"
                classNamePrefix="select"
                isClearable={isClearable}
                isSearchable={isSearchable}
                name="bonus"
                options={bonus}
              />
            </div>
            <input
              className="cursor-pointer px-4 py-2 mt-4 bg-cyan-700 rounded-md text-white font-medium text-sm"
              type="submit"
              value="Submit"
            />
          </form>
        </div>
      </Rodal>
    </div>
  );
};

export default CreateBonusLog;

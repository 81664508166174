import { Menu, MenuButton, MenuItem } from "@szhsin/react-menu";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import React, { useEffect, useState } from "react";
import { AiFillEdit, AiOutlineDelete, AiOutlinePlus } from "react-icons/ai";
import { BsThreeDotsVertical } from "react-icons/bs";
import { toast } from "react-toastify";
import CreateModal from "./CreateModal";
import UpdateModal from "./UpdateModal";
import { MainUrl } from "../../utils/helper";
import useData from "../../ContextApi/UseData/useData";
import Loader from "../Loader/Loader";
import DeleteModal from "../AllEmployee/DeleteModal/DeleteModal";

const ProjectRole = () => {
  const [update, setUpdate] = useState(false);
  const [projectRole, setProjectRole] = useState(null);
  const [show, setShow] = useState(false);
  const [showUpdate, setShowUpdate] = useState(false);
  const [updateModaldata, setUpdateModalData] = useState(null);
  const [isLoader, setIsLoader] = useState(false);
  // Create Modal
  const CreateURL = `/project/roles`;
  const createTham = "Create A New Project Role";
  const CreatePlaceholder = "Project Role";
  const successMessage = "successfully Create A Project Role";
  // Update Modal
  const UpdateURL = "project/roles";
  const UpdateThem = "Update A New Project Role";
  const successUpdateMessage = "successfully Update A Project Role";
  const { token } = useData().user;
  const [showDelete, setShowDelete] = useState(false)
  const [selectData, setSelectData] = useState(null)

  useEffect(() => {
    if (!token) {
      return;
    }
    const fetchData = async () => {
      try {
        setIsLoader(true);
        const res = await fetch(`${MainUrl.url}/api/v1/project/roles`, {
          method: "get",
          headers: {
            Authorization: token,
          },
        });
        const data = await res.json();
        if (res.ok) {
          setProjectRole(data.data);
          setIsLoader(false);
        }
      } catch (error) {
        toast.error(error?.message);
        setIsLoader(false);
      }
    };
    fetchData();
  }, [update, token]);

  // const handleDelete = async (id, data) => {
  //   if (!token) {
  //     return;
  //   }
  //   const agree = window.confirm(`are you sure to delete ${data.name}`);
  //   if (agree) {
  //     try {
  //       const res = await fetch(`${MainUrl.url}/api/v1/project/roles/${id}`, {
  //         method: "delete",
  //         headers: {
  //           Authorization: token,
  //         },
  //       });
  //       if (res.ok) {
  //         toast.success("successfully deleted data");
  //         setUpdate(!update);
  //       }
  //     } catch (error) {
  //       toast.error(error.message);
  //     }
  //   }
  // };

  const projectRoleTemplate = (data) => {
    return <p className="text-lg font-medium">{data.name}</p>;
  };

  const ActionTemplate = (data) => {
    // const id = data?.id;
    // const name = data?.client?.name;
    return (
      <Menu
        direction="right"
        align="center"
        menuButton={
          <MenuButton className="px-4">
            <BsThreeDotsVertical />
          </MenuButton>
        }
        transition
      >
        <MenuItem
          className="hover:text-red-400"
          onClick={() => {
            setUpdateModalData(data);
            setShowUpdate(!showUpdate);
          }}
        >
          <span className="mr-2">
            <AiFillEdit />
          </span>
          UPdate
        </MenuItem>

        <MenuItem
          className="hover:text-red-400"
          onClick={() => {
            setSelectData(data);
            setShowDelete(!showDelete)
          }}
        >
          <span>
            <AiOutlineDelete className="mr-2" />
          </span>
          Delete
        </MenuItem>
      </Menu>
    );
  };

  const renderHeader = () => {
    return (
      <div className="grid grid-cols-12 justify-between">
        <div className="col-span-6">
          <p>Project Role</p>
        </div>
        <div className="col-span-6">
          <div className="flex items-end justify-end">
            <button
              onClick={() => setShow(!show)}
              className="flex items-center gap-x-2  px-2 py-2.5 bg-cyan-700 text-white rounded-md"
            >
              <span className="text-base font-semibold">
                <AiOutlinePlus />
              </span>
              <span className="text-base font-medium">Project Role</span>
            </button>
          </div>
        </div>
      </div>
    );
  };
  const header = renderHeader();

  const modaldata = {
    setShow,
    show,
    update,
    setUpdate,
    CreateURL,
    createTham,
    CreatePlaceholder,
    successMessage,
  };

  const updateData = {
    update,
    setUpdate,
    showUpdate,
    setShowUpdate,
    updateModaldata,
    setUpdateModalData,
    UpdateURL,
    UpdateThem,
    successUpdateMessage,
  };
  const DeleteUrl = "api/v1/project/roles";
  const DeleteData = {
    showDelete,
    setShowDelete,
    update,
    setUpdate,
    selectData,
    DeleteUrl,
  }
  return (
    <div className="border-top-none">
      {isLoader ? (
        <Loader />
      ) : (
        <DataTable
          paginator
          rows={5}
          rowsPerPageOptions={[5, 10, 25, 50]}
          size="small"
          header={header}
          value={projectRole}
          selectionMode="single"
          dataKey="id"
          tableStyle={{ Width: "100%" }}
        >
          <Column
            field="provident_fund_account?.user?.office_id"
            header="Project Role"
            body={projectRoleTemplate}
            sortable
          ></Column>

          <Column
            field="provident_fund_account?.user?.office_id"
            header="Action"
            body={ActionTemplate}
          ></Column>
        </DataTable>
      )}

      {<CreateModal modaldata={modaldata} />}
      {<UpdateModal updateData={updateData} />}
      {<DeleteModal DeleteData={DeleteData}/>}
    </div>
  );
};

export default ProjectRole;

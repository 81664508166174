import React from 'react'
import Breadcrumbs from '../Breadcrumbs/Breadcrumbs'
import ProjectRole from '../ProjectManagement/ProjectRole'

const ProjectRolePage = () => {
    const data = [
        {
          name: "Project Role",
        },
      ];
  return (
    <>
    <Breadcrumbs data={data}/>
    <ProjectRole/>
    </>
  )
}

export default ProjectRolePage
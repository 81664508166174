import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import React, { useState } from "react";
import { MainUrl } from "../../utils/helper";
import useData from "../../ContextApi/UseData/useData";
import { toast } from "react-toastify";

const DeductionUpdateModal = ({ updateData }) => {
  
    const {
    showUpdate,
    setShowUpdate,
    selectUpdate,
    update,
    setUpdate,
    deductionType,
  } = updateData;

  const { token } = useData().user;

  const [deductionTypeId, setDeductionTypeId] = useState(null);

  const handleUpdateDeduction = (e) => {
    e.preventDefault();
    const form = e.target;
    const deductionAmount = form.amount.value;
    const updateData = {
      deduction_type_id: deductionTypeId
        ? deductionTypeId
        : selectUpdate?.deduction_type?.id,
      total_amount: deductionAmount,
      user_id: selectUpdate.user.id,
    };

    let updateNote = async () => {
      const res = await fetch(
        `${MainUrl.url}/api/v1/deduction_amount/one/${selectUpdate.id}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
          body: JSON.stringify(updateData),
        }
      );
      const resData = await res.json();
      if (res.ok) {
        setUpdate(!update);
        toast.success("successfully updated");
        setShowUpdate(!showUpdate);
        
      } else {
        toast.warn(resData.message);
        setShowUpdate(!showUpdate);
      }
    };

    updateNote();
  };

  return (
    <div>
      <Dialog
        header="Update Deduction"
        visible={showUpdate}
        style={{ width: "30vw" }}
        breakpoints={{ "960px": "75vw", "641px": "100vw" }}
        draggable={false}
        resizable={false}
        onHide={() => setShowUpdate(!showUpdate)}
      >
        <div className="m-0 myCalender">
          <form onSubmit={handleUpdateDeduction}>
            <div className="flex flex-col gap-y-1 pt-1 w-full">
              <label htmlFor=" ">Employee Name</label>
              <InputText
                type="text"
                readOnly
                className="p-inputtext-sm"
                value={selectUpdate?.user?.user_profile?.name}
                placeholder="Employee Name"
              />
            </div>

            <div className="w-full">
              <label className="">Deduction Type</label>
              <Dropdown
                value={deductionTypeId}
                onChange={(e) => setDeductionTypeId(e.value)}
                options={deductionType}
                optionLabel="label"
                placeholder={selectUpdate?.deduction_type?.name}
                filter
                className="w-full md:w-14rem"
              />
            </div>
            <div className="flex flex-col gap-y-1 pt-1 w-full">
              <label htmlFor=" ">Total Amount</label>
              <InputText
                type="text"
                defaultValue={selectUpdate?.total_amount}
                className="p-inputtext-sm "
                placeholder="Deduction Amount"
                name="amount"
              />
            </div>
            <input
              type="submit"
              value="submit"
              className="cursor-pointer mt-6 px-4 py-2 bg-cyan-700 rounded-md text-white font-medium text-base"
            />
          </form>
        </div>
      </Dialog>
    </div>
  );
};

export default DeductionUpdateModal;

import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import React, { useState } from "react";
import useData from "../../ContextApi/UseData/useData";
import { MainUrl } from "../../utils/helper";
import { toast } from "react-toastify";

const CreateSpecialAllowance = ({ modalData }) => {
  const { showModal, setShowModal, update, setUpdate } = modalData;
  const { modifyAllUser, token } = useData().user;
  const [selectUser, setSelectUser] = useState(null);
  const [changeValue, setChangeValue] = useState({});

  const setValue = (e) => {
    setChangeValue({
      ...changeValue,
      [e.target.name]: e.target.value,
    });
  };

  console.log("change", changeValue);

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = e.target;
    const data = {
      user_id: selectUser,
      duration: changeValue.duration,
      amount: changeValue.amount,
    };

    console.log("data", data);

    const postData = async () => {
      let res = await fetch(
        `${MainUrl.url}/api/v1/special_allowance/give_allowance`,
        {
          method: "post",
          headers: {
            "Content-type": "application/json",
            Authorization: token,
          },
          body: JSON.stringify(data),
        }
      );
      if (res.ok) {
        toast.success("successfully updated");
        setShowModal(!showModal);
        setUpdate(!update);
        setSelectUser(null);
        form.reset();
      } else {
        const resData = await res.json();
        toast.error(resData.message);
        setShowModal(!showModal);
        form.reset();
      }
    };
    postData();
  };

  return (
    <Dialog
      header="Create Transport Allowance"
      visible={showModal}
      onHide={() => {
        setShowModal(!showModal);
        setSelectUser(null);
      }}
      style={{ width: "30vw" }}
      breakpoints={{ "960px": "75vw", "641px": "100vw" }}
      draggable={false}
      resizable={false}
    >
      <form onSubmit={handleSubmit} className="common">
        <div className="flex flex-col mb-2">
          <label className="pb-1 ps-1"> Select User</label>
          <Dropdown
            value={selectUser}
            onChange={(e) => setSelectUser(e.value)}
            options={modifyAllUser}
            optionLabel="label"
            placeholder="Select a User"
            filter
          />
        </div>

        <div className="flex flex-col mb-2">
          <label>Allowance Duration</label>
          <input
            type="text"
            className="border-2 ps-2 py-1 rounded border-gray-200 focus-visible:outline-none w-full"
            onChange={(e) => setValue(e)}
            //   defaultValue={selectedData?.allowance_per_km && selectedData?.allowance_per_km}
            placeholder="Allowance Duration"
            name="duration"
          />
        </div>

        <div className="flex flex-col mb-2">
          <label className="pb-1 ps-1"> Allowance Amount</label>
          <input
            type="text"
            className="border-2 ps-2 py-1 rounded border-gray-200 focus-visible:outline-none w-full"
            onChange={(e) => setValue(e)}
            //   defaultValue={selectedData?.allowance_per_km && selectedData?.allowance_per_km}
            placeholder="Amount"
            name="amount"
          />
        </div>
        <input
          className="cursor-pointer px-4 py-1 rounded-md bg-cyan-700  text-white text-base font-medium "
          type="submit"
          value="Submit"
        />
      </form>
    </Dialog>
  );
};

export default CreateSpecialAllowance;

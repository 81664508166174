import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import Noimg from "../../Assets/noProfile.png";
import { toast } from "react-toastify";
import Select from "react-select";
import { MainUrl } from "../../utils/helper";
import useData from "../../ContextApi/UseData/useData";

const CreateUserProfile = ({ userId }) => {
  const [bloodName, setBloodName] = useState("")
  const [selectMarital, setSelectMarital] = useState("");
  const [relationShip, setRelationShip] = useState(null);
  const [relationId, setRelationId] = useState("")
  const [imgUpload, setImgUpload] = useState();
  const [isClearable ] = useState(true);
  const [isSearchable] = useState(true);
  const {token} = useData().user
  // const navigate = useNavigate()

  // image update
  const imgChange = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      setImgUpload(e.target.files[0]);
    }
  };


  const BloodGroup = (e)=>{
    if(e?.value){
      setBloodName(e?.value)
    }
 
  }

  const MaritalStatus = (e)=>{
    if(e?.value){
      setSelectMarital(e?.value)
    }
  
  }
  const Relation = (e)=>{
    if(e?.value){
      setRelationId(e?.value)
    }
  }

  // get emergency person
  useEffect(() => {
    if(!token){
      return
    }
    const fetchData = async () => {
      const res = await fetch(
        `${MainUrl.url}/api/v1/user/emergency`,
        {
          method: "get",
          headers:{
            Authorization:token
          }
         
        }
      );
      const data = await res.json();
      if (res.ok) {
        setRelationShip(data?.data);
      } else {
        toast.error(data?.message);
        console.error(data?.message);
      }
    };
    fetchData();
  }, [token]);

  const bloodGroup = [
    { label: "A+", value: "A+" },
    { label: "A-", value: "A-" },
    { label: "B+", value: "B+" },
    { label: "AB+", value: "AB+" },
    { label: "Ab-", value: "AB-" },
    { label: "O+", value: "O+" },
    { label: "O-", value: "A-" },
  ];

  const maritalStatus = [
    { label: "Single", value: "0" },
    { label: "married", value: "1" },
  ];

  // modify relationShip Data
  const relation = relationShip?.map((user) => {
    let obj = {};
    obj.label = user?.name;
    obj.value = user?.id;
    return obj;
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    if(!token){
      return
    }
    data.user_id = userId;
    data.blood_group = bloodName;
    data.marital_status = selectMarital;
    data.emergency_relationship_id= relationId;

    let formData = new FormData();
    formData.append("user_id", data.user_id);
    formData.append("name", data.name);
    formData.append("phone_no", data.phone_no);
    formData.append("blood_group", data.blood_group);
    formData.append("present_address", data.present_address);
      if(data.permanent_address.length) formData.append("permanent_address", data.permanent_address)
    formData.append("nid_no", data.nid_no);
    formData.append("fathers_name", data.fathers_name);
    formData.append("mothers_name", data.mothers_name);
    formData.append("marital_status", data.marital_status);
    if(data.profile_photo) formData.append("profile_photo", data.profile_photo[0]);
    if(data.emergency_contact.length ) formData.append("emergency_contact", data.emergency_contact)
    if(data.emergency_phone.length) formData.append("emergency_phone", data.emergency_phone);
    if(data.emergency_relationship_id) formData.append("emergency_relationship_id",data.emergency_relationship_id);

    // Create user Profile
    const CreateProfile = async () => {
      try {
        const res = await fetch(
          `${MainUrl.url}/api/v1/user/profile/${userId}`,
          {
            method: "Post",
            headers: {
              // "Content-Type": "Application/json",
              Authorization: token
            },
            body: formData,
          }
        );
        // const resData = await res.json();
        if (res.ok) {
          toast.success("Successfully Create A Profile");
          // navigate("/allUser")
        }
      } catch (err) {
        toast.error(err.message);
        console.error(err.message);
      }
    };
    CreateProfile();
  };

  return (
    <div
      className={`${
        !userId && "blur"
      }  mb-24 text-gray-600 rounded shadow-lg bg-white`}
    >
      <div className="px-4 pt-4 pb-4 md:px-8 md:pt-8">
        <h1 className="mb-1 text-xl font-bold">Create User Profile</h1>
        <span className="text-base text-gray-500">
          Request User Profile details down below.
        </span>
      </div>
      <div>
        <div className="px-4 pt-3 pb-4 w-full md:px-8 ">
          <form
            action=""
            onSubmit={handleSubmit(onSubmit)}
            className="CreateUser"
          >
            <div className="w-40 h-40 rounded-full relative mb-3">
              <img
                src={`${
                  imgUpload ? URL.createObjectURL(imgUpload) : `${Noimg}`
                }`}
                alt=""
                className=" h-full w-full rounded-full"
              />
              <input
                type="file"
                className={`absolute top-0 left-0 w-48 h-full rounded-full opacity-0`}
                {...register("profile_photo")}
                accept={`"image/*"`}
                onChange={imgChange}
              />
            </div>

            <div className="flex flex-col mb-3 md:flex-row md:items-center w-full">
              <span className="w-24 md:w-28 lg:w-[172px] text-base">
                Name :
              </span>
              <div className="w-full">
                <input
                  className="border py-1 pl-3 pr-3 w-full  rounded-md focus:outline-none dark:text-black placeholder:text-base"
                  type="text"
                  placeholder="Name"
                  {...register("name", {
                    required: "This field is required",
                    message: "Only text ",
                  })}
                />
                {errors.name && (
                  <p className="text-red-500 pl-0.5 text-[12px] pt-0.5">
                    This field is required *
                  </p>
                )}
              </div>
            </div>

            <div className="flex gap-x-2 justify-center w-full lg:flex-row">
              {/* Phone Number */}
              <div className="flex flex-col mb-3 md:flex-row md:items-center w-full">
                <span className="w-24 md:w-28 lg:w-[200px] text-base">
                  Phone Number :
                </span>
                <div className="w-full">
                  <input
                    className="border py-1 ps-3 w-full  rounded-md focus:outline-none dark:text-black placeholder:text-base"
                    type="text"
                    placeholder="Phone Number Example: +8801*****11"
                    {...register("phone_no", {
                      required: "This field is required *",
                      pattern: {
                        value: /(^(\+8801))[|3-9]{1}(\d){8}$/i,
                        message: "Invalid Phone Number",
                      },
                    })}
                  />
                  {errors.phone_no && (
                    <p className="text-red-500 pl-0.5 text-[12px] pt-0.5">
                      {errors.phone_no?.message}
                    </p>
                  )}
                </div>
              </div>

              {/* NID Number */}
              <div className="flex mb-3 md:flex-row md:items-center w-full">
                <span className="w-24 md:w-28 lg:w-[200px] text-base">
                  NID Number :
                </span>
                <div className="w-full">
                  <input
                    className="border py-1 ps-3 w-full  rounded-md focus:outline-none dark:text-black placeholder:text-base"
                    type="number"
                    placeholder="Nid Number"
                    {...register("nid_no", {
                      required: "This field is required",
                      pattern: {
                        value: /^0|[1-9]\d*$/i,
                        message: "Only Number Accepted",
                      },
                    })}
                  />
                  {errors.nid_no && (
                    <p className="text-red-500 pl-0.5 text-[12px] pt-0.5">
                      {errors.nid_no?.message}
                    </p>
                  )}
                </div>
              </div>
            </div>

            <div className="flex gap-x-2 justify-center w-full lg:flex-row">
              {/* Present Address */}
              <div className="flex flex-col mb-3 md:flex-row md:items-center w-full">
                <span className="w-24 md:w-28 lg:w-[200px] text-base">
                  Present Address :
                </span>
                <div className="w-full">
                  <input
                    className="border py-1 ps-3 w-full  rounded-md focus:outline-none dark:text-black placeholder:text-base"
                    type="text"
                    placeholder="Present Address"
                    {...register("present_address", {
                      required: "This field is required *",
                    })}
                  />
                  {errors.present_address && (
                    <p className="text-red-500 pl-0.5 text-[12px] pt-0.5">
                      {errors.present_address?.message}
                    </p>
                  )}
                </div>
              </div>

              {/* Permanent Address */}
              <div className="flex mb-3 md:flex-row md:items-center w-full">
                <span className="w-24 md:w-28 lg:w-[200px] text-base">
                  Permanent Address :
                </span>
                <div className="w-full">
                  <input
                    className="border py-1 ps-3 w-full  rounded-md focus:outline-none dark:text-black placeholder:text-base"
                    type="text"
                    placeholder="Permanent Address"
                    {...register("permanent_address", {})}
                  />
                  {errors.permanent_address && (
                    <p className="text-red-500 pl-0.5 text-[12px] pt-0.5">
                      {errors.permanent_address?.message}
                    </p>
                  )}
                </div>
              </div>
            </div>

            <div className="flex gap-x-2 justify-center w-full lg:flex-row">
              {/* Father Name */}
              <div className="flex flex-col mb-3 md:flex-row md:items-center w-full">
                <span className="w-24 md:w-28 lg:w-[200px] text-base">
                  Father Name :
                </span>
                <div className="w-full">
                  <input
                    className="border py-1 ps-3 w-full  rounded-md focus:outline-none dark:text-black placeholder:text-base"
                    type="text"
                    placeholder="Father Name"
                    {...register("fathers_name", {
                      required: "This field is required *",
                    })}
                  />
                  {errors.fathers_name && (
                    <p className="text-red-500 pl-0.5 text-[12px] pt-0.5">
                      {errors.fathers_name?.message}
                    </p>
                  )}
                </div>
              </div>

              {/* Permanent Address */}
              <div className="flex mb-3 md:flex-row md:items-center w-full">
                <span className="w-24 md:w-28 lg:w-[200px] text-base">
                  Mother Name :
                </span>
                <div className="w-full">
                  <input
                    className="border py-1 ps-3 w-full  rounded-md focus:outline-none dark:text-black placeholder:text-base"
                    type="text"
                    placeholder="Mother Name"
                    {...register("mothers_name", {
                      required: "This field is required *",
                    })}
                  />
                  {errors.mothers_name && (
                    <p className="text-red-500 pl-0.5 text-[12px] pt-0.5">
                      {errors.mothers_name?.message}
                    </p>
                  )}
                </div>
              </div>
            </div>

            <div className="flex gap-x-2 justify-center w-full lg:flex-row">
              {/* Blood Group:*/}
              <div className="flex flex-col mb-3 md:flex-row md:items-center w-full">
                <span className="w-24 md:w-28 lg:w-[200px] text-base">
                  Blood Group:
                </span>
                <div className="w-full">
                  <span>
                    <Select
                      className="basic-single w-full"
                      classNamePrefix="select"
                      isClearable={isClearable}
                      isSearchable={isSearchable}
                      // name="blood_group"
                      onChange={BloodGroup}
                      options={bloodGroup}
                    />
                  </span>
                  {errors.blood_group && (
                    <p className="text-red-500 pl-0.5 text-[12px] pt-0.5">
                      {errors.blood_group?.message}
                    </p>
                  )}
                </div>
              </div>

              {/* Marital Status */}
              <div className="flex mb-3 md:flex-row md:items-center w-full">
                <span className="w-24 md:w-28 lg:w-[200px] text-base">
                  Marital Status :
                </span>
                <div className="w-full">
                  <Select
                    className="basic-single w-full"
                    classNamePrefix="select"
                    isClearable={isClearable}
                    isSearchable={isSearchable}
                    onChange={MaritalStatus}
                    options={maritalStatus}
                    required
                  />

                  {errors.marital_status && (
                    <p className="text-red-500 pl-0.5 text-[12px] pt-0.5">
                      {errors.marital_status?.message}
                    </p>
                  )}
                </div>
              </div>
            </div>

            <p className="text-base mt-3">Emergency Contact</p>
            <div className="flex gap-x-2 justify-center w-full lg:flex-row mt-5">
              {/* Name */}
              <div className="flex flex-col mb-3 md:flex-row md:items-center w-full">
                <span className="w-24 md:w-28 lg:w-[200px] text-base">
                  Name :
                </span>
                <div className="w-full">
                  <input
                    className="border py-1 pl-3 pr-3 w-full  rounded-md focus:outline-none dark:text-black placeholder:text-base"
                    type="text"
                    placeholder="Name"
                    {...register("emergency_contact")}
                  />
                  {errors.emergency_contact && (
                    <p className="text-red-500 pl-0.5 text-[12px] pt-0.5">
                      {errors.emergency_contact?.message}
                    </p>
                  )}
                </div>
              </div>

              {/* Phone Number */}
              <div className="flex flex-col mb-3 md:flex-row md:items-center w-full">
                <span className="w-24 md:w-28 lg:w-[200px] text-base">
                  Phone Number :
                </span>
                <div className="w-full">
                  <input
                    className="border py-1 ps-3 w-full  rounded-md focus:outline-none dark:text-black placeholder:text-base"
                    type="text"
                    placeholder="Phone Number Example: +8801*****11"
                    {...register("emergency_phone", {
                      pattern: {
                        value: /(^(\+8801))[|3-9]{1}(\d){8}$/i,
                        message: "Invalid Phone Number",
                      },
                    })}
                  />
                  {errors.emergency_phone && (
                    <p className="text-red-500 pl-0.5 text-[12px] pt-0.5">
                      {errors.emergency_phone?.message}
                    </p>
                  )}
                </div>
              </div>
            </div>

            <div className="w-1/2">
              <div className=" flex flex-col mb-3 md:flex-row md:items-center w-full">
                <span className="w-24 md:w-28 lg:w-[200px] text-base">
                  RelationShip:
                </span>
                <div className="w-full">
                  <Select
                    className="basic-single w-full"
                    classNamePrefix="select"
                    isClearable={isClearable}
                    isSearchable={isSearchable}
                    onChange={Relation}
                    options={relation}
                  />
                </div>
              </div>
            </div>
            <input
              type="submit"
              className="cursor-pointer mt-5 py-2 px-6 rounded-md bg-cyan-700  text-white font-medium text-sm"
            />
          </form>
        </div>
      </div>
    </div>
  );
};

export default CreateUserProfile;

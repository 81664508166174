import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import React, { useEffect, useState } from "react";
import { BsCheck2All } from "react-icons/bs";
import { IoIosRefresh } from "react-icons/io";
import { toast } from "react-toastify";
import useData from "../../ContextApi/UseData/useData";
import { MainUrl } from "../../utils/helper";
import Loader from "../Loader/Loader";
import { useLocation, useSearchParams } from "react-router-dom";
import Pagination from "../Pagination/Pagination";

const AllPaySlip = () => {
  const [totalData, setTotalData] = useState(null);
  const [payAllSlip, setPayAllSlip] = useState(null);
  const [update, setUpdate] = useState(false);
  const [filterData, setFilterData] = useState({
    office_id: "",
  });
  const { token } = useData().user;
  const [isLoader, setIsLoader] = useState(false);
  const [changeValue, setChangeValue] = useState({});

  const [viewData, setViewData] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchParams, setSearchParams] = useSearchParams();
  const [pageCount, setPageCount] = useState();
  const location = useLocation();

  // GET TABLE DATA
  useEffect(() => {
    if (!token) {
      return;
    }

    const page = location?.search?.split("=")[1];
    if (page) {
      setPageNumber(+page);
      setCurrentPage(+page);
    }
    const fetchData = async () => {
      try {
        setIsLoader(true);
        const res = await fetch(
          `${
            MainUrl.url
          }/api/v1/provident_fund/payslips/all/${viewData}/${pageNumber}?${
            filterData.office_id && `office_id=${filterData.office_id}`
          }`,
          {
            method: "get",
            headers: {
              Authorization: token,
            },
          }
        );
        const data = await res.json();
        if (res.ok) {
          setPayAllSlip(data.data?.results);
          setTotalData(data.data?.totalCount);
          setIsLoader(false);
        }
      } catch (error) {
        toast.error(error?.message);
        setIsLoader(false);
      }
    };
    fetchData();
  }, [
    update,
    token,
    pageNumber,
    viewData,
    filterData,
    location,
    searchParams,
    currentPage,
  ]);

  useEffect(() => {
    const pages = Math.ceil(totalData / viewData);
    setPageCount(pages);
  }, [totalData, viewData]);

  const handleUpdate = (id) => {
    if (!token) {
      return;
    }
    const upsDate = async () => {
      try {
        const res = await fetch(
          `${MainUrl.url}/api/v1/provident_fund/payslip/approve/${id}`,
          {
            method: "get",
            headers: {
              Authorization: token,
            },
          }
        );

        if (res.ok) {
          toast.success("SuccessFully Update");
          setUpdate(!update);
        }
      } catch (error) {
        toast.error(error.message);
      }
    };
    upsDate();
  };

  // all template start
  const UserIdTempalte = (data) => {
    return (
      <p className="text-lg font-medium">
        {data?.provident_fund_account?.user?.office_id}
      </p>
    );
  };

  const statusTemplate = (data) => {
    return <p className="text-lg font-medium">{data.withdraw_status?.name}</p>;
  };

  const AmountTemplate = (data) => {
    return <p className="text-lg font-medium">{data?.withdrawn_amount}</p>;
  };

  const ApprovalTemplate = (data) => {
    return (
      <p className="text-lg font-medium">
        {data?.approval_status === 0 ? (
          <span className="text-yellow-400">Pending</span>
        ) : (
          <span className="text-green-400">Acknowledged</span>
        )}
      </p>
    );
  };

  const DateTemplate = (data) => {
    return <p className="text-lg font-medium">{data?.date_of_withdrawal}</p>;
  };

  const ActionTemplate = (data) => {
    const id = data?.id;
    return data.approval_status !== 0 ? (
      <p
        disabled
        className="text-center gap-x-2 px-2 bg-slate-300 text-base font-medium text-white rounded-md disabled:opacity-75 cursor-not-allowed"
      >
        Acknowledge
      </p>
    ) : (
      <p
        onClick={() => handleUpdate(id)}
        className="flex items-center gap-x-2 bg-yellow-300 px-2 text-base font-medium text-gray-600 rounded-md"
      >
        <span>
          <BsCheck2All />
        </span>
        Acknowledge
      </p>
    );
  };

  // all template end

  const renderHeader = () => {
    const setValue = (e) => {
      setChangeValue({
        ...changeValue,
        [e?.target?.name]: e?.target?.value,
      });
    };

    console.log("chan", changeValue);

    const handleSearch = async (e) => {
      e.preventDefault();
      const data = {
        office_id: changeValue.office_id ? changeValue.office_id : "",
      };
      setFilterData(data);
    };

    const handleFresh = () => {
      setUpdate(!update);
      setChangeValue({
        office_id: "",
      });
      setFilterData({
        office_id: "",
      });
    };

    return (
      <div className="grid grid-cols-12 items-center justify-between">
        <div className="col-span-8">
          <div className="grid grid-cols-12 gap-x-2 items-center">
            <div className="col-span-10">
              <form
                className="flex gap-x-3  w-full myInput"
                onSubmit={handleSearch}
              >
                <div className="w-full">
                  <input
                    onChange={(e) => setValue(e)}
                    className="ps-3 py-1.5 border border-gray-300 rounded-lg inputShadow w-full"
                    placeholder="Search by office ID"
                    name="office_id"
                    value={changeValue.office_id}
                  />
                </div>
                <div className="">
                  <input
                    type="submit"
                    value="Search"
                    className="cursor-pointer px-4 py-2 w-full bg-cyan-700 rounded-md text-white font-medium text-sm submit"
                  />
                </div>
              </form>
            </div>
            <div className="col-span-2 gap-x-2">
              <div>
                <span
                  onClick={handleFresh}
                  className="bg-cyan-700 text-white h-8 w-8 flex items-center justify-center rounded-full font-medium cursor-pointer"
                >
                  <IoIosRefresh />
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  const header = renderHeader();

  return (
    <div className="bg-white pb-4 mb-4">
      {isLoader ? (
        <Loader />
      ) : (
        <DataTable
          size="small"
          header={header}
          value={payAllSlip}
          selectionMode="single"
          dataKey="id"
          tableStyle={{ Width: "100%" }}
        >
          <Column
            field="provident_fund_account?.user?.office_id"
            header="User Id"
            body={UserIdTempalte}
            sortable
            className=""
          ></Column>
          <Column
            field="withdraw_status?.name"
            header="Status"
            sortable
            body={statusTemplate}
            className=""
          ></Column>
          <Column
            field="withdrawn_amount"
            header="Amount"
            sortable
            body={AmountTemplate}
            className=""
          ></Column>
          <Column
            field="approval_status"
            header="Approval"
            body={ApprovalTemplate}
            sortable
            className=""
          ></Column>
          <Column
            field="date_of_withdrawal"
            header="Date"
            body={DateTemplate}
            sortable
            className=""
          ></Column>
          <Column
            field=""
            header="Action"
            body={ActionTemplate}
            className="w-[10%]"
          ></Column>
        </DataTable>
      )}

      <div className="mt-4">
        {payAllSlip?.length > 0 && (
          <Pagination
            currentPage={currentPage}
            pageNumber={pageNumber}
            setCurrentPage={setCurrentPage}
            setSearchParams={setSearchParams}
            pageCount={pageCount}
            setViewData={setViewData}
            viewData={viewData}
            setPageNumber={setPageNumber}
          />
        )}
      </div>
    </div>
  );
};

export default AllPaySlip;
